import React, { CSSProperties, ReactNode, useEffect, useRef, useState } from "react";
import { throttle } from "lodash";

import WideProductInfo from "./components/WideProductInfo";
import BasicProductInfo from "./components/BasicProductInfo";
import { BrandName, LikeButton, LikeCount, PriceInfo, ProductName } from "./components/atoms";
import { BasicCard, WideCard } from "./types";
import { CardStyle } from "./style";

type CardProps = {
  className?: string;
  image: ReactNode;
  onClickCard?: () => void;
  style?: CSSProperties;
} & (BasicCard | WideCard);

/**
 * <pre>
 * 카드(Cards)란 상품정보를 전달하기 위해 사용
 * 쓰임새에 따라 이미지(Image), 정보(Info)등을 조합
 * 상품 정보를 명확하게 전달해 이후 페이지 이동을 유도
 *
 * type 은 wide 와 basic 타입이 있으며 default 로 basic 타입을 가짐
 * 이미지는 wide 타입의 경우 3:2 비율을 가지며 basic 타입은 1:1 비율을 가짐
 * 이미지는 next/image 를 사용하기 위해 ReactNode 를 받음
 * next/image 사용 시 layout="fill", objectFit="cover" 속성을 사용해야 크롭된 이미지를 렌더링 할 수 있음
 * </pre>
 *
 * @param props
 * @constructor
 */
const Card = (props: CardProps): JSX.Element => {
  const { className, image, onClickCard, style, ...info } = props;
  const [imageHeight, setImageHeight] = useState(0);
  const imageRef = useRef<HTMLDivElement>(null);

  const calcImageHeightByWidth = (width: number, type?: "basic" | "wide"): number => {
    switch (type) {
      case "wide":
        return (width * 2) / 3;
      case "basic":
      default:
        return width;
    }
  };

  useEffect(() => {
    const onResize = throttle(() => {
      if (!imageRef.current) return;

      const imageWidth = imageRef.current.offsetWidth;
      setImageHeight(calcImageHeightByWidth(imageWidth, info.type));
    }, 200);

    onResize();
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [image, info.type]);

  const renderProductInfoByType = (productInfo: BasicCard | WideCard): JSX.Element => {
    switch (productInfo.type) {
      case "wide":
        return <WideProductInfo {...productInfo} />;
      case "basic":
      default:
        return <BasicProductInfo {...productInfo} />;
    }
  };

  return (
    <CardStyle
      className={className}
      imageHeight={imageHeight}
      onClick={() => onClickCard && onClickCard()}
      style={style}
    >
      <div className="card-image">
        <div ref={imageRef}>{image}</div>
      </div>
      <div className="card-info">{renderProductInfoByType(info)}</div>
    </CardStyle>
  );
};

Card.BrandName = BrandName;
Card.ProductName = ProductName;
Card.PriceInfo = PriceInfo;
Card.LikeCount = LikeCount;
Card.LikeButton = LikeButton;

export default Card;
