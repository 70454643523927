import React, { PropsWithChildren } from "react";
import { ValueOf } from "@amondz/types";
import { TAB_ACTION_TYPE, TAB_SIZE, TAB_TYPE } from "./constants";
import ScrollTab from "./ScrollTab";
import SwipeTab from "./SwipeTab";
import { useScrollOverTabStatus } from "./hooks/useScrollOverTabStatus";
import { TabsWrapperStyle } from "./style";

export interface ScrollTabHeaderColorProps {
  activeColor?: string;
  normalColor?: string;
  backgroundColor?: string;
}

interface CommonTabProps extends ScrollTabHeaderColorProps {
  size?: ValueOf<typeof TAB_SIZE>;
  type: ValueOf<typeof TAB_TYPE>;
  list: { title: string; component: JSX.Element }[];
}

interface ScrollTabProps extends CommonTabProps {
  actionType: typeof TAB_ACTION_TYPE.SCROLL;
  currentActive: number | null;
  onChangeTab: (tabId: number | null) => void;
}

interface SwipeTabProps extends CommonTabProps {
  actionType: typeof TAB_ACTION_TYPE.SWIPE;
  currentActive: number;
  onChangeTab: (tabId: number) => void;
}

interface NormalTabProps extends CommonTabProps {
  actionType: typeof TAB_ACTION_TYPE.NORMAL;
  currentActive: number;
  onChangeTab: (tabId: number) => void;
}

export type TabsProps = ScrollTabProps | SwipeTabProps | NormalTabProps;

const Tabs = (props: PropsWithChildren<TabsProps>): React.JSX.Element => {
  const { tabRef, isScrollOverTab } = useScrollOverTabStatus();

  const tabFactory = (props: TabsProps): React.JSX.Element => {
    switch (props.actionType) {
      case TAB_ACTION_TYPE.NORMAL:
        return <SwipeTab {...props} enableSwipe={false} />;
      case TAB_ACTION_TYPE.SWIPE:
        return <SwipeTab {...props} />;
      case TAB_ACTION_TYPE.SCROLL:
        return <ScrollTab {...props} />;
    }
  };

  return (
    <TabsWrapperStyle ref={tabRef} isScrollOverTab={isScrollOverTab}>
      {tabFactory(props)}
    </TabsWrapperStyle>
  );
};

export default Tabs;
