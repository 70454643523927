/* tslint:disable */
/* eslint-disable */
/**
 * AMONDZ-USER-BACKEND-V2
 * AMONDZ-USER-BACKEND-V2 API 문서입니다.
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface HomeTabsFindAllResponseBodyHomeTabDto
 */
export interface HomeTabsFindAllResponseBodyHomeTabDto {
  /**
   * 홈 탭 식별 id
   * @type {number}
   * @memberof HomeTabsFindAllResponseBodyHomeTabDto
   */
  id: number;
  /**
   * 홈 탭 링크 타입
   * @type {string}
   * @memberof HomeTabsFindAllResponseBodyHomeTabDto
   */
  linkType: HomeTabsFindAllResponseBodyHomeTabDtoLinkTypeEnum;
  /**
   * 홈 탭 링크 id
   * @type {number}
   * @memberof HomeTabsFindAllResponseBodyHomeTabDto
   */
  linkId: number;
  /**
   * 홈 탭 정렬 순서
   * @type {number}
   * @memberof HomeTabsFindAllResponseBodyHomeTabDto
   */
  no: number;
  /**
   * 홈 탭 제목
   * @type {string}
   * @memberof HomeTabsFindAllResponseBodyHomeTabDto
   */
  title: string;
}

export const HomeTabsFindAllResponseBodyHomeTabDtoLinkTypeEnum = {
  Main: "main",
  SubPage: "subPage",
} as const;

export type HomeTabsFindAllResponseBodyHomeTabDtoLinkTypeEnum =
  (typeof HomeTabsFindAllResponseBodyHomeTabDtoLinkTypeEnum)[keyof typeof HomeTabsFindAllResponseBodyHomeTabDtoLinkTypeEnum];
