export const WIDGET = {
  PRODUCT: 0,
  BRAND: 1,
  EVENT: 2,
  BANNER: 3,
  TIME_DEAL: 4,
  VIDEO: 901,
} as const;

export const WIDGET_NAME = {
  [WIDGET.PRODUCT]: "상품",
  [WIDGET.BRAND]: "브랜드",
  [WIDGET.EVENT]: "기획전",
  [WIDGET.BANNER]: "배너",
  [WIDGET.TIME_DEAL]: "타임딜",
  [WIDGET.VIDEO]: "숏폼",
} as const;

export const WIDGET_LAYOUT_TYPE = {
  PRODUCT: {
    GRID: {
      _2x2: 0,
      _3x2: 1,
    },
    SWIPE: {
      BIG: 300,
      SMALL: 301,
    },
    GALLERY: 400,
  },
  BRAND: {
    GRID: {
      _1x4: 0,
      _2x2: 1,
    },
    SCROLL: {
      MARGIN_YES: 100,
      MARGIN_NO: 101,
    },
    SWIPE: 200,
  } as const,
  EVENT: {
    SWIPE: 2,
  },
  BANNER: {
    MARGIN_YES: 0,
    MARGIN_NO: 1,
    SWIPE_BIG: 2,
    SWIPE_SMALL: 3,
    GRID_1X2: 100,
    GRID_2X2: 101,
    SCROLL: 200,
  },
};
