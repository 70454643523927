import React from "react";

const DefaultAvatarImg = () => {
  return (
    <svg viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <rect fill="#F1F1F1" fillRule="nonzero" width="72" height="72" rx="8" />
        <path d="M24 24h24v24H24z" />
        <path
          d="M43.127 35.498c0-4.065-3.066-6.996-7.127-6.996-4.06 0-7.127 2.931-7.127 6.996 0 4.07 3.066 7 7.127 7 4.06 0 7.127-2.93 7.127-7zM48 24.564v21.867h-4.873v-3.145C41.413 45.603 38.75 47 35.235 47 29.099 47 24 41.97 24 35.498 24 28.984 29.099 24 35.235 24c3.516 0 6.178 1.398 7.892 3.673v-3.109H48z"
          fill="#FFF"
        />
      </g>
    </svg>
  );
};

export default DefaultAvatarImg;
