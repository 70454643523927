import { createReducer } from "typesafe-actions";
import { produce } from "immer";

import { STATUS_CODE_COMMON } from "@constants/status/code/statusCodeCommon";
import { asyncState } from "@lib/utility/reducerUtils";
import { REFERRAL_IMG_TYPE_LIST } from "@constants/service/mypage/mypage";
import { SIGN_UP_PAGE_STEP, FIND_ID_PAGE_STEP, FIND_PASSWORD_PAGE_STEP } from "@constants/service/auth/auth";
import {
  AuthStateType,
  EmailLoginStateType,
  UserProfileStateType,
  FindIdStateType,
  FindPasswordStateType,
  SignUpStateType,
  AuthAction,
  UserAuthStateType,
  RemoteImageReferralStateType,
} from "./types";
import {
  EMAIL_LOGIN,
  SOCIAL_LOGIN_AND_JOIN,
  SET_AUTH,
  SMS_AUTH_ID,
  SMS_AUTH_ID_CONFIRM,
  SMS_AUTH_PASSWORD,
  SMS_AUTH_PASSWORD_CONFIRM,
  MEMBER_PASSWORD_UPDATE,
  SMS_AUTH_JOIN,
  SMS_AUTH_JOIN_CONFIRM,
  AUTH_JOIN_PASSWORD,
  MEMBER_EMAIL_JOIN,
  USER_AUTH,
  LOG_OUT,
  UPDATE_CART_COUNT,
  SOCIAL_JOIN_PREPARATION,
  SOCIAL_JOIN_PREPARATION_RESET,
  FORCE_LOGOUT,
  MEMBER_ERROR_UPDATE,
  MEMBER_ERROR_RESET,
  AUTH_JOIN_AGREE,
} from "./actions";

export const initialAuthState: AuthStateType = {
  // 로그인 여부
  isLoggedIn: false,

  // 강제 로그아웃 여부
  isForceLogout: false,

  // 유저 정보 상태
  userProfileState: asyncState.initial<UserProfileStateType>(),

  // email 로그인 정보 상태
  emailLoginState: asyncState.initial<EmailLoginStateType>({
    isEmailLoginLoading: false,
  }),

  // 아이디 찾기 상태
  findIdState: asyncState.initial<FindIdStateType>({
    pageStep: FIND_ID_PAGE_STEP.AUTH_FORM,
    isSendSmsLoading: false,
    isSmsAuthLoading: false,
    loginType: null,
    account: null,
  }),

  // 비밀번호 찾기 상태
  findPasswordState: asyncState.initial<FindPasswordStateType>({
    pageStep: FIND_PASSWORD_PAGE_STEP.AUTH_FORM,
    isSendSmsLoading: false,
    isSmsAuthLoading: false,
    isChangePasswordLoading: false,
    account: null,
    cellPhone: null,
    authCode: null,
  }),

  // 회원가입 정보 상태
  signUpState: asyncState.initial<SignUpStateType>({
    pageStep: SIGN_UP_PAGE_STEP.AUTH_FORM,
    isSendSmsLoading: false,
    isSmsAuthLoading: false,
    isMemberJoinLoading: false,
    snsLoginType: null,
    loginType: null,
    cellPhone: null,
    email: null,
    password: null,
    isNewMember: null,
  }),

  // 유저 인증 상태
  userAuthState: asyncState.initial<UserAuthStateType>({
    loginType: null,
    account: null,
    userId: null,
    userName: null,
    cartCount: 0,
    likeCount: 0,
    point: 0,
    couponCount: 0,
    cellPhone: null,
    remoteImageReferral: {
      desktop: <RemoteImageReferralStateType>{},
      tablet: <RemoteImageReferralStateType>{},
      phone: <RemoteImageReferralStateType>{},
    },
  }),

  // 유저 인증 에러 상태
  errorState: {
    code: null,
    message: null,
    name: null,
  },
};

const authReducer = createReducer<AuthStateType, AuthAction>(initialAuthState, {
  // 강제 로그 아웃
  [FORCE_LOGOUT]: produce((draft, action) => {
    draft.isForceLogout = action.payload.isForceLogout;
  }),

  // 로그인 여부 변경
  [SET_AUTH]: produce((draft, action) => {
    draft.isLoggedIn = action.payload.isLoggedIn;
  }),

  // SNS 가입 준비
  [SOCIAL_JOIN_PREPARATION]: produce((draft, action) => {
    draft.signUpState.data.id = action.payload.id;
    draft.signUpState.data.loginType = action.payload.snsLoginType;
    draft.signUpState.data.snsLoginType = action.payload.snsLoginType;
    draft.signUpState.data.email = action.payload.email;
    draft.signUpState.data.name = action.payload.name;
    draft.signUpState.data.socialLoginAccessToken = action.payload.socialLoginAccessToken;
    draft.signUpState.status = null;
    draft.signUpState.data.isMemberJoinLoading = false;
  }),

  //SNS 가입 준비 초기화(가입 도중 페이지 이탈시 sns 정보가 그대로 남아있어서 초기화 필요)
  [SOCIAL_JOIN_PREPARATION_RESET]: produce((draft) => {
    draft.signUpState.data.id = initialAuthState.signUpState.data!.id;
    draft.signUpState.data.loginType = initialAuthState.signUpState.data!.snsLoginType;
    draft.signUpState.data.snsLoginType = initialAuthState.signUpState.data!.snsLoginType;
    draft.signUpState.data.email = initialAuthState.signUpState.data!.email;
    draft.signUpState.data.name = initialAuthState.signUpState.data!.name;
    draft.signUpState.status = null;
    draft.signUpState.data.isMemberJoinLoading = false;
    // 유저 인증 페이지 단계 초기화
    draft.signUpState.data.pageStep = SIGN_UP_PAGE_STEP.AUTH_FORM;
  }),

  // SNS 로그인/회원 가입 요청
  [SOCIAL_LOGIN_AND_JOIN.REQUEST]: produce((draft) => {
    draft.signUpState.data.isMemberJoinLoading = true;
  }),

  // SNS 로그인/회원 가입 성공
  [SOCIAL_LOGIN_AND_JOIN.SUCCESS]: produce((draft, action) => {
    draft.signUpState.data.isNewMember = action.payload.isNewMember;
  }),

  // SNS 로그인/회원 가입 요청 실패
  [SOCIAL_LOGIN_AND_JOIN.FAILURE]: produce((draft) => {
    draft.signUpState.data.isMemberJoinLoading = false;
  }),

  // 이메일 로그인 요청시 status 초기화
  [EMAIL_LOGIN.REQUEST]: produce((draft) => {
    draft.emailLoginState.status = null;
    draft.emailLoginState.data.isEmailLoginLoading = true;
  }),

  // 이메일 로그인 요청 성공
  [EMAIL_LOGIN.SUCCESS]: produce((draft, action) => {
    draft.emailLoginState.data.isEmailLoginLoading = false;
    if (action.payload.status === STATUS_CODE_COMMON.SUCCESS) {
      // 로그인 성공
      draft.emailLoginState.data = action.payload;
      draft.isLoggedIn = true;
    } else {
      // 로그인 실패
      draft.emailLoginState.status = action.payload.status;
    }
  }),

  // 로그아웃 성공
  [LOG_OUT.SUCCESS]: produce((draft) => {
    // 로그아웃 처리
    draft.userProfileState.data = null;
    draft.isLoggedIn = false;
    draft.userAuthState.data = {
      loginType: null,
      account: null,
      userId: null,
      userName: null,
      cartCount: 0,
      likeCount: 0,
      point: 0,
      couponCount: 0,
      cellPhone: null,
      remoteImageReferral: {
        desktop: <RemoteImageReferralStateType>{},
        tablet: <RemoteImageReferralStateType>{},
        phone: <RemoteImageReferralStateType>{},
      },
    };
  }),

  // 계정 찾기 SMS 인증 번호 요청
  [SMS_AUTH_ID.REQUEST]: produce((draft) => {
    draft.findIdState.status = null;
    draft.findIdState.data.isSendSmsLoading = true;
  }),

  // 계정 찾기 SMS 인증 번호 요청 성공
  [SMS_AUTH_ID.SUCCESS]: produce((draft, action) => {
    draft.findIdState.data.isSendSmsLoading = false;
    draft.findIdState.status = action.payload.status;
  }),

  // 계정 찾기 SMS 코드 인증 요청
  [SMS_AUTH_ID_CONFIRM.REQUEST]: produce((draft) => {
    draft.findIdState.status = null;
    draft.findIdState.data.isSmsAuthLoading = true;
  }),

  // 계정 찾기 SMS 코드 인증 요청 성공
  [SMS_AUTH_ID_CONFIRM.SUCCESS]: produce((draft, action) => {
    draft.findIdState.status = action.payload.status;
    draft.findIdState.data.isSmsAuthLoading = false;
    if (action.payload.status === STATUS_CODE_COMMON.SUCCESS) {
      // 계정 찾기 SMS 코드 인증 성공 && 계정 저장
      draft.findIdState.data.account = action.payload.account;
      // 아이디 정보 페이지 단계로 변경
      draft.findIdState.data.pageStep = FIND_ID_PAGE_STEP.INFO_FROM;
    } else {
      // SNS 로 가입되었을 경우
      draft.findIdState.data.loginType = action.payload.loginType;
    }
  }),

  // 비밀번호 찾기 SMS 인증 번호 요청
  [SMS_AUTH_PASSWORD.REQUEST]: produce((draft) => {
    draft.findPasswordState.status = null;
    draft.findPasswordState.data.isSendSmsLoading = true;
  }),

  // 비밀번호 찾기 SMS 인증 번호 요청 성공
  [SMS_AUTH_PASSWORD.SUCCESS]: produce((draft, action) => {
    draft.findPasswordState.data.isSendSmsLoading = false;
    draft.findPasswordState.status = action.payload.status;
  }),

  // 비밀번호 찾기 SMS 코드 인증 요청
  [SMS_AUTH_PASSWORD_CONFIRM.REQUEST]: produce((draft) => {
    draft.findPasswordState.status = null;
    draft.findPasswordState.data.isSmsAuthLoading = true;
  }),

  // 비밀번호 찾기 SMS 코드 인증 요청 성공
  [SMS_AUTH_PASSWORD_CONFIRM.SUCCESS]: produce((draft, action) => {
    draft.findPasswordState.data.isSmsAuthLoading = false;
    if (action.payload.status === STATUS_CODE_COMMON.SUCCESS) {
      // status 초기화
      draft.findPasswordState.status = null;
      draft.findPasswordState.data.account = action.payload.account;
      draft.findPasswordState.data.cellPhone = action.payload.cellPhone;
      draft.findPasswordState.data.authCode = action.payload.authCode;
      // 비밀번호 변경 페이지 단계로 변경
      draft.findPasswordState.data.pageStep = FIND_PASSWORD_PAGE_STEP.PASSWORD_FORM;
    } else {
      draft.findPasswordState.status = action.payload.status;
    }
  }),

  // 비밀번호 업데이트 요청
  [MEMBER_PASSWORD_UPDATE.REQUEST]: produce((draft) => {
    draft.findPasswordState.data.isChangePasswordLoading = true;
  }),

  // 비밀번호 업데이트 요청 성공
  [MEMBER_PASSWORD_UPDATE.SUCCESS]: produce((draft, action) => {
    draft.findPasswordState.status = action.payload.status;
    draft.findPasswordState.data.isChangePasswordLoading = false;
  }),

  // 회원가입 SMS 코드 인증 요청
  [SMS_AUTH_JOIN.REQUEST]: produce((draft) => {
    draft.signUpState.status = null;
    draft.signUpState.data.isSendSmsLoading = true;
    draft.signUpState.data.loginType = null;
  }),

  // 회원가입 SMS 코드 인증 요청 실패
  [SMS_AUTH_JOIN.FAILURE]: produce((draft) => {
    draft.signUpState.status = null;
    draft.signUpState.data.isSendSmsLoading = false;
  }),

  // 회원가입 SMS 코드 인증 요청 성공
  [SMS_AUTH_JOIN.SUCCESS]: produce((draft, action) => {
    draft.signUpState.status = action.payload.status;
    draft.signUpState.data.isSendSmsLoading = false;
    if (action.payload.loginType !== undefined) {
      draft.signUpState.data.loginType = action.payload.loginType;
    }
  }),

  // 회원가입 SMS 코드 인증 요청
  [SMS_AUTH_JOIN_CONFIRM.REQUEST]: produce((draft) => {
    draft.signUpState.status = null;
    draft.signUpState.data.isSmsAuthLoading = true;
  }),

  // 회원가입 SMS 코드 인증 실패
  [SMS_AUTH_JOIN_CONFIRM.FAILURE]: produce((draft) => {
    draft.signUpState.status = null;
    draft.signUpState.data.isSmsAuthLoading = false;
  }),

  // 회원가입 SMS 코드 인증 성공
  [SMS_AUTH_JOIN_CONFIRM.SUCCESS]: produce((draft, action) => {
    draft.signUpState.status = null;
    draft.signUpState.data.isSmsAuthLoading = false;
    draft.signUpState.data.loginType = action.payload.currentLoginType;
    draft.signUpState.data.email = action.payload.email;
    draft.signUpState.data.cellPhone = action.payload.cellPhone;
    draft.signUpState.data.pageStep = SIGN_UP_PAGE_STEP.AGREEMENT;
  }),

  // 약관동의
  [AUTH_JOIN_AGREE]: produce((draft) => {
    if (!draft.signUpState.data.snsLoginType) {
      // 비밀번호 등록 페이지 단계로 변경
      draft.signUpState.data.pageStep = SIGN_UP_PAGE_STEP.EMAIL_FORM;
    } else {
      // 소셜 회원가입에서는 email 을 받지 않고,
      // 비밀번호를 설정하지 않기 때문에
      // 추가 정보 입력 페이지 단계로 변경
      draft.signUpState.data.pageStep = SIGN_UP_PAGE_STEP.SNS_FROM;
    }
  }),

  // 회원가입 비밀번호 설정 성공
  [AUTH_JOIN_PASSWORD]: produce((draft, action) => {
    draft.signUpState.data.password = action.payload.password;
    // 추가 정보 입력 페이지 단계로 변경
    draft.signUpState.data.pageStep = SIGN_UP_PAGE_STEP.SNS_FROM;
  }),

  // 이메일 회원가입 요청
  [MEMBER_EMAIL_JOIN.REQUEST]: produce((draft) => {
    draft.signUpState.data.isMemberJoinLoading = true;
  }),

  // 이메일 회원가입 요청 성공
  [MEMBER_EMAIL_JOIN.SUCCESS]: produce((draft, action) => {
    draft.signUpState.data.isMemberJoinLoading = false;
    if (action.payload.status === STATUS_CODE_COMMON.SUCCESS) {
      // 로그인 성공
      draft.userProfileState.data = action.payload;
      // 첫 가입 여부
      draft.signUpState.data.isNewMember = !!action.payload.data.isNewMember;
    }
    draft.signUpState.status = action.payload.status;
  }),

  // 유저 인증 성공
  [USER_AUTH.SUCCESS]: produce((draft, action) => {
    draft.isLoggedIn = true;
    draft.userAuthState.data.loginType = action.payload.loginType;
    draft.userAuthState.data.userId = action.payload.userId;
    draft.userAuthState.data.userName = action.payload.userName;
    draft.userAuthState.data.cartCount = action.payload.cartCount;
    draft.userAuthState.data.likeCount = action.payload.likeCount;
    draft.userAuthState.data.point = action.payload.point;
    draft.userAuthState.data.account = action.payload.account ?? null;
    draft.userAuthState.data.couponCount = action.payload.couponCount ?? null;
    if (action.payload.remoteImageReferralList) {
      draft.userAuthState.data.remoteImageReferral = action.payload.remoteImageReferralList.reduce(
        (a: {}, c: RemoteImageReferralStateType) => {
          a = {
            ...a,
            [REFERRAL_IMG_TYPE_LIST[c.imgType]]: c,
          };
          return a;
        },
        {},
      );
    } else {
      draft.userAuthState.data.remoteImageReferral = {
        desktop: null,
        tablet: null,
        phone: null,
      };
    }
  }),

  // 장바구니 개수 업데이트
  [UPDATE_CART_COUNT]: produce((draft, action) => {
    draft.userAuthState.data.cartCount += action.payload.addCount;
  }),

  // 유저 인증 에러 업데이트
  [MEMBER_ERROR_UPDATE]: produce((draft, action) => {
    draft.errorState = action.payload;
  }),

  // 유저 인증 에러 초기화
  [MEMBER_ERROR_RESET]: produce((draft) => {
    draft.errorState = {
      code: null,
      message: null,
      name: null,
    };
  }),
});

export default authReducer;
