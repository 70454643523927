import * as React from "react";
import { FC } from "react";

import ModalCover from "@components/base/modal/ModalCover";
import Button from "@components/common/Button/Button";
import { BUTTON_COLOR, BUTTON_SIZE } from "@constants/enum/baseEnums";
import { OneButtonModalType } from "@store/modules/base";
import { CONFIRM_TEXT } from "@constants/service/common/text";
import { OneButtonModalLayoutStyle } from "./style";

interface IOneButtonModalLayoutProps extends OneButtonModalType {}

const OneButtonModalLayout: FC<IOneButtonModalLayoutProps> = (props: IOneButtonModalLayoutProps) => {
  const {
    visible,
    isCloseBtn = true,
    onCloseBtn = () => {},
    msg,
    subMsg,
    btnText = CONFIRM_TEXT,
    onClickBtn = () => {},
    canClickDimmed,
  } = props;

  return (
    <ModalCover
      visible={visible}
      isCloseBtn={isCloseBtn}
      onCloseBtn={onCloseBtn}
      onClickBtn={onClickBtn}
      canClickDimmed={canClickDimmed}
    >
      <OneButtonModalLayoutStyle>
        <div className={`modal-body ${subMsg ? "is-sub-msg" : ""}`}>
          <div className="msg">{msg}</div>
          {subMsg && <div className="sub-msg">{subMsg}</div>}
        </div>

        <div className="modal-footer">
          <Button color={BUTTON_COLOR.BLACK} size={BUTTON_SIZE.LARGE} onClick={onClickBtn} text={btnText} />
        </div>
      </OneButtonModalLayoutStyle>
    </ModalCover>
  );
};

export default OneButtonModalLayout;
