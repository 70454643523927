import { createReducer } from "typesafe-actions";
import { produce } from "immer";
import { isNil, sumBy } from "lodash";

import { asyncState } from "@lib/utility/reducerUtils";
import { LIKE_RESULT_CODE, LIKE_STATUS } from "@constants/service/common/common";
import { MAXIMUM_VALUE_OF_PRODUCT_PURCHASE_COUNT } from "@constants/common/common";
import { PRODUCT_OPTION_STATUS } from "@constants/enum/productEnum";
import { QnaProductItemStateType } from "../mypage";
import {
  ProductStateType,
  ProductDetailStateType,
  ProductAction,
  ProductListStateType,
  ProductOptionItemType,
  ProductReviewDetailStateType,
  BrandFilterGroup,
} from "./types";
import {
  GET_PRODUCT_LIST,
  LIKE_PRODUCT,
  PRODUCT_MODAL_TOGGLE,
  GET_PRODUCT_DETAIL,
  SET_PRODUCT_PURCHASE_INFO,
  SELECT_PRODUCT_OPTION,
  LIKE_STORE,
  INIT_PRODUCT_DETAIL,
  GET_PRODUCT_QNA_LIST,
  INSERT_PRODUCT_QNA,
  UPDATE_PRODUCT_QNA,
  DELETE_PRODUCT_QNA,
  GET_AS_INFO,
  GET_PRODUCT_REVIEW_DETAIL,
  GET_PRODUCT_REVIEW_LIST,
  GET_BRAND_FILTER_TREE,
  GET_PRODUCT_CATEGORY_TREE,
  INSERT_PRODUCT_REVIEW,
  LIKE_PRODUCT_REVIEW,
} from "./actions";

export const initialProductState: ProductStateType = {
  // 상품 리스트 상태
  productListState: asyncState.initial<ProductListStateType>({
    allCount: 0,
    productList: [],
  }),

  // 상품 상세 상태
  productDetailState: asyncState.initial<ProductDetailStateType>(),

  // 상품 후기 상태
  productReviewState: asyncState.initial<ProductReviewDetailStateType>(),

  writtenReview: null,

  // 상품 모달 상태
  productModalState: {
    productDeliveryInfoModal: {
      visible: false,
    },
    orderReturnExchangeDeliveryInfoModal: {
      visible: false,
      data: null,
    },
    asInfoModal: {
      visible: false,
      data: null,
    },
  },
};

const productReducer = createReducer<ProductStateType, ProductAction>(initialProductState, {
  // 상품 카테고리 트리 요청 성공
  [GET_PRODUCT_CATEGORY_TREE.SUCCESS]: produce((draft, action) => {
    draft.productListState.data.productCategoryTree = action.payload.productCategoryTree;
  }),

  // 상품 리스트 요청
  [GET_PRODUCT_LIST.REQUEST]: produce((draft) => {
    draft.productListState.loading = true;
    draft.productListState.status = null;
  }),

  // 상품 리스트 요청 성공
  [GET_PRODUCT_LIST.SUCCESS]: produce((draft, action) => {
    draft.productListState.loading = false;
    draft.productListState.status = action.payload.status;
    draft.productListState.data.allCount = action.payload.allCount;
    draft.productListState.data.productList = action.payload.productList;
    if (action.payload.productCategoryTree) {
      draft.productListState.data.productCategoryTree = action.payload.productCategoryTree;
    }
  }),

  // 브랜드 필터 트리 요청 성공
  [GET_BRAND_FILTER_TREE.SUCCESS]: produce((draft, action) => {
    draft.productListState.data.brandFilterTree = action.payload.brandTree;
    draft.productListState.data.brandFilterTree.brandFilterIndexList = action.payload.brandTree.allBrandList.map(
      (brandGroup: BrandFilterGroup) => brandGroup.groupKey,
    );
  }),

  // 상품 상세 초기화
  [INIT_PRODUCT_DETAIL]: produce((draft) => {
    draft.productDetailState.loading = false;
    draft.productDetailState.data = null;
    draft.productDetailState.status = null;
    draft.productDetailState.error = null;
  }),

  // 상품 상세 요청
  [GET_PRODUCT_DETAIL.REQUEST]: produce((draft) => {
    draft.productDetailState.loading = true;
    draft.productDetailState.status = null;
  }),
  // 상품 상세 요청 성공
  [GET_PRODUCT_DETAIL.SUCCESS]: produce((draft, action) => {
    draft.productDetailState.loading = false;
    draft.productDetailState.status = action.payload.status;
    draft.productDetailState.data = action.payload.productDetail || null;

    if (!draft.productDetailState.data) {
      return;
    }

    // qna 초기화
    draft.productDetailState.data.qna = {
      status: null,
      allCount: 0,
      myQnaList: [],
      qnaList: [],
    };

    // 상품 상세에서 노출되는 후기 정보 초기화
    if (!draft.productDetailState.data.review) {
      draft.productDetailState.data.review = {
        allCount: 0,
        reviewList: [],
        rateAverage: 1,
      };
    }

    if (draft.productDetailState.data.productOption?.itemList) {
      draft.productDetailState.data.productOption.itemList = draft.productDetailState.data.productOption.itemList.map(
        (item: ProductOptionItemType) => ({
          ...item,
          disabled: item.status === PRODUCT_OPTION_STATUS.SOLD_OUT,
        }),
      );
    }
  }),

  // 상품 좋아요
  [LIKE_PRODUCT.SUCCESS]: produce((draft, action) => {
    if (!isNil(action.payload.targetIndex)) {
      // 상품 리스트에서 좋아요
      if (
        draft.productListState.data.productList[action.payload.targetIndex] &&
        draft.productListState.data.productList[action.payload.targetIndex].id === action.payload.productId
      ) {
        if (action.payload.code === LIKE_RESULT_CODE.INSERT_SUCCESS) {
          draft.productListState.data.productList[action.payload.targetIndex].likeFlag = LIKE_STATUS.LIKE;
          draft.productListState.data.productList[action.payload.targetIndex].likeCount++;
        } else {
          draft.productListState.data.productList[action.payload.targetIndex].likeFlag = LIKE_STATUS.UN_LIKE;
          draft.productListState.data.productList[action.payload.targetIndex].likeCount--;
        }
      }
    } else {
      // 상품 상세에서 좋아요
      if (action.payload.code === LIKE_RESULT_CODE.INSERT_SUCCESS) {
        draft.productDetailState.data.like.flag = LIKE_STATUS.LIKE;
        draft.productDetailState.data.like.count++;
      } else {
        draft.productDetailState.data.like.flag = LIKE_STATUS.UN_LIKE;
        draft.productDetailState.data.like.count--;
      }
    }
  }),

  // 브랜드 좋아요
  [LIKE_STORE.SUCCESS]: produce((draft, action) => {
    if (action.payload.code === LIKE_RESULT_CODE.INSERT_SUCCESS) {
      draft.productDetailState.data.brand.likeFlag = LIKE_STATUS.LIKE;
      draft.productDetailState.data.brand.likeCount++;
    } else {
      draft.productDetailState.data.brand.likeFlag = LIKE_STATUS.UN_LIKE;
      draft.productDetailState.data.brand.likeCount--;
    }
  }),

  // 상품 후기 좋아요
  [LIKE_PRODUCT_REVIEW.SUCCESS]: produce((draft, action) => {
    if (!isNil(action.payload.targetIndex)) {
      // 상품 상세 - 상품 후기 리스트에서 좋아요
      if (
        draft.productDetailState.data.review.reviewList[action.payload.targetIndex] &&
        draft.productDetailState.data.review.reviewList[action.payload.targetIndex].id === action.payload.productId
      ) {
        if (action.payload.code === LIKE_RESULT_CODE.INSERT_SUCCESS) {
          draft.productDetailState.data.review.reviewList[action.payload.targetIndex].likeFlag = LIKE_STATUS.LIKE;
          draft.productDetailState.data.review.reviewList[action.payload.targetIndex].likeCount++;
        } else {
          draft.productDetailState.data.review.reviewList[action.payload.targetIndex].likeFlag = LIKE_STATUS.UN_LIKE;
          draft.productDetailState.data.review.reviewList[action.payload.targetIndex].likeCount--;
        }
      }
    } else {
      // 상품 후기 상세에서 좋아요
      if (action.payload.code === LIKE_RESULT_CODE.INSERT_SUCCESS) {
        draft.productReviewState.data.reviewDetail.likeFlag = LIKE_STATUS.LIKE;
        draft.productReviewState.data.reviewDetail.likeCount++;
      } else {
        draft.productReviewState.data.reviewDetail.likeFlag = LIKE_STATUS.UN_LIKE;
        draft.productReviewState.data.reviewDetail.likeCount--;
      }
    }
  }),

  // 상품 구매 정보 변경
  [SET_PRODUCT_PURCHASE_INFO]: produce((draft, action) => {
    const { count, optionId } = action.payload;
    // 상품 단가
    const { salesPrice } = draft.productDetailState.data.productDetail;
    // 선택한 상품 옵션 리스트, 총 상품 금액
    const { optionList } = draft.productDetailState.data.purchaseInfo;
    // 총 상품 금액
    let totalPrice;
    if (optionId) {
      // 업데이트 할(추가, 삭제) 옵션
      const targetSelectedOption = optionList.find((item: any) => item.id === optionId);
      // 단품 수량 변경
      targetSelectedOption.count = count;
      totalPrice = sumBy(optionList, (item: any) => (salesPrice + item.price) * item.count);
    } else {
      // 단품 수량 변경
      draft.productDetailState.data.purchaseInfo.count = count;
      totalPrice = salesPrice * count;
    }

    draft.productDetailState.data.purchaseInfo.totalPrice = totalPrice;
  }),

  // 상품 옵션 선택
  [SELECT_PRODUCT_OPTION]: produce((draft, action) => {
    const { optionId, isDelete } = action.payload;
    // 상품 옵션 선택
    const targetOption = draft.productDetailState.data.productOption?.itemList.find(
      (item: ProductOptionItemType) => item.id === optionId,
    );
    // 업데이트 할 상품 옵션이 없을 경우
    if (!targetOption) {
      return;
    }

    // 상품 단가
    const { salesPrice } = draft.productDetailState.data.productDetail;
    // 선택한 상품 옵션 리스트, 총 상품 금액
    let { optionList } = draft.productDetailState.data.purchaseInfo;
    // 업데이트 할(추가, 삭제) 옵션
    const targetSelectedOption = optionList?.find((item: any) => item.id === optionId);

    if (isDelete) {
      // 옵션 삭제
      if (targetSelectedOption) {
        // 선택한 옵션 리스트에서 삭제할 옵션 제거
        optionList = optionList.filter((item: any) => item.id !== optionId);
      }
    } else {
      // 옵션 추가
      if (targetSelectedOption) {
        if (targetSelectedOption.count + 1 <= MAXIMUM_VALUE_OF_PRODUCT_PURCHASE_COUNT) {
          // 업데이트 할 옵션이 선택되어 있다면, 수량을 +1 함
          ++targetSelectedOption.count;
        }
      } else {
        if (!optionList) {
          optionList = [];
        }
        optionList.unshift({
          id: targetOption.id,
          name: targetOption.name,
          price: targetOption.price,
          count: 1,
          isTodayDelivery: targetOption.isTodayDelivery,
        });
      }
    }

    // 옵션 리스트 업데이트
    draft.productDetailState.data.purchaseInfo.optionList = optionList;
    // 총 상품 금액 업데이트
    draft.productDetailState.data.purchaseInfo.totalPrice = sumBy(
      optionList,
      (item: any) => (salesPrice + item.price) * item.count,
    );
  }),

  // 상품 후기 요청 성공
  [GET_PRODUCT_REVIEW_LIST.SUCCESS]: produce((draft, action) => {
    const { review } = draft.productDetailState.data;
    const { allCount, reviewList } = action.payload;
    review.allCount = allCount;
    review.reviewList = reviewList;
  }),
  // 상품 후기 요청 실패
  [GET_PRODUCT_REVIEW_LIST.FAILURE]: produce((draft) => {
    const { review } = draft.productDetailState.data;
    review.allCount = 0;
    review.reviewList = [];
  }),

  // 상품 구매 후기 성공
  [GET_PRODUCT_REVIEW_DETAIL.SUCCESS]: produce((draft, action) => {
    draft.productReviewState.loading = true;
    draft.productReviewState.status = action.payload.status;
    draft.productReviewState.data = action.payload.data || null;
  }),

  // 상품 구매 후기 작성 요청 성공
  [INSERT_PRODUCT_REVIEW.REQUEST]: produce((draft) => {
    draft.writtenReview = null;
  }),
  [INSERT_PRODUCT_REVIEW.SUCCESS]: produce((draft, action) => {
    draft.writtenReview = action.payload;
  }),

  // 상품 문의 요청 성공
  [GET_PRODUCT_QNA_LIST.SUCCESS]: produce((draft, action) => {
    draft.productDetailState.data.qna.allCount = action.payload.allCount;
    draft.productDetailState.data.qna.myQnaList = action.payload.myQnaList;
    draft.productDetailState.data.qna.qnaList = action.payload.qnaList;
  }),
  // 상품 문의 요청 실패
  [GET_PRODUCT_QNA_LIST.FAILURE]: produce((draft) => {
    draft.productDetailState.data.qna.allCount = 0;
    draft.productDetailState.data.qna.myQnaList = [];
    draft.productDetailState.data.qna.qnaList = [];
  }),

  // 상품 문의 등록 요청
  [INSERT_PRODUCT_QNA.REQUEST]: produce((draft) => {
    draft.productDetailState.data.qna.status = null;
    draft.productDetailState.data.qna.loading = true;
  }),

  // 상품 문의 등록 요청 성공
  [INSERT_PRODUCT_QNA.SUCCESS]: produce((draft, action) => {
    draft.productDetailState.data.qna.loading = false;
    const { qna } = draft.productDetailState.data;

    // 해당 상품에 qna가 없을 경우
    if (!qna.allCount) {
      qna.allCount = 0;
    }

    qna.allCount += 1;
    qna.status = action.payload.status;
    qna.myQnaList.unshift({
      ...action.payload.data,
      answer: null,
      answerDate: null,
    });
  }),

  // 상품 문의 수정 요청
  [UPDATE_PRODUCT_QNA.REQUEST]: produce((draft) => {
    draft.productDetailState.data.qna.status = null;
    draft.productDetailState.data.qna.loading = true;
  }),

  // 상품 문의 수정 요청 성공
  [UPDATE_PRODUCT_QNA.SUCCESS]: produce((draft, action) => {
    draft.productDetailState.data.qna.loading = false;
    const updatedQna = draft.productDetailState.data.qna.myQnaList.find(
      (qna: QnaProductItemStateType) => qna.id === action.payload.qnaId,
    );
    updatedQna.question = action.payload.question;
    draft.productDetailState.data.qna.status = action.payload.status;
  }),

  // 상품 문의 삭제 요청
  [DELETE_PRODUCT_QNA.REQUEST]: produce((draft) => {
    draft.productDetailState.data.qna.status = null;
    draft.productDetailState.data.qna.loading = true;
  }),

  // 상품 문의 삭제 요청 성공
  [DELETE_PRODUCT_QNA.SUCCESS]: produce((draft, action) => {
    draft.productDetailState.data.qna.loading = false;
    draft.productDetailState.data.qna.myQnaList = draft.productDetailState.data.qna.myQnaList.filter(
      (qna: QnaProductItemStateType) => qna.id !== action.payload.qnaId,
    );
    draft.productDetailState.data.qna.status = action.payload.status;
    draft.productDetailState.data.qna.allCount -= 1;
  }),

  // A/S 정보 요청 성공
  [GET_AS_INFO.SUCCESS]: produce((draft, action) => {
    draft.productModalState.asInfoModal.visible = true;
    // 교환 및 반품 배송 정보와 A/S 정보 는 같은 api 를 사용하고 있기 때문에 두 정보에 데이터를 넣음
    draft.productModalState.orderReturnExchangeDeliveryInfoModal.data = action.payload;
    draft.productModalState.asInfoModal.data = action.payload;
  }),

  // 상품 모달 토글
  [PRODUCT_MODAL_TOGGLE]: produce((draft, action) => {
    draft.productModalState[action.payload.targetModal].visible =
      !draft.productModalState[action.payload.targetModal].visible;
    draft.productModalState[action.payload.targetModal].productDetailDeliveryType =
      action.payload.productDetailDeliveryType;
    draft.productModalState[action.payload.targetModal].deliveryPeriod = action.payload.deliveryPeriod;
    draft.productModalState[action.payload.targetModal].timeLimitText = action.payload.timeLimitText;
    draft.productModalState[action.payload.targetModal].modalContentsType = action.payload.modalContentsType;
  }),
});

export default productReducer;
