import React, { ReactElement, MouseEvent, MouseEventHandler } from "react";

import { useGtmEventLogging } from "./useGtmEventLogging";
import { GTM_EVENT, GTM_EVENT_PROPERTY } from "./types";

interface IClickEventLoggingProps<GtmEvent extends GTM_EVENT> {
  event: GtmEvent;
  property?: GTM_EVENT_PROPERTY[GtmEvent];
  skip?: boolean;
  children: ReactElement<{ onClick?: MouseEventHandler<HTMLElement> }>;
}

const ClickGtmEventLogging = <GtmEvent extends GTM_EVENT>(
  props: IClickEventLoggingProps<GtmEvent>,
): React.JSX.Element => {
  const { event, property, skip = false, children } = props;
  const { trigger } = useGtmEventLogging();

  const handleClick = (_onClick?: MouseEventHandler<HTMLElement>): MouseEventHandler<HTMLElement> => {
    return (e: MouseEvent<HTMLElement>): void => {
      if (!skip) {
        trigger(event, property);
      }

      if (_onClick) {
        _onClick(e);
      }
    };
  };

  const child = React.Children.only(children);

  return React.cloneElement(child, {
    onClick: handleClick(child.props.onClick),
  });
};

export default ClickGtmEventLogging;
