import React, { forwardRef, useEffect, useState } from "react";
import { FreeMode, Swiper as SwiperInstance } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import classNames from "classnames";
import { ValueOf } from "@amondz/types";
import { useScroll } from "@amondz/use-scroll";
import { TAB_SIZE, TAB_TYPE } from "../constants";
import { ScrollTabHeaderColorProps } from "../Tabs";
import { useAds } from "../../../contexts";
import { TabListStyle, FlexTabStyle, FullTabStyle } from "./style";

export interface TabHeaderProps extends ScrollTabHeaderColorProps {
  size: ValueOf<typeof TAB_SIZE>;
  type: ValueOf<typeof TAB_TYPE>;
  list: string[];
  currentActive: number | null;
  onClickTab: (idx: number) => void;
}

const TabHeader = forwardRef<HTMLDivElement, TabHeaderProps>((props, ref) => {
  const { size, type, list, currentActive, onClickTab, activeColor, normalColor, backgroundColor } = props;
  const [controlledSwiper, setControlledSwiper] = useState<SwiperInstance>();
  const TabItem = type === TAB_TYPE.FLEX ? FlexTabStyle : FullTabStyle;
  const { stickyTop } = useScroll();
  const deviceType = useAds();

  useEffect(() => {
    if (controlledSwiper && currentActive !== null) {
      controlledSwiper.slideTo(Number(currentActive));
    }
  }, [currentActive, controlledSwiper]);

  return (
    <TabListStyle
      className="tab-header"
      top={stickyTop}
      ref={ref}
      deviceType={deviceType}
      activeColor={activeColor}
      normalColor={normalColor}
      backgroundColor={backgroundColor}
    >
      <Swiper
        onSwiper={setControlledSwiper}
        slidesPerView={type === "flex" ? "auto" : list.length}
        freeMode={{ enabled: true, sticky: true }}
        grabCursor={true}
        modules={[FreeMode]}
      >
        {list.map((title, idx) => (
          <SwiperSlide key={title}>
            <TabItem
              className={classNames("item", { active: currentActive === idx })}
              size={size}
              onClick={() => onClickTab(idx)}
              isActive={currentActive === idx}
              deviceType={deviceType}
              data-testid={`tab-item-${idx + 1}`}
            >
              <span>{title}</span>
            </TabItem>
          </SwiperSlide>
        ))}
      </Swiper>
    </TabListStyle>
  );
});

export default TabHeader;
