import { PAGE_URL } from "./pageUrl";

// todo: 마이페이지 관련, 선물하기 정리 필요
export const PAGE_NAME = {
  [PAGE_URL.HOME]: "home",
  [PAGE_URL.SUB_HOME]: "sub_home",
  [PAGE_URL.SEARCH]: "search",
  [PAGE_URL.SIGN_IN]: "signIn",
  [PAGE_URL.SIGN_UP]: "signUp",
  [PAGE_URL.FIND_ID]: "findId",
  [PAGE_URL.FIND_PASSWORD]: "findPassword",
  [PAGE_URL.EVENT_DETAIL]: "eventDetail",
  [PAGE_URL.WIDGET_DETAIL]: "widget_detail",
  [PAGE_URL.PRODUCT_LIST]: "productList",
  [PAGE_URL.PRODUCT_DETAIL]: "product_detail",
  [PAGE_URL.PRODUCT_REVIEW]: "productReview",
  [PAGE_URL.BRAND_LIST]: "brandList",
  [PAGE_URL.BRAND_DETAIL]: "brandDetail",
  [PAGE_URL.PAYMENT_CART]: "paymentCart",
  [PAGE_URL.PAYMENT_CHECKOUT]: "paymentCheckout",
  [PAGE_URL.PAYMENT_COMPLETE]: "paymentComplete",
  [PAGE_URL.REVIEW_WRITE]: "review_write",
};
