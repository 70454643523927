/* tslint:disable */
/* eslint-disable */
/**
 * AMONDZ-USER-BACKEND-V2
 * AMONDZ-USER-BACKEND-V2 API 문서입니다.
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface BrandsFindOneResponseBrandAddressesDto
 */
export interface BrandsFindOneResponseBrandAddressesDto {
    /**
     * 주소 타입( brand : 회사 대표주소 (영업 소재지) , origin : 출고지(발송지) 정보 , return : 반송지 정보 )
     * @type {string}
     * @memberof BrandsFindOneResponseBrandAddressesDto
     */
    'type': BrandsFindOneResponseBrandAddressesDtoTypeEnum;
    /**
     * 메인 주소
     * @type {string}
     * @memberof BrandsFindOneResponseBrandAddressesDto
     */
    'mainAddress': string;
    /**
     * 상세 주소
     * @type {string}
     * @memberof BrandsFindOneResponseBrandAddressesDto
     */
    'detailAddress': string;
    /**
     * 우편번호
     * @type {string}
     * @memberof BrandsFindOneResponseBrandAddressesDto
     */
    'postCode': string;
}

export const BrandsFindOneResponseBrandAddressesDtoTypeEnum = {
    Brand: 'brand',
    Origin: 'origin',
    Return: 'return'
} as const;

export type BrandsFindOneResponseBrandAddressesDtoTypeEnum = typeof BrandsFindOneResponseBrandAddressesDtoTypeEnum[keyof typeof BrandsFindOneResponseBrandAddressesDtoTypeEnum];


