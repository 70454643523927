import TagManager from "react-gtm-module";
import { EVENT_LOCATION, EventLocationType, GTM_EVENT } from "../../../types";

export interface GTMSelectItemEventType {
  locationType: EventLocationType;
  id: string | number;
  name: string;
  items: { item_id: string; item_name: string; index: number }[];
}

const createItemListDetails = ({
  locationType,
  id,
  name,
  items,
}: GTMSelectItemEventType): {
  item_list_id: string;
  item_list_name: string;
  items: { item_id: string; item_name: string; index: number }[];
} => {
  if (locationType === EVENT_LOCATION.CART || locationType === EVENT_LOCATION.LIKE_PRODUCT) {
    return {
      item_list_id: locationType,
      item_list_name: locationType,
      items,
    };
  }
  return {
    item_list_id: `${locationType}=${id}`,
    item_list_name: `${locationType}=${name}`,
    items,
  };
};

/**
 * 상품 선택 이벤트 ga4 문서
 *
 * https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#select_item
 * */
export const selectItem = (data: GTMSelectItemEventType): void => {
  const itemListDetails = createItemListDetails(data);

  const dataLayer = {
    event: GTM_EVENT.SELECT_ITEM,
    ...itemListDetails,
  };

  TagManager.dataLayer({
    dataLayer,
  });
};
