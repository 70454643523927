import { createReducer } from "typesafe-actions";
import { produce } from "immer";

import { asyncState } from "@lib/utility/reducerUtils";
import { LIKE_STATUS } from "@constants/service/common/common";
import { HomeStateType, HomeDetailStateType, HomeAction } from "./types";
import { DETAIL_LIKE_PRODUCT, GET_WIDGET_DETAIL } from "./actions";

const initialState: HomeStateType = {
  homeDetailState: asyncState.initial<HomeDetailStateType>({
    title: "",
    description: "",
    contentsList: [],
    allCount: 0,
    itemList: [],
  }),
};

const homeReducer = createReducer<HomeStateType, HomeAction>(initialState, {
  // 위젯 상세 요청
  [GET_WIDGET_DETAIL.REQUEST]: produce((draft) => {
    draft.homeDetailState.loading = true;
  }),
  // 위젯 상세 요청 성공
  [GET_WIDGET_DETAIL.SUCCESS]: produce((draft, action) => {
    draft.homeDetailState.loading = false;
    draft.homeDetailState.status = action.payload.status;
    if (action.payload.data) {
      draft.homeDetailState.data = action.payload.data;
    }
  }),

  // 홈 상세 좋아요 버튼 클릭
  [DETAIL_LIKE_PRODUCT.SUCCESS]: produce((draft, action) => {
    const target = draft.homeDetailState.data.itemList[action.payload.targetIndex];
    if (target && target.id === action.payload.productId) {
      if (action.payload.code === LIKE_STATUS.LIKE) {
        target.likeFlag = LIKE_STATUS.LIKE;
        target.likeCount++;
      } else {
        target.likeFlag = LIKE_STATUS.UN_LIKE;
        target.likeCount--;
      }
    }
  }),
});

export default homeReducer;
