import { REVIEW_SORT_SELECTOR_TYPE } from "@amondz/constants";
import { ReviewSortType } from "@amondz/types";

export const convertSortQuery = (sortType: ReviewSortType) => {
  switch (sortType) {
    case REVIEW_SORT_SELECTOR_TYPE.HIGH_RATE:
      return "-rate,-createDate";
    case REVIEW_SORT_SELECTOR_TYPE.LOW_RATE:
      return "rate,-createDate";
    case REVIEW_SORT_SELECTOR_TYPE.LATEST:
      return "-createDate";
    default:
      return "-rate,-createDate";
  }
};
