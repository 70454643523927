import React from "react";

import { ads_color } from "../../../tokens";
import { IconProps } from "../types";

const StarFilled = (props: IconProps) => {
  const { className, rotate, size = 24, fill = ads_color.black, stroke = ads_color.black } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" fill="none">
      <path d="M8 11.856L4.2918 13.6666L4.88 9.60493L2 6.66226L6.07173 5.96265L8 2.33331L9.92827 5.96265L14 6.66226L11.12 9.60493L11.7082 13.6666L8 11.856Z" fill={fill} stroke={fill} strokeWidth="0.8" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};
export default StarFilled;
