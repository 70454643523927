import { GtmEvent } from "./index";
import { eventType, eventValue } from "../type";
import { beginPaymentInfoDto, submitPaymentInfoDto } from "./dto/product";

/**
 * 클라이언트에서 발생하는 이벤트를 gtm 이벤트로 맵핑
 * @param type
 * @param value
 */
function gtmEventChannel<EventType extends eventType>(type: EventType, value: eventValue[EventType]) {
  const event = new GtmEvent();
  switch (type) {
    case eventType.viewHome: {
      const { isLoggedIn, userId } = value as eventValue[eventType.viewHome];
      event.viewHome(isLoggedIn, userId);
      break;
    }
    case eventType.beginPayment: {
      const beginValue = value as eventValue[eventType.beginPayment];
      const orderInfo = beginPaymentInfoDto(beginValue);
      event.beginPayment(orderInfo);
      break;
    }
    case eventType.submitPayment: {
      const submitValue = value as eventValue[eventType.submitPayment];
      const orderInfo = submitPaymentInfoDto(submitValue);
      event.submitPayment(orderInfo);
      break;
    }
    case eventType.applyEvent: {
      const { code } = value as eventValue[eventType.applyEvent];
      event.applyEvent(code);
      break;
    }
  }
}

export default gtmEventChannel;
