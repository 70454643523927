export const AVATAR_SIZE = {
  ["large"]: 100,
  ["normal"]: 72,
  ["small"]: 64,
};

export const AVATAR_SHAPE = {
  ["round"]: "50%",
  ["round-square"]: "8px",
  ["square"]: "0",
};
