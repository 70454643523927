import * as React from "react";
import OneButtonModalLayout from "@components/base/layout/modal/OneButtonModalLayout";
import { OneButtonModalType } from "@store/modules/base";

interface IOneButtonModalProps extends OneButtonModalType {
  onCloseBtn: () => void;
  onClickBtn: () => void;
}
const OneButtonModal = (props: IOneButtonModalProps): JSX.Element => {
  return <OneButtonModalLayout {...props} />;
};

export default OneButtonModal;
