import { rest } from "msw";

type MockReturnMap = {
  [key: string]: any
};

type ExtractorFunc = (req: any) => string;

/**
 * msw에서 mock데이터를 만들때 rest.get의 2번째 인자로 들어가는 함수 자체를 mock으로 만드는 것을 권장하여
 * 해당 함수를 쉽게 만들 수 있도록 util로 만듦
 * 참고 - https://mswjs.io/docs/basics/response-resolver
 * */
export const mock = (returnValue: any): Parameters<typeof rest.get>[1] => {
  return (req, res, ctx) => {
    return res(ctx.json(returnValue));
  };
};
/**
 * 파라미터에 따라, 목 데이터를 얻고자 할 경우 다음 유틸 함수를 사용
 * */
export const mockWithParams = (
  returnMap: MockReturnMap,
  extractor: ExtractorFunc,
  responseKey: string
): Parameters<typeof rest.post>[1] => {
  return (req, res, ctx) => {
    const key = extractor(req);
    const returnValue = returnMap[key];

    if (returnValue) {
      return res(ctx.json({ [responseKey]: returnValue }));
    } else {
      return res(
        ctx.status(404),
        ctx.json({ error: '정보가 존재하지 않습니다.' })
      );
    }
  };
};
/**
 * 배열 파라미터에서 값(다이나믹 키) 유무에 따라, 목 데이터를 얻고자 할 경우 다음 유틸 함수를 사용
 * */
export const mockWithArrayParams = (
  returnMap: MockReturnMap,
  extractor: ExtractorFunc,
  responseKey: string,
  dynamicKey: string
): Parameters<typeof rest.post>[1] => {
  return (req, res, ctx) => {
    const params = extractor(req);

    if (!Array.isArray(params)) {
      return res(
        ctx.status(400),
        ctx.json({ error: '잘못된 형식의 요청입니다.' })
      );
    }

    const returnValues = params.map((param) => {
      return returnMap[param[dynamicKey]];
    }).filter(Boolean);

    if (returnValues.length > 0) {
      return res(ctx.json({ [responseKey]: returnValues }));
    } else {
      return res(
        ctx.status(404),
        ctx.json({ error: '정보가 존재하지 않습니다.' })
      );
    }
  };
};
