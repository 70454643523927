// 로그인 타입 리스트
export enum LOGIN_TYPE {
  EMAIL,
  KAKAO,
  NAVER,
  FACEBOOK,
  APPLE,
}

// 성별 타입 리스트
export enum GENDER_TYPE {
  WOMAN, // 여자
  MAN, // 남자
}

// 이미 가입한 유저 여부 타입 리스트
export enum ALREADY_JOIN_TYPE {
  NEW, // 신규 가입
  OLD, // 기가입자
}

// 이미 가입한 유저 여부 타입 리스트
export enum NEW_MEMBER_TYPE {
  OLD, // 재가입
  NEW, // 신규 가입
}

// 주문자 정보 연락처 선택 타입 리스트
export enum BUYER_CELL_PHONE_SELECT_TYPE {
  OLD, // 기존 연락처 사용
  NEW, // 새로운 연락처 등록
}
