import { createAction } from "typesafe-actions";

import { BaseProductInfoType } from "@lib/utility/gtm";
import { BASE_MODAL_NAME_TYPE } from "@constants/enum/baseEnums";
import { HellobarStateType, NotifyToastType, OneButtonModalType, TwoButtonModalType } from "./types";

// ******** Base Common Type **********
// 상품 좋아요 요청, 응답 공통 타입
export type LikeProductCommonType = {
  productId: number;
  targetIndex?: number;
  // 위젯 인식 index id
  widgetId?: number;
};

export type LikeProductCommonTypeForBraze = LikeProductCommonType & {
  product: Omit<BaseProductInfoType, "imgUrl">;
};

// 상품 후기 좋아요 요청, 응답 공통 타입
export type LikeProductReviewCommonType = {
  reviewId: number;
  targetIndex?: number;
};
// ************************************

export const BASE_MODAL_TOGGLE = "base/BASE_MODAL_TOGGLE";
export type BaseModalToggleType = {
  targetModal: BASE_MODAL_NAME_TYPE;
  productId?: number;
  position?: any;
};

export const baseModalToggle = createAction(BASE_MODAL_TOGGLE)<BaseModalToggleType>();

// ****** 토스트 메세지 액션 설정 ******
export const NOTIFY_TOAST_TOGGLE = "base/NOTIFY_TOAST_TOGGLE";
export const notifyToastToggle = createAction(NOTIFY_TOAST_TOGGLE)<NotifyToastType>();
// ****** 토스트 메세지 액션 설정 ******

// 상품 좋아요 요청 성공 타입
export type LikeProductSuccessType = LikeProductCommonType & {
  code: number;
  likeProductCount?: number;
};

// 브랜드 좋아요 요청 타입
export type LikeBrandRequestType = {
  storeId: number;
};

// 브랜드 좋아요 요청 성공 타입
export type LikeBrandSuccessType = {
  code: number;
  // storeId?: number;
  likeStoreCount?: number;
};

// 상품 후기 좋아요 요청 성공 타입
export type LikeProductReviewSuccessType = LikeProductReviewCommonType & {
  code: number;
};

// 원버튼 모달
export const ONE_BUTTON_MODAL_TOGGLE = "base/ONE_BUTTON_MODAL_TOGGLE";
export const oneButtonModalToggle = createAction(ONE_BUTTON_MODAL_TOGGLE)<OneButtonModalType>();

// 투버튼 모달
export const TWO_BUTTON_MODAL_TOGGLE = "base/TWO_BUTTON_MODAL_TOGGLE";
export const twoButtonModalToggle = createAction(TWO_BUTTON_MODAL_TOGGLE)<TwoButtonModalType>();

// 헬로바 쿠키
export const HELLOBAR_COOKIE = "base/HELLOBAR_COOKIE";
export const hellobarCookie = createAction(HELLOBAR_COOKIE)<HellobarStateType>();
