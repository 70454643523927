export const REMOTE_CONFIG_KEY = {
  SEARCH_PLACEHOLDER: "search_placeholder",
  SHORTFORM_WIDGET: "shortform_widget",
  REVIEW_PLACEHOLDER: "review_placeholder",
  ADDITIONAL_BENEFITS: "additional_benefits",
  BENEFIT: "benefit",
  SERVICE_INFO: "service_info",
} as const;

export const DEFAULT_CONFIG = {
  [REMOTE_CONFIG_KEY.SEARCH_PLACEHOLDER]: "궁금한 주얼리를 검색해보세요!",
  [REMOTE_CONFIG_KEY.SHORTFORM_WIDGET]: JSON.stringify({
    title: "영상으로 만나는 주얼리",
    subTitle: "지금 바로 눌러 확인해보세요!",
    position: 3,
  }),
  [REMOTE_CONFIG_KEY.REVIEW_PLACEHOLDER]: "내용을 입력해 주세요",
  [REMOTE_CONFIG_KEY.SERVICE_INFO]: JSON.stringify({
    serviceSlogan: "주얼리, a부터 z까지. 아몬즈",
  }),
  [REMOTE_CONFIG_KEY.BENEFIT]: JSON.stringify({
    firstPhoto: 2000,
    photo: 1000,
    signupCouponSuccessModal: "신규 고객을 위한 쿠폰팩이 발급 되었습니다.",
    helloBarMessage: "첫 구매 시 바로 사용 가능한 쿠폰팩을 드려요✨",
    signupBenefit: "지금 가입하고 다양한 혜택받기",
    signupCouponImage: "https://cdn.amondz.com/common/images/login_hero@2x.jpg",
  }),
};

export type ShortformWidgetRemoteConfigType = {
  title: string;
  subTitle: string;
  position: number;
};
