import styled from "@styles/themedComponents";

export const BackdropStyle = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: ${(props) => props.theme.zIndex.modalBackdrop};
`;
