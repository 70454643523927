import * as dateFns from "date-fns";
import { getDifferenceInDays } from "@amondz/utils";

export const calculateCouponExpiryDate = (endDate: string) => {
  const daysDifference = getDifferenceInDays(new Date(), new Date(endDate));
  let tillDate: string;

  if (daysDifference === 0) {
    tillDate = `D-${daysDifference}`;
  } else if (daysDifference > 0) {
    tillDate = `D+${daysDifference}`;
  } else {
    tillDate = `D${daysDifference}`;
  }

  const formattedDate = `${dateFns.format(new Date(endDate), "yyyy.MM.dd HH:mm")}까지`;

  return { tillDate, formattedDate };
};