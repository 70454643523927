import { mock } from "@services/msw/utils";

export const MEMBER_SUMMARY_MOCK = {
  userName: "김선태",
  userGradeImgUrl: "https://amondz-test.s3.ap-northeast-2.amazonaws.com/user/grade/2.jpeg",
  userGradeName: "실버",
  availablePurchaseConfirmCount: 10,
  writableReviewCount: 5,
  availableCouponCount: 10,
  availablePointAmount: 30000,
};

export const userSummaryMock = mock(MEMBER_SUMMARY_MOCK);

export const recentOrderMock = mock({
  orders: [
    {
      orderId: "21674799042235",
      orderTotalPaidPrice: 2000,
      orderDate: "2023-01-27T05:57:22.000Z",
      orderItemCount: 1,
      firstOrderItemName: "아몬즈금은방-제품번호O-사입-옵션X-일반배송",
      firstOrderItemImgUrl:
        "https://cdntest.amondz.com/product/77444/resize/mainImg/PSI_819524_50.jpeg?v=1672404284186",
    },
    {
      orderId: "21674797109632",
      orderTotalPaidPrice: 2000,
      orderDate: "2023-01-27T05:25:09.000Z",
      orderItemCount: 1,
      firstOrderItemName: "아몬즈금은방-제품번호O-위탁-옵션O-일반",
      firstOrderItemImgUrl:
        "https://cdntest.amondz.com/product/77445/resize/mainImg/PSI_819527_50.jpeg?v=1672404566396",
    },
    {
      orderId: "21674615805797",
      orderTotalPaidPrice: 2000,
      orderDate: "2023-01-25T03:03:25.000Z",
      orderItemCount: 1,
      firstOrderItemName: "아몬즈금은방-제품번호O-위탁-옵션O-일반",
      firstOrderItemImgUrl:
        "https://cdntest.amondz.com/product/77445/resize/mainImg/PSI_819527_50.jpeg?v=1672404566396",
    },
  ],
  totalCount: 3,
});

export const likeStoresMock = mock({
  likeStores: [
    {
      storeId: 1605,
      storeName: "a14",
      storeEngName: "a14",
      newProductCount: 0,
      products: [
        {
          id: "33877",
          imgUrl: "https://cdntest.amondz.com/product/33877/resize/mainImg/PSI_631386_50.jpeg?v=1649838221834",
        },
        {
          id: "33879",
          imgUrl: "https://cdntest.amondz.com/product/33879/resize/mainImg/PSI_522099_50.jpeg?v=1636684626732",
        },
        {
          id: "33894",
          imgUrl: "https://cdntest.amondz.com/product/33894/resize/mainImg/PSI_631462_50.jpeg?v=1649840312716",
        },
        {
          id: "34038",
          imgUrl: "https://cdntest.amondz.com/product/34038/resize/mainImg/PSI_604666_50.jpeg?v=1646993884652",
        },
      ],
      storeMainImgUrl: "https://cdntest.amondz.com/store/1605/original/titleImg/SAI_6521.png?v=1636098753004",
    },
    {
      storeId: 930,
      storeName: "위아몬즈",
      storeEngName: "weamondz",
      newProductCount: 0,
      products: [
        {
          id: "25475",
          imgUrl: "https://cdntest.amondz.com/product/25475/resize/mainImg/PSI_241070_50.jpeg?v=1595988039537",
        },
        {
          id: "25478",
          imgUrl: "https://cdntest.amondz.com/product/25478/resize/mainImg/PSI_241184_50.jpeg?v=1595993053691",
        },
        {
          id: "25479",
          imgUrl: "https://cdntest.amondz.com/product/25479/resize/mainImg/PSI_339428_50.jpeg?v=1608456800041",
        },
        {
          id: "25483",
          imgUrl: "https://cdntest.amondz.com/product/25483/resize/mainImg/PSI_577988_50.jpeg?v=1643250390881",
        },
      ],
      storeMainImgUrl: "https://cdntest.amondz.com/store/930/original/titleImg/SAI_6112.jpeg?v=1628473914306",
    },
  ],
  totalCount: 2,
});

export const likeProductsMock = mock({
  totalCount: 3,
  likeProducts: [
    {
      productId: 35134,
      productName: "입생로랑 리브르 오 드 빠르펭 50ml",
      isTodayDelivery: 0,
      isGift: 0,
      originPrice: 152000,
      salesPrice: 132000,
      discountRate: 13,
      discountStartDate: null,
      discountEndDate: null,
      productStatus: 2,
      storeId: 1110,
      storeName: "퍼퓸그라피",
      productImgUrl: "https://cdntest.amondz.com/product/35134/resize/mainImg/PSI_348935_50.jpeg?v=1610084114814",
      productCategories: [
        {
          id: 9,
          name: "향수",
          parentId: null,
        },
        {
          id: 210,
          name: "입생로랑",
          parentId: 9,
        },
      ],
    },
    {
      productId: 33417,
      productName: "조말론 잉글리쉬 페어 앤 프리지아 코롱 30ml",
      isTodayDelivery: 0,
      isGift: 0,
      originPrice: 107000,
      salesPrice: 105000,
      discountRate: 1,
      discountStartDate: null,
      discountEndDate: null,
      productStatus: 2,
      storeId: 1110,
      storeName: "퍼퓸그라피",
      productImgUrl: "https://cdntest.amondz.com/product/33417/resize/mainImg/PSI_327600_50.jpeg?v=1607336983207",
      productCategories: [
        {
          id: 9,
          name: "향수",
          parentId: null,
        },
        {
          id: 194,
          name: "조말론",
          parentId: 9,
        },
      ],
    },
    {
      productId: 34524,
      productName: "조말론 블랙베리 앤 베이 코롱 30ml",
      isTodayDelivery: 0,
      isGift: 0,
      originPrice: 107000,
      salesPrice: 105000,
      discountRate: 1,
      discountStartDate: null,
      discountEndDate: null,
      productStatus: 2,
      storeId: 1110,
      storeName: "퍼퓸그라피",
      productImgUrl: "https://cdntest.amondz.com/product/34524/resize/mainImg/PSI_340523_50.jpeg?v=1608608359013",
      productCategories: [
        {
          id: 9,
          name: "향수",
          parentId: null,
        },
        {
          id: 194,
          name: "조말론",
          parentId: 9,
        },
      ],
    },
  ],
});

export const imageReferralsMock = mock({
  imageReferrals: [
    {
      type: 0,
      linkType: 10,
      linkId: null,
      url: "https://cdntest.amondz.com/remoteImage/referral/3/myPage/RIRA_11.jpeg",
      imgType: 1,
      width: 1296,
      height: 495,
    },
  ],
});

//주문 상세 내역 api mock
export const orderDetailMock = mock({
  status: 1,
  data: {
    orderType: 0,
    itemList: [
      {
        orderItemId: 11674797109873,
        storeId: 1150,
        storeName: "아몬즈금은방",
        storeStatus: 2,
        productStatus: 2,
        purchaseConfirmDate: null,
        productId: 77445,
        productName: "아몬즈금은방-제품번호O-위탁-옵션O-일반",
        productOptionText: "크기 : 1",
        usedCouponPrice: 0,
        couponName: null,
        couponDescription: null,
        couponDiscountRate: null,
        couponDate: null,
        usedPoint: 7900,
        quantity: 1,
        isTodayDelivery: 1,
        totalPrice: 2000,
        productPrice: 9900,
        orderMessage: null,
        status: 3,
        imgUrl: "https://cdntest.amondz.com/product/77445/resize/mainImg/PSI_819527_50.jpeg?v=1672404566396",
      },
    ],
    shipping: {
      recipient: "심승태",
      cellPhone: "01075332136",
      addressLine1: "경기 의정부시 용민로 64",
      addressLine2: "407호",
      postCode: "11785",
      message: "부재시 전화주시거나 문자 남겨주세요.",
    },
    payment: {
      amount: 2000,
      method: 6,
      payInform: "(카카오/네이버페이)",
      orderDate: "2023-01-27",
      paidDate: "2023-01-27",
    },
    price: {
      totalItemPrice: 9900,
      totalDeliveryPrice: 0,
      totalUsedPoint: 7900,
      totalUsedCouponPrice: 0,
      totalPaidPrice: 2000,
    },
  },
});

//결제 취소 정보 요청 api mock
export const orderCancelSelectMock = mock({
  status: 1,
  data: {
    orderItemList: [
      {
        storeId: 1150,
        storeName: "아몬즈금은방",
        productId: 77445,
        productName: "아몬즈금은방-제품번호O-위탁-옵션O-일반",
        productOptionText: "크기 : 1",
        totalPrice: 2000,
        quantity: 1,
        isTodayDelivery: 1,
        orderMessage: null,
        imgUrl: "https://cdntest.amondz.com/product/77445/resize/mainImg/PSI_819527_50.jpeg?v=1672404566396",
      },
    ],
    type: [
      {
        id: 0,
        text: "주문실수",
      },
      {
        id: 1,
        text: "단순변심",
      },
      {
        id: 2,
        text: "결제수단 변경",
      },
      {
        id: 3,
        text: "배송지연",
      },
    ],
    payMethod: 7,
    status: 4,
    refundExpectAmount: 2000,
  },
});

//결제 취소 요청 api mock
export const orderCancelSubmitMock = mock({
  status: 1,
  data: {
    cancelResultType: 2,
    cancelId: 79741,
    limitDate: "2023.02.17",
    store: {
      name: "판도라(트랜드메카)",
      phone: "0215993735",
      address: "(06107) 서울 강남구 논현로 626 7층 트랜드메카",
    },
    orderItem: {
      storeId: 884,
      storeName: "판도라(트랜드메카)",
      productId: 23477,
      productName: "모먼트 하트 클래습 실버 팔찌",
      productOptionText: "길이 : 590719-17",
      totalPrice: 83900,
      isTodayDelivery: 0,
      quantity: 1,
      orderMessage: null,
      imgUrl: "https://cdntest.amondz.com/product/23477/resize/mainImg/PSI_520825_50.jpeg?v=1636438834791",
    },
    cancelInform: {
      type: "단순 변심",
      reason: "asdsad",
    },
    refundInform: {
      payMethod: 0,
      payInformList: ["(신한카드/일시불)"],
      refundExpectAmount: 83900,
    },
  },
});

//취소 결과 내역 api mock
export const orderCancelResultMock = mock({
  status: 1,
  data: {
    orderCancelStatus: 4,
    cancelInform: {
      type: "단순 변심",
      reason: "ㅁㄴㅇㅁㄴㅇ",
      cancelRequestDate: "2023-01-25",
      cancelCompleteDate: "2023-01-25",
    },
    refundInform: {
      payMethod: 5,
      payInformList: ["(카카오/네이버페이)"],
      refundActualAmount: 2000,
    },
    isSellerCancel: 0,
  },
});

//반품 요청 api mock
export const orderReturnSubmitMock = mock({
  status: 1,
  data: {
    returnId: 10052,
    store: {
      name: "아몬즈",
      phone: "0216681753",
      address: "(06193) 서울 강남구 선릉로90길 38 3층 비주얼 글로벌 허브",
    },
    orderItem: {
      storeId: 1150,
      storeName: "아몬즈금은방",
      productId: 77444,
      productName: "아몬즈금은방-제품번호O-사입-옵션X-일반배송",
      productOptionText: null,
      totalPrice: 2000,
      isTodayDelivery: 1,
      quantity: 1,
      orderMessage: null,
      imgUrl: "https://cdntest.amondz.com/product/77444/resize/mainImg/PSI_819524_50.jpeg?v=1672404284186",
    },
    returnInform: {
      type: "주문실수",
      reason: "ㄴㅁㅇㄹㅁㅇㄴㄹ",
      imgUrlList: [],
    },
    refundInform: {
      payMethod: 7,
      payInformList: ["(카카오/네이버페이)"],
      paidAmount: 2000,
      returnDeliveryFee: 3000,
      refundExpectAmount: -1000,
      responsibility: 0,
    },
  },
});

//반품 결과 내역 api mock
export const orderReturnResultMock = mock({
  status: 1,
  data: {
    orderReturnStatus: 0,
    store: {
      name: "아몬즈",
      phone: "0216681753",
      address: "(06193) 서울 강남구 선릉로90길 38 3층 비주얼 글로벌 허브",
    },
    orderItem: {
      storeId: 1150,
      storeName: "아몬즈금은방",
      productId: 77444,
      productName: "아몬즈금은방-제품번호O-사입-옵션X-일반배송",
      productOptionText: null,
      totalPrice: 2000,
      quantity: 1,
      isTodayDelivery: 1,
      orderMessage: null,
      imgUrl: "https://cdntest.amondz.com/product/77444/resize/mainImg/PSI_819524_50.jpeg?v=1672404284186",
    },
    returnInform: {
      type: "주문실수",
      date: "2023-01-27",
      reason: "ㄴㅁㅇㄹㅁㅇㄴㄹ",
    },
    refundInform: {
      payMethod: 8,
      payInformList: ["(카카오/네이버페이)"],
      paidAmount: 2000,
      returnDeliveryFee: 3000,
      refundExpectAmount: -1000,
      responsibility: 0,
    },
  },
});
