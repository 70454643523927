import { isNaN, isNil, isNumber } from "lodash";

/**
 * 천 단위마다 "," 붙이기
 *
 * @param {number | string} number 변환하고자 하는 number 값
 * @param {any} defaultValue number 값이 0 | null | undefined 인 경우 노출되는 값
 *
 * @returns {string} 문자로 변환된 숫자
 */
export const numberWithCommas = (number?: number | string | null, defaultValue?: any): string => {
  if (!number) {
    return !isNil(defaultValue) ? defaultValue : "-";
  }
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

/**
 * 숫자에 붙은 "," 제거하기
 * @param value 변환하고자 하는 값
 * @returns { number } 쉼표가 제거된 숫자
 */
export const numberWithoutCommas = <T = number>(value?: number | string | null, defaultValue?: T): T | number => {
  if (!value) {
    return !isNil(defaultValue) ? defaultValue : 0;
  }
  return Number(value.toString().replace(/,/g, ""));
};

export const isNumeric = (number: any): boolean => {
  return number && isNumber(number) && !isNaN(number);
};
