import TagManager from "react-gtm-module";
import { GTM_EVENT, GTMProductType } from "../../../types";

export interface GTMViewItemEventType {
  price: number;
  products: GTMProductType[];
}

/**
 * 상세페이지 이벤트 설정
 *
 * https://developers.google.com/analytics/devguides/collection/ga4/reference/events?sjid=15760346114663595559-AP&client_type=gtag#view_item
 */
export function viewItem(data: GTMViewItemEventType): void {
  const { price, products} = data;

  TagManager.dataLayer({
    dataLayer: {
      event: GTM_EVENT.VIEW_ITEM,
      currency: "KRW",
      value: price,
      items: products
    },
  });
}
