import * as React from "react";
import { FC, ReactNode, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import Router from "next/router";

import { MODAL_CLOSE_BTN_IMAGE_PATH } from "@constants/static/images";
import { useSSRStatus } from "@lib/hooks/useSSRStatus";
import Backdrop from "../Backdrop";
import Icon from "../Icon";
import { ModalStyle } from "./style";

interface IModalProps {
  className?: string;
  visible: boolean;
  toggleModal: () => void;
  header?: ReactNode | string;
  children: ReactNode;
  closeOnDimmedClick?: boolean;
}

const Modal: FC<IModalProps> = (props: IModalProps) => {
  const { className, visible, toggleModal, header, children, closeOnDimmedClick = false } = props;
  const [currentPageYOffset, setCurrentPageYOffset] = useState<null | number>(null);
  const { isSSR } = useSSRStatus();

  useEffect(() => {
    // 페이지 이동시 해당 모달 닫기
    Router.events.on("routeChangeComplete", () => {
      // toggleModal();
    });
  }, []);

  useEffect(() => {
    // 모달을 닫힐 때, 뒷 배경이 스크롤이 되도록 하기
    return () => {
      // if (!document.querySelector(".modal-box")) {
      //   document.body.style.cssText = "overflow: auto;";
      // }
      if (currentPageYOffset) {
        window.scrollTo(0, currentPageYOffset);
        setCurrentPageYOffset(null);
      }
    };
  }, [currentPageYOffset]);

  const handleDimmedClick = () => {
    if (closeOnDimmedClick) {
      toggleModal();
    }
  };

  if (isSSR) {
    return null;
  }

  if (visible) {
    return createPortal(
      <ModalStyle className={className}>
        <Backdrop visible={true} onClick={handleDimmedClick} />

        <div className="modal-box">
          <div className="modal-header">
            {header}
            <Icon
              className="modal-close-btn"
              alt="모달 닫힘 버튼"
              src={MODAL_CLOSE_BTN_IMAGE_PATH}
              onClick={toggleModal}
            />
          </div>
          {children}
        </div>
      </ModalStyle>,
      document.body,
    );
  }

  return null;
};

export default Modal;
