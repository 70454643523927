import * as styledComponents from "styled-components";
import { StyledComponent, ThemedStyledFunction } from "styled-components";
import { AdsTheme } from "./theme";

const {
  default: styled,
  css,
  createGlobalStyle,
  ThemeProvider,
} = styledComponents as unknown as styledComponents.ThemedStyledComponentsModule<AdsTheme>;

export { css, createGlobalStyle, ThemeProvider };
export type { ThemedStyledFunction, StyledComponent };
export default styled;
