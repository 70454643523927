import TagManager from "react-gtm-module";
import { GTM_EVENT, GTMPromotionEventProperty } from "../../../types";

/**
 *  기획전 클릭 이벤트
 *
 * @param {GTMPromotionEventProperty} data 기획전 데이터
 * https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#select_promotion
 */
export function selectPromotion(data: GTMPromotionEventProperty): void {
  TagManager.dataLayer({
    dataLayer: {
      event: GTM_EVENT.SELECT_PROMOTION,
      promotion_id: data.promotion_id,
      promotion_name: data.promotion_name,
      items: data.items?.map((item) => ({
        ...item,
        ...(data.promotion_id && { promotion_id: data.promotion_id }),
        ...(data.promotion_name && { promotion_name: data.promotion_name }),
      })),
    },
  });
}
