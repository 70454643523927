import React, { useEffect, useRef, useState } from "react";
import { ValueOf } from "@amondz/types";
import { TAB_SIZE, TAB_TYPE } from "../constants";
import { ScrollTabHeaderColorProps } from "../Tabs";
import TabHeader from "../TabHeader";
import { useScrollTabViewModel } from "./useScrollTabViewModel";
import { ScrollTabWrapperStyle } from "./style";

export interface ScrollTabProps extends ScrollTabHeaderColorProps {
  size?: ValueOf<typeof TAB_SIZE>;
  type: ValueOf<typeof TAB_TYPE>;
  list: { title: string; component: JSX.Element }[];
  currentActive: number | null;
  onChangeTab: (tabId: number | null) => void;
}

const ScrollTab = (props: ScrollTabProps): JSX.Element => {
  const {
    currentActive,
    onChangeTab,
    size = TAB_SIZE.MEDIUM,
    type,
    list,
    activeColor,
    normalColor,
    backgroundColor,
  } = props;
  const headerRef = useRef<HTMLDivElement>(null);
  const [tabHeaderHeight, setTabHeaderHeight] = useState(0);

  const { handleScroll, scrollSectionRef } = useScrollTabViewModel({
    additionalBaseTop: tabHeaderHeight,
    currentActive,
    onChangeTab,
  });

  useEffect(() => {
    if (headerRef.current) setTabHeaderHeight(headerRef.current?.offsetHeight);
  }, []);

  return (
    <ScrollTabWrapperStyle>
      <TabHeader
        ref={headerRef}
        size={size}
        type={type}
        list={list.map((item) => item.title)}
        currentActive={currentActive}
        onClickTab={handleScroll}
        activeColor={activeColor}
        normalColor={normalColor}
        backgroundColor={backgroundColor}
      />
      <div className="scroll-tab-body">
        {list.map((tabItem, idx) => (
          <section key={tabItem.title + "component"} ref={scrollSectionRef(idx)} data-testid={`tab-content-${idx + 1}`}>
            {tabItem.component}
          </section>
        ))}
      </div>
    </ScrollTabWrapperStyle>
  );
};

export default ScrollTab;
