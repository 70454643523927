import { Brand, SearchInfoType } from "@amondz/types";
import { numberWithCommas } from "./number";

export const BASE_TITLE_TEXT = `주얼리, a부터 Z까지. 아몬즈`;

// 상품 리스트 페이지 설명
export const PRODUCT_LIST_PAGE_DESCRIPTION_TEXT = "500개 브랜드, 10만 개의 주얼리를 만나보세요!";

// 상품 리스트 페이지 OG 설명
export const PRODUCT_LIST_PAGE_OG_DESCRIPTION_TEXT = `${BASE_TITLE_TEXT} | 신규 가입 시 20% 할인`;

// 카테고리트리의 기본형
export interface ICategoryTree {
  categoriesTree: {
    id: number;
    name: string;
    subCategories: {
      id: number;
      name: string;
    }[];
  }[];
}

/**
 * 리스트 페이지 seo 데이터
 */
export const getProductListPageSeoData = (
  categoryTreeResponse: ICategoryTree,
  categoryId: number | null = null,
  selectedSubCategoryIds: number[] = [],
): {
  title: string;
  description: string;
  ogDescription: string;
} => {
  let title = BASE_TITLE_TEXT;
  let description = "";
  const ogDescription = PRODUCT_LIST_PAGE_OG_DESCRIPTION_TEXT;

  if (categoryId) {
    const mainCategory = categoryTreeResponse.categoriesTree.find((category) => category.id === categoryId);

    if (mainCategory) {
      const mainCategoryAndSubCategoriesToString = [
        mainCategory.name,
        ...mainCategory.subCategories.map((subCategory) => subCategory.name),
      ].join(" ");

      if (selectedSubCategoryIds.length > 0) {
        const subCategoryNames: string[] = [];

        selectedSubCategoryIds.forEach((subCategoryId) => {
          const subCategory = mainCategory.subCategories.find((subCategory) => subCategory.id === subCategoryId);
          if (subCategory) {
            subCategoryNames.push(subCategory.name);
          }
        });

        title = `${subCategoryNames.join(" | ")} | ${title}`;
        description = `아몬즈에서 ${subCategoryNames.join(
          " | ",
        )} 상품, 관련 브랜드와 프로모션, 다양한 후기 정보를 만나보세요! | 신규 가입 시 20% 할인 | 전상품 무료배송 | ${mainCategoryAndSubCategoriesToString}`;
      } else {
        title = `${mainCategory.name} | ${title}`;
        description = `아몬즈에서 ${mainCategory.name} 상품, 관련 브랜드와 프로모션, 다양한 후기 정보를 만나보세요! | 신규 가입 시 20% 할인 | 전상품 무료배송 | ${mainCategoryAndSubCategoriesToString}`;
      }
    }
  }

  return { title, description, ogDescription };
};

const search = {
  title: (searchKeyword: string): string => (searchKeyword ? `${searchKeyword} | ${BASE_TITLE_TEXT}` : BASE_TITLE_TEXT),
  description: (searchInfo: SearchInfoType): string =>
    `${searchInfo.keyword} 관련 ${numberWithCommas(searchInfo.productCount, 0)}건의 상품 | ${numberWithCommas(
      searchInfo.reviewCount,
      0,
    )}건의 후기 | ${numberWithCommas(searchInfo.eventCount, 0)}건의 기획전이 있습니다.`,
  og: `${BASE_TITLE_TEXT} | 신규 가입 시 20% 할인`,
};

export const seoText = {
  search,
};

export const tagToHashTagString = (tagsArray?: { id: number; name: string }[] | null): string => {
  const validTagsArray = tagsArray ?? [];
  if (validTagsArray.length === 0) {
    return "";
  }
  // 배열의 각 요소 앞에 '#'을 붙이고, 공백으로 구분하여 하나의 문자열로 합칩니다.
  return validTagsArray.map((tag) => `#${tag.name}`).join(" ");
};

// 브랜드 페이지 타이틀 생성 함수
export const createBrandPageTitle = (brandDetailInfo: Brand): string => {
  const brandName = brandDetailInfo.name || "";
  const brandEngName = brandDetailInfo.engName ? `(${brandDetailInfo.engName})` : "";
  return `${brandName}${brandEngName} | 주얼리, a부터 z까지. 아몬즈`;
};

// 브랜드 페이지 description 생성 함수
export const createBrandPageDescription = ({
  brandDetailInfo,
  allCount,
}: {
  brandDetailInfo: Brand;
  allCount: number;
}): string => {
  const brandName = brandDetailInfo.name || BASE_TITLE_TEXT;
  const brandHashTags = tagToHashTagString(brandDetailInfo?.tags || []);

  return `${brandName}${brandHashTags ? ` | ${brandHashTags}` : ""}${
    allCount > 0 ? ` | ${numberWithCommas(allCount)}개의 상품을 만나보세요!` : ""
  } | 전상품 무료배송 | 신규 가입 시 20% 할인`;
};
