import { AxiosError } from "axios";
import { createAction, createAsyncAction } from "typesafe-actions";
import { PAY_METHOD_NUMBER_KEY_TYPE, ProductBundleItemType } from "@amondz/types";

import { DEFAULT_SHIPPING_ADDRESS, PAYMENT_MODAL_NAME_TYPE } from "@constants/enum/paymentEnums";
import { TODAY_DELIVERY_FILTER_TYPE } from "@constants/service/common/common";
import { ORDER_TYPE } from "@constants/service/mypage/mypage";
import { ApiHelperResponseType } from "@store/modules/common/types";
import {
  DeleteItemStateType,
  OrderPayResultType,
  BuyerInfoType,
  DeliveryInfoType,
  CouponListType,
  ExtraFeeItemType,
} from "./types";

// 장바구니 데이터 동기화
export const SYNC_CART_LIST = " payment/SYNC_CART_LIST";
export const syncCartList = createAction(SYNC_CART_LIST)();

// 장바구니 리스트 요청
export const GET_CART_LIST = {
  REQUEST: " payment/GET_CART_LIST_REQUEST",
  SUCCESS: " payment/GET_CART_LIST_SUCCESS",
  FAILURE: " payment/GET_CART_LIST_FAILURE",
};
export const getCartListAsync = createAsyncAction(GET_CART_LIST.REQUEST, GET_CART_LIST.SUCCESS, GET_CART_LIST.FAILURE)<
  {},
  ApiHelperResponseType,
  AxiosError
>();

// 체크 박스 토글
export const TOGGLE_CHECK_BOX = " payment/TOGGLE_CHECK_BOX_CHECK_BOX";
type ToggleCheckBoxType = {
  storeIndex: number; // 매장 식별 index
  productIndex: number; // 제품 식별 index
};
export const toggleCheckBox = createAction(TOGGLE_CHECK_BOX)<ToggleCheckBoxType>();
// 전체 체크 박스 토글
export const TOGGLE_CHECK_BOX_ALL = " payment/TOGGLE_CHECK_BOX_CHECK_BOX_ALL";
type ToggleCheckBoxAllType = {
  state: boolean; // 전체 선택 여부
};
export const toggleCheckBoxAll = createAction(TOGGLE_CHECK_BOX_ALL)<ToggleCheckBoxAllType>();

// 장바구니 상품 여러개 삭제
export const DELETE_CART_LIST = {
  REQUEST: " payment/DELETE_CART_LIST_REQUEST",
  SUCCESS: " payment/DELETE_CART_LIST_SUCCESS",
  FAILURE: " payment/DELETE_CART_LIST_FAILURE",
};
export type DeleteCartListRequestType = {
  cartList: DeleteItemStateType[]; // 장바구니 삭제 상품 리스트
};
export const deleteCartListAsync = createAsyncAction(
  DELETE_CART_LIST.REQUEST,
  DELETE_CART_LIST.SUCCESS,
  DELETE_CART_LIST.FAILURE,
)<DeleteCartListRequestType, ApiHelperResponseType, AxiosError>();

// 장바구니 상품 옵션 변경 타입
type UpdateCartOptionRequestType = {
  storeIndex: number; // 브랜드 인식 index
  productIndex: number; // 제품 인식 index
  cartId: number; // cart 보관 내역 인식 index id
  productId: number; // 제품 인식 index id
  price?: number; // 제품 1개 가격
  count?: number; // 제품 수량
  optionText?: string; // 기존 제품 옵션 text
  optionPrice?: number; // 기존 제품 옵션 가격 변동값
  optionStatus?: number; // 기존 제품 품절 상태
  oldOptionId?: number; // 기존 옵션 index id
  newOptionId?: number; // 새로 선택한 옵션 index id
};

// 장바구니 상품 수량 변경
export const UPDATE_CART_COUNT = {
  REQUEST: " payment/UPDATE_CART_COUNT_REQUEST",
  SUCCESS: " payment/UPDATE_CART_COUNT_SUCCESS",
  FAILURE: " payment/UPDATE_CART_COUNT_FAILURE",
};
export const updateCartCountAsync = createAsyncAction(
  UPDATE_CART_COUNT.REQUEST,
  UPDATE_CART_COUNT.SUCCESS,
  UPDATE_CART_COUNT.FAILURE,
)<UpdateCartOptionRequestType, ApiHelperResponseType, AxiosError>();

// 장바구니 상품 수량 변경
export const UPDATE_CART_OPTION = {
  REQUEST: " payment/UPDATE_CART_OPTION_REQUEST",
  SUCCESS: " payment/UPDATE_CART_OPTION_SUCCESS",
  FAILURE: " payment/UPDATE_CART_OPTION_FAILURE",
};
export const updateCartOptionAsync = createAsyncAction(
  UPDATE_CART_OPTION.REQUEST,
  UPDATE_CART_OPTION.SUCCESS,
  UPDATE_CART_OPTION.FAILURE,
)<UpdateCartOptionRequestType, ApiHelperResponseType, AxiosError>();

// ****** 상품 번들 리스트 액션 설정 ******
export const SET_PRODUCT_BUNDLE_LIST = "payment/SET_PRODUCT_BUNDLE_LIST";
type SetProductBundleListType = {
  productBundleList: ProductBundleItemType[];
  isFromCart: boolean;
  isRefresh?: boolean;
};
export const setProductBundleList = createAction(SET_PRODUCT_BUNDLE_LIST)<SetProductBundleListType>();
// ****** 상품 번들 리스트 액션 설정 완료 ******

// ****** 결제 상태 초기화 액션 설정 ******
export const INIT_PAYMENT_CHECKOUT = "payment/INIT_PAYMENT_CHECKOUT";
export const initPaymentCheckout = createAction(INIT_PAYMENT_CHECKOUT)();
// ****** 결제 상태 초기화 액션 설정 ******

// ****** 결제 준비 액션 설정 ******
export const ORDER_PREPARATION = {
  REQUEST: "payment/ORDER_PREPARATION_REQUEST",
  SUCCESS: "payment/ORDER_PREPARATION_SUCCESS",
  FAILURE: "payment/ORDER_PREPARATION_FAILURE",
};

type OrderPreparationRequestType = {
  productIdCSV: string;
  storeIdCSV: string;
  /**
   * 주문서 타입
   * 0: 일반 주문건
   * 1: 선물하기 주문건
   */
  orderType: (typeof ORDER_TYPE)[keyof typeof ORDER_TYPE];
};

export const orderPreparationAsync = createAsyncAction(
  ORDER_PREPARATION.REQUEST,
  ORDER_PREPARATION.SUCCESS,
  ORDER_PREPARATION.FAILURE,
)<OrderPreparationRequestType, ApiHelperResponseType, AxiosError>();
// ****** 결제 준비 액션 설정 완료 ******

// ****** 주문자 정보 수정 액션 설정 ******
export const SET_BUYER_INFO = "payment/SET_BUYER_INFO";
export const setBuyerInfo = createAction(SET_BUYER_INFO)<BuyerInfoType>();
// ****** 주문자 정보 수정 액션 설정 완료 ******

// ****** 배송지 정보 수정 액션 설정 ******
export const SET_DELIVERY_INFO = "payment/SET_DELIVERY_INFO";
export const setDeliveryInfo = createAction(SET_DELIVERY_INFO)<DeliveryInfoType>();
// ****** 배송지 정보 수정 액션 설정 완료 ******

// ****** 포인트 적용 액션 설정 ******
export const SET_POINT = "payment/SET_POINT";
type SetPointType = {
  point: number;
};
export const setPoint = createAction(SET_POINT)<SetPointType>();
// ****** 포인트 적용 액션 설정 완료 ******

// ****** 쿠폰 적용 액션 설정 ******
export const SET_COUPON = "payment/SET_COUPON";
type SetCouponType = {
  beforeProductBundleIndex: number | null;
  beforeProductIndex: number | null;
  productBundleIndex: number | null;
  productIndex: number | null;
  coupon: {
    id: number;
    name: string;
    discountPrice: number;
  } | null;
};
export const setCoupon = createAction(SET_COUPON)<SetCouponType>();
// ****** 쿠폰 적용 액션 설정 완료 ******

// ****** 결제 진행 액션 설정 ******
export const ORDER_PAY = {
  REQUEST: "payment/ORDER_PAY_REQUEST",
  SUCCESS: "payment/ORDER_PAY_SUCCESS",
  FAILURE: "payment/ORDER_PAY_FAILURE",
};

type OrderPayRequestRequestType = {
  orderForm: {
    /**
     * 주문서 타입
     * 0: 일반 주문건
     * 1: 선물하기 주문건
     */
    type: (typeof ORDER_TYPE)[keyof typeof ORDER_TYPE];
    payMethod: PAY_METHOD_NUMBER_KEY_TYPE;
    totalPrice: number;
    totalDeliveryExtraFee: number;
    totalUsedCouponAmount: number;
    totalUsedPoint: number;
    totalPaidPrice: number;
    savingPoint: number;
    buyer?: {
      name: string;
      cellPhone: string;
      email: string;
    };
    delivery: {
      recipient: string;
      cellPhone: string;
      addressLine1: string;
      addressLine2: string;
      postCode: string;
      message?: string | null;
      saveMain: DEFAULT_SHIPPING_ADDRESS;
    };
    refund: {
      bankId: number | null;
      bankNumber: string | null;
      bankHolder: string | null;
    };
    orderList: {
      storeId: number;
      deliveryExtraFee: number;
      productList: {
        productId: number;
        productName: string;
        orderMessage: string | null;
        optionId: number | null;
        optionText: string | null;
        optionPrice: number | null;
        count: number;
        price: number;
        sumPrice: number;
        // 오늘출발 여부
        isTodayDelivery?: TODAY_DELIVERY_FILTER_TYPE;
        coupon?: {
          id: number;
          discountAmount: number;
        };
      }[];
    }[];
  };
};

export const orderPayAsync = createAsyncAction(ORDER_PAY.REQUEST, ORDER_PAY.SUCCESS, ORDER_PAY.FAILURE)<
  OrderPayRequestRequestType,
  ApiHelperResponseType,
  AxiosError
>();
// ****** 결제 진행 액션 설정 완료 ******

// ****** 쿠폰 목록 액션 설정 ******
export const COUPON_LIST = {
  REQUEST: "payment/COUPON_LIST_REQUEST",
  SUCCESS: "payment/COUPON_LIST_SUCCESS",
  FAILURE: "payment/COUPON_LIST_FAILURE",
};

type CouponListRequestType = {
  requestData: {
    productId: number;
    count: number;
    optionId?: number;
  };
  data: {
    productBundleIndex: number;
    productIndex: number;
    selectedCouponId?: number;
  };
};

type CouponListResponseType = {
  couponList: CouponListType;
  selectedCouponId?: number;
};

export const couponListAsync = createAsyncAction(COUPON_LIST.REQUEST, COUPON_LIST.SUCCESS, COUPON_LIST.FAILURE)<
  CouponListRequestType,
  CouponListResponseType,
  AxiosError
>();
// ****** 쿠폰 목록 액션 설정 완료 ******

// ****** 배송지 목록 액션 설정 ******
export const DELIVERY_ADDRESS_LIST = {
  REQUEST: "payment/DELIVERY_ADDRESS_LIST_REQUEST",
  SUCCESS: "payment/DELIVERY_ADDRESS_LIST_SUCCESS",
  FAILURE: "payment/DELIVERY_ADDRESS_LIST_FAILURE",
};

export const deliveryAddressListAsync = createAsyncAction(
  DELIVERY_ADDRESS_LIST.REQUEST,
  DELIVERY_ADDRESS_LIST.SUCCESS,
  DELIVERY_ADDRESS_LIST.FAILURE,
)<{}, DeliveryInfoType[], AxiosError>();
// ****** 배송지 목록 액션 설정 완료 ******

// ****** 추가 배송비 요금 액션 설정 ******
export const DELIVERY_EXTRA_FEE = {
  REQUEST: "payment/DELIVERY_EXTRA_FEE_REQUEST",
  SUCCESS: "payment/DELIVERY_EXTRA_FEE_SUCCESS",
  FAILURE: "payment/DELIVERY_EXTRA_FEE_FAILURE",
};

type DeliveryExtraFeeRequestType = {
  postCode?: string;
  zoneCode: number;
  storeIdCSV: string;
};

export const deliveryExtraFeeAsync = createAsyncAction(
  DELIVERY_EXTRA_FEE.REQUEST,
  DELIVERY_EXTRA_FEE.SUCCESS,
  DELIVERY_EXTRA_FEE.FAILURE,
)<DeliveryExtraFeeRequestType, ExtraFeeItemType[], AxiosError>();
// ****** 추가 배송비 요금 액션 설정 완료 ******

// ****** 배송지 등록 액션 설정 ******
export const INSERT_DELIVERY_ADDRESS = {
  REQUEST: "payment/INSERT_DELIVERY_ADDRESS_REQUEST",
  SUCCESS: "payment/INSERT_DELIVERY_ADDRESS_SUCCESS",
  FAILURE: "payment/INSERT_DELIVERY_ADDRESS_FAILURE",
};

type InsertDeliveryAddressRequestType = {
  deliveryInfo: DeliveryInfoType;
  storeIdCSV: string;
};

export const insertDeliveryAddressAsync = createAsyncAction(
  INSERT_DELIVERY_ADDRESS.REQUEST,
  INSERT_DELIVERY_ADDRESS.SUCCESS,
  INSERT_DELIVERY_ADDRESS.FAILURE,
)<InsertDeliveryAddressRequestType, null, AxiosError>();
// ****** 배송지 등록 액션 설정 완료 ******

// ****** 결제 결과 액션 설정 ******
export const PAY_RESULT = {
  REQUEST: "payment/PAY_RESULT_REQUEST",
  SUCCESS: "payment/PAY_RESULT_SUCCESS",
  FAILURE: "payment/PAY_RESULT_FAILURE",
};

type PayResultRequestType = {
  imp_uid: string; // 아임포트의 결제 결과 id
  merchant_uid: string; // 아몬즈 에서의 orderId
  imp_success: string; // 아임포트에서 결제 성공 여부 ("true" 이런식으로 문자형)
  error_msg?: string; // 아임포트에서 넘겨주는 실패 메세지
  isFromCart: number; // cart 를 통한 결제 인지 아닌지 (0: 아님, 1: 카트통함)
};

export const payResultAsync = createAsyncAction(PAY_RESULT.REQUEST, PAY_RESULT.SUCCESS, PAY_RESULT.FAILURE)<
  PayResultRequestType,
  OrderPayResultType,
  AxiosError
>();
// ****** 결제 결과 액션 설정 완료 ******

// ****** 결제 모달 토글 액션 설정 ******
export const PAYMENT_MODAL_TOGGLE = "payment/PAYMENT_MODAL_TOGGLE";
type PaymentModalToggleType = {
  targetModal: PAYMENT_MODAL_NAME_TYPE;
  data?: any;
};
export const paymentModalToggle = createAction(PAYMENT_MODAL_TOGGLE)<PaymentModalToggleType>();
// ****** 결제 모달 토글 액션 설정 완료 ******

// ****** 결제 모달 데이터 초기화 액션 설정 ******
export const RESET_PAYMENT_MODAL_STATE = "payment/RESET_PAYMENT_MODAL_STATE";

export const resetPaymentModalState = createAction(RESET_PAYMENT_MODAL_STATE)();
// ****** 결제 모달 데이터 초기화 액션 설정 완료 ******
