import { useContext } from "react";
import { FirebaseContext } from "./FirebaseProvider";

const useFirebase = () => {
  const context = useContext(FirebaseContext);

  if (context == null) {
    throw new Error("useFirebase is only available within FirebaseProvider.");
  }

  return context.firebaseApp;
};

export default useFirebase;
