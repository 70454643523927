import React, { CSSProperties, ReactNode } from "react";
import { RowStyle } from "./style";

interface RowProps {
  style?: CSSProperties;
  children: ReactNode;
}
const Row = (props: RowProps) => {
  const { style, children } = props;
  return <RowStyle style={style}>{children}</RowStyle>;
};
export default Row;
