import React from "react";
import { ads_color, ads_space } from "@amondz/design-system";
import { DividerProps, DividerType } from "./types";

const Divider = ({ className, canShow = true, type }: DividerProps): JSX.Element | null => {
  if (!canShow) return null;

  const style = getStyleByType(type);

  return <div className={className} data-testid="divider" style={style} />;
};

const getStyleByType = (type: DividerType): React.CSSProperties => {
  const baseStyle: React.CSSProperties = {
    height: "1px",
    backgroundColor: ads_color.gray_02,
  };

  switch (type) {
    case "full-thin":
      return {
        ...baseStyle,
        width: "100%",
      };
    case "full-thick":
      return {
        ...baseStyle,
        width: "100%",
        height: "8px",
      };
    case "middle-thin":
      return {
        ...baseStyle,
        width: `calc(100% - ${ads_space.m * 2}px)`,
        marginLeft: `${ads_space.m}px`,
        marginRight: `${ads_space.m}px`,
      };
    default:
      return {};
  }
};

export default Divider;
