import { startOfDay, differenceInDays } from "date-fns";

export const getExpireDateIncludeStart = (startDate: Date, expirationDate: Date): number => {
  const start = startOfDay(startDate);
  const expiration = startOfDay(expirationDate);
  // 시작 날짜를 포함하여 만료일까지 남은 날짜를 계산
  const difference = differenceInDays(expiration, start) + 1;
  // 만료일이 시작일보다 이전이면 0 반환
  return difference > 0 ? difference : 0;
};
