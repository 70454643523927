import { ValueOf } from "@amondz/types";
import { Z_INDEX } from "@amondz/constants";
import { DEVICE_TYPE } from "../../../contexts";
import styled from "../../../style/themeComponents";
import { TAB_SIZE } from "../constants";
import { AdsTypographyKey } from "../../../tokens";

const tabTypoMap: { [key in ValueOf<typeof TAB_SIZE>]: AdsTypographyKey } = {
  [TAB_SIZE.SMALL]: "bodyL",
  [TAB_SIZE.MEDIUM]: "headingM",
  [TAB_SIZE.LARGE]: "headingL",
};

type PaddingMap = {
  [key in ValueOf<typeof DEVICE_TYPE>]: {
    [key in ValueOf<typeof TAB_SIZE>]: string;
  };
};

const flexTapPaddingMap: PaddingMap = {
  [DEVICE_TYPE.MO]: {
    [TAB_SIZE.SMALL]: "11px 0 9px 0",
    [TAB_SIZE.MEDIUM]: "7px 0 5px 0",
    [TAB_SIZE.LARGE]: "6px 0 4px 0",
  },
  [DEVICE_TYPE.PC]: {
    [TAB_SIZE.SMALL]: "9px 0 7px 0",
    [TAB_SIZE.MEDIUM]: "23px 0 21px 0",
    [TAB_SIZE.LARGE]: "22px 0 19px 0",
  },
};

const fullTabPaddingMap: PaddingMap = {
  [DEVICE_TYPE.MO]: {
    [TAB_SIZE.SMALL]: "11px 0 9px 0",
    [TAB_SIZE.MEDIUM]: "7px 0 5px 0",
    [TAB_SIZE.LARGE]: "5px 0 3px 0",
  },
  [DEVICE_TYPE.PC]: {
    [TAB_SIZE.SMALL]: "8px 0 6px 0",
    [TAB_SIZE.MEDIUM]: "23px 0 21px 0",
    [TAB_SIZE.LARGE]: "22px 0 19px 0",
  },
};

export const TabListStyle = styled.div<{
  top: number;
  deviceType: DEVICE_TYPE | null;
  activeColor?: string;
  normalColor?: string;
  backgroundColor?: string;
}>`
  position: sticky;
  top: ${({ top }) => top + "px"};
  width: 100%;
  z-index: ${Z_INDEX.TAB};
  background-color: ${({ backgroundColor, theme }) => backgroundColor || theme.adsColor.white};
  border-bottom: ${({ theme }) => `1px solid ${theme.adsColor.gray_04}`};
  .swiper-slide {
    width: auto;
  }

  .item.active {
    color: ${({ activeColor, theme }) => activeColor || theme.adsColor.black};
    border-color: ${({ activeColor, theme }) => activeColor || theme.adsColor.black};
  }

  .item {
    user-select: none;
    -webkit-text-size-adjust: none;
    color: ${({ normalColor, theme }) => normalColor || theme.adsColor.gray_07};
  }
`;

export const FlexTabStyle = styled.li<{ isActive: boolean; size: ValueOf<typeof TAB_SIZE>; deviceType: DEVICE_TYPE | null }>`
  width: fit-content;
  padding: ${({ deviceType }) => (deviceType === DEVICE_TYPE.PC ? "0 26px" : "0 16px")};
  text-align: center;
  cursor: pointer;

  & > span {
    ${({ theme, size }) => theme.adsTypo[tabTypoMap[size]]};
    border-bottom: ${({ isActive, deviceType,size }) =>
      isActive ? `${deviceType === DEVICE_TYPE.PC && size === TAB_SIZE.LARGE ? 3 : 2}px solid` : "none"};
    display: block;
    padding: ${({ size, deviceType }) => (deviceType ? flexTapPaddingMap[deviceType][size] : 0)};
  }
`;

export const FullTabStyle = styled.li<{
  isActive: boolean;
  size: ValueOf<typeof TAB_SIZE>;
  deviceType: DEVICE_TYPE | null;
}>`
  ${({ theme, size }) => theme.adsTypo[tabTypoMap[size]]};
  width: 100%;
  padding: ${({ size, deviceType }) => (deviceType ? fullTabPaddingMap[deviceType][size] : 0)};
  box-sizing: border-box;
  border-bottom: ${({ isActive, deviceType, size }) =>
    isActive ? `${deviceType === DEVICE_TYPE.PC && size === TAB_SIZE.LARGE ? 3 : 2}px solid` : "none"};
  text-align: center;
  cursor: pointer;

  & > span {
    display: block;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;
