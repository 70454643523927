import React, { ReactNode, useState, useEffect, JSX } from "react";
import { FirebaseApp } from "firebase/app";
import { fetchAndActivate, getRemoteConfig, RemoteConfig } from "firebase/remote-config";
import { DEFAULT_CONFIG } from "./constants/remoteConfig";

export const FirebaseContext = React.createContext<{
  firebaseApp: FirebaseApp;
  remoteConfig: RemoteConfig | null;
} | null>(null);

interface IFirebaseProviderProps {
  children: ReactNode;
  firebaseApp: FirebaseApp;
}

export const FirebaseProvider = ({ children, firebaseApp }: IFirebaseProviderProps): JSX.Element => {
  const [remoteConfig, setRemoteConfig] = useState<RemoteConfig | null>(null);

  useEffect(() => {
    const _remoteConfig = getRemoteConfig(firebaseApp);
    const minimumFetchIntervalMinute = process.env.NODE_ENV === "production" ? 60 : 5;
    _remoteConfig.settings.minimumFetchIntervalMillis = minimumFetchIntervalMinute * 60 * 1000;
    _remoteConfig.defaultConfig = DEFAULT_CONFIG;

    fetchAndActivate(_remoteConfig)
      .then(() => {
        setRemoteConfig(_remoteConfig);
      })
      .catch((error) => console.error(error));
  }, [firebaseApp]);

  return (
    <FirebaseContext.Provider
      value={{
        firebaseApp,
        remoteConfig,
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};
