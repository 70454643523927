import { isValid } from "date-fns";

/**
 * 날짜 유효성 체크
 *
 * @param {any} targetDate 유효한 날짜인지 판별할 날짜
 *
 * @returns {boolean} 유효한 날짜인지 여부
 */
export const isValidDate = (targetDate: string | Date): boolean => {
  return isValid(typeof targetDate === "string" ? new Date(targetDate) : targetDate);
};
