import styled from "../../style/themeComponents";

export const TabsWrapperStyle = styled.section<{ isScrollOverTab: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;

  .tab-header {
    position: ${({ isScrollOverTab }) => (isScrollOverTab ? "initial" : "sticky")};
  }
`;

export const PanelStyle = styled.section``;
