import styled from "../../../style/themeComponents";

export const SwipeTabPanelStyle = styled.div`
  width: 100%;

  .swiper-slide {
    width: 100%;
  }
`;

export const SwipeTabWrapperStyle = styled.div`
  width: 100%;
`;
