import * as React from "react";
import { FC, memo } from "react";

import { SpinnerIconStyle } from "./style";

interface ISpinnerIconProps {
  className?: string;
}

const SpinnerIcon: FC<ISpinnerIconProps> = (props: ISpinnerIconProps) => {
  const { className } = props;

  return (
    <SpinnerIconStyle className={className}>
      <div className="ispinner-blade" />
      <div className="ispinner-blade" />
      <div className="ispinner-blade" />
      <div className="ispinner-blade" />
      <div className="ispinner-blade" />
      <div className="ispinner-blade" />
      <div className="ispinner-blade" />
      <div className="ispinner-blade" />
      <div className="ispinner-blade" />
      <div className="ispinner-blade" />
      <div className="ispinner-blade" />
      <div className="ispinner-blade" />
    </SpinnerIconStyle>
  );
};

export default memo(SpinnerIcon);
