/**
 * 전화번호를 지정된 형식으로 포맷합니다.
 *
 * @param {string} [phoneNumber] 변환할 전화번호
 * @returns {string} "-"이 포함된 전화번호
 */
export const formatCustomerServiceNumber = (phoneNumber?: string): string => {
  // 입력된 전화번호가 없거나, 길이가 기준에 맞지 않으면 "-" 반환
  if (!phoneNumber || (phoneNumber.length !== 10 && phoneNumber.length !== 11 && phoneNumber.length !== 12)) {
    return "-";
  }

  // 안심번호(050으로 시작하는 번호) 포맷 조건 체크
  const isSafetyNumber = phoneNumber.startsWith("050");
  // 지역번호가 2자리인 경우 (지역번호가 두자리인 서울)
  const isLocalTwoDigits = phoneNumber.startsWith("02");

  // 포맷 패턴 설정
  let pattern;
  if (isSafetyNumber) {
    // 12자리 안심번호
    pattern = /(\d{4})(\d{4})(\d{4})/;
  } else if (isLocalTwoDigits && phoneNumber.length === 10) {
    // 2자리 지역번호, 10자리 전체
    pattern = /(\d{2})(\d{4})(\d{4})/;
  } else {
    // 기본 11자리 전화번호
    pattern = /(\d{3})(\d{4})(\d{4})/;
  }
  // 전화번호를 지정된 패턴으로 변환
  return phoneNumber.replace(pattern, "$1-$2-$3");
};
