import { ProductBundleItemType } from "@amondz/types";

export enum FromType {
  HOME = "home",
  PRODUCT_DETAIL_PAGE = "product_detail_page",
}

export enum eventType {
  viewHome = "VIEW_HOME",
  // viewSubHome = "VIEW_SUB_HOME",
  viewProductList = "VIEW_PRODUCT_LIST",
  viewProductDetail = "VIEW_PRODUCT_DETAIL",
  // viewBrandList = "VIEW_BRAND_LIST",
  viewBrandDetail = "VIEW_BRAND_DETAIL",
  viewWidgetDetail = "VIEW_WIDGET_DETAIL",
  viewEventDetail = "VIEW_EVENT_DETAIL",
  viewSearchResult = "VIEW_SEARCH_RESULT",
  // viewCart = "VIEW_CART",
  // viewPayment = "VIEW_PAYMENT",
  likeProduct = "LIKE_PRODUCT",
  unLikeProduct = "UNLIKE_PRODUCT",
  likeBrand = "LIKE_BRAND",
  unLikeBrand = "UNLIKE_BRAND",
  addCart = "ADD_CART",
  beginPayment = "BEGIN_PAYMENT",
  submitPayment = "SUBMIT_PAYMENT",
  completePayment = "COMPLETE_PAYMENT",
  applyEvent = "APPLY_EVENT",
  search = "SEARCH",
}

export type viewHome = {
  userId?: number | null;
  isLoggedIn: boolean;
};
export type viewProductList = {
  userId?: number | null;
};
export type viewProductDetail = {
  userId?: number | null;
  productId: number;
  from?: FromType;
};
export type viewBrandDetail = {
  userId?: number | null;
  brandId: number;
};
export type viewSearchResult = {
  userId?: number | null;
};
export type viewEventDetail = {
  userId?: number | null;
};
export type viewWidgetDetail = {
  userId?: number | null;
};
export type likeProduct = {
  userId?: number | null;
  productId: number;
  productName: string;
};
export type unLikeProduct = {
  userId?: number | null;
  productId: number;
  productName: string;
};
export type likeBrand = {
  userId?: number | null;
  brandId: number;
  brandName: string;
};
export type unLikeBrand = {
  userId?: number | null;
  brandId: number;
  brandName: string;
};
export type addCart = {
  userId?: number | null;
  productId: number;
};
export type completePayment = {
  userId?: number | null;
  productId: number | number[];
  price: number | number[];
};
export type search = {
  userId?: number | null;
  search: string;
};
export type beginPayment = {
  hasCoupon: boolean;
  productBundleList: ProductBundleItemType[];
};
export type submitPayment = {
  hasCoupon: boolean;
  point: number;
  paymentMethod: string;
  productBundleList: ProductBundleItemType[];
};
export type applyEvent = {
  code: string;
};

export type eventValue = {
  [eventType.viewHome]: viewHome;
  [eventType.viewProductList]: viewProductList;
  [eventType.viewProductDetail]: viewProductDetail;
  [eventType.viewBrandDetail]: viewBrandDetail;
  [eventType.viewEventDetail]: viewEventDetail;
  [eventType.viewWidgetDetail]: viewWidgetDetail;
  [eventType.viewSearchResult]: viewSearchResult;
  [eventType.likeProduct]: likeProduct;
  [eventType.unLikeProduct]: unLikeProduct;
  [eventType.likeBrand]: likeBrand;
  [eventType.unLikeBrand]: unLikeBrand;
  [eventType.addCart]: addCart;
  [eventType.beginPayment]: beginPayment;
  [eventType.submitPayment]: submitPayment;
  [eventType.completePayment]: completePayment;
  [eventType.applyEvent]: applyEvent;
  [eventType.search]: search;
};
