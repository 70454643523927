import TagManager from "react-gtm-module";
import { GTM_EVENT, GTMProductType } from "../../../types";

export interface GTMPurchaseEventType {
  // 주문 번호
  orderId: string;
  // 결제 금액
  totalPrice: number;
  products: GTMProductType[];
}

/**
 * 결제 완료 이벤트 설정
 *
 * https://developers.google.com/analytics/devguides/collection/ga4/reference/events#purchase
 */
export const purchase = (data: GTMPurchaseEventType): void => {
  const { orderId, totalPrice, products} = data;

  const dataLayer = {
    event: GTM_EVENT.PURCHASE,
    currency: "KRW",
    value: totalPrice,
    transaction_id: orderId,
    items: products,
  };

  TagManager.dataLayer({
    dataLayer,
  });
};
