import { createReducer } from "typesafe-actions";
import { produce } from "immer";

import { SearchAction, SearchStateType } from "@store/modules/search/types";
import {
  INTEGRATION_SEARCH_COUNT,
  PRODUCT_SEARCH_COUNT,
  STORE_SEARCH_COUNT,
  REVIEW_SEARCH_COUNT,
  EVENT_SEARCH_COUNT,
  RELATED_SEARCH_WORD_LIST,
  SEARCH_MODAL_VISIBLE,
  SET_SEARCH_KEYWORD,
  SET_LAST_SEARCH_KEYWORD,
  SET_RECENT_SEARCH_KEYWORD_LIST,
} from "@store/modules/search/actions";

export const initialSearchState: SearchStateType = {
  // 검색 결과 갯수
  searchResultCountState: {
    // 통합 검색 결과 갯수
    integration: 0,
    // 상품 검색 결과 갯수
    product: 0,
    // 브랜드 검색 결과 갯수
    store: 0,
    // 리뷰 검색 결과 갯수
    review: 0,
    // 기획전 검색 결과 갯수
    event: 0,
  },
  // 연관 검색어
  relatedSearchWordListState: {
    relatedSearchWordList: [],
  },
  // 검색 모달
  searchModalState: {
    // 검색한 키워드
    searchKeyword: "",
    // 마지막으로 검색한 키워드
    lastSearchKeyword: "",
    // 검색한 키워드 리스트
    recentSearchKeywordList: [],
    // 검색 모달 노출 여부
    isSearchModalVisible: false,
  },
};

const searchReducer = createReducer<SearchStateType, SearchAction>(initialSearchState, {
  // 통합 검색 결과 수
  [INTEGRATION_SEARCH_COUNT]: produce((draft, action) => {
    draft.searchResultCountState.integration = action.payload.count;
    draft.searchResultCountState.status = action.payload.status;
  }),
  // 상품 검색 결과 수
  [PRODUCT_SEARCH_COUNT]: produce((draft, action) => {
    draft.searchResultCountState.product = action.payload.count;
    draft.searchResultCountState.status = action.payload.status;
  }),
  // 브랜드 검색 결과 수
  [STORE_SEARCH_COUNT]: produce((draft, action) => {
    draft.searchResultCountState.store = action.payload.count;
    draft.searchResultCountState.status = action.payload.status;
  }),
  // 리뷰 검색 결과 수
  [REVIEW_SEARCH_COUNT]: produce((draft, action) => {
    draft.searchResultCountState.review = action.payload.count;
    draft.searchResultCountState.status = action.payload.status;
  }),
  // 기획전 검색 결과 수
  [EVENT_SEARCH_COUNT]: produce((draft, action) => {
    draft.searchResultCountState.event = action.payload.count;
    draft.searchResultCountState.status = action.payload.status;
  }),
  // 연관 검색어
  [RELATED_SEARCH_WORD_LIST]: produce((draft, action) => {
    draft.relatedSearchWordListState = action.payload;
  }),
  // 검색한 키워드 설정
  [SET_SEARCH_KEYWORD]: produce((draft, action) => {
    draft.searchModalState.searchKeyword = action.payload.searchKeyword;
  }),
  // 마지막으로 검색한 키워드 설정
  [SET_LAST_SEARCH_KEYWORD]: produce((draft, action) => {
    draft.searchModalState.lastSearchKeyword = action.payload.lastSearchKeyword;
  }),
  // 검색한 키워드 리스트 설정
  [SET_RECENT_SEARCH_KEYWORD_LIST]: produce((draft, action) => {
    draft.searchModalState.recentSearchKeywordList = action.payload.recentSearchKeywordList;
  }),
  // 검색 모달 노출 여부
  [SEARCH_MODAL_VISIBLE]: produce((draft, action) => {
    draft.searchModalState.isSearchModalVisible = action.payload.isSearchModalVisible;
  }),
});

export default searchReducer;
