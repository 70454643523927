import { AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { createBrowserHistory } from "history";
import { isNil } from "lodash";
import SuperFetch from "@amondz/apis-v1";
import cookies, { getAmondzDeviceType, getAmondzLoginEx } from "@lib/utility/cookies";
import { AMONDZ_DEVICE_TYPE_KEY, AMONDZ_LOGIN_TOKEN_KEY, AMONDZ_UUID_KEY } from "@constants/service/auth/auth";
import { setUuidOption } from "@lib/utility/common";
import { getDeviceOption } from "@lib/utility/deviceDetect";
import { ProductListKey } from "@swr/keys/productKeys";
import { SubHomeListKey } from "@swr/keys/homeKeys";
import { PAGE_URL_PAGE_VIEW_MAP } from "@constants/common/pageViews";
import {
  INTRO_PAGE_URL_PATH,
  PRODUCT_DETAIL_PAGE_URL_PATH,
  MY_PAGE_COUPON_PRODUCT_URL_PATH,
  WIDGET_PAGE_URL_PATH,
  EVENT_PAGE_URL_PATH,
  APP_EVENT_PAGE_URL_PATH,
  BRAND_PAGE_URL_PATH,
  BRAND_LIST_PAGE_URL_PATH,
  MY_PAGE_COUPON_BRAND_URL_PATH,
  HOME_SUB_PAGE_URL_PATH,
  SEARCH_RESULT_PAGE_URL_PATH,
} from "@constants/url/internalUrlConstants";
import { WEB_SERVICE_VERSION_CODE } from "@constants/service/common/common";
import { errorHandlerV1 } from "@services/errorHandlers";

const baseURL = process.env.NEXT_PUBLIC_API_URL;
//https://github.com/axios/axios/issues/5494
SuperFetch.interceptors.request.use((req: InternalAxiosRequestConfig) => {
  console.log("request base url: ", baseURL);
  console.log("api url: ", req.url);

  if (!req.headers) {
    return req;
  }

  let userKey = getAmondzLoginEx(req.headers?.Cookie as string);

  if (!userKey) {
    userKey = cookies.get(AMONDZ_LOGIN_TOKEN_KEY) || null;
  }
  req.headers["user-key"] = userKey;

  let urlPath: string | undefined;
  if (typeof window !== "undefined") {
    // CSR 에서 UUID 설정
    // CSR 에서 Device option 확인
    let uuid = cookies.get(AMONDZ_UUID_KEY);
    console.log("uuid: ", uuid);
    if (!uuid) {
      // browser 에 uuid 가 없을 경우 uuid 를 새롭게 만들어서 쿠키에 저장함
      const uuidOption = setUuidOption();
      cookies.set(AMONDZ_UUID_KEY, uuidOption.uuid, {
        path: "/",
        expires: uuidOption.date,
      });
      uuid = uuidOption.uuid;
    }
    req.headers.uuid = uuid;

    // page view 설정
    const history = createBrowserHistory();
    const prevUrlPath = sessionStorage.getItem("prevUrlPath");
    if (
      prevUrlPath &&
      prevUrlPath === INTRO_PAGE_URL_PATH &&
      (req.url === ProductListKey || req.url === SubHomeListKey)
    ) {
      urlPath = prevUrlPath;
      // 이전 페이지 path 를 삭제하여 browser 를 refresh 를 하더라도 이전 페이지 path 를 사용하지 못하도록 함
      sessionStorage.removeItem("prevUrlPath");
    } else {
      urlPath = history.location.pathname;
    }
  } else {
    // SRR 에서는 요청한 페이지의 url 을 이용하여 pageview 를 만들어야 함
    urlPath = req.headers?.prevUrlPath as string;
    delete req.headers.prevUrlPath;
  }

  if (urlPath) {
    let pageView = PAGE_URL_PAGE_VIEW_MAP[urlPath];
    if (isNil(pageView)) {
      if (urlPath.substr(0, HOME_SUB_PAGE_URL_PATH.length) === HOME_SUB_PAGE_URL_PATH) {
        // 홈 서브 페이지
        pageView = PAGE_URL_PAGE_VIEW_MAP[HOME_SUB_PAGE_URL_PATH];
      } else if (urlPath.substr(0, PRODUCT_DETAIL_PAGE_URL_PATH.length) === PRODUCT_DETAIL_PAGE_URL_PATH) {
        // 상품 상세 페이지
        pageView = PAGE_URL_PAGE_VIEW_MAP[PRODUCT_DETAIL_PAGE_URL_PATH];
      } else if (urlPath.substr(0, 15) === "/mypage/coupon/" && urlPath.substr(-8) === "/product") {
        // 마이 > 쿠폰 > 적용 가능 상품 페이지
        pageView = PAGE_URL_PAGE_VIEW_MAP[MY_PAGE_COUPON_PRODUCT_URL_PATH];
      } else if (urlPath.substr(0, 15) === "/mypage/coupon/" && urlPath.substr(-6) === "/brand") {
        // 마이 > 쿠폰 > 적용 가능 브랜드 페이지
        pageView = PAGE_URL_PAGE_VIEW_MAP[MY_PAGE_COUPON_BRAND_URL_PATH];
      } else if (urlPath.substr(0, WIDGET_PAGE_URL_PATH.length) === WIDGET_PAGE_URL_PATH) {
        // 위젯 상세 페이지
        pageView = PAGE_URL_PAGE_VIEW_MAP[WIDGET_PAGE_URL_PATH];
      } else if (
        urlPath.substr(0, EVENT_PAGE_URL_PATH.length) === EVENT_PAGE_URL_PATH ||
        urlPath.substr(0, APP_EVENT_PAGE_URL_PATH.length) === APP_EVENT_PAGE_URL_PATH
      ) {
        // 기획전 상세 페이지
        pageView = PAGE_URL_PAGE_VIEW_MAP[EVENT_PAGE_URL_PATH];
      } else if (urlPath.substr(0, BRAND_LIST_PAGE_URL_PATH.length) === BRAND_LIST_PAGE_URL_PATH) {
        // 브랜드 목록 페이지
        pageView = PAGE_URL_PAGE_VIEW_MAP[BRAND_LIST_PAGE_URL_PATH];
      } else if (urlPath.substr(0, BRAND_PAGE_URL_PATH.length) === BRAND_PAGE_URL_PATH) {
        // 브랜드 상세 페이지
        pageView = PAGE_URL_PAGE_VIEW_MAP[BRAND_PAGE_URL_PATH];
      } else if (urlPath.substr(0, SEARCH_RESULT_PAGE_URL_PATH.length) === SEARCH_RESULT_PAGE_URL_PATH) {
        // 검색 페이지
        pageView = PAGE_URL_PAGE_VIEW_MAP[SEARCH_RESULT_PAGE_URL_PATH];
      }
    }

    if (typeof req.headers["page-view"] !== "number" && !isNil(pageView)) {
      req.headers["page-view"] = pageView;
    }
  }

  console.log("-------------------------디바이스 옵션-------------------------");
  const deviceOption = getDeviceOption(req.headers?.userAgent as string);

  // deviceType 설정
  let deviceType = getAmondzDeviceType(req.headers?.Cookie as string);
  if (!deviceType) {
    deviceType = cookies.get(AMONDZ_DEVICE_TYPE_KEY) || deviceOption.deviceType;
  }
  console.log("device_type: ", deviceType);
  req.headers["device-type"] = deviceType;
  req.headers["os"] = deviceOption.os;
  req.headers["version"] = WEB_SERVICE_VERSION_CODE;

  console.log("req.headers: ", req.headers);

  console.log("------- 서버 요청 -------");
  console.log(req.data);
  return req;
});

SuperFetch.interceptors.response.use((res: AxiosResponse) => {
  console.log("------- 서버 응답 -------");
  console.log(res.data);
  errorHandlerV1(res.data.status);
  return res.data;
});
