import { createReducer } from "typesafe-actions";
import { produce } from "immer";
import { sumBy, isNil } from "lodash";

import { asyncState } from "@lib/utility/reducerUtils";
import { STATUS_CODE_COMMON } from "@constants/status/code/statusCodeCommon";
import { POINT_RATE } from "@constants/service/payment/payment";
import {
  DEFAULT_SHIPPING_ADDRESS,
  PAY_METHOD_AVAILABILITY,
  PAYMENT_MODAL_NAME_TYPE,
} from "@constants/enum/paymentEnums";
import { CartAction, CartInfoStateType, CartItemStateType, PaymentStateType } from "./types";
import {
  SYNC_CART_LIST,
  GET_CART_LIST,
  TOGGLE_CHECK_BOX,
  DELETE_CART_LIST,
  UPDATE_CART_COUNT,
  TOGGLE_CHECK_BOX_ALL,
  UPDATE_CART_OPTION,
  ORDER_PREPARATION,
  ORDER_PAY,
  PAY_RESULT,
  DELIVERY_ADDRESS_LIST,
  PAYMENT_MODAL_TOGGLE,
  SET_PRODUCT_BUNDLE_LIST,
  SET_BUYER_INFO,
  SET_DELIVERY_INFO,
  SET_POINT,
  SET_COUPON,
  COUPON_LIST,
  DELIVERY_EXTRA_FEE,
  INIT_PAYMENT_CHECKOUT,
  RESET_PAYMENT_MODAL_STATE,
} from "./actions";

export const initialPaymentState: PaymentStateType = {
  cartInfoState: asyncState.initial<CartInfoStateType>({
    totalPrice: 0,
    totalCount: 0,
    point: 0,
    totalLength: 0,
    checkedLength: 0,
    cartList: [],
  }),

  paymentCheckoutState: {},

  paymentModalState: {
    // 주문자 정보 수정 모달
    buyerInfoModal: {
      visible: false,
    },
    // 쿠폰 리스트 모달
    couponListModal: {
      visible: false,
      data: {
        productBundleIndex: null,
        productIndex: null,
        selectedCouponId: null,
      },
    },
    // 배송지 정보 모달
    deliveryInfoModal: {
      visible: false,
    },
  },
};

const cartReducer = createReducer<PaymentStateType, CartAction>(initialPaymentState, {
  // 장바구니 데이터 동기화
  [SYNC_CART_LIST]: produce((draft) => {
    const total = draft.cartInfoState.data;
    const cartList: CartItemStateType[] = [];

    total.totalPrice = 0;
    total.totalCount = 0;
    total.totalLength = 0;
    total.checkedLength = 0;
    total.cartList.forEach((store: CartItemStateType) => {
      if (!store.productList.length) return;

      store.storeSumPrice = 0;
      store.productList.forEach((product) => {
        product.sumPrice = product.price * product.count;
        total.totalLength += 1;

        if (product.isChecked) {
          store.storeSumPrice += product.sumPrice;
          total.totalPrice += product.sumPrice;
          total.totalCount += product.count;
          total.checkedLength += 1;
        }
      });
      cartList.push(store);
    });
    total.cartList = cartList;
    total.point = Math.floor(total.totalPrice / 100);
  }),
  // 장바구니 리스트 요청
  [GET_CART_LIST.REQUEST]: produce((draft) => {
    draft.cartInfoState.loading = true;
    draft.cartInfoState.data.totalPrice = 0;
    draft.cartInfoState.data.totalCount = 0;
    draft.cartInfoState.data.point = 0;
    draft.cartInfoState.data.totalLength = 0;
    draft.cartInfoState.data.checkedLength = 0;
    draft.cartInfoState.data.cartList = [];
  }),
  // 장바구니 리스트 요청 성공
  [GET_CART_LIST.SUCCESS]: produce((draft, action) => {
    draft.cartInfoState.loading = false;
    draft.cartInfoState.data.totalPrice = action.payload.data.totalPrice;
    draft.cartInfoState.data.totalCount = action.payload.data.totalCount;
    draft.cartInfoState.data.point = action.payload.data.point;
    draft.cartInfoState.data.cartList = action.payload.data.cartList;
    draft.cartInfoState.data.cartList.forEach((store: CartItemStateType) => {
      store.productList.forEach((product) => {
        draft.cartInfoState.data.totalLength += 1;
        draft.cartInfoState.data.checkedLength += 1;
        product.isChecked = true;
        product.loading = false;
      });
    });
  }),

  // 체크 박스 토글
  [TOGGLE_CHECK_BOX]: produce((draft, action) => {
    const store = draft.cartInfoState.data.cartList[action.payload.storeIndex];
    const product = store.productList[action.payload.productIndex];
    product.isChecked = !product.isChecked;
  }),
  // 전체 체크 박스 토글
  [TOGGLE_CHECK_BOX_ALL]: produce((draft, action) => {
    draft.cartInfoState.data.cartList.forEach((store: CartItemStateType) => {
      store.productList.forEach((product) => {
        product.isChecked = action.payload.state;
      });
    });
  }),

  // 장바구니 상품 여러개 삭제 요청
  // TODO: 스피너
  [DELETE_CART_LIST.REQUEST]: produce(() => {}),

  // 장바구니 상품 여러개 삭제 요청 성공
  [DELETE_CART_LIST.SUCCESS]: produce((draft, action) => {
    const { cartList } = action.payload?.data;
    let index = 0;
    draft.cartInfoState.data.cartList.forEach((store: CartItemStateType) => {
      if (cartList.length <= index) return;

      store.productList = store.productList.filter((product) => {
        if (cartList.length <= index) return true;

        // 삭제된 상품 찾기
        const isFlag =
          product.id === cartList[index].cartId &&
          product.productId === cartList[index].productId &&
          product.optionId === cartList[index].productOptionId;

        // 삭제한 상품 제
        if (isFlag) {
          index += 1;
        }

        return !isFlag;
      });
    });
  }),

  // 장바구니 상품 수량 변경 요청
  [UPDATE_CART_COUNT.REQUEST]: produce((draft, action) => {
    const store = draft.cartInfoState.data.cartList[action.payload.storeIndex];
    const product = store.productList[action.payload.productIndex];
    product.loading = true;
  }),
  // 장바구니 상품 수량 변경 요청 성공
  [UPDATE_CART_COUNT.SUCCESS]: produce((draft, action) => {
    const store = draft.cartInfoState.data.cartList[action.payload.data.storeIndex];
    const product = store.productList[action.payload.data.productIndex];
    product.loading = false;
    product.count = action.payload.data.count;
  }),
  // 장바구니 상품 옵션 변경 요청
  [UPDATE_CART_OPTION.REQUEST]: produce((draft, action) => {
    const store = draft.cartInfoState.data.cartList[action.payload.storeIndex];
    const product = store.productList[action.payload.productIndex];
    product.loading = true;
  }),
  // 장바구니 상품 옵션 변경 요청 성공
  [UPDATE_CART_OPTION.SUCCESS]: produce((draft, action) => {
    const store = draft.cartInfoState.data.cartList[action.payload.data.storeIndex];
    const product = store.productList[action.payload.data.productIndex];
    product.loading = false;
    product.count = action.payload.data.count;
  }),

  // 상품 번들 데이터 설정
  [SET_PRODUCT_BUNDLE_LIST]: produce((draft, action) => {
    draft.paymentCheckoutState = {
      productBundleList: action.payload.productBundleList,
      isFromCart: action.payload.isFromCart,
      isRefresh: action.payload.isRefresh,
    };
  }),

  // 결제 데이터 초기화
  [INIT_PAYMENT_CHECKOUT]: produce((draft) => {
    draft.paymentCheckoutState = {};
  }),

  // 결제 준비 요청 성공
  [ORDER_PREPARATION.SUCCESS]: produce((draft, action) => {
    let { productBundleList } = draft.paymentCheckoutState;

    // 각 상품번들 마다 추가 배송비와 총 결제 금액 업데이트
    productBundleList = productBundleList.map((productBundle: any) => {
      const sumDeliveryExtraFee =
        action.payload.data.extraFeeList?.find((extraFee: any) => extraFee.storeId === productBundle.storeId)
          ?.extraFee || 0;
      return {
        ...productBundle,
        sumDeliveryExtraFee,
        sumTotalPrice: sumBy(productBundle.productList, "totalPrice") + sumDeliveryExtraFee,
      };
    });

    const totalPrice = sumBy(productBundleList, (productBundle: any) =>
      sumBy(productBundle.productList, (product: any) => product.totalPrice),
    );
    const totalDeliveryExtraFee = sumBy(productBundleList, (productBundle: any) => productBundle.sumDeliveryExtraFee);

    draft.paymentCheckoutState.orderPreparation = action.payload;
    // 주문 결제 집계 데이터
    draft.paymentCheckoutState.orderPreparation.data.orderSummaryPrice = {
      totalPrice,
      totalDeliveryExtraFee,
      totalUsedPoint: 0,
      totalUsedCouponAmount: 0,
      totalPaidPrice: totalPrice + totalDeliveryExtraFee,
      savingPoint: Math.floor(totalPrice * POINT_RATE),
    };

    draft.paymentCheckoutState.productBundleList = productBundleList;

    // 환불 은행 리스트에 "은행 선택" 항목 추가
    draft.paymentCheckoutState.orderPreparation.data.refund.bankList = [
      {
        id: "all",
        name: "은행 선택",
      },
      ...draft.paymentCheckoutState.orderPreparation.data.refund.bankList,
    ];

    // 메인 주소가 있을 경우 isMainAddress 에 true(1) 로 설정
    if (draft.paymentCheckoutState.orderPreparation.data.mainAddress) {
      draft.paymentCheckoutState.orderPreparation.data.mainAddress.isMainAddress = DEFAULT_SHIPPING_ADDRESS.SET;
    }

    draft.paymentCheckoutState.orderPreparation.data.payMethodSelectList = action.payload.data.payMethodList
      .filter((method: any) => method.availabilityType !== PAY_METHOD_AVAILABILITY.IMPOSSIBLE)
      .map((method: any) => ({ ...method, id: method.type }));
  }),

  // 추가 배송비 요금
  [DELIVERY_EXTRA_FEE.SUCCESS]: produce((draft, action) => {
    let { productBundleList } = draft.paymentCheckoutState;

    // 각 상품번들 마다 추가 배송비와 총 결제 금액 업데이트
    productBundleList = productBundleList.map((productBundle: any) => {
      const sumDeliveryExtraFee =
        action.payload.extraFeeList?.find((extraFee: any) => extraFee.storeId === productBundle.storeId)?.extraFee || 0;
      return {
        ...productBundle,
        sumDeliveryExtraFee,
        sumTotalPrice: sumBy(productBundle.productList, "totalPrice") + sumDeliveryExtraFee,
      };
    });

    const totalDeliveryExtraFee = sumBy(productBundleList, (productBundle: any) => productBundle.sumDeliveryExtraFee);
    // 주문 결제 집계 데이터
    draft.paymentCheckoutState.orderPreparation.data.orderSummaryPrice.totalDeliveryExtraFee = totalDeliveryExtraFee;
    draft.paymentCheckoutState.orderPreparation.data.orderSummaryPrice.totalPaidPrice =
      draft.paymentCheckoutState.orderPreparation.data.orderSummaryPrice.totalPrice +
      totalDeliveryExtraFee -
      draft.paymentCheckoutState.orderPreparation.data.orderSummaryPrice.totalUsedCouponAmount -
      draft.paymentCheckoutState.orderPreparation.data.orderSummaryPrice.totalUsedPoint;
    draft.paymentCheckoutState.productBundleList = productBundleList;
  }),

  // 주문자 정보 수정
  [SET_BUYER_INFO]: produce((draft, action) => {
    draft.paymentCheckoutState.orderPreparation.data.buyer = action.payload;
  }),

  // 배송지 정보 수정
  [SET_DELIVERY_INFO]: produce((draft, action) => {
    draft.paymentCheckoutState.orderPreparation.data.mainAddress = action.payload;
  }),

  // 포인트 적용
  [SET_POINT]: produce((draft, action) => {
    // 최종 결제 금액 - 포인트 사용
    draft.paymentCheckoutState.orderPreparation.data.orderSummaryPrice.totalUsedPoint = action.payload.point;

    // 추가 배송비를 제외한 총 결제 금액
    const totalPrice =
      draft.paymentCheckoutState.orderPreparation.data.orderSummaryPrice.totalPrice -
      draft.paymentCheckoutState.orderPreparation.data.orderSummaryPrice.totalUsedPoint -
      draft.paymentCheckoutState.orderPreparation.data.orderSummaryPrice.totalUsedCouponAmount;

    // 최종 결제 금액 - 총 결제금액
    draft.paymentCheckoutState.orderPreparation.data.orderSummaryPrice.totalPaidPrice =
      totalPrice + draft.paymentCheckoutState.orderPreparation.data.orderSummaryPrice.totalDeliveryExtraFee;

    // 최종 결제 금액 - 적립 예정 포인트
    draft.paymentCheckoutState.orderPreparation.data.orderSummaryPrice.savingPoint = Math.floor(
      totalPrice * POINT_RATE,
    );
  }),

  // 쿠폰 적용
  [SET_COUPON]: produce((draft, action) => {
    const { beforeProductBundleIndex, beforeProductIndex, productBundleIndex, productIndex, coupon } = action.payload;

    // 쿠폰 적용할 상품이 없을 경우 쿠폰 적용 로직을 태우지 않음
    const productBundle = draft.paymentCheckoutState.productBundleList[productBundleIndex];
    if (isNil(productBundle) || isNil(productBundle.productList[productIndex])) return;

    if (!isNil(beforeProductBundleIndex) && !isNil(beforeProductIndex)) {
      // 이전 상품 번들(브랜드) - 상품 건 - 쿠폰 적용
      draft.paymentCheckoutState.productBundleList[beforeProductBundleIndex].productList[beforeProductIndex].coupon =
        undefined;

      // 이전 상품 번들(브랜드) - 쿠폰 금액
      draft.paymentCheckoutState.productBundleList[beforeProductBundleIndex].sumCouponPrice = sumBy(
        draft.paymentCheckoutState.productBundleList[beforeProductBundleIndex].productList,
        (product: any) => {
          if (product.coupon) {
            return product.coupon.discountPrice;
          }
          return 0;
        },
      );

      // 이전 상품 번들(브랜드) - 총 결제금액
      draft.paymentCheckoutState.productBundleList[beforeProductBundleIndex].sumTotalPrice =
        draft.paymentCheckoutState.productBundleList[beforeProductBundleIndex].sumSalesPrice -
        draft.paymentCheckoutState.productBundleList[beforeProductBundleIndex].sumCouponPrice;
    }

    // 상품 번들(브랜드) - 상품 건 - 쿠폰 적용
    productBundle.productList[productIndex].coupon = coupon;

    // 상품 번들(브랜드) - 쿠폰 금액
    productBundle.sumCouponPrice = sumBy(productBundle.productList, (product: any) => {
      if (product.coupon) {
        return product.coupon.discountPrice;
      }
      return 0;
    });

    // 상품 번들(브랜드) - 총 결제금액
    productBundle.sumTotalPrice = productBundle.sumSalesPrice - productBundle.sumCouponPrice;

    // 최종 결제 금액 - 쿠폰 사용
    draft.paymentCheckoutState.orderPreparation.data.orderSummaryPrice.totalUsedCouponAmount = sumBy(
      draft.paymentCheckoutState.productBundleList,
      "sumCouponPrice",
    );

    // 추가 배송비를 제외한 총 결제 금액
    const totalPrice =
      draft.paymentCheckoutState.orderPreparation.data.orderSummaryPrice.totalPrice -
      draft.paymentCheckoutState.orderPreparation.data.orderSummaryPrice.totalUsedPoint -
      draft.paymentCheckoutState.orderPreparation.data.orderSummaryPrice.totalUsedCouponAmount;

    // 최종 결제 금액 - 총 결제금액
    draft.paymentCheckoutState.orderPreparation.data.orderSummaryPrice.totalPaidPrice =
      totalPrice + draft.paymentCheckoutState.orderPreparation.data.orderSummaryPrice.totalDeliveryExtraFee;

    // 최종 결제 금액 - 적립 예정 포인트
    draft.paymentCheckoutState.orderPreparation.data.orderSummaryPrice.savingPoint = Math.floor(
      totalPrice * POINT_RATE,
    );
  }),

  // 쿠폰 리스트 요청 성공
  [COUPON_LIST.SUCCESS]: produce((draft, action) => {
    const { couponList, selectedCouponId } = action.payload;
    const { productBundleList } = draft.paymentCheckoutState;

    if (!productBundleList) {
      return;
    }

    // 현재 결제페이지에서 사용중인 쿠폰 리스트
    const usedCouponList: { id: number; productBundleIndex: number; productIndex?: number }[] = [];
    productBundleList.forEach((productBundle: any, productBundleIndex: number) => {
      productBundle.productList.forEach((product: any, productIndex: number) => {
        if (product.coupon && product.coupon.id && product.coupon.id !== selectedCouponId) {
          usedCouponList.push({
            id: product.coupon.id,
            productBundleIndex,
            productIndex,
          });
        }
      });
    });

    // 활성 쿠폰 리스트
    couponList.activateCouponList.forEach((couponItem: any) => {
      const targetUsedCoupon = usedCouponList.find((coupon) => coupon.id === couponItem.id);
      if (targetUsedCoupon) {
        couponItem.productBundleIndex = targetUsedCoupon.productBundleIndex;
        couponItem.productIndex = targetUsedCoupon.productIndex;
      }
    });

    draft.paymentCheckoutState.couponList = {
      activateCouponList: couponList.activateCouponList,
      deactivateCouponList: couponList.deactivateCouponList,
    };
  }),

  // 배송지 주소 목록 요청 성공
  [DELIVERY_ADDRESS_LIST.SUCCESS]: produce((draft, action) => {
    draft.paymentCheckoutState.deliveryAddressList = action.payload;
  }),

  // 결제 진행 요청 성공
  [ORDER_PAY.SUCCESS]: produce((draft, action) => {
    draft.paymentCheckoutState.orderPayRequest = {
      status: action.payload.status,
      msg: action.payload.msg,
      orderPayRequestInfo: action.payload.data,
    };
  }),

  // 결제 결과 요청 성공
  [PAY_RESULT.SUCCESS]: produce((draft, action) => {
    draft.paymentCheckoutState.orderPayResult = action.payload;

    if (action.payload.status === STATUS_CODE_COMMON.SUCCESS) {
      // 결제가 성공할 경우에는 서버에서 msg 데이터를 내려주지 않기 때문에 msg 속성을 삭제함
      delete draft.paymentCheckoutState.orderPayResult.msg;
    }
  }),

  // 결제 모달 토글
  [PAYMENT_MODAL_TOGGLE]: produce((draft, action) => {
    draft.paymentModalState[action.payload.targetModal].visible =
      !draft.paymentModalState[action.payload.targetModal].visible;
    draft.paymentModalState[action.payload.targetModal].modalContentsType = action.payload.modalContentsType;

    if (action.payload.data) {
      draft.paymentModalState[action.payload.targetModal].data = action.payload.data;
    }
  }),

  // 결제 모달 토글 초기화
  [RESET_PAYMENT_MODAL_STATE]: produce((draft, action) => {
    const values = Object.values(PAYMENT_MODAL_NAME_TYPE);
    values.forEach((value) => {
      if (!draft.paymentModalState[value]) return;
      draft.paymentModalState[value].visible = false;
    });
  }),
});

export default cartReducer;
