import { STATUS_CODE_COMMON } from "@amondz/constants";
import { GetProductDetailAdditionalSectionRequestType } from "@amondz/types";
import { superFetch } from "../superFetch";
import {
  LikeProductRequest,
  InsertProductQnaRequestType,
  DeleteQnaProductType,
  UpdateQnaProductType,
  ProductAdditionalResponseType,
  GetProductCustomerServiceRequestType,
  GetProductCustomerServiceResponseType,
} from "./type";

export const productAPI = {
  likeProduct: async (data: LikeProductRequest) => {
    const res = await superFetch.post("/like/product/click/web/v2", data);
    if (res.status !== STATUS_CODE_COMMON.SUCCESS) {
      throw new Error("좋아요가 실패했습니다. 잠시 후 다시 시도해주세요");
    }
    return res;
  },
  getProductAdditional: (data: { productId: number }): Promise<ProductAdditionalResponseType> =>
    superFetch.post("/product/detail/first/web/v3", data),
  getProductQnaList: async (data: GetProductDetailAdditionalSectionRequestType) => {
    return superFetch.post<GetProductDetailAdditionalSectionRequestType>("/product/qna/list/pagination/web/v1", data);
  },
  // 상품 문의 등록 요청 API
  insertProductQna: async (data: InsertProductQnaRequestType) => {
    return superFetch.post<InsertProductQnaRequestType>("/product/qna/insert/web/v2", data);
  },
  // 상품 문의 수정 요청 API
  updateProductQna: async (data: UpdateQnaProductType) => {
    return superFetch.post<UpdateQnaProductType>("/mypage/qna/update/web/v1", data);
  },
  // 상품 문의 삭제 요청 API
  deleteProductQna: async (data: DeleteQnaProductType) => {
    return superFetch.post<DeleteQnaProductType>("/mypage/qna/delete/web/v1", data);
  },
  // 교환 및 반품 배송 정보 요청 API
  getProductCustomerServiceInfo: async (data: GetProductCustomerServiceRequestType) => {
    return superFetch.post<GetProductCustomerServiceResponseType>("/product/detail/inform/web/v1", data);
  },
};
