import { DELIVERY_MESSAGE_TYPE, PAYMENT_PAGE_STEP, DELIVERY_INFO_MODAL_TAB_TYPE } from "../../enum/paymentEnums";

// 결제 페이지 스텝 타이틀
export const PAYMENT_PAGE_STEP_TITLE = {
  [PAYMENT_PAGE_STEP.CART]: "장바구니",
  [PAYMENT_PAGE_STEP.PAYMENT]: "결제하기",
  [PAYMENT_PAGE_STEP.COMPLETION]: "주문완료",
};

// 결제 페이지 스텝 리스트
export const PAYMENT_PAGE_STEP_LIST = [PAYMENT_PAGE_STEP.CART, PAYMENT_PAGE_STEP.PAYMENT, PAYMENT_PAGE_STEP.COMPLETION];

// 주문 메시지 최대 글자수
export const MAXIMUM_NUMBER_OF_ORDER_MESSAGE = 100;

// 상세 주소 최대 글자수
export const MAXIMUM_NUMBER_OF_ADDRESS_LINE_2 = 30;

// 포인트 적용 최소 값
export const MINIMUM_VALUE_OF_APPLY_POINT = 0;

// 포인트 적용 최대 값
export const MAXIMUM_VALUE_OF_APPLY_POINT = 999999;

// 계좌번호 최대 글자수
export const MAXIMUM_NUMBER_OF_ACCOUNT_NUMBER = 16;

// 예금주 최대 글자수
export const MAXIMUM_NUMBER_OF_ACCOUNT_HOLDER = 20;

// 배송시 요청사항 최대 글자수
export const MAXIMUM_NUMBER_OF_DELIVERY_MESSAGE = 50;

// 결제 최소 값
export const MINIMUM_VALUE_OF_PAYMENT_PRICE = 2000;

// 포인트 비율
export const POINT_RATE = 0.01;

// 배송 요청 사항 타입 텍스트
export const DELIVERY_MESSAGE_TYPE_TEXT: any = {
  [DELIVERY_MESSAGE_TYPE.PRE_CONTACT]: "배송 전에 미리 연락 바랍니다.",
  [DELIVERY_MESSAGE_TYPE.SECURITY_OFFICE]: "부재시 경비실에 맡겨주세요.",
  [DELIVERY_MESSAGE_TYPE.CONTACT_OR_MESSAGE]: "부재시 전화주시거나 문자 남겨주세요.",
  [DELIVERY_MESSAGE_TYPE.DIRECT_INPUT]: "직접입력",
  [DELIVERY_MESSAGE_TYPE.CANCEL]: "요청사항 취소",
};

// 배송 요청 사항 타입 선택 리스트
export const DELIVERY_MESSAGE_TYPE_SELECT_LIST = [
  {
    id: DELIVERY_MESSAGE_TYPE.PRE_CONTACT,
    name: DELIVERY_MESSAGE_TYPE_TEXT[DELIVERY_MESSAGE_TYPE.PRE_CONTACT],
  },
  {
    id: DELIVERY_MESSAGE_TYPE.SECURITY_OFFICE,
    name: DELIVERY_MESSAGE_TYPE_TEXT[DELIVERY_MESSAGE_TYPE.SECURITY_OFFICE],
  },
  {
    id: DELIVERY_MESSAGE_TYPE.CONTACT_OR_MESSAGE,
    name: DELIVERY_MESSAGE_TYPE_TEXT[DELIVERY_MESSAGE_TYPE.CONTACT_OR_MESSAGE],
  },
  {
    id: DELIVERY_MESSAGE_TYPE.DIRECT_INPUT,
    name: DELIVERY_MESSAGE_TYPE_TEXT[DELIVERY_MESSAGE_TYPE.DIRECT_INPUT],
  },
  {
    id: DELIVERY_MESSAGE_TYPE.CANCEL,
    name: DELIVERY_MESSAGE_TYPE_TEXT[DELIVERY_MESSAGE_TYPE.CANCEL],
  },
];

// 배송 요청 사항 타입 텍스트
export const DELIVERY_INFO_MODAL_TAB_TYPE_TEXT = {
  [DELIVERY_INFO_MODAL_TAB_TYPE.DELIVERY_ADDRESS_LIST]: "배송지 목록",
  [DELIVERY_INFO_MODAL_TAB_TYPE.NEW_DELIVERY_ADDRESS]: "새로 입력",
};

// 배송 요청 사항 타입 텍스트
export const DELIVERY_INFO_MODAL_TAB_TYPE_SELECT_LIST = [
  {
    id: DELIVERY_INFO_MODAL_TAB_TYPE.DELIVERY_ADDRESS_LIST,
    name: DELIVERY_INFO_MODAL_TAB_TYPE_TEXT[DELIVERY_INFO_MODAL_TAB_TYPE.DELIVERY_ADDRESS_LIST],
  },
  {
    id: DELIVERY_INFO_MODAL_TAB_TYPE.NEW_DELIVERY_ADDRESS,
    name: DELIVERY_INFO_MODAL_TAB_TYPE_TEXT[DELIVERY_INFO_MODAL_TAB_TYPE.NEW_DELIVERY_ADDRESS],
  },
];

// 결제 기한 날짜
export const PAYMENT_DAY_LIMIT = {
  DEPOSIT: 1, // 입금기한
};

// 결제하기 상품 정보 이미지 사이즈
export const PAYMENT_PRODUCT_INFO_IMAGE_SIZE = 200;

// 결제 동의 문구
export const PAYMENT_AGREEMENT_ESSENTIAL_CHECK_TEXT =
  "본인은 만 14세 이상이며, 주문 및 결제 정보를 확인하였고 이에 동의합니다.(필수)";

// 결제 동의 하단 안내 문구
export const PAYMENT_AGREEMENT_ESSENTIAL_CHECK_SUB_TEXT =
  "아몬즈는 통신판매중개자이며 통신판매의 각 당사자가 아닙니다. 따라서, 각 판매자가 등록한 상품 정보 및 거래 등에 대해 책임지지 않습니다.";
