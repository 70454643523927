import styled from "../../../../style/themeComponents";

export const BrandStyle = styled.div`
  ${({ theme }) => theme.adsTypo.bodyM};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const ProductStyle = styled.div`
  ${({ theme }) => theme.adsTypo.detailM};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const PriceStyle = styled.div<{ size: "regular" | "large" }>`
  display: flex;
  align-items: baseline;
  gap: 2px;

  &.sold-out {
    color: ${({ theme }) => theme.adsColor.gray_07};
    .price {
      text-decoration-line: line-through;
    }
  }

  .discount-rate {
    ${({ theme, size }) => (size === "regular" ? theme.adsTypo.bodyXL : theme.adsTypo.headingXL)};
    color: ${({ theme }) => theme.adsColor.secondary};
  }
  .sales-price {
    ${({ theme, size }) => (size === "regular" ? theme.adsTypo.bodyXL : theme.adsTypo.headingXL)};
  }
  .origin-price {
    ${({ theme, size }) => (size === "regular" ? theme.adsTypo.bodyL : theme.adsTypo.headingM)};
    color: ${({ theme }) => theme.adsColor.gray_06};
    text-decoration-line: line-through;
  }
  .sub-text {
    ${({ theme }) => theme.adsTypo.detailM};
    color: ${({ theme }) => theme.adsColor.gray_06};
  }
`;

export const LikeCountStyle = styled.div`
  display: flex;
  gap: 2px;
  ${({ theme }) => theme.adsTypo.bodyS};
  color: ${({ theme }) => theme.adsColor.gray_06};
`;

export const LikeButtonStyle = styled.div`
  display: flex;
  cursor: pointer;
`;
