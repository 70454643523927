import React from "react";
import { SectionItem } from "@amondz/components/src/types";
import {
  BrandInfo,
  DeliveryInfoParams,
  Optional,
  PurchaseTermsInfoParams,
  ReturnExchangeInfoParams,
} from "@amondz/types";
import { valueOrDash } from "../common";

// 배송 정보 아이템
export const createDeliveryInfoItems = (params: Optional<DeliveryInfoParams>): SectionItem[] => [
  {
    label: "배송비",
    content: "아몬즈는 전상품 무료배송입니다",
  },
  {
    label: "추가 배송비",
    content: `제주 및 도서산간 지역은 배송비가 별도로 부과됩니다.\n- 제주도 : ${valueOrDash(
      params.jeju,
    )}원 / 그 외 도서산간 지역 : ${valueOrDash(params.etc)}원`,
  },
];

// 반품/교환 안내 아이템
export const createReturnExchangeInfoItems = (params: Optional<ReturnExchangeInfoParams>): SectionItem[] => [
  {
    label: "반품 / 교환 안내",
    content: valueOrDash(params.returnInfo),
  },
  {
    label: "반품 / 교환 불가사유",
    content: valueOrDash(params.cancelLimit),
  },
  {
    label: "반품 사유에 따른 환불정보",
    content: "",
    subItems: [
      {
        label: "- 단순 변심이나 구매자의 사유로 인한 반품 시 :",
        content: "왕복 배송비를 차감하여 환불해드립니다.\n(도서산간 지역의 경우, 도서산간 추가배송비 부과)",
      },
      {
        label: "- 제품의 하자나 기타 브랜드의 과실로 인한 반품 시 :",
        content: (
          <>
            <span>전액</span> 환불해드립니다.
          </>
        ),
      },
      {
        label: "- 반품/교환 시 택배 발송 방법 :",
        content:
          "상품을 받으신 택배사/송장번호로 반품 접수 해주세요. 다른 택배사로 반품 접수하실 경우, 해당 반송 비용은 판매자가 책임지지 않습니다.",
      },
    ],
  },
  {
    label: "반품 / 교환 시 보내실 곳",
    content: valueOrDash(params.returnAddress),
  },
];

// 구매 조건 및 정책 아이템
export const createPurchaseTermsInfoItems = (params: Optional<PurchaseTermsInfoParams>): SectionItem[] => [
  {
    label: "브랜드 책임으로 인한 반품의 경우 반품 가능 기간 및 통신판매업자가 부담하는 반품 비용에 대한 정보",
    content: valueOrDash(params.refundBySeller),
  },
  {
    label: "재화등의 교환/반품/보증 조건 및 품질보증기준",
    content: valueOrDash(params.exchangeRefundGuarantee),
  },
  {
    label:
      "대금을 환불받기 위한 방법과 환불이 지연될 경우 지연에 따른 배상금을 지급받을 수 있다는 사실 및 배상금 지급의 구체적 조건 및 절차",
    content: valueOrDash(params.deliveryDelay),
  },
  {
    label: "소비자피해보상의 처리, 재화등에 대한 불만 처리 및 소비자와 사업자 사이의 분쟁처리에 관한 사항",
    content: valueOrDash(params.consumerInjury),
  },
];

// 판매자 정보 아이템
export const createSellerInfoItems = (params: Optional<BrandInfo>): SectionItem[] => [
  {
    label: "상호",
    content: valueOrDash(params.companyName),
  },
  {
    label: "대표자",
    content: valueOrDash(params.ceoName),
  },
  {
    label: "사업자 소재지",
    content: valueOrDash(params.address),
  },
  {
    label: "전화번호",
    content: valueOrDash(params.phoneNumber),
  },
  {
    label: "E-mail",
    content: valueOrDash(params.sellerEmail),
  },
  {
    label: "사업자 등록 번호",
    content: valueOrDash(params.businessLicenseNumber),
  },
  {
    label: "통신판매 신고 번호",
    content: valueOrDash(params.onlineSalesRegNumber),
  },
];
