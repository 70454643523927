// 상품 옵션 선택 필요 모달
export const REQUIRED_SELECTED_PRODUCT_OPTION_INFO_MODAL_CONTENTS = {
  MSG: "선택된 옵션이 없습니다.",
};

// 상품 URL 복사 모달
export const COPY_PRODUCT_URL_INFO_MODAL_CONTENTS = {
  MSG: "URL이 복사되었습니다.",
};

// 장바구니 담기 성공 모달
export const ADD_CART_SUCCESS_INFO_MODAL_CONTENTS = {
  MSG: "장바구니에 상품을 담았습니다.",
  SUB_MSG: "장바구니로 이동하시겠습니까?",
  LEFT_BTN_TEXT: "쇼핑 계속하기",
  RIGHT_BTN_TEXT: "장바구니로 이동",
};

// 유효하지 않은교환 및 반품 배송 정보 모달
export const INVALID_ORDER_RETURN_EXCHANGE_DELIVERY_INFO_MODAL_CONTENTS = {
  MSG: "반품/교환 관련 정보가 존재하지 않습니다.",
};
