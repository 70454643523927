import { REVIEW_SORT_SELECTOR_TYPE } from "@amondz/constants";
import { ValueOf } from "./utils";

export const REVIEW_BENEFIT_TYPE = {
  TEXT: "text",
  PHOTO: "photo",
  FIRST_PHOTO: "firstPhoto",
  BEST: "best",
} as const;

export type RateType = 1 | 2 | 3 | 4 | 5;
export type ReviewWriterType = {
  //작성자 계정
  account: string;
  //작성자 이메일
  email: string;
};
export type ReviewProductType = {
  //상품 식별 id
  id: number;
  //상품명
  name: string;
  //브랜드명
  storeName: string;
  option?: {
    name: string;
    value: string;
  };
  mainImageUrl: string;
};
export type ReviewAttachmentType = {
  //첨부파일 식별 id
  id: number;
  //첨부파일 url
  url: string;
};
export type ReviewTagType = {
  id: number;
  name: string;
};
export type GetReviewListOptionsType = Partial<{
  //페이지번호
  page: number;
  //페이지당 갯수
  pageSize: number;
  //정렬
  sort: string;
  //평점
  rate: RateType;
  //포토 후기 여부
  isPhotoReview: boolean;
}>;

export type Review = {
  //식별 id
  id: number;
  // 내 좋아요 여부
  isMine: boolean;
  //평점
  rate: RateType;
  //후기 내용
  content: string;
  //생성일
  createDate: string;
  //수정일
  updateDate: string;
  //작성자 정보
  writer: ReviewWriterType;
  //상품 정보
  product: ReviewProductType;
  //상품 후기 첨부 이미지
  attachments: ReviewAttachmentType[];
  // 상품 후기 평가항목
  reviewCriteria: ReviewDetailCriterionDto[];
  //상품 후기 태그
  tags: ReviewTagType[];
  //상품 후기 좋아요 수
  likeCount: number;
  //상품 후기 좋아요 여부
  likeFlag: boolean;
};

export type GetReviewListResponseType = {
  totalCount: number;
  productReviews: Review[];
  photoCount: number;
  averageRating: number;
};

export type BenefitType = ValueOf<typeof REVIEW_BENEFIT_TYPE>;

export type Benefit = {
  type: BenefitType;
  detail: string;
  point: number;
};

export type ProductReviewBenefitResponse = {
  pointBenefits: Benefit[];
};

export const CATEGORY_STATUS = {
  INACTIVE: "inactive",
  ACTIVE: "active",
} as const;

export type CategoryType = {
  id: number;
  name: string;
  status: ValueOf<typeof CATEGORY_STATUS>;
};

export type PhotographyGuideType = {
  type: "product" | "closeup" | "coordination";
  title: string;
  content: string;
  image: {
    width: number;
    height: number;
    url: string;
  };
};

export type PhotographyGuidesResponse = {
  category: CategoryType;
  photographyGuides: PhotographyGuideType[];
};

export type RegisterReviewParameterType = {
  rate: number;
  content: string;
  imgUrls: string[];
  productId: number;
  orderItemId: string;
  reviewCriteria: ReviewCriteria[];
};

export type ReviewCriteria = {
  id: number;
  type: string;
};

export type CriterionDto = {
  id: number;
  criterion: string;
  criterionLabel: string;
};

export type ReviewCriteriaGroupDto = {
  type: string;
  typeLabel: string;
  criteria: CriterionDto[];
};

export type ReviewCriteriaResponse = {
  reviewCriteria: ReviewCriteriaGroupDto[];
};

export type ReviewDetailCriterionDto = Omit<CriterionDto & ReviewCriteriaGroupDto, "criteria">;

export type ReviewRewardsConfigType = {
  [key: string]: string | number;
};

export type ReviewRewardsType = {
  [key: string]: string;
};

export type PhotoReviewPreview = {
  reviewId: number;
  attachmentId: number;
  url: string;
};

export type ReviewPreviewImage = {
  url: string;
  id: number;
  reviewId: number;
};

export type PreviewImagesType = ReviewPreviewImage[];

export type ReviewSortType = ValueOf<typeof REVIEW_SORT_SELECTOR_TYPE>;
