import { REVIEW_SORT_SELECTOR_TYPE } from "@amondz/constants";
import { ReviewSortType } from "@amondz/types";

export const converSortTypeToGtmItemType = (sortType: ReviewSortType): string => {
  switch (sortType) {
    case REVIEW_SORT_SELECTOR_TYPE.HIGH_RATE:
      return "high_rate";
    case REVIEW_SORT_SELECTOR_TYPE.LOW_RATE:
      return "low_rate";
    case REVIEW_SORT_SELECTOR_TYPE.LATEST:
      return "latest";
    default:
      return "high_rate";
  }
};
