import * as React from "react";
import { FC, MouseEvent, MouseEventHandler, memo } from "react";
import { CSSTransition } from "react-transition-group";

import { BackdropStyle } from "./style";

export interface BackdropProps {
  className?: string;
  onClick?: MouseEventHandler;
  visible: boolean;
}

const Backdrop: FC<BackdropProps> = (props: BackdropProps) => {
  const { className, onClick, visible } = props;

  if (!visible) {
    return null;
  }

  const handleClick = (e: MouseEvent) => {
    if (!onClick) return;
    onClick(e);
  };

  return (
    <CSSTransition in={visible} timeout={3000}>
      <BackdropStyle className={className} role="presentation" onClick={handleClick} />
    </CSSTransition>
  );
};

export default memo(Backdrop);
