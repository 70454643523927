import React, { useEffect, useRef, useState } from "react";
import { FreeMode, Swiper as SwiperInstance } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { ValueOf } from "@amondz/types";
import { useScroll } from "@amondz/use-scroll";
import { TAB_SIZE, TAB_TYPE } from "../constants";
import { ScrollTabHeaderColorProps } from "../Tabs";
import TabHeader from "../TabHeader";
import { SwipeTabPanelStyle, SwipeTabWrapperStyle } from "./style";

export interface SwipeTabProps extends ScrollTabHeaderColorProps {
  size?: ValueOf<typeof TAB_SIZE>;
  type: ValueOf<typeof TAB_TYPE>;
  additionalBaseTop?: number;
  enableSwipe?: boolean;
  list: { title: string; component: JSX.Element }[];
  currentActive: number;
  onChangeTab: (tabId: number) => void;
}

const SwipeTab = (props: SwipeTabProps) => {
  const {
    currentActive,
    onChangeTab,
    enableSwipe = true,
    list,
    size = TAB_SIZE.MEDIUM,
    type,
    activeColor,
    normalColor,
    backgroundColor,
  } = props;
  const [swiper, setSwiper] = useState<SwiperInstance | null>(null);
  const tabRef = useRef<HTMLDivElement | null>(null);
  const { scrollTo } = useScroll();

  const handleClickTab = (clickedTabIdx: number) => onChangeTab(clickedTabIdx);

  useEffect(() => {
    swiper?.slideTo(currentActive);
  }, [currentActive]);

  return (
    <SwipeTabWrapperStyle ref={tabRef}>
      <TabHeader
        size={size}
        type={type}
        list={list.map((item) => item.title)}
        onClickTab={handleClickTab}
        currentActive={currentActive}
        activeColor={activeColor}
        normalColor={normalColor}
        backgroundColor={backgroundColor}
      />
      <SwipeTabPanelStyle>
        <Swiper
          initialSlide={currentActive}
          modules={[FreeMode]}
          onSwiper={setSwiper}
          allowTouchMove={enableSwipe}
          speed={enableSwipe ? 300 : 0}
        >
          {list.map((tabItem, idx) => (
            <SwiperSlide key={tabItem.title + "component"} data-testid={`tab-content-${idx + 1}`}>
              {({ isActive }) => isActive && tabItem.component}
            </SwiperSlide>
          ))}
        </Swiper>
      </SwipeTabPanelStyle>
    </SwipeTabWrapperStyle>
  );
};

export default SwipeTab;
