import { mock_coupons } from "@test/mocks/product/couponList/mockData";
import { maxDiscountAmountTypeCouponBenefit } from "@test/mocks/product/benefits/mockData";
import { mock } from "@services/msw/utils";

//반품 결과 내역 api mock
export const categoryTreeMock = mock({
  categoriesTree: [
    {
      id: 2,
      parentId: null,
      name: "목걸이",
      type: 0,
      exposureType: 0,
      sortType: 0,
      subText: null,
      createDate: "2021-07-14T20:34:47.000Z",
      updateDate: "2023-02-09T09:34:56.000Z",
      no: 1,
      categoryAttachments: [],
      isExhibit: 1,
      subCategories: [
        {
          id: 55,
          parentId: 2,
          no: 1,
          name: "비즈목걸이",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 1,
        },
        {
          id: 56,
          parentId: 2,
          no: 2,
          name: "토글목걸이",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2023-02-13T05:37:16.000Z",
          isExhibit: 1,
        },
        {
          id: 57,
          parentId: 2,
          no: 3,
          name: "진주목걸이",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 1,
        },
        {
          id: 59,
          parentId: 2,
          no: 4,
          name: "원석목걸이",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 1,
        },
        {
          id: 62,
          parentId: 2,
          no: 5,
          name: "체인목걸이",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 1,
        },
        {
          id: 63,
          parentId: 2,
          no: 6,
          name: "롱목걸이",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 1,
        },
        {
          id: 66,
          parentId: 2,
          no: 7,
          name: "이니셜목걸이",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 1,
        },
        {
          id: 68,
          parentId: 2,
          no: 8,
          name: "레이어드목걸이",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 1,
        },
        {
          id: 70,
          parentId: 2,
          no: 9,
          name: "하트목걸이",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 1,
        },
        {
          id: 73,
          parentId: 2,
          no: 10,
          name: "초커",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
        {
          id: 75,
          parentId: 2,
          no: 11,
          name: "진주초커",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
        {
          id: 76,
          parentId: 2,
          no: 12,
          name: "가죽초커",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
        {
          id: 81,
          parentId: 2,
          no: 13,
          name: "안경줄",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 1,
        },
        {
          id: 83,
          parentId: 2,
          no: 14,
          name: "마스크스트랩",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 1,
        },
        {
          id: 84,
          parentId: 2,
          no: 15,
          name: "피시후크목걸이",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 1,
        },
        {
          id: 85,
          parentId: 2,
          no: 16,
          name: "펜던트목걸이",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 1,
        },
        {
          id: 86,
          parentId: 2,
          no: 17,
          name: "서브펜던트",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 1,
        },
      ],
    },
    {
      id: 4,
      parentId: null,
      name: "팔찌",
      type: 0,
      exposureType: 0,
      sortType: 0,
      subText: null,
      createDate: "2021-07-14T20:34:47.000Z",
      updateDate: "2023-02-09T09:34:56.000Z",
      no: 2,
      categoryAttachments: [],
      isExhibit: 1,
      subCategories: [
        {
          id: 131,
          parentId: 4,
          no: 1,
          name: "체인팔찌",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 1,
        },
        {
          id: 139,
          parentId: 4,
          no: 2,
          name: "레이어드팔찌",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 1,
        },
        {
          id: 140,
          parentId: 4,
          no: 3,
          name: "뱅글",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 1,
        },
        {
          id: 145,
          parentId: 4,
          no: 4,
          name: "커플팔찌",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 1,
        },
        {
          id: 147,
          parentId: 4,
          no: 5,
          name: "토글팔찌",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
        {
          id: 149,
          parentId: 4,
          no: 6,
          name: "바디커프",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
        {
          id: 150,
          parentId: 4,
          no: 7,
          name: "펜던트팔찌",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
        {
          id: 151,
          parentId: 4,
          no: 8,
          name: "비즈팔찌",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 1,
        },
        {
          id: 152,
          parentId: 4,
          no: 9,
          name: "패브릭팔찌",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
        {
          id: 128,
          parentId: 4,
          no: null,
          name: "진주팔찌",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 0,
        },
        {
          id: 130,
          parentId: 4,
          no: null,
          name: "원석팔찌",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 0,
        },
      ],
    },
    {
      id: 1,
      parentId: null,
      name: "귀걸이1 ",
      type: 0,
      exposureType: 0,
      sortType: 4,
      subText: null,
      createDate: "2021-07-14T20:34:47.000Z",
      updateDate: "2023-02-27T05:04:03.000Z",
      no: 3,
      categoryAttachments: [
        {
          id: 1,
          productCategoryId: 1,
          type: 1,
          width: 100,
          height: 100,
          path: "path1",
          url: "https://s3.ap-northeast-2.amazonaws.com/amondz-test/path1",
          createDate: "2023-03-24T10:15:24.000Z",
          updateDate: "2023-03-24T10:15:24.000Z",
        },
        {
          id: 2,
          productCategoryId: 1,
          type: 2,
          width: 200,
          height: 200,
          path: "path2",
          url: "https://s3.ap-northeast-2.amazonaws.com/amondz-test/path2",
          createDate: "2023-03-24T10:15:39.000Z",
          updateDate: "2023-03-24T10:15:39.000Z",
        },
      ],
      isExhibit: 1,
      subCategories: [
        {
          id: 13,
          parentId: 1,
          no: 1,
          name: "원석귀걸이",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 1,
        },
        {
          id: 17,
          parentId: 1,
          no: 2,
          name: "링귀걸이~!@##$",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2023-02-10T07:40:36.000Z",
          isExhibit: 1,
        },
        {
          id: 18,
          parentId: 1,
          no: 3,
          name: "스터드귀걸이",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 1,
        },
        {
          id: 19,
          parentId: 1,
          no: 4,
          name: "원터치귀걸이",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 1,
        },
        {
          id: 20,
          parentId: 1,
          no: 5,
          name: "드롭귀걸이",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
        {
          id: 24,
          parentId: 1,
          no: 6,
          name: "언발귀걸이",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 1,
        },
        {
          id: 45,
          parentId: 1,
          no: 7,
          name: "이어클라이머",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 1,
        },
        {
          id: 52,
          parentId: 1,
          no: 8,
          name: "피어싱",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 1,
        },
        {
          id: 14,
          parentId: 1,
          no: null,
          name: "진주귀걸이",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 0,
        },
        {
          id: 27,
          parentId: 1,
          no: null,
          name: "오버사이즈귀걸이",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2023-02-10T08:09:26.000Z",
          isExhibit: 0,
        },
      ],
    },
    {
      id: 3,
      parentId: null,
      name: "반지22",
      type: 0,
      exposureType: 0,
      sortType: 0,
      subText: null,
      createDate: "2021-07-14T20:34:47.000Z",
      updateDate: "2023-02-10T14:02:15.000Z",
      no: 4,
      categoryAttachments: [],
      isExhibit: 1,
      subCategories: [
        {
          id: 91,
          parentId: 3,
          no: 1,
          name: "원석반지",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 1,
        },
        {
          id: 93,
          parentId: 3,
          no: 2,
          name: "체인반지",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 1,
        },
        {
          id: 96,
          parentId: 3,
          no: 3,
          name: "마디링",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
        {
          id: 98,
          parentId: 3,
          no: 4,
          name: "멜팅링",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 1,
        },
        {
          id: 100,
          parentId: 3,
          no: 5,
          name: "애끼반지",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 1,
        },
        {
          id: 101,
          parentId: 3,
          no: 6,
          name: "오픈링",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 1,
        },
        {
          id: 104,
          parentId: 3,
          no: 7,
          name: "커플링",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 1,
        },
        {
          id: 106,
          parentId: 3,
          no: 8,
          name: "실반지",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 1,
        },
        {
          id: 108,
          parentId: 3,
          no: 9,
          name: "볼드링",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 1,
        },
        {
          id: 110,
          parentId: 3,
          no: 10,
          name: "트위스트링",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 1,
        },
        {
          id: 113,
          parentId: 3,
          no: 11,
          name: "각인반지",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 1,
        },
        {
          id: 117,
          parentId: 3,
          no: 12,
          name: "아크릴반지",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 1,
        },
        {
          id: 118,
          parentId: 3,
          no: 13,
          name: "비즈반지",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 1,
        },
        {
          id: 121,
          parentId: 3,
          no: 14,
          name: "더블링",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 1,
        },
        {
          id: 123,
          parentId: 3,
          no: 15,
          name: "드롭링",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 1,
        },
        {
          id: 124,
          parentId: 3,
          no: 16,
          name: "매트링",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 1,
        },
        {
          id: 125,
          parentId: 3,
          no: 17,
          name: "투웨이이어커프",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 1,
        },
        {
          id: 412,
          parentId: 3,
          no: 18,
          name: "시그넷링",
          createDate: "2022-11-08T06:14:04.000Z",
          updateDate: "2022-11-08T06:14:04.000Z",
          isExhibit: 1,
        },
        {
          id: 493,
          parentId: 3,
          no: null,
          name: "테스트다",
          createDate: "2023-02-09T09:29:59.000Z",
          updateDate: "2023-02-09T09:29:59.000Z",
          isExhibit: 0,
        },
        {
          id: 494,
          parentId: 3,
          no: null,
          name: "테스트2",
          createDate: "2023-02-09T09:30:27.000Z",
          updateDate: "2023-02-09T09:30:27.000Z",
          isExhibit: 0,
        },
        {
          id: 559,
          parentId: 3,
          no: null,
          name: "2345",
          createDate: "2023-02-09T14:22:08.000Z",
          updateDate: "2023-02-09T14:22:08.000Z",
          isExhibit: 0,
        },
      ],
    },
    {
      id: 5,
      parentId: null,
      name: "펜던트1",
      type: 0,
      exposureType: 0,
      sortType: 0,
      subText: null,
      createDate: "2021-07-14T20:34:47.000Z",
      updateDate: "2023-02-13T09:01:03.000Z",
      no: 5,
      categoryAttachments: [],
      isExhibit: 1,
      subCategories: [
        {
          id: 153,
          parentId: 5,
          no: 1,
          name: "고리형펜던트",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
        {
          id: 154,
          parentId: 5,
          no: 2,
          name: "14K펜던트",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
        {
          id: 155,
          parentId: 5,
          no: 3,
          name: "판도라참",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
        {
          id: 156,
          parentId: 5,
          no: 4,
          name: "옴비즈참",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 1,
        },
      ],
    },
    {
      id: 8,
      parentId: null,
      name: "이어커프1",
      type: 0,
      exposureType: 0,
      sortType: 0,
      subText: null,
      createDate: "2021-07-14T20:34:47.000Z",
      updateDate: "2023-02-13T10:07:33.000Z",
      no: 6,
      categoryAttachments: [],
      isExhibit: 1,
      subCategories: [
        {
          id: 179,
          parentId: 8,
          no: 1,
          name: "볼드이어커프",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 1,
        },
        {
          id: 181,
          parentId: 8,
          no: 2,
          name: "귀걸이이어커프",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 1,
        },
        {
          id: 184,
          parentId: 8,
          no: 3,
          name: "드롭이어커프",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 1,
        },
        {
          id: 188,
          parentId: 8,
          no: 4,
          name: "미니이어커프",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 1,
        },
        {
          id: 189,
          parentId: 8,
          no: 5,
          name: "스톤이어커프",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
        {
          id: 190,
          parentId: 8,
          no: 6,
          name: "볼이어커프",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
        {
          id: 191,
          parentId: 8,
          no: 7,
          name: "투웨이이어커프",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
      ],
    },
    {
      id: 10,
      parentId: null,
      name: "ACC",
      type: 0,
      exposureType: 0,
      sortType: 0,
      subText: null,
      createDate: "2021-07-14T20:34:47.000Z",
      updateDate: "2023-02-09T09:34:56.000Z",
      no: 7,
      categoryAttachments: [],
      isExhibit: 1,
      subCategories: [
        {
          id: 216,
          parentId: 10,
          no: 1,
          name: "브로치",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
        {
          id: 217,
          parentId: 10,
          no: 2,
          name: "키링",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
        {
          id: 218,
          parentId: 10,
          no: 3,
          name: "오브제",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
        {
          id: 220,
          parentId: 10,
          no: 4,
          name: "마스크스트랩",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
        {
          id: 221,
          parentId: 10,
          no: 5,
          name: "핑거스트랩",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
        {
          id: 366,
          parentId: 10,
          no: 6,
          name: "주얼리박스",
          createDate: "2021-12-16T16:34:15.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
        {
          id: 367,
          parentId: 10,
          no: 7,
          name: "주얼리리페어",
          createDate: "2021-12-16T16:34:15.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
        {
          id: 402,
          parentId: 10,
          no: 8,
          name: "카드지갑",
          createDate: "2022-02-03T00:55:49.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
      ],
    },
    {
      id: 267,
      parentId: null,
      name: "BEST",
      type: 0,
      exposureType: 1,
      sortType: 0,
      subText: null,
      createDate: "2021-07-14T20:45:13.000Z",
      updateDate: "2022-11-07T22:26:15.000Z",
      no: 8,
      categoryAttachments: [],
      isExhibit: 1,
      subCategories: [
        {
          id: 284,
          parentId: 267,
          no: 1,
          name: "반지",
          createDate: "2021-07-14T20:50:34.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 1,
        },
        {
          id: 285,
          parentId: 267,
          no: 2,
          name: "이어커프",
          createDate: "2021-07-14T20:50:34.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
        {
          id: 286,
          parentId: 267,
          no: 3,
          name: "귀걸이",
          createDate: "2021-07-14T20:50:34.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
        {
          id: 287,
          parentId: 267,
          no: 4,
          name: "목걸이",
          createDate: "2021-07-14T20:50:34.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
        {
          id: 288,
          parentId: 267,
          no: 5,
          name: "팔찌",
          createDate: "2021-07-14T20:50:34.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
        {
          id: 289,
          parentId: 267,
          no: 6,
          name: "발찌",
          createDate: "2021-07-14T20:50:34.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 1,
        },
      ],
    },
    {
      id: 269,
      parentId: null,
      name: "14K GOLD",
      type: 0,
      exposureType: 1,
      sortType: 0,
      subText: null,
      createDate: "2021-07-14T20:45:13.000Z",
      updateDate: "2023-02-09T09:34:56.000Z",
      no: 9,
      categoryAttachments: [],
      isExhibit: 1,
      subCategories: [
        {
          id: 296,
          parentId: 269,
          no: 1,
          name: "반지",
          createDate: "2021-07-14T20:51:42.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 1,
        },
        {
          id: 297,
          parentId: 269,
          no: 2,
          name: "이어커프",
          createDate: "2021-07-14T20:51:42.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
        {
          id: 298,
          parentId: 269,
          no: 3,
          name: "귀걸이",
          createDate: "2021-07-14T20:51:42.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 1,
        },
        {
          id: 299,
          parentId: 269,
          no: 4,
          name: "목걸이",
          createDate: "2021-07-14T20:51:42.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 1,
        },
        {
          id: 300,
          parentId: 269,
          no: 5,
          name: "팔찌",
          createDate: "2021-07-14T20:51:42.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 1,
        },
        {
          id: 301,
          parentId: 269,
          no: 6,
          name: "발찌",
          createDate: "2021-07-14T20:51:42.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 1,
        },
        {
          id: 405,
          parentId: 269,
          no: 7,
          name: "펜던트",
          createDate: "2022-02-03T01:22:33.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
      ],
    },
    {
      id: 379,
      parentId: null,
      name: "24K PURE GOLD",
      type: 0,
      exposureType: 1,
      sortType: 0,
      subText: null,
      createDate: "2021-12-17T02:07:14.000Z",
      updateDate: "2022-11-07T22:26:15.000Z",
      no: 10,
      categoryAttachments: [],
      isExhibit: 1,
      subCategories: [
        {
          id: 380,
          parentId: 379,
          no: 1,
          name: "목걸이",
          createDate: "2021-12-17T02:07:14.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
        {
          id: 381,
          parentId: 379,
          no: 2,
          name: "귀걸이",
          createDate: "2021-12-17T02:07:14.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
        {
          id: 382,
          parentId: 379,
          no: 3,
          name: "반지",
          createDate: "2021-12-17T02:07:14.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
        {
          id: 383,
          parentId: 379,
          no: 4,
          name: "팔찌",
          createDate: "2021-12-17T02:07:14.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
        {
          id: 384,
          parentId: 379,
          no: 5,
          name: "골드바",
          createDate: "2021-12-17T02:07:14.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
        {
          id: 385,
          parentId: 379,
          no: 6,
          name: "돌반지",
          createDate: "2021-12-17T02:07:14.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
        {
          id: 386,
          parentId: 379,
          no: 7,
          name: "선물/기념품",
          createDate: "2021-12-17T02:07:14.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
      ],
    },
    {
      id: 387,
      parentId: null,
      name: "타임피스",
      type: 0,
      exposureType: 0,
      sortType: 0,
      subText: null,
      createDate: "2021-12-17T02:07:14.000Z",
      updateDate: "2022-11-07T22:26:15.000Z",
      no: 11,
      categoryAttachments: [],
      isExhibit: 1,
      subCategories: [
        {
          id: 388,
          parentId: 387,
          no: 1,
          name: "남성시계",
          createDate: "2021-12-17T02:07:14.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
        {
          id: 389,
          parentId: 387,
          no: 2,
          name: "여성시계",
          createDate: "2021-12-17T02:07:14.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
        {
          id: 390,
          parentId: 387,
          no: 3,
          name: "워치스트랩",
          createDate: "2021-12-17T02:07:14.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
      ],
    },
    {
      id: 391,
      parentId: null,
      name: "DIGITAL",
      type: 0,
      exposureType: 0,
      sortType: 0,
      subText: null,
      createDate: "2021-12-17T02:07:14.000Z",
      updateDate: "2022-11-07T22:26:15.000Z",
      no: 12,
      categoryAttachments: [],
      isExhibit: 1,
      subCategories: [
        {
          id: 392,
          parentId: 391,
          no: 1,
          name: "핑거스트랩",
          createDate: "2021-12-17T02:07:14.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
        {
          id: 393,
          parentId: 391,
          no: 2,
          name: "워치스트랩",
          createDate: "2021-12-17T02:07:14.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
        {
          id: 394,
          parentId: 391,
          no: 3,
          name: "모바일케이스",
          createDate: "2021-12-17T02:07:14.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
        {
          id: 395,
          parentId: 391,
          no: 4,
          name: "그립톡",
          createDate: "2021-12-17T02:07:14.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
        {
          id: 396,
          parentId: 391,
          no: 5,
          name: "이어폰케이스",
          createDate: "2021-12-17T02:07:14.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
      ],
    },
    {
      id: 423,
      parentId: null,
      name: "이석호 테스트",
      type: 0,
      exposureType: 1,
      sortType: 1,
      subText: null,
      createDate: "2022-12-23T07:48:15.000Z",
      updateDate: "2023-02-23T06:05:52.000Z",
      no: 13,
      categoryAttachments: [],
      isExhibit: 1,
      subCategories: [
        {
          id: 426,
          parentId: 423,
          no: null,
          name: "asdas",
          createDate: "2022-12-25T08:02:02.000Z",
          updateDate: "2023-02-14T07:19:06.000Z",
          isExhibit: 0,
        },
        {
          id: 609,
          parentId: 423,
          no: null,
          name: "2차 테스트2",
          createDate: "2023-02-14T06:52:38.000Z",
          updateDate: "2023-02-14T06:52:38.000Z",
          isExhibit: 0,
        },
        {
          id: 610,
          parentId: 423,
          no: null,
          name: "2차 테스트3",
          createDate: "2023-02-14T06:52:57.000Z",
          updateDate: "2023-02-14T06:52:57.000Z",
          isExhibit: 0,
        },
      ],
    },
    {
      id: 429,
      parentId: null,
      name: "이석호테스트123",
      type: 0,
      exposureType: 0,
      sortType: 0,
      subText: null,
      createDate: "2022-12-27T06:59:32.000Z",
      updateDate: "2023-02-13T01:27:02.000Z",
      no: 14,
      categoryAttachments: [],
      isExhibit: 1,
      subCategories: [
        {
          id: 442,
          parentId: 429,
          no: 1,
          name: "2차 테스트7",
          createDate: "2023-01-26T07:27:09.000Z",
          updateDate: "2023-01-26T07:27:09.000Z",
          isExhibit: 1,
        },
        {
          id: 443,
          parentId: 429,
          no: 2,
          name: "2차 테스트8",
          createDate: "2023-01-26T07:27:31.000Z",
          updateDate: "2023-01-26T07:27:31.000Z",
          isExhibit: 1,
        },
        {
          id: 441,
          parentId: 429,
          no: 3,
          name: "2차 테스트6",
          createDate: "2023-01-26T07:26:38.000Z",
          updateDate: "2023-01-26T07:26:38.000Z",
          isExhibit: 1,
        },
        {
          id: 445,
          parentId: 429,
          no: 4,
          name: "2차 테스트10",
          createDate: "2023-01-26T07:49:19.000Z",
          updateDate: "2023-01-26T07:49:19.000Z",
          isExhibit: 1,
        },
        {
          id: 444,
          parentId: 429,
          no: null,
          name: "2차 테스트9",
          createDate: "2023-01-26T07:37:26.000Z",
          updateDate: "2023-01-26T07:37:26.000Z",
          isExhibit: 0,
        },
        {
          id: 448,
          parentId: 429,
          no: null,
          name: "2차 테스트13",
          createDate: "2023-01-26T07:52:33.000Z",
          updateDate: "2023-01-26T07:52:33.000Z",
          isExhibit: 0,
        },
        {
          id: 449,
          parentId: 429,
          no: null,
          name: "2차 테스트14",
          createDate: "2023-01-26T07:54:39.000Z",
          updateDate: "2023-01-26T07:54:39.000Z",
          isExhibit: 0,
        },
        {
          id: 450,
          parentId: 429,
          no: null,
          name: "2차 테스트15",
          createDate: "2023-01-26T08:03:41.000Z",
          updateDate: "2023-01-26T08:03:41.000Z",
          isExhibit: 0,
        },
        {
          id: 451,
          parentId: 429,
          no: null,
          name: "2차 테스트16",
          createDate: "2023-01-26T08:11:49.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 0,
        },
        {
          id: 452,
          parentId: 429,
          no: null,
          name: "2차 테스트17",
          createDate: "2023-01-26T08:31:04.000Z",
          updateDate: "2023-01-26T08:31:04.000Z",
          isExhibit: 0,
        },
      ],
    },
    {
      id: 6,
      parentId: null,
      name: "헤어",
      type: 0,
      exposureType: 0,
      sortType: 0,
      subText: null,
      createDate: "2021-07-14T20:34:47.000Z",
      updateDate: "2023-02-09T09:34:56.000Z",
      no: 15,
      categoryAttachments: [],
      isExhibit: 1,
      subCategories: [
        {
          id: 157,
          parentId: 6,
          no: 1,
          name: "헤어핀",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
        {
          id: 160,
          parentId: 6,
          no: 2,
          name: "집게핀",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
        {
          id: 161,
          parentId: 6,
          no: 3,
          name: "헤어밴드",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 1,
        },
        {
          id: 164,
          parentId: 6,
          no: 4,
          name: "스크런치",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 1,
        },
      ],
    },
    {
      id: 495,
      parentId: null,
      name: "테스트 100",
      type: 0,
      exposureType: 0,
      sortType: 0,
      subText: null,
      createDate: "2023-02-09T11:21:28.000Z",
      updateDate: "2023-02-09T11:21:28.000Z",
      no: 16,
      categoryAttachments: [],
      isExhibit: 1,
      subCategories: [],
    },
    {
      id: 431,
      parentId: null,
      name: "sdf",
      type: 0,
      exposureType: 1,
      sortType: 2,
      subText: null,
      createDate: "2022-12-27T08:27:22.000Z",
      updateDate: "2022-12-27T08:27:22.000Z",
      no: 17,
      categoryAttachments: [],
      isExhibit: 1,
      subCategories: [],
    },
    {
      id: 7,
      parentId: null,
      name: "발찌",
      type: 0,
      exposureType: 0,
      sortType: 1,
      subText: null,
      createDate: "2021-07-14T20:34:47.000Z",
      updateDate: "2023-03-20T03:40:21.000Z",
      no: 18,
      categoryAttachments: [],
      isExhibit: 1,
      subCategories: [
        {
          id: 167,
          parentId: 7,
          no: 1,
          name: "체인발찌",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 1,
        },
        {
          id: 172,
          parentId: 7,
          no: 2,
          name: "펜던트발찌",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 1,
        },
        {
          id: 173,
          parentId: 7,
          no: 3,
          name: "원석발찌",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 1,
        },
        {
          id: 174,
          parentId: 7,
          no: 4,
          name: "비즈발찌",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
        {
          id: 175,
          parentId: 7,
          no: 5,
          name: "패브릭발찌",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
        {
          id: 176,
          parentId: 7,
          no: 6,
          name: "드롭발찌",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 1,
        },
      ],
    },
    {
      id: 9,
      parentId: null,
      name: "향수",
      type: 0,
      exposureType: 0,
      sortType: 0,
      subText: null,
      createDate: "2021-07-14T20:34:47.000Z",
      updateDate: "2023-02-09T09:34:56.000Z",
      no: 19,
      categoryAttachments: [],
      isExhibit: 1,
      subCategories: [
        {
          id: 192,
          parentId: 9,
          no: 1,
          name: "산타마리아노벨라",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
        {
          id: 193,
          parentId: 9,
          no: 2,
          name: "딥디크",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 1,
        },
        {
          id: 194,
          parentId: 9,
          no: 3,
          name: "조말론",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
        {
          id: 195,
          parentId: 9,
          no: 4,
          name: "에르메스",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 1,
        },
        {
          id: 196,
          parentId: 9,
          no: 5,
          name: "구찌",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 1,
        },
        {
          id: 197,
          parentId: 9,
          no: 6,
          name: "데렉 램",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
        {
          id: 198,
          parentId: 9,
          no: 7,
          name: "디올",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 1,
        },
        {
          id: 199,
          parentId: 9,
          no: 8,
          name: "랄프로렌",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
        {
          id: 200,
          parentId: 9,
          no: 9,
          name: "메종 프란시스 커정",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
        {
          id: 201,
          parentId: 9,
          no: 10,
          name: "바이레도",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 1,
        },
        {
          id: 202,
          parentId: 9,
          no: 11,
          name: "발렌티노",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
        {
          id: 203,
          parentId: 9,
          no: 12,
          name: "버버리",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
        {
          id: 204,
          parentId: 9,
          no: 13,
          name: "분더샵",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 1,
        },
        {
          id: 205,
          parentId: 9,
          no: 14,
          name: "보테가 베네타",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
        {
          id: 206,
          parentId: 9,
          no: 15,
          name: "빅터 앤 롤프",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
        {
          id: 207,
          parentId: 9,
          no: 16,
          name: "아이젠버그",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
        {
          id: 208,
          parentId: 9,
          no: 17,
          name: "아쿠아 디 파르마",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
        {
          id: 209,
          parentId: 9,
          no: 18,
          name: "에트로",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
        {
          id: 210,
          parentId: 9,
          no: 19,
          name: "입생로랑",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 1,
        },
        {
          id: 211,
          parentId: 9,
          no: 20,
          name: "티파니 앤 코",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 1,
        },
        {
          id: 212,
          parentId: 9,
          no: 21,
          name: "프라고나르",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 1,
        },
        {
          id: 213,
          parentId: 9,
          no: 22,
          name: "프라다",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
        {
          id: 214,
          parentId: 9,
          no: 23,
          name: "프레데릭 말",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
        {
          id: 215,
          parentId: 9,
          no: 24,
          name: "산타 마리아 노벨라",
          createDate: "2021-07-14T20:35:27.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
        {
          id: 363,
          parentId: 9,
          no: 25,
          name: "조르지오 아르마니",
          createDate: "2021-12-16T16:34:03.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
        {
          id: 364,
          parentId: 9,
          no: 26,
          name: "메종 마르지엘라",
          createDate: "2021-12-16T16:34:03.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
        {
          id: 365,
          parentId: 9,
          no: 27,
          name: "마크 제이콥스",
          createDate: "2021-12-16T16:34:03.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
      ],
    },
    {
      id: 266,
      parentId: null,
      name: "EXCLUSIVE",
      type: 0,
      exposureType: 1,
      sortType: 0,
      subText: null,
      createDate: "2021-07-14T20:45:13.000Z",
      updateDate: "2022-11-07T22:26:15.000Z",
      no: 20,
      categoryAttachments: [],
      isExhibit: 1,
      subCategories: [
        {
          id: 272,
          parentId: 266,
          no: 1,
          name: "반지",
          createDate: "2021-07-14T20:47:48.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
        {
          id: 273,
          parentId: 266,
          no: 2,
          name: "이어커프",
          createDate: "2021-07-14T20:47:48.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
        {
          id: 274,
          parentId: 266,
          no: 3,
          name: "귀걸이",
          createDate: "2021-07-14T20:47:48.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
        {
          id: 275,
          parentId: 266,
          no: 4,
          name: "목걸이",
          createDate: "2021-07-14T20:47:48.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
        {
          id: 276,
          parentId: 266,
          no: 5,
          name: "팔찌",
          createDate: "2021-07-14T20:47:48.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
        {
          id: 277,
          parentId: 266,
          no: 6,
          name: "ACC",
          createDate: "2021-07-14T20:47:48.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
      ],
    },
    {
      id: 312,
      parentId: null,
      name: "오늘 주문, 오늘 출발1",
      type: 0,
      exposureType: 0,
      sortType: 0,
      subText: null,
      createDate: "2021-07-16T09:35:36.000Z",
      updateDate: "2023-02-13T08:53:46.000Z",
      no: null,
      categoryAttachments: [],
      isExhibit: 0,
      subCategories: [],
    },
    {
      id: 424,
      parentId: null,
      name: "김상미 업로드 테스트1",
      type: 0,
      exposureType: 1,
      sortType: 3,
      subText: null,
      createDate: "2022-12-23T09:24:13.000Z",
      updateDate: "2023-02-15T09:04:09.000Z",
      no: null,
      categoryAttachments: [],
      isExhibit: 0,
      subCategories: [
        {
          id: 425,
          parentId: 424,
          no: null,
          name: "2차 카테고리 추가 ",
          createDate: "2022-12-23T14:35:59.000Z",
          updateDate: "2022-12-23T14:35:59.000Z",
          isExhibit: 0,
        },
        {
          id: 455,
          parentId: 424,
          no: null,
          name: "api test",
          createDate: "2023-02-06T05:56:37.000Z",
          updateDate: "2023-02-06T05:57:47.000Z",
          isExhibit: 0,
        },
        {
          id: 457,
          parentId: 424,
          no: null,
          name: "2차 카테고리 추가1",
          createDate: "2023-02-06T06:10:20.000Z",
          updateDate: "2023-02-06T06:10:20.000Z",
          isExhibit: 0,
        },
        {
          id: 458,
          parentId: 424,
          no: null,
          name: "2차 카테고리 추가2",
          createDate: "2023-02-06T06:12:04.000Z",
          updateDate: "2023-02-06T06:12:04.000Z",
          isExhibit: 0,
        },
        {
          id: 459,
          parentId: 424,
          no: null,
          name: "2차 카테고리 추가3",
          createDate: "2023-02-06T06:18:59.000Z",
          updateDate: "2023-02-06T06:18:59.000Z",
          isExhibit: 0,
        },
        {
          id: 460,
          parentId: 424,
          no: null,
          name: "2차 카테고리 추가4",
          createDate: "2023-02-06T06:20:37.000Z",
          updateDate: "2023-02-06T06:20:37.000Z",
          isExhibit: 0,
        },
        {
          id: 465,
          parentId: 424,
          no: null,
          name: "1",
          createDate: "2023-02-06T06:23:40.000Z",
          updateDate: "2023-02-06T06:23:40.000Z",
          isExhibit: 0,
        },
        {
          id: 466,
          parentId: 424,
          no: null,
          name: "2",
          createDate: "2023-02-06T06:24:09.000Z",
          updateDate: "2023-02-06T06:24:09.000Z",
          isExhibit: 0,
        },
      ],
    },
    {
      id: 430,
      parentId: null,
      name: "1차 테스트",
      type: 0,
      exposureType: 1,
      sortType: 1,
      subText: null,
      createDate: "2022-12-27T08:25:25.000Z",
      updateDate: "2022-12-27T08:25:25.000Z",
      no: null,
      categoryAttachments: [],
      isExhibit: 0,
      subCategories: [],
    },
    {
      id: 433,
      parentId: null,
      name: "카테고리추가",
      type: 0,
      exposureType: 0,
      sortType: 0,
      subText: null,
      createDate: "2023-01-26T03:06:01.000Z",
      updateDate: "2023-01-26T03:06:01.000Z",
      no: null,
      categoryAttachments: [],
      isExhibit: 0,
      subCategories: [],
    },
    {
      id: 649,
      parentId: null,
      name: "심승태",
      type: 0,
      exposureType: 0,
      sortType: 0,
      subText: null,
      createDate: "2023-03-08T00:44:54.000Z",
      updateDate: "2023-03-08T00:44:54.000Z",
      no: null,
      categoryAttachments: [],
      isExhibit: 0,
      subCategories: [],
    },
    {
      id: 271,
      parentId: null,
      name: "LUXURY/GLOBAL",
      type: 1,
      exposureType: 1,
      sortType: 0,
      subText: "세계적으로 사랑받는 브랜드",
      createDate: "2021-07-14T20:45:13.000Z",
      updateDate: "2022-11-07T22:26:15.000Z",
      no: 1,
      categoryAttachments: [],
      isExhibit: 1,
      subCategories: [
        {
          id: 306,
          parentId: 271,
          no: 1,
          name: "반지",
          createDate: "2021-07-14T20:53:15.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 1,
        },
        {
          id: 309,
          parentId: 271,
          no: 2,
          name: "목걸이",
          createDate: "2021-07-14T20:53:15.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
        {
          id: 308,
          parentId: 271,
          no: 3,
          name: "귀걸이",
          createDate: "2021-07-14T20:53:15.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 1,
        },
        {
          id: 369,
          parentId: 271,
          no: 4,
          name: "구찌",
          createDate: "2021-12-16T16:34:25.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
        {
          id: 376,
          parentId: 271,
          no: 5,
          name: "비비안 웨스트우드",
          createDate: "2021-12-16T16:34:25.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
        {
          id: 373,
          parentId: 271,
          no: 6,
          name: "판도라",
          createDate: "2021-12-16T16:34:25.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
        {
          id: 374,
          parentId: 271,
          no: 7,
          name: "스와로브스키",
          createDate: "2021-12-16T16:34:25.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
        {
          id: 326,
          parentId: 271,
          no: 8,
          name: "펜던트",
          createDate: "2021-07-14T20:53:15.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 1,
        },
        {
          id: 307,
          parentId: 271,
          no: 9,
          name: "이어커프",
          createDate: "2021-07-14T20:53:15.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 1,
        },
        {
          id: 310,
          parentId: 271,
          no: 10,
          name: "팔찌",
          createDate: "2021-07-14T20:53:15.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 1,
        },
        {
          id: 368,
          parentId: 271,
          no: 11,
          name: "시계",
          createDate: "2021-12-16T16:34:25.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
        {
          id: 370,
          parentId: 271,
          no: 12,
          name: "루이비통",
          createDate: "2021-12-16T16:34:25.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
        {
          id: 371,
          parentId: 271,
          no: 13,
          name: "생 로랑",
          createDate: "2021-12-16T16:34:25.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
        {
          id: 372,
          parentId: 271,
          no: 14,
          name: "보테가 베네타",
          createDate: "2021-12-16T16:34:25.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
        {
          id: 375,
          parentId: 271,
          no: 15,
          name: "메종 마르지엘라",
          createDate: "2021-12-16T16:34:25.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
        {
          id: 377,
          parentId: 271,
          no: 16,
          name: "에르메스",
          createDate: "2021-12-16T16:34:25.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
        {
          id: 378,
          parentId: 271,
          no: 17,
          name: "디올",
          createDate: "2021-12-16T16:34:25.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
        {
          id: 311,
          parentId: 271,
          no: 18,
          name: "발찌",
          createDate: "2021-07-14T20:53:15.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 1,
        },
      ],
    },
    {
      id: 319,
      parentId: null,
      name: "오늘 주문, 오늘 출발",
      type: 1,
      exposureType: 1,
      sortType: 0,
      subText: "선물하기도 함께 만나보세요",
      createDate: "2021-07-16T09:54:51.000Z",
      updateDate: "2023-02-09T09:34:56.000Z",
      no: 2,
      categoryAttachments: [],
      isExhibit: 1,
      subCategories: [
        {
          id: 322,
          parentId: 319,
          no: 1,
          name: "이어커프",
          createDate: "2021-07-16T09:54:51.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 1,
        },
        {
          id: 324,
          parentId: 319,
          no: 2,
          name: "팔찌",
          createDate: "2021-07-16T09:54:51.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 1,
        },
        {
          id: 323,
          parentId: 319,
          no: 3,
          name: "반지",
          createDate: "2021-07-16T09:54:51.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 1,
        },
        {
          id: 325,
          parentId: 319,
          no: 4,
          name: "발찌",
          createDate: "2021-07-16T09:54:51.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 1,
        },
        {
          id: 334,
          parentId: 319,
          no: 5,
          name: "향수",
          createDate: "2021-07-16T09:54:51.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 1,
        },
        {
          id: 335,
          parentId: 319,
          no: 6,
          name: "ACC",
          createDate: "2021-07-16T09:54:51.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
        {
          id: 403,
          parentId: 319,
          no: 7,
          name: "14K GOLD",
          createDate: "2022-02-03T00:56:42.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
        {
          id: 320,
          parentId: 319,
          no: null,
          name: "목걸이",
          createDate: "2021-07-16T09:54:51.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 0,
        },
        {
          id: 321,
          parentId: 319,
          no: null,
          name: "귀걸이",
          createDate: "2021-07-16T09:54:51.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 0,
        },
      ],
    },
    {
      id: 397,
      parentId: null,
      name: "For Couples",
      type: 1,
      exposureType: 0,
      sortType: 0,
      subText: "커플들을 위한 주얼리를 모아봤어요",
      createDate: "2021-12-17T02:07:14.000Z",
      updateDate: "2022-11-07T22:26:15.000Z",
      no: 3,
      categoryAttachments: [],
      isExhibit: 1,
      subCategories: [
        {
          id: 399,
          parentId: 397,
          no: 1,
          name: "커플링",
          createDate: "2021-12-17T02:07:14.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
        {
          id: 400,
          parentId: 397,
          no: 2,
          name: "팔찌",
          createDate: "2021-12-17T02:07:14.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
        {
          id: 398,
          parentId: 397,
          no: 3,
          name: "목걸이",
          createDate: "2021-12-17T02:07:14.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
      ],
    },
    {
      id: 270,
      parentId: null,
      name: "Men's",
      type: 1,
      exposureType: 0,
      sortType: 0,
      subText: "남자들을 위한 젠더리스 주얼리",
      createDate: "2021-07-14T20:45:13.000Z",
      updateDate: "2022-11-07T22:26:15.000Z",
      no: 4,
      categoryAttachments: [],
      isExhibit: 1,
      subCategories: [
        {
          id: 302,
          parentId: 270,
          no: 1,
          name: "반지",
          createDate: "2021-07-14T20:52:16.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 1,
        },
        {
          id: 362,
          parentId: 270,
          no: 2,
          name: "워치스트랩",
          createDate: "2021-12-16T16:33:53.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
        {
          id: 303,
          parentId: 270,
          no: 3,
          name: "이어커프",
          createDate: "2021-07-14T20:52:16.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 1,
        },
        {
          id: 304,
          parentId: 270,
          no: 4,
          name: "목걸이",
          createDate: "2021-07-14T20:52:16.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 1,
        },
        {
          id: 305,
          parentId: 270,
          no: 5,
          name: "팔찌",
          createDate: "2021-07-14T20:52:16.000Z",
          updateDate: "2023-02-09T09:34:56.000Z",
          isExhibit: 1,
        },
        {
          id: 360,
          parentId: 270,
          no: 6,
          name: "귀걸이",
          createDate: "2021-12-16T16:33:53.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
        {
          id: 361,
          parentId: 270,
          no: 7,
          name: "시계",
          createDate: "2021-12-16T16:33:53.000Z",
          updateDate: "2022-11-07T22:26:07.000Z",
          isExhibit: 1,
        },
      ],
    },
    {
      id: 578,
      parentId: null,
      name: "김김김",
      type: 1,
      exposureType: 0,
      sortType: 3,
      subText: "김김김",
      createDate: "2023-02-10T12:29:30.000Z",
      updateDate: "2023-02-10T12:29:30.000Z",
      no: 5,
      categoryAttachments: [],
      isExhibit: 1,
      subCategories: [],
    },
    {
      id: 564,
      parentId: null,
      name: "니기럴1",
      type: 1,
      exposureType: 1,
      sortType: 4,
      subText: "니기럴니기기럴",
      createDate: "2023-02-10T08:12:45.000Z",
      updateDate: "2023-02-10T09:48:49.000Z",
      no: null,
      categoryAttachments: [],
      isExhibit: 0,
      subCategories: [
        {
          id: 569,
          parentId: 564,
          no: null,
          name: "25",
          createDate: "2023-02-10T09:52:39.000Z",
          updateDate: "2023-02-10T09:52:39.000Z",
          isExhibit: 0,
        },
        {
          id: 583,
          parentId: 564,
          no: null,
          name: "24",
          createDate: "2023-02-13T02:22:51.000Z",
          updateDate: "2023-02-13T02:22:51.000Z",
          isExhibit: 0,
        },
        {
          id: 585,
          parentId: 564,
          no: null,
          name: "22",
          createDate: "2023-02-13T02:23:06.000Z",
          updateDate: "2023-02-13T02:23:06.000Z",
          isExhibit: 0,
        },
        {
          id: 586,
          parentId: 564,
          no: null,
          name: "21",
          createDate: "2023-02-13T02:23:13.000Z",
          updateDate: "2023-02-13T02:23:13.000Z",
          isExhibit: 0,
        },
        {
          id: 589,
          parentId: 564,
          no: null,
          name: "18",
          createDate: "2023-02-13T02:23:43.000Z",
          updateDate: "2023-02-13T02:23:43.000Z",
          isExhibit: 0,
        },
        {
          id: 590,
          parentId: 564,
          no: null,
          name: "23",
          createDate: "2023-02-13T02:28:15.000Z",
          updateDate: "2023-02-13T02:28:15.000Z",
          isExhibit: 0,
        },
      ],
    },
    {
      id: 675,
      parentId: null,
      name: "강조형 카테고리",
      type: 1,
      exposureType: 0,
      sortType: 1,
      subText: null,
      createDate: "2023-03-14T09:06:15.000Z",
      updateDate: "2023-03-14T09:06:15.000Z",
      no: null,
      categoryAttachments: [],
      isExhibit: 0,
      subCategories: [],
    },
  ],
});

export const couponListMock = mock({
  productDownloadableCoupons: mock_coupons
});

// 상품 최저가 혜택 api mock
export const productBenefitsMock = mock({
  ...maxDiscountAmountTypeCouponBenefit,
});