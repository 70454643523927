import axios from "axios";
import { takeEvery, put, throttle } from "redux-saga/effects";

import paymentAPI from "@services/apis/paymentAPI";
import { ApiHelperResponseType } from "@store/modules/common/types";
import { PAYMENT_MODAL_NAME_TYPE } from "@constants/enum/paymentEnums";
import { STATUS_CODE_COMMON } from "@constants/status/code/statusCodeCommon";
import asyncApiHelper from "@lib/utility/apiHelper";
import { userAuthAsync } from "../auth";
import {
  syncCartList,
  GET_CART_LIST,
  getCartListAsync,
  TOGGLE_CHECK_BOX,
  TOGGLE_CHECK_BOX_ALL,
  DELETE_CART_LIST,
  deleteCartListAsync,
  UPDATE_CART_COUNT,
  updateCartCountAsync,
  UPDATE_CART_OPTION,
  updateCartOptionAsync,
  ORDER_PREPARATION,
  orderPreparationAsync,
  COUPON_LIST,
  couponListAsync,
  DELIVERY_ADDRESS_LIST,
  deliveryAddressListAsync,
  INSERT_DELIVERY_ADDRESS,
  insertDeliveryAddressAsync,
  ORDER_PAY,
  orderPayAsync,
  PAY_RESULT,
  payResultAsync,
  paymentModalToggle,
  setDeliveryInfo,
  DELIVERY_EXTRA_FEE,
  deliveryExtraFeeAsync,
} from "./actions";

/**
 * 장바구니 데이터 동기화 SAGA
 */
function* syncCartListSaga() {
  yield put(syncCartList());
  yield put(userAuthAsync.request());
}

/**
 * 장바구니 가져오기 SAGA
 */
function* getCartListSaga(action: ReturnType<typeof getCartListAsync.request>) {
  try {
    // 서버 요청 헬퍼
    const result: ApiHelperResponseType = yield asyncApiHelper(paymentAPI.getCartList, action.payload);

    yield put(getCartListAsync.success(result));
  } catch (e) {
    if (axios.isAxiosError(e)) {
      yield put(getCartListAsync.failure(e));
    } else {
      console.error(e);
    }
  }
}

/**
 * 장바구니 상품 여러개 삭제 SAGA
 */
function* deleteCartListSaga(action: ReturnType<typeof deleteCartListAsync.request>) {
  try {
    // 서버 요청 헬퍼
    const result: ApiHelperResponseType = yield asyncApiHelper(paymentAPI.deleteCartMulti, action.payload);

    result.data = action.payload;
    yield put(deleteCartListAsync.success(result));
  } catch (e) {
    if (axios.isAxiosError(e)) {
      yield put(deleteCartListAsync.failure(e));
    } else {
      console.error(e);
    }
  }
}

/**
 * 장바구니 상품 수량 변경 SAGA
 */
function* updateCartCountSaga(action: ReturnType<typeof updateCartCountAsync.request>) {
  try {
    // 서버 요청 헬퍼
    const result: ApiHelperResponseType = yield asyncApiHelper(paymentAPI.updateCartCount, action.payload);

    result.data = action.payload;
    yield put(updateCartCountAsync.success(result));
  } catch (e) {
    if (axios.isAxiosError(e)) {
      yield put(updateCartCountAsync.failure(e));
    } else {
      console.error(e);
    }
  }
}

/**
 * 장바구니 상품 옵션 변경 SAGA
 */
function* updateCartOptionSaga(action: ReturnType<typeof updateCartOptionAsync.request>) {
  try {
    // 서버 요청 헬퍼
    const result: ApiHelperResponseType = yield asyncApiHelper(paymentAPI.updateCartOption, action.payload);

    result.data = action.payload;
    yield put(updateCartOptionAsync.success(result));
  } catch (e) {
    if (axios.isAxiosError(e)) {
      yield put(updateCartOptionAsync.failure(e));
    } else {
      console.error(e);
    }
  }
}

/**
 * 결제 준비 요청 SAGA
 */
function* orderPreparationSaga(action: ReturnType<typeof orderPreparationAsync.request>) {
  try {
    // 서버 요청 헬퍼
    const result: ApiHelperResponseType = yield asyncApiHelper(paymentAPI.orderPreparation, action.payload);

    yield put(orderPreparationAsync.success(result));
  } catch (e) {
    if (axios.isAxiosError(e)) {
      yield put(orderPreparationAsync.failure(e));
    } else {
      console.error(e);
    }
  }
}

/**
 * 쿠폰 리스트 요청 SAGA
 */
function* couponListSaga(action: ReturnType<typeof couponListAsync.request>) {
  try {
    // 서버 요청 헬퍼
    const result: ApiHelperResponseType = yield asyncApiHelper(paymentAPI.couponList, action.payload.requestData);

    yield put(
      couponListAsync.success({
        couponList: result.data,
        selectedCouponId: action.payload.data.selectedCouponId,
      }),
    );

    // 쿠폰 리스트 모달 띄우기
    yield put(
      paymentModalToggle({
        targetModal: PAYMENT_MODAL_NAME_TYPE.COUPON_LIST_MODAL,
        data: action.payload.data,
      }),
    );
  } catch (e) {
    if (axios.isAxiosError(e)) {
      yield put(couponListAsync.failure(e));
    } else {
      console.error(e);
    }
  }
}

/**
 * 배송지 리스트 요청 SAGA
 */
function* deliveryAddressListSaga(action: ReturnType<typeof deliveryAddressListAsync.request>) {
  try {
    // 서버 요청 헬퍼
    const result: ApiHelperResponseType = yield asyncApiHelper(paymentAPI.deliveryAddressList, action.payload);

    yield put(deliveryAddressListAsync.success(result.data.addressList));

    // 배송지 주소 모달 띄우기
    yield put(
      paymentModalToggle({
        targetModal: PAYMENT_MODAL_NAME_TYPE.DELIVERY_INFO_MODAL,
      }),
    );
  } catch (e) {
    if (axios.isAxiosError(e)) {
      yield put(deliveryAddressListAsync.failure(e));
    } else {
      console.error(e);
    }
  }
}

/**
 * 배송지 등록 요청 SAGA
 */
function* insertDeliveryAddressSaga(action: ReturnType<typeof insertDeliveryAddressAsync.request>) {
  try {
    // 서버 요청 헬퍼
    yield asyncApiHelper(paymentAPI.insertDeliveryAddress, action.payload.deliveryInfo);

    yield put(setDeliveryInfo(action.payload.deliveryInfo));

    yield put(
      deliveryExtraFeeAsync.request({
        zoneCode: Number(action.payload.deliveryInfo.postCode),
        storeIdCSV: action.payload.storeIdCSV,
      }),
    );

    // 배송지 주소 모달 닫기
    yield put(
      paymentModalToggle({
        targetModal: PAYMENT_MODAL_NAME_TYPE.DELIVERY_INFO_MODAL,
      }),
    );
  } catch (e) {
    if (axios.isAxiosError(e)) {
      yield put(insertDeliveryAddressAsync.failure(e));
    } else {
      console.error(e);
    }
  }
}

/**
 * 결제 진행 요청 SAGA
 */
function* orderPaySaga(action: ReturnType<typeof orderPayAsync.request>) {
  try {
    // 서버 요청 헬퍼
    const result: ApiHelperResponseType = yield asyncApiHelper(paymentAPI.orderPay, action.payload);

    yield put(orderPayAsync.success(result));
  } catch (e) {
    if (axios.isAxiosError(e)) {
      yield put(orderPayAsync.failure(e));
    } else {
      console.error(e);
    }
  }
}

/**
 * 결제 결과 요청 SAGA
 */
function* payResultSaga(action: ReturnType<typeof payResultAsync.request>) {
  try {
    // 서버 요청 헬퍼
    const result: ApiHelperResponseType = yield asyncApiHelper(paymentAPI.payResult, action.payload);

    yield put(
      payResultAsync.success({
        status: result.status,
        msg: result.msg,
        recommendList: result.data ? result.data.recommendList : null,
        orderItemList: result.data ? result.data.orderItemList : null,
        payCompleteInfo: {
          orderId: result.data ? result.data.orderId : null,
          totalPrice: result.data ? result.data.totalPrice : null,
          payMethod: result.data ? result.data.payMethod : null,
          totalDeliveryExtraFee: result.data ? result.data.totalDeliveryExtraFee : null,
          totalDiscountPrice: result.data ? result.data.totalDiscountPrice : null,
          savingPoint: result.data ? result.data.savingPoint : null,
        },
      }),
    );
  } catch (e) {
    if (axios.isAxiosError(e)) {
      yield put(payResultAsync.failure(e));
    } else {
      console.error(e);
    }
  }
}

/**
 * 추가 배송비 요금 요청 SAGA
 */
function* deliveryExtraFeeSaga(action: ReturnType<typeof deliveryExtraFeeAsync.request>) {
  try {
    // 서버 요청 헬퍼
    const result: ApiHelperResponseType = yield asyncApiHelper(paymentAPI.deliveryExtraFee, action.payload);

    if (result.status === STATUS_CODE_COMMON.SUCCESS) {
      yield put(deliveryExtraFeeAsync.success(result.data));
    } else {
      alert("서버 오류 발생");
    }
  } catch (e) {
    if (axios.isAxiosError(e)) {
      yield put(deliveryExtraFeeAsync.failure(e));
    } else {
      console.error(e);
    }
  }
}

export function* paymentSaga() {
  yield takeEvery(GET_CART_LIST.REQUEST, getCartListSaga);
  yield throttle(500, DELETE_CART_LIST.REQUEST, deleteCartListSaga);
  yield throttle(500, UPDATE_CART_COUNT.REQUEST, updateCartCountSaga);
  yield throttle(500, UPDATE_CART_OPTION.REQUEST, updateCartOptionSaga);
  yield takeEvery(ORDER_PREPARATION.REQUEST, orderPreparationSaga);
  yield takeEvery(COUPON_LIST.REQUEST, couponListSaga);
  yield takeEvery(DELIVERY_ADDRESS_LIST.REQUEST, deliveryAddressListSaga);
  yield takeEvery(INSERT_DELIVERY_ADDRESS.REQUEST, insertDeliveryAddressSaga);
  yield takeEvery(ORDER_PAY.REQUEST, orderPaySaga);
  yield takeEvery(PAY_RESULT.REQUEST, payResultSaga);
  yield takeEvery(DELIVERY_EXTRA_FEE.REQUEST, deliveryExtraFeeSaga);

  const syncActionList = [
    TOGGLE_CHECK_BOX,
    TOGGLE_CHECK_BOX_ALL,
    DELETE_CART_LIST.SUCCESS,
    UPDATE_CART_COUNT.SUCCESS,
    UPDATE_CART_OPTION.SUCCESS,
  ];
  yield takeEvery(syncActionList, syncCartListSaga);
}
