import styled from "../../../style/themeComponents";
import { AdsTypographyValue } from "../../../tokens";

export const GiftBadgeStyle = styled.span`
  padding: 1px 4px;
  gap: 8px;
  border-radius: 2px;
  background: ${({ theme }): string => theme.adsColor.gray_03};
  ${({ theme }): AdsTypographyValue => theme.adsTypo.bodyS};
  color: ${({ theme }): string => theme.adsColor.gray_07};
`;
