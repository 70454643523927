import { AxiosError } from "axios";
import { createAsyncAction } from "typesafe-actions";

import { HomeDetailStateType } from "./types";
import { BasePaginationRequestType, LikeProductCommonTypeForBraze, LikeProductSuccessType } from "../base";
import { HOME_EVENT_DETAIL_FILTER_TYPE } from "@constants/service/home/home";

// 홈 상세 타입
export type GetHomeDetailRequestType = {
  // 위젯 인식 index id
  widgetId: number;
  // 필터 접근 여부 flag
  isFilter: HOME_EVENT_DETAIL_FILTER_TYPE;
} & BasePaginationRequestType;

export type GetHomeDetailSuccessType = {
  data: HomeDetailStateType;
  status: number;
};

// ****** 위젯 상세 요청 액션 설정 ******
export const GET_WIDGET_DETAIL = {
  REQUEST: "home/GET_WIDGET_DETAIL_REQUEST",
  SUCCESS: "home/GET_WIDGET_DETAIL_SUCCESS",
  FAILURE: "home/GET_WIDGET_DETAIL_FAILURE",
};

export const getWidgetDetailAsync = createAsyncAction(
  GET_WIDGET_DETAIL.REQUEST,
  GET_WIDGET_DETAIL.SUCCESS,
  GET_WIDGET_DETAIL.FAILURE,
)<GetHomeDetailRequestType, GetHomeDetailSuccessType, AxiosError>();
// ****** 위젯 리스트 요청 액션 설정 완료 ******

// ****** 상세화면 상품 좋아요 액션 설정 ******
export const DETAIL_LIKE_PRODUCT = {
  REQUEST: "home/DETAIL_LIKE_PRODUCT_REQUEST",
  SUCCESS: "home/DETAIL_LIKE_PRODUCT_SUCCESS",
  FAILURE: "home/DETAIL_LIKE_PRODUCT_FAILURE",
};

export const detailLikeProductAsync = createAsyncAction(
  DETAIL_LIKE_PRODUCT.REQUEST,
  DETAIL_LIKE_PRODUCT.SUCCESS,
  DETAIL_LIKE_PRODUCT.FAILURE,
)<LikeProductCommonTypeForBraze, LikeProductSuccessType, AxiosError>();
// ****** 상세화면 상품 좋아요 액션 설정 완료 ******

// ****** 기획전 상세 링크 공유 액션 설정 ******
export const SHARE_EVENT_DETAIL_LINK = {
  REQUEST: "product/SHARE_EVENT_DETAIL_LINK",
  SUCCESS: "product/SHARE_EVENT_DETAIL_LINK",
  FAILURE: "product/SHARE_EVENT_DETAIL_LINK",
};

export type ShareEventDetailLinkRequestType = {
  // 기획전 인식 index id
  eventId: number;
};

export const shareEventDetailLink = createAsyncAction(
  SHARE_EVENT_DETAIL_LINK.REQUEST,
  SHARE_EVENT_DETAIL_LINK.SUCCESS,
  SHARE_EVENT_DETAIL_LINK.FAILURE,
)<ShareEventDetailLinkRequestType, undefined, AxiosError>();
// ****** 기획전 상세 링크 공유 액션 설정 ******
