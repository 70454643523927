import React, { createContext, PropsWithChildren, useContext } from "react";
import { ThemeProvider } from "../../style/themeComponents";
import theme from "../../style/theme";

export const DEVICE_TYPE = {
  PC: "pc",
  MO: "mo",
} as const;
export type DEVICE_TYPE = (typeof DEVICE_TYPE)[keyof typeof DEVICE_TYPE];

const AdsContext = createContext<DEVICE_TYPE | null>(null);

interface AdsContextProps {
  deviceType: DEVICE_TYPE;
}
export const AdsProvider = (props: PropsWithChildren<AdsContextProps>) => {
  const { deviceType, children } = props;

  return (
    <AdsContext.Provider value={deviceType}>
      <ThemeProvider theme={deviceType === DEVICE_TYPE.PC ? theme.pc : theme.mo}>{children}</ThemeProvider>
    </AdsContext.Provider>
  );
};

export const useAds = () => {
  return useContext(AdsContext);
};
