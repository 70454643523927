import { put, select, takeEvery, throttle } from "redux-saga/effects";
import Router from "next/router";
import axios from "axios";
import GTM from "@amondz/gtm";
import { RootStateType } from "@store/modules";
import { ApiHelperResponseType } from "@store/modules/common/types";
import cookies, { getCookieExpireDate, ICookieOption } from "@lib/utility/cookies";
import asyncApiHelper from "@lib/utility/apiHelper";
import gtm from "@lib/utility/gtm";
import brazeAttribute from "@lib/utility/braze";
import authAPI from "@services/apis/authAPI";
import { STATUS_CODE_COMMON } from "@constants/status/code/statusCodeCommon";
import { AMONDZ_LOGIN_TOKEN_KEY } from "@constants/service/auth/auth";
import { INTRO_PAGE_URL_PATH, SIGN_UP_PAGE_URL_PATH } from "@constants/url/internalUrlConstants";
import { ALREADY_JOIN_TYPE, LOGIN_TYPE } from "@constants/enum/authEnums";
import {
  EMAIL_LOGIN,
  emailLoginAsync,
  forceLogout,
  LOG_OUT,
  LogOutAsync,
  MEMBER_EMAIL_JOIN,
  MEMBER_PASSWORD_UPDATE,
  memberEmailJoinAsync,
  memberErrorReset,
  memberPasswordUpdateAsync,
  setAuth,
  SMS_AUTH_ID,
  SMS_AUTH_ID_CONFIRM,
  SMS_AUTH_JOIN,
  SMS_AUTH_JOIN_CONFIRM,
  SMS_AUTH_PASSWORD,
  SMS_AUTH_PASSWORD_CONFIRM,
  smsAuthIdAsync,
  smsAuthJoinAsync,
  smsAuthJoinConfirmAsync,
  smsAuthPasswordAsync,
  smsAuthPasswordConfirmAsync,
  smsCodeAuthAsync,
  SOCIAL_LOGIN_AND_JOIN,
  socialJoinPreparation,
  socialLoginAndJoinAsync,
  SocialLoginAndJoinSuccessType,
  USER_AUTH,
  userAuthAsync,
} from "./actions";

/**
 * sns 로그인 & 가입 SAGA
 */
function* snsLoginAndJoinSaga(action: ReturnType<typeof socialLoginAndJoinAsync.request>) {
  const { id, ...requestData } = action.payload;
  try {
    // 서버 요청 헬퍼
    const result: SocialLoginAndJoinSuccessType = yield asyncApiHelper(authAPI.snsLoginAndJoin, requestData);

    if (result.alreadyJoin === ALREADY_JOIN_TYPE.NEW && !action.payload.cellPhone) {
      let signUpUrl = `${SIGN_UP_PAGE_URL_PATH}?socialLogin=true`;
      if (action.payload.rtnUrl !== undefined && action.payload.rtnUrl !== "") {
        // rtnUrl이 있을 경우
        signUpUrl += `&rtnUrl=${encodeURIComponent(action.payload.rtnUrl)}`;
      }

      // 에러 상태 초기화
      yield put(memberErrorReset());

      // SNS 에서 받은 loginType, email, name, id 받아옴
      yield put(
        socialJoinPreparation({
          snsLoginType: action.payload.loginType,
          socialLoginAccessToken: action.payload.socialLoginAccessToken,
          id: action.payload.id,
          email: action.payload.email,
          name: action.payload.name,
        }),
      );

      // SNS 회원가입을 해야하는 경우
      // SNS 회원 가입 페이지로 이동
      Router.push(signUpUrl);
    } else {
      // 로그인 성공 시 토큰 저장
      yield cookies.set(AMONDZ_LOGIN_TOKEN_KEY, result.userKey, { path: "/" });

      // 소셜 회원가입 시
      if (action.payload.isJoinRequest) {
        gtm.completeSignUp({
          userId: result.userId,
          loginType: action.payload.loginType,
          isSmsAgree: !!action.payload.smsReceive,
        });
      }
      gtm.completeSignIn({ userId: result.userId, loginType: action.payload.loginType });

      // changeUser 이후 attribute 를 보내기 위한 delay
      setTimeout(() => {
        brazeAttribute.setEmailNotificationSubscriptionType(!!action.payload.emailReceive);
        brazeAttribute.setFirstName(result?.name || "");
      }, 1000);
      // 에러 상태 초기화
      yield put(memberErrorReset());

      yield put(socialLoginAndJoinAsync.success(result));

      // 로그인 활성
      yield put(
        setAuth({
          isLoggedIn: true,
        }),
      );

      if (result.alreadyJoin) {
        if (!action.payload.rtnUrl) {
          Router.push(INTRO_PAGE_URL_PATH);
        } else {
          Router.push(action.payload.rtnUrl);
        }
      }
    }
  } catch (e) {
    if (axios.isAxiosError(e)) {
      yield put(socialLoginAndJoinAsync.failure(e));
    } else {
      console.error(e);
    }
  }
}

/**
 * email 로그인 SAGA
 */
function* emailLoginSaga(action: ReturnType<typeof emailLoginAsync.request>) {
  try {
    // 서버 요청 헬퍼
    const result: ApiHelperResponseType = yield asyncApiHelper(authAPI.emailLogin, action.payload);

    if (result.status === STATUS_CODE_COMMON.SUCCESS) {
      // 로그인 성공 시 토큰을 쿠키에 저장함
      // 로그인 유지 여부에 따라서 토큰을 만료 기간 설정
      const cookieOption: ICookieOption = {
        path: "/",
      };

      if (action.payload.isLogInRemember) {
        // 로그인 유지하는 경우에는 쿠키 유지 기간을 10년을 설정
        cookieOption.expires = getCookieExpireDate();
      }

      // 로그인 성공 시 토큰 저장
      yield cookies.set(AMONDZ_LOGIN_TOKEN_KEY, result.data.userKey, cookieOption);

      gtm.completeSignIn({
        userId: result.data.userId,
        loginType: LOGIN_TYPE.EMAIL,
      });

      // changeUser 이후 attribute 를 보내기 위한 delay
      setTimeout(() => {
        brazeAttribute.setFirstName(result?.data.name || "");
      }, 1000);

      // rtnUrl 여부에 따라 페이지 이동
      if (action.payload.rtnUrl === undefined || action.payload.rtnUrl === "") {
        Router.push(INTRO_PAGE_URL_PATH);
      } else {
        Router.push(action.payload.rtnUrl);
      }
    }
    yield put(emailLoginAsync.success(result));
  } catch (e) {
    if (axios.isAxiosError(e)) {
      yield put(emailLoginAsync.failure(e));
    } else {
      console.error(e);
    }
  }
}

/**
 * 로그아웃 SAGA
 */
function* logOutSaga(action: ReturnType<typeof LogOutAsync.request>) {
  try {
    if (action.payload.isApiRequest) {
      // 로그아웃 시 서버로 로그아웃 api 를 요청하는 경우
      yield asyncApiHelper(authAPI.logOut);
    }

    // 에러 상태 초기화
    yield put(memberErrorReset());

    // 로그아웃 시 토큰 삭제
    yield cookies.remove(AMONDZ_LOGIN_TOKEN_KEY, { path: "/" });

    GTM.logout();

    if (Router.router?.pathname === INTRO_PAGE_URL_PATH) {
      // 홈 페이지에서 로그아웃 시, 홈 페이지를 reload 하여 홈 페이지 상태를 초기화 함
      Router.reload();
    } else {
      // 홈 페이지로 이동
      Router.push(INTRO_PAGE_URL_PATH);
    }

    // 상태 로그아웃 처리
    yield put(LogOutAsync.success());
  } catch (e) {
    console.log(e);
  }
}

/**
 * 계정 찾기 SMS 인증 요청 SAGA
 */
function* smsAuthSaga(action: ReturnType<typeof smsAuthIdAsync.request>) {
  try {
    // 서버 요청 헬퍼
    const result: ApiHelperResponseType = yield asyncApiHelper(authAPI.smsAuthRequest, action.payload);

    yield put(
      smsAuthIdAsync.success({
        status: result.status,
        isSendSmsAuthCode: result.status === STATUS_CODE_COMMON.SUCCESS,
      }),
    );
  } catch (e) {
    if (axios.isAxiosError(e)) {
      yield put(smsAuthIdAsync.failure(e));
    } else {
      console.error(e);
    }
  }
}

/**
 * 계정 찾기 SMS 코드 인증 요청 SAGA
 */
function* smsCodeAuthSaga(action: ReturnType<typeof smsCodeAuthAsync.request>) {
  try {
    // 서버 요청 헬퍼
    const result: ApiHelperResponseType = yield asyncApiHelper(authAPI.smsCodeAuthRequest, action.payload);

    yield put(
      smsCodeAuthAsync.success({
        status: result.status,
        loginType: result.data?.loginType,
        account: result.data?.account,
      }),
    );
  } catch (e) {
    if (axios.isAxiosError(e)) {
      yield put(smsCodeAuthAsync.failure(e));
    } else {
      console.error(e);
    }
  }
}

/**
 * 비밀번호 찾기 SMS 인증 요청 SAGA
 */
function* smsAuthPasswordSaga(action: ReturnType<typeof smsAuthPasswordAsync.request>) {
  try {
    // 서버 요청 헬퍼
    const result: ApiHelperResponseType = yield asyncApiHelper(authAPI.smsAuthPasswordRequest, action.payload);

    yield put(
      smsAuthPasswordAsync.success({
        status: result.status,
        isSendSmsAuthCode: result.status === STATUS_CODE_COMMON.SUCCESS,
      }),
    );
  } catch (e) {
    if (axios.isAxiosError(e)) {
      yield put(smsAuthPasswordAsync.failure(e));
    } else {
      console.error(e);
    }
  }
}

/**
 * 비밀번호 찾기 SMS 코드 인증 요청 SAGA
 */
function* smsAuthPasswordConfirmSaga(action: ReturnType<typeof smsAuthPasswordConfirmAsync.request>) {
  try {
    // 서버 요청 헬퍼
    const result: ApiHelperResponseType = yield asyncApiHelper(authAPI.smsAuthPasswordConfirm, action.payload);

    yield put(
      smsAuthPasswordConfirmAsync.success({
        status: result.status,
        account: result.status === STATUS_CODE_COMMON.SUCCESS ? action.payload.account : null,
        cellPhone: result.status === STATUS_CODE_COMMON.SUCCESS ? action.payload.cellPhone : null,
        authCode: result.status === STATUS_CODE_COMMON.SUCCESS ? action.payload.authCode : null,
      }),
    );
  } catch (e) {
    if (axios.isAxiosError(e)) {
      yield put(smsAuthPasswordConfirmAsync.failure(e));
    } else {
      console.error(e);
    }
  }
}

/**
 * 비밀번호 변경 요청 SAGA
 */
function* memberPasswordUpdateSaga(action: ReturnType<typeof memberPasswordUpdateAsync.request>) {
  try {
    // 서버 요청 헬퍼
    const result: ApiHelperResponseType = yield asyncApiHelper(authAPI.memberPasswordUpdate, action.payload);

    yield put(
      memberPasswordUpdateAsync.success({
        status: result.status,
      }),
    );
  } catch (e) {
    if (axios.isAxiosError(e)) {
      yield put(memberPasswordUpdateAsync.failure(e));
    } else {
      console.error(e);
    }
  }
}

/**
 * 회원가입 SMS 인증 번호 요청 SAGA
 */
function* smsAuthJoinSaga(action: ReturnType<typeof smsAuthJoinAsync.request>) {
  try {
    // 서버 요청 헬퍼
    const result: ApiHelperResponseType = yield asyncApiHelper(authAPI.smsAuthJoin, action.payload);

    // 에러 상태 초기화
    yield put(memberErrorReset());
    yield put(
      smsAuthJoinAsync.success({
        status: result.status,
        isSendSmsAuthCode: result.status === STATUS_CODE_COMMON.SUCCESS,
        loginType: result.data?.loginType,
      }),
    );
  } catch (e) {
    if (axios.isAxiosError(e)) {
      yield put(smsAuthJoinAsync.failure(e));
    } else {
      console.error(e);
    }
  }
}

/**
 * 회원가입 SMS 코드 인증 요청 SAGA
 */
function* smsAuthJoinConfirmSaga(action: ReturnType<typeof smsAuthJoinConfirmAsync.request>) {
  const currentLoginType: typeof LOGIN_TYPE = yield select(
    (state: RootStateType) => state.auth.signUpState.data.snsLoginType,
  );
  try {
    // 서버 요청 헬퍼
    yield asyncApiHelper(authAPI.smsAuthJoinConfirm, action.payload);

    // 에러 상태 초기화
    yield put(memberErrorReset());
    yield put(
      smsAuthJoinConfirmAsync.success({
        currentLoginType,
        email: action.payload.email,
        cellPhone: action.payload.cellPhone,
      }),
    );
  } catch (e) {
    if (axios.isAxiosError(e)) {
      yield put(smsAuthJoinConfirmAsync.failure(e));
    } else {
      console.error(e);
    }
  }
}

/**
 * 이메일 회원가입 요청 SAGA
 */
function* memberEmailJoinSaga(action: ReturnType<typeof memberEmailJoinAsync.request>) {
  try {
    // 서버 요청 헬퍼
    const result: ApiHelperResponseType = yield asyncApiHelper(authAPI.memberEmailJoin, action.payload);

    if (result.status === STATUS_CODE_COMMON.SUCCESS) {
      // 로그인 성공 시 토큰 저장
      yield cookies.set(AMONDZ_LOGIN_TOKEN_KEY, result.data.userKey, { path: "/" });

      gtm.completeSignUp({
        userId: result.data.userId,
        loginType: LOGIN_TYPE.EMAIL,
        isSmsAgree: !!action.payload.smsReceive,
      });
      gtm.completeSignIn({ userId: result.data.userId, loginType: LOGIN_TYPE.EMAIL });

      // changeUser 이후 attribute 를 보내기 위한 delay
      setTimeout(() => {
        brazeAttribute.setEmailNotificationSubscriptionType(!!action.payload.emailReceive);
        brazeAttribute.setFirstName(result?.data.name || "");
      }, 1000);

      // 로그인 활성
      yield put(
        setAuth({
          isLoggedIn: true,
        }),
      );
    }
    yield put(memberEmailJoinAsync.success(result));
  } catch (e) {
    if (axios.isAxiosError(e)) {
      yield put(memberEmailJoinAsync.failure(e));
    } else {
      console.error(e);
    }
  }
}

/**
 * 유저 인증 요청 SAGA
 */
function* userAuthSaga() {
  try {
    // 서버 요청 헬퍼
    const result: ApiHelperResponseType = yield asyncApiHelper(authAPI.userAuth);

    yield put(userAuthAsync.success(result.data));
  } catch (e) {
    console.log(e);
    // 회원정보가 유효하지 않을 경우에는 강제로그 아웃 처리
    yield put(LogOutAsync.request({}));

    // 강제 로그 아웃
    yield put(
      forceLogout({
        isForceLogout: true,
      }),
    );
  }
}

export function* authSaga() {
  yield throttle(500, SOCIAL_LOGIN_AND_JOIN.REQUEST, snsLoginAndJoinSaga);
  yield takeEvery(EMAIL_LOGIN.REQUEST, emailLoginSaga);
  yield takeEvery(LOG_OUT.REQUEST, logOutSaga);
  yield takeEvery(SMS_AUTH_ID.REQUEST, smsAuthSaga);
  yield takeEvery(SMS_AUTH_ID_CONFIRM.REQUEST, smsCodeAuthSaga);
  yield takeEvery(SMS_AUTH_PASSWORD.REQUEST, smsAuthPasswordSaga);
  yield takeEvery(SMS_AUTH_PASSWORD_CONFIRM.REQUEST, smsAuthPasswordConfirmSaga);
  yield takeEvery(MEMBER_PASSWORD_UPDATE.REQUEST, memberPasswordUpdateSaga);
  yield takeEvery(SMS_AUTH_JOIN.REQUEST, smsAuthJoinSaga);
  yield takeEvery(SMS_AUTH_JOIN_CONFIRM.REQUEST, smsAuthJoinConfirmSaga);
  yield throttle(500, MEMBER_EMAIL_JOIN.REQUEST, memberEmailJoinSaga);
  yield takeEvery(USER_AUTH.REQUEST, userAuthSaga);
}
