import * as React from "react";
import { FC } from "react";

import NotifyToast from "./toast/NotifyToast";

const Core: FC = () => {
  return (
    <>
      <NotifyToast />
    </>
  );
};

export default Core;
