export const STATUS_CODE_COMMON = {
  SERVER_ERROR: 0, // exception 이 발생한 경우

  SUCCESS: 1, // 성공

  MAINTENANCE: 10, // 점검중 or 공사중인 상태

  INCOMPLETE_PARAM: 100, // 매개변수가 제대로 전달되지 않은 경우,

  INVALID_MEMBER: {
    // 회원 정보가 유효하지 않을 경우
    CODE_200: 200, // 회원 정보처리중 exception 발생한 경우
    CODE_201: 201,
    CODE_202: 202,
    CODE_203: 203,
    CODE_204: 204,
  },

  FORCE_LOGOUT: 300, // 강제 로그아웃 시켜야할 경우

  FORCE_CLOSE: 310, // 앱을 강제로 종료 시켜야 하는 경우

  FORCE_VERSION_CHECK: 320, // 버전 체크를 해야하는 경우
};
