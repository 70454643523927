import React from "react";
import { ads_color } from "../../../tokens";

interface TodayDeliveryBadgeProps {
  color?: (typeof ads_color)[keyof typeof ads_color];
}
export const TodayDeliveryBadge = (props: TodayDeliveryBadgeProps) => {
  const { color = ads_color.purple_04 } = props;
  return (
    <svg width="52" height="14" viewBox="0 0 52 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="today_delivery">
        <path
          id="&#236;&#152;&#164;&#235;&#138;&#152;"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.1583 13.4587L7.09388 13.5001C2.55565 13.5206 -0.723347 10.0641 0.137756 6.16723C0.84657 2.95977 4.15628 0.558396 7.89156 0.541393L16.956 0.500092C21.4943 0.479405 24.7732 3.936 23.9121 7.83289C23.2033 11.0404 19.8936 13.4417 16.1583 13.4587ZM7.05381 6.3556C6.88628 6.14659 6.82981 5.87458 6.89067 5.56892C7.04053 4.81579 7.79089 4.32917 8.80238 4.32917C9.37986 4.32917 9.8284 4.49198 10.0653 4.78751C10.2348 4.99895 10.292 5.27349 10.2307 5.58126C10.082 6.3288 9.33239 6.81183 8.32079 6.81183C7.73965 6.81183 7.28964 6.64987 7.05381 6.3556ZM8.56624 9.09348H8.51458L8.52483 9.04178L8.74726 7.9239L8.75396 7.88993L8.78795 7.88497C10.2727 7.67079 11.3867 6.75443 11.6262 5.55067C11.7396 4.9807 11.6277 4.46507 11.3026 4.05939C10.8592 3.5062 10.0553 3.20148 9.03915 3.20148C7.23338 3.20148 5.7791 4.1726 5.50249 5.56301C5.26604 6.75137 6.00133 7.66287 7.3755 7.88508L7.41869 7.89215L7.40991 7.93625L7.18747 9.05413L7.17973 9.09348H7.1403H4.20979L4 10.1478H11.302L11.5117 9.09348H8.56624ZM14.0884 9.60716L14.0805 9.64652L14.0258 9.92148L14.0155 9.97318H14.0673H18.5204L18.3161 11H12.5036L12.9431 8.79104H17.1724H17.2117L17.2196 8.75179L17.2762 8.46765L17.2864 8.41595H17.2346H13.0177L13.213 7.43492H18.8255L18.3934 9.60716H14.1277H14.0884ZM19.2937 5.4471L19.4944 4.43863H15.2955H15.2437L15.254 4.38693L15.53 3H14.1861L13.6992 5.4471H19.2937ZM20 5.91383L19.7921 6.95901H12.4902L12.6981 5.91383H20Z"
          fill={color}
        />
        <g id="&#236;&#182;&#156;&#235;&#176;&#156;">
          <path
            id="Fill-12"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M29.034 1.73474L28.8064 2.87639H32.3459L32.5733 1.73474H29.034Z"
            fill={color}
          />
          <path
            id="Fill-14"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M26.3652 6.14442C27.989 6.14442 29.3875 5.73777 30.1064 5.05649L30.1587 5.0069L30.1953 5.06428C30.6067 5.71026 31.8936 6.14442 33.3976 6.14442H33.613L34.3967 5.00277H34.3021C33.0203 5.00277 32.1587 4.81834 31.7411 4.45455L31.6209 4.34994H31.7849H34.1645L34.3896 3.22021H26.6612L26.436 4.34994H28.7642H28.9747L28.7875 4.45951C28.239 4.78055 27.3233 4.94338 26.0661 4.94338H25.9106L26.2191 6.14442H26.3652Z"
            fill={color}
          />
          <path
            id="Fill-16"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M28.6484 7.71297H28.7155L28.7022 7.78004L28.6288 8.14843L28.6187 8.19943H28.5676H25.8103L25.5994 9.25784H31.1251H31.1923L31.1789 9.32491L31.1079 9.68137L31.0977 9.7325H31.0466H25.5049L25.0097 12.217H32.6083L32.8192 11.1586H27.0338H26.9666L26.98 11.0915L27.0439 10.7706L27.0541 10.7196H27.1052H32.6588L33.161 8.19943H30.4082H30.3409L30.3543 8.13237L30.4277 7.76398L30.4379 7.71297H30.489H34.3672L34.6089 6.50012H25.1224L24.8806 7.71297H28.6484Z"
            fill={color}
          />
          <path
            id="Fill-18"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M44.2041 3.92139H44.137L44.1503 3.85432L44.5774 1.71094H42.8434L41.7893 6.99991H43.5234L43.8495 5.36342L43.8597 5.31241H43.9108H45.1812L45.4585 3.92139H44.2041Z"
            fill={color}
          />
          <path
            id="Fill-20"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M39.3302 4.77348L39.1858 5.49846L39.1756 5.54946H39.1245H37.5199H37.4526L37.4659 5.4824L37.6105 4.75743L37.6206 4.70642H37.6718H39.2764H39.3436L39.3302 4.77348ZM39.8151 2.34082L39.6097 3.37125L39.5995 3.42225H39.5484H37.9438H37.8766L37.8899 3.35519L38.0921 2.34082H36.3932L35.4907 6.86929H40.6468L41.5493 2.34082H39.8151Z"
            fill={color}
          />
          <path
            id="Fill-22"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M37.4981 10.4141L37.5081 10.3631H37.5593H42.8533L43.4171 7.53406H36.3259L36.0772 8.78257H41.367H41.4343L41.4208 8.84963L41.3498 9.20621L41.3397 9.25722H41.2886H35.9825L35.4141 12.11H49.9131C50.214 12.11 50.4731 11.8962 50.5324 11.599C50.6109 11.2052 50.3118 10.8376 49.9131 10.8376H37.4808H37.4137L37.427 10.7707L37.4981 10.4141Z"
            fill={color}
          />
          <path
            id="Fill-24"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M49.3386 8.87411H45.3999C45.0378 8.87411 44.7603 8.58495 44.7803 8.22801C44.8002 7.87132 45.11 7.58215 45.4721 7.58215H49.4108C49.773 7.58215 50.0505 7.87132 50.0304 8.22801C50.0106 8.58495 49.7009 8.87411 49.3386 8.87411Z"
            fill={color}
          />
          <path
            id="Fill-26"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M51.3071 5.69394H47.3685C47.0062 5.69394 46.7288 5.40477 46.7488 5.04784C46.7686 4.69114 47.0784 4.40198 47.4406 4.40198H51.3793C51.7415 4.40198 52.019 4.69114 51.999 5.04784C51.9791 5.40477 51.6694 5.69394 51.3071 5.69394Z"
            fill={color}
          />
          <mask
            id="mask0_6474_403"
            style={{ maskType: "luminance" }}
            maskUnits="userSpaceOnUse"
            x="46"
            y="4"
            width="6"
            height="2"
          >
            <g id="Group">
              <path id="Vector" d="M46.7478 4.40198H52V5.69394H46.7478V4.40198Z" fill={color} />
            </g>
          </mask>
          <g mask="url(#mask0_6474_403)"></g>
        </g>
      </g>
    </svg>
  );
};
