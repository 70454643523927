import { COMMON_ERROR_CODE } from "@amondz/types";

/**
 * 공통 에러 코드 메세지
 */
export const COMMON_ERROR_MESSAGES = {
  [COMMON_ERROR_CODE.COMMON_001]: "예기치 않은 서버 오류가 발생했습니다.",
  [COMMON_ERROR_CODE.COMMON_002]: "회원정보가 유효하지 않습니다.\n다시 로그인해주세요.",
  [COMMON_ERROR_CODE.COMMON_003]: "회원정보가 유효하지 않습니다.\n다시 로그인해주세요.",
  [COMMON_ERROR_CODE.COMMON_004]: "예기치 못한 오류가 발생하였습니다.\n홈으로 이동합니다.",
  [COMMON_ERROR_CODE.COMMON_005]: "예기치 못한 오류가 발생하였습니다.\n홈으로 이동합니다.",
  [COMMON_ERROR_CODE.COMMON_006]: "일시적으로 오류가 발생하였습니다.\n60초 후 다시 시도해주세요.",
  [COMMON_ERROR_CODE.COMMON_009]: "일시적으로 오류가 발생하였습니다.",
};

export const COMMON_ERROR_TEXT = "일시적인 오류가 발생했습니다.";
