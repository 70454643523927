import { CouponListItemType } from "@amondz/types";
import { calculateCouponExpiryDate } from "@amondz/utils";

export const getCouponValidityText = (coupon: CouponListItemType) => {
  if (coupon.periodType === 'fixedDate' && coupon.date) {
    return calculateCouponExpiryDate(coupon.date.endDate);
  }
  if (coupon.periodType === 'generatedDate') {
    if (!coupon.latestAvailableCouponGenerated) {
      return {
        tillDate: null,
        formattedDate: `발급일로부터 ${coupon.dayPlus}일 동안 사용 가능`
      };
    } else {
      return calculateCouponExpiryDate(coupon.latestAvailableCouponGenerated.endDate);
    }
  }
  if (coupon.periodType === 'infinity') {
    return {
      tillDate: null,
      formattedDate: '무제한 사용 가능'
    };
  }
  return { tillDate: null, formattedDate: '' };
};
