import * as React from "react";
import { FC, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import OneButtonModalLayout from "@components/base/layout/modal/OneButtonModalLayout";
import { RootStateType } from "@store/modules";
import { BaseModalStateType, oneButtonModalToggle } from "@store/modules/base";
import { LogOutAsync } from "@store/modules/auth";

const OneButtonModal: FC = () => {
  const { oneButtonModal } = useSelector<RootStateType, BaseModalStateType>(
    (state: RootStateType) => state.base.baseModalState,
  );
  const { onCloseBtn, onClickBtn, isLogOut = false, ...props } = oneButtonModal;
  const dispatch = useDispatch();

  // 모달 닫기
  const toggleModal = useCallback(
    () =>
      dispatch(
        oneButtonModalToggle({
          visible: false,
        }),
      ),
    [],
  );

  // 로그아웃 (api 호출시 회원정보가 유효하지 않을 때 사용)
  const setLogOut = useCallback(() => dispatch(dispatch(LogOutAsync.request({}))), []);

  return (
    <OneButtonModalLayout
      {...props}
      onCloseBtn={() => {
        onCloseBtn && onCloseBtn();
        toggleModal();
      }}
      onClickBtn={() => {
        isLogOut && setLogOut();
        onClickBtn && onClickBtn();
        toggleModal();
      }}
    />
  );
};

export default OneButtonModal;
