/* tslint:disable */
/* eslint-disable */
/**
 * AMONDZ-USER-BACKEND-V2
 * AMONDZ-USER-BACKEND-V2 API 문서입니다.
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { ReviewsResponseBodyProductDto } from './reviews-response-body-product-dto';
// May contain unused imports in some cases
// @ts-ignore
import { ReviewsResponseBodyReviewAttachmentsDto } from './reviews-response-body-review-attachments-dto';
// May contain unused imports in some cases
// @ts-ignore
import { ReviewsResponseBodyReviewCriteriaDto } from './reviews-response-body-review-criteria-dto';
// May contain unused imports in some cases
// @ts-ignore
import { ReviewsResponseBodyReviewTagsDto } from './reviews-response-body-review-tags-dto';
// May contain unused imports in some cases
// @ts-ignore
import { ReviewsResponseBodyUserDto } from './reviews-response-body-user-dto';

/**
 * 
 * @export
 * @interface ReviewsResponseBodyDto
 */
export interface ReviewsResponseBodyDto {
    /**
     * 후기 식별 id
     * @type {number}
     * @memberof ReviewsResponseBodyDto
     */
    'id': number;
    /**
     * 후기 평점
     * @type {number}
     * @memberof ReviewsResponseBodyDto
     */
    'rate': ReviewsResponseBodyDtoRateEnum;
    /**
     * 후기 본문 내용
     * @type {string}
     * @memberof ReviewsResponseBodyDto
     */
    'content': string;
    /**
     * 상품 후기 좋아요 수
     * @type {number}
     * @memberof ReviewsResponseBodyDto
     */
    'likeCount': number;
    /**
     * 후기 생성 일자
     * @type {string}
     * @memberof ReviewsResponseBodyDto
     */
    'createDate': string;
    /**
     * 후기 수정 일자
     * @type {string}
     * @memberof ReviewsResponseBodyDto
     */
    'updateDate': string;
    /**
     * 유저의 후기 작성여부
     * @type {boolean}
     * @memberof ReviewsResponseBodyDto
     */
    'isMine': boolean;
    /**
     * 
     * @type {ReviewsResponseBodyUserDto}
     * @memberof ReviewsResponseBodyDto
     */
    'writer': ReviewsResponseBodyUserDto;
    /**
     * 
     * @type {ReviewsResponseBodyProductDto}
     * @memberof ReviewsResponseBodyDto
     */
    'product': ReviewsResponseBodyProductDto;
    /**
     * 후기 평가항목 리스트
     * @type {Array<ReviewsResponseBodyReviewCriteriaDto>}
     * @memberof ReviewsResponseBodyDto
     */
    'reviewCriteria': Array<ReviewsResponseBodyReviewCriteriaDto>;
    /**
     * 후기 첨부파일 리스트
     * @type {Array<ReviewsResponseBodyReviewAttachmentsDto>}
     * @memberof ReviewsResponseBodyDto
     */
    'attachments': Array<ReviewsResponseBodyReviewAttachmentsDto>;
    /**
     * 후기 태그 리스트
     * @type {Array<ReviewsResponseBodyReviewTagsDto>}
     * @memberof ReviewsResponseBodyDto
     */
    'tags': Array<ReviewsResponseBodyReviewTagsDto>;
    /**
     * 상품 후기 좋아요 여부
     * @type {boolean}
     * @memberof ReviewsResponseBodyDto
     */
    'likeFlag': boolean;
}

export const ReviewsResponseBodyDtoRateEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5
} as const;

export type ReviewsResponseBodyDtoRateEnum = typeof ReviewsResponseBodyDtoRateEnum[keyof typeof ReviewsResponseBodyDtoRateEnum];


