import { OrderReasonStateType } from "../../store/modules/mypage";

export const resetOrderReason = (orderReason: OrderReasonStateType) => {
  const keys = Object.keys(orderReason) as (keyof OrderReasonStateType)[];
  keys.forEach((key) => {
    orderReason[key].loading = true;
    orderReason[key].status = null;
    orderReason[key].data = null;
  });
  return orderReason;
};
