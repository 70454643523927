import * as React from "react";
import { FC, ReactNode } from "react";
import { createPortal } from "react-dom";

import { MODAL_CLOSE_BTN_IMAGE_PATH } from "@constants/static/images";
import theme from "@styles/theme";
import { ThemeProvider } from "@styles/themedComponents";
import Icon from "../../../common/Icon";
import { ModalCoverStyle } from "./style";
import { AdsProvider } from "@amondz/design-system";

interface IModalBaseProps {
  className?: string;
  visible: boolean;
  isCloseBtn?: boolean;
  canClickDimmed?: boolean;
  hasDimmedClose?: boolean;
  onCloseBtn?: () => void;
  onClickBtn?: () => void;
  children: ReactNode;
}

const ModalCover: FC<IModalBaseProps> = (props: IModalBaseProps) => {
  const { className, visible, isCloseBtn = true, canClickDimmed = true, onCloseBtn, onClickBtn, children } = props;

  /**
   * visible 값에 따라서 body 에 스크롤을 가능/불가능으로 설정
   */
  // useBodyScroll(visible);
  if (!visible) {
    return null;
  }

  const stopPropagation = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
  };

  const onClickDimmed = () => {
    if (canClickDimmed) {
      if (onCloseBtn) onCloseBtn();
      else if (onClickBtn) onClickBtn();
    }
  };

  return createPortal(
    <AdsProvider deviceType="pc">
      <ThemeProvider theme={(adsTheme) => ({ ...theme, ...adsTheme })}>
        <ModalCoverStyle data-testid="dimmed" className={className} onClick={onClickDimmed}>
          <div className="modal-box" onClick={stopPropagation}>
            {isCloseBtn && (
              <Icon
                className="modal-close-btn"
                alt="모달 닫힘 버튼"
                src={MODAL_CLOSE_BTN_IMAGE_PATH}
                onClick={onCloseBtn}
              />
            )}
            {children}
          </div>
        </ModalCoverStyle>
      </ThemeProvider>
    </AdsProvider>,
    document.body,
  );
};

export default ModalCover;
