import { ads_color } from "../../tokens";

export interface IconProps {
  className?: string;
  rotate?: number;
  size?: number;
  fill?: (typeof ads_color)[keyof typeof ads_color];
  stroke?: (typeof ads_color)[keyof typeof ads_color];
}

export const ICON_SIZE_TYPE = {
  SMALL: "small",
  MEDIUM: "medium",
  BIG: "big",
} as const;

export const ICON_SIZE = {
  [ICON_SIZE_TYPE.SMALL]: 16,
  [ICON_SIZE_TYPE.MEDIUM]: 20,
  [ICON_SIZE_TYPE.BIG]: 24,
} as const;
