import styled from "@styles/themedComponents";

export const ModalStyle = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  font-family: ${(props) => props.theme.fonts.notoSansCJKkr};
  z-index: ${(props) => props.theme.zIndex.modalBackdrop};

  .modal-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: ${(props) => props.theme.color.amondz_white};
    border-radius: 2px;
    z-index: ${(props) => props.theme.zIndex.modalBox};

    .modal-header {
      position: relative;
      height: 24px;
      line-height: 24px;

      .modal-close-btn {
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
        display: inline-block;
      }
    }

    .modal-full-screen-header {
      .modal-close-btn-section {
        padding: 18px 24px;
      }

      .modal-header-section {
        padding: 4px 24px 24px;
      }
    }

    .modal-header,
    .modal-full-screen-header {
      .modal-close-btn {
        width: 24px;
        height: 24px;
      }
    }

    .modal-footer {
      button {
        height: 40px;
        cursor: pointer;
      }
    }
  }

  &.mobile-full-screen {
    position: relative;

    .modal-box {
      position: fixed;
      width: 100%;
      height: 100%;
    }
  }
`;
