import { format } from "date-fns";
import { DATE_FORMAT } from "@amondz/constants";

/**
 * 날짜 format 지정하기
 *
 * @param {string} targetDate 특정 포맷으로 변경 및 문자열로 변환 할 날짜 데이터
 * @param {string} [format] 설정하고자는 하는 날짜 포맷
 *
 * @returns {string} 포맷이 설정된 날짜 문자열
 */
export const formatDate = (targetDate: string | Date, formatString?: DATE_FORMAT): string => {
  if (!formatString) formatString = DATE_FORMAT.YMD_DASH_FORMAT;
  return format(new Date(targetDate), formatString);
};
