export const ads_color = {
  black: "#000000",
  white: "#FFFFFF",
  primary: "#000000",
  secondary: "#6200F0",
  error: "#FE4F00",
  gray_01: "#FAF9F7",
  gray_02: "#F5F5F5",
  gray_03: "#EDEDED",
  gray_04: "#E5E5E5",
  gray_05: "#DDDDDD",
  gray_06: "#BCBCBC",
  gray_07: "#9B9B9B",
  gray_08: "#888888",
  gray_09: "#666666",
  gray_10: "#333333",
  purple_01: "#D7BEFA",
  purple_02: "#AF82FA",
  purple_03: "#8741F5",
  purple_04: "#6200F0",
  purple_05: "#5500D2",
  purple_06: "#4600B4",
  purple_07: "#3C0091",
  purple_08: "#2D0073",
  orange_01: "#FF6D2C",
  orange_02: "#FE4F00",
  orange_03: "#EB4900",
} as const;
