import { productCategoryListType } from "@amondz/types";

/**
 * subCategory name을 중복 없는 문자열배열로 변환
 * @param list productCategoryList
 */
export const subCategoryName = (list: productCategoryListType) =>
  list.reduce((a: string[], c) => {
    return a.find((v) => v === c.name) ? a : [...a, c.name];
  }, []);
