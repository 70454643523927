import { css } from "styled-components";
import { ads_color, ads_fonts, ads_typography, AdsTypography } from "../tokens";

export type AdsTheme = {
  adsTypo: AdsTypography;
  adsColor: typeof ads_color;
  adsFonts: typeof ads_fonts;
};

const mobileTypo = Object.entries(ads_typography.mo).reduce((sum, [key, value]) => {
  return {
    ...sum,
    [key]: css`
      font-size: ${value.fontSize};
      line-height: ${value.lineHeight};
      letter-spacing: ${value.letterSpacing};
      font-weight: ${value.fontWeight};
      font-family: ${value.fontFamily};
    `,
  };
}, {} as AdsTypography);

const pcTypo = Object.entries(ads_typography.pc).reduce((sum, [key, value]) => {
  return {
    ...sum,
    [key]: css`
      font-size: ${value.fontSize};
      line-height: ${value.lineHeight};
      letter-spacing: ${value.letterSpacing};
      font-weight: ${value.fontWeight};
      font-family: ${value.fontFamily};
    `,
  };
}, {} as AdsTypography);

const theme = {
  mo: {
    adsTypo: mobileTypo,
    adsColor: ads_color,
    adsFonts: ads_fonts,
  },
  pc: {
    adsTypo: pcTypo,
    adsColor: ads_color,
    adsFonts: ads_fonts,
  },
};

export default theme;
