import { AxiosError } from "axios";
import { createAction, createAsyncAction } from "typesafe-actions";

import { PRODUCT_DETAIL_DELIVERY_TYPE, PRODUCT_MODAL_NAME_TYPE } from "@constants/enum/productEnum";
import {
  OrderReturnExchangeDeliveryStateType,
  ProductDetailStateType,
  ProductItemType,
  ProductQnaItemType as GetProductQnaListSuccessType,
  ProductReviewDetailStateType,
  ProductReviewListType,
  ProductCategoryTree,
} from "./types";
import {
  LikeBrandRequestType,
  LikeProductSuccessType,
  LikeBrandSuccessType,
  LikeProductReviewSuccessType,
  LikeProductReviewCommonType,
  LikeProductCommonTypeForBraze,
} from "../base";
import { DeleteQnaProductType, ReviewIdType, UpdateQnaProductType } from "../mypage";
import { TODAY_DELIVERY_FILTER_TYPE } from "@constants/service/common/common";

// ****** 상품 카테고리 요청 액션 설정 ******
export const GET_PRODUCT_CATEGORY_TREE = {
  REQUEST: "product/GET_PRODUCT_CATEGORY_TREE_REQUEST",
  SUCCESS: "product/GET_PRODUCT_CATEGORY_TREE_SUCCESS",
  FAILURE: "product/GET_PRODUCT_CATEGORY_TREE_FAILURE",
};

type GetProductCategoryTreeSuccessType = {
  productCategoryTree: ProductCategoryTree;
};

export const getProductCategoryAsync = createAsyncAction(
  GET_PRODUCT_CATEGORY_TREE.REQUEST,
  GET_PRODUCT_CATEGORY_TREE.SUCCESS,
  GET_PRODUCT_CATEGORY_TREE.FAILURE,
)<undefined, GetProductCategoryTreeSuccessType, AxiosError>();
// ****** 상품 카테고리 요청 액션 설정 완료 ******

// ****** 상품 리스트 요청 액션 설정 ******
export const GET_PRODUCT_LIST = {
  REQUEST: "product/GET_PRODUCT_LIST_REQUEST",
  SUCCESS: "product/GET_PRODUCT_LIST_SUCCESS",
  FAILURE: "product/GET_PRODUCT_LIST_FAILURE",
};

export type GetProductListRequestType = {
  startIndex: number;
  sortType: number;
  isTodayDelivery: TODAY_DELIVERY_FILTER_TYPE;
  categoryIdCSV: string;
  storeIdCSV: string;
  baseIdCSV: string;
  shapeIdCSV: string;
  gemstoneIdCSV: string;
  postEarringIdCSV: string | null;
  chainShapeIdCSV: string | null;
  colorIdCSV: string;
  priceRangeCSV: string;
  discountRate: number;
  exceptAllCount?: boolean;
  hasProductList?: boolean;
  isMobile?: boolean;
  exceptCategoryTree?: boolean;
  widgetId?: number;
};

type GetProductListSuccessType = {
  allCount: number;
  productList: ProductItemType[];
  productCategoryTree: ProductCategoryTree;
  status: number;
  exceptAllCount?: boolean;
};

export const getProductListAsync = createAsyncAction(
  GET_PRODUCT_LIST.REQUEST,
  GET_PRODUCT_LIST.SUCCESS,
  GET_PRODUCT_LIST.FAILURE,
)<GetProductListRequestType, GetProductListSuccessType, AxiosError>();
// ****** 상품 리스트 요청 액션 설정 완료 ******

// ****** 브랜드 필터 트리 요청 액션 설정 ******
export const GET_BRAND_FILTER_TREE = {
  REQUEST: "product/GET_BRAND_FILTER_TREE_REQUEST",
  SUCCESS: "product/GET_BRAND_FILTER_TREE_SUCCESS",
  FAILURE: "product/GET_BRAND_FILTER_TREE_FAILURE",
};

type GetBrandFilterTreeSuccessType = {
  brandTree: number;
};

export const getBrandFilterTreeAsync = createAsyncAction(
  GET_BRAND_FILTER_TREE.REQUEST,
  GET_BRAND_FILTER_TREE.SUCCESS,
  GET_BRAND_FILTER_TREE.FAILURE,
)<undefined, GetBrandFilterTreeSuccessType, AxiosError>();
// ****** 브랜드 필터 트리 요청 액션 설정 완료 ******

// ****** 상품 상세 초기화 액션 설정 ******
export const INIT_PRODUCT_DETAIL = "product/INIT_PRODUCT_DETAIL";
export const initProductDetail = createAction(INIT_PRODUCT_DETAIL)();
// ****** 상품 상세 초기화 액션 설정 완료 ******

// ****** 상품 상세 요청 액션 설정 ******
export const GET_PRODUCT_DETAIL = {
  REQUEST: "product/GET_PRODUCT_DETAIL_REQUEST",
  SUCCESS: "product/GET_PRODUCT_DETAIL_SUCCESS",
  FAILURE: "product/GET_PRODUCT_DETAIL_FAILURE",
};

export type GetProductDetailRequestType = {
  // 상품 인식 index id
  productId: number;
  // 위젯 인식 index id
  widgetId?: number;
};

type GetProductDetailSuccessType = {
  productDetail: ProductDetailStateType;
  status: number;
};

export const getProductDetailAsync = createAsyncAction(
  GET_PRODUCT_DETAIL.REQUEST,
  GET_PRODUCT_DETAIL.SUCCESS,
  GET_PRODUCT_DETAIL.FAILURE,
)<GetProductDetailRequestType, GetProductDetailSuccessType, AxiosError>();
// ****** 상품 상세 요청 액션 설정 완료 ******

// ****** 상품 좋아요 액션 설정 ******
export const LIKE_PRODUCT = {
  REQUEST: "product/LIKE_PRODUCT_REQUEST",
  SUCCESS: "product/LIKE_PRODUCT_SUCCESS",
  FAILURE: "product/LIKE_PRODUCT_FAILURE",
};

export const likeProductAsync = createAsyncAction(LIKE_PRODUCT.REQUEST, LIKE_PRODUCT.SUCCESS, LIKE_PRODUCT.FAILURE)<
  LikeProductCommonTypeForBraze,
  LikeProductSuccessType,
  AxiosError
>();
// ****** 상품 좋아요 액션 설정 완료 ******

// ****** 브랜드 좋아요 액션 설정 ******
export const LIKE_STORE = {
  REQUEST: "product/LIKE_STORE_REQUEST",
  SUCCESS: "product/LIKE_STORE_SUCCESS",
  FAILURE: "product/LIKE_STORE_FAILURE",
};

export const likeStoreAsync = createAsyncAction(LIKE_STORE.REQUEST, LIKE_STORE.SUCCESS, LIKE_STORE.FAILURE)<
  LikeBrandRequestType & {
    storeName: string;
  },
  LikeBrandSuccessType,
  AxiosError
>();
// ****** 브랜드 좋아요 액션 설정 완료 ******

// ****** 상품 후기 좋아요 액션 설정 ******
export const LIKE_PRODUCT_REVIEW = {
  REQUEST: "product/LIKE_PRODUCT_REVIEW_REQUEST",
  SUCCESS: "product/LIKE_PRODUCT_REVIEW_SUCCESS",
  FAILURE: "product/LIKE_PRODUCT_REVIEW_FAILURE",
};

export const likeProductReviewAsync = createAsyncAction(
  LIKE_PRODUCT_REVIEW.REQUEST,
  LIKE_PRODUCT_REVIEW.SUCCESS,
  LIKE_PRODUCT_REVIEW.FAILURE,
)<LikeProductReviewCommonType, LikeProductReviewSuccessType, AxiosError>();
// ****** 상품 후기 좋아요 액션 설정 완료 ******

// ****** 상품 구매 정보 변경 액션 설정 ******
export const SET_PRODUCT_PURCHASE_INFO = "product/SET_PRODUCT_PURCHASE_INFO";
type SetProductPurchaseInfoType = {
  count: number;
  optionId?: number;
};
// ****** 상품 옵션 선택 액션 설정 ******
export const SELECT_PRODUCT_OPTION = "product/SELECT_PRODUCT_OPTION";
type SelectProductOptionType = {
  optionId: number;
  isDelete: boolean;
};
export const selectProductOption = createAction(SELECT_PRODUCT_OPTION)<SelectProductOptionType>();
// ****** 상품 옵션 선택 액션 설정 완료 ******

// ****** 상품 장바구니 액션 설정 ******
export const INSERT_PRODUCT_CART = {
  REQUEST: "product/INSERT_PRODUCT_CART_REQUEST",
  SUCCESS: "product/INSERT_PRODUCT_CART_SUCCESS",
  FAILURE: "product/INSERT_PRODUCT_CART_FAILURE",
};
type InsertProductCartType = {
  productId: number;
  count: number;
};
export const insertProductCart = createAsyncAction(
  INSERT_PRODUCT_CART.REQUEST,
  INSERT_PRODUCT_CART.SUCCESS,
  INSERT_PRODUCT_CART.FAILURE,
)<InsertProductCartType, null, AxiosError>();
// ****** 상품 장바구니 액션 설정 완료 ******

// ****** 상품 옵션 장바구니 액션 설정 ******
export const INSERT_PRODUCT_OPTION_CART = {
  REQUEST: "product/INSERT_PRODUCT_OPTION_CART_REQUEST",
  SUCCESS: "product/INSERT_PRODUCT_OPTION_CART_SUCCESS",
  FAILURE: "product/INSERT_PRODUCT_OPTION_CART_FAILURE",
};
type InsertProductOptionCartType = {
  productId: number;
  productOptionList: {
    optionId: number;
    count: number;
  }[];
};
export const insertProductOptionCart = createAsyncAction(
  INSERT_PRODUCT_OPTION_CART.REQUEST,
  INSERT_PRODUCT_OPTION_CART.SUCCESS,
  INSERT_PRODUCT_OPTION_CART.FAILURE,
)<InsertProductOptionCartType, null, AxiosError>();
// ****** 상품 옵션 장바구니 액션 설정 완료 ******

// ****** 상품 모달 토글 액션 설정 ******
export const PRODUCT_MODAL_TOGGLE = "product/PRODUCT_MODAL_TOGGLE";
type ProductModalToggleType = {
  targetModal: PRODUCT_MODAL_NAME_TYPE;
  productDetailDeliveryType?: PRODUCT_DETAIL_DELIVERY_TYPE;
  deliveryPeriod?: number | null;
  timeLimitText?: string | null;
};
export const productModalToggle = createAction(PRODUCT_MODAL_TOGGLE)<ProductModalToggleType>();
// ****** 상품 모달 토글 액션 설정 완료 ******

// ****** 상품 문의 리스트 요청 액션 설정 ******
export const GET_PRODUCT_QNA_LIST = {
  REQUEST: "product/GET_PRODUCT_QNA_LIST_REQUEST",
  SUCCESS: "product/GET_PRODUCT_QNA_LIST_SUCCESS",
  FAILURE: "product/GET_PRODUCT_QNA_LIST_FAILURE",
};
export type GetProductDetailAdditionalSectionRequestType = {
  productId: number;
  startIndex: number;
  exceptAllCount: boolean;
};
export const getProductQnaList = createAsyncAction(
  GET_PRODUCT_QNA_LIST.REQUEST,
  GET_PRODUCT_QNA_LIST.SUCCESS,
  GET_PRODUCT_QNA_LIST.FAILURE,
)<GetProductDetailAdditionalSectionRequestType, GetProductQnaListSuccessType, AxiosError>();
// ****** 상품 문의 리스트 요청 액션 설정 완료 ******

// ****** 상품 문의 작성 액션 설정 ******
export const INSERT_PRODUCT_QNA = {
  REQUEST: "product/INSERT_PRODUCT_QNA_REQUEST",
  SUCCESS: "product/INSERT_PRODUCT_QNA_SUCCESS",
  FAILURE: "product/INSERT_PRODUCT_QNA_FAILURE",
};
export type InsertProductQnaRequestType = {
  productId: number;
  question: string;
  date: string;
  orderItemId: null;
};

export type InsertProductQnaSuccessType = {
  status: number;
  data: {
    id?: number;
    account?: string;
    question: string;
    date: string;
  };
};
export const insertProductQna = createAsyncAction(
  INSERT_PRODUCT_QNA.REQUEST,
  INSERT_PRODUCT_QNA.SUCCESS,
  INSERT_PRODUCT_QNA.FAILURE,
)<InsertProductQnaRequestType, InsertProductQnaSuccessType, AxiosError>();
// ****** 상품 문의 작성 액션 설정 완료 ******

// ****** 상품 문의 수정 액션 설정 ******
export const UPDATE_PRODUCT_QNA = {
  REQUEST: "product/UPDATE_PRODUCT_QNA_REQUEST",
  SUCCESS: "product/UPDATE_PRODUCT_QNA_SUCCESS",
  FAILURE: "product/UPDATE_PRODUCT_QNA_FAILURE",
};
export const updateProductQna = createAsyncAction(
  UPDATE_PRODUCT_QNA.REQUEST,
  UPDATE_PRODUCT_QNA.SUCCESS,
  UPDATE_PRODUCT_QNA.FAILURE,
)<UpdateQnaProductType, UpdateQnaProductType, AxiosError>();
// ****** 상품 문의 수정 액션 설정 완료 ******

// ****** 상품 문의 삭제 액션 설정 ******
export const DELETE_PRODUCT_QNA = {
  REQUEST: "product/DELETE_PRODUCT_QNA_REQUEST",
  SUCCESS: "product/DELETE_PRODUCT_QNA_SUCCESS",
  FAILURE: "product/DELETE_PRODUCT_QNA_FAILURE",
};
export const deleteProductQna = createAsyncAction(
  DELETE_PRODUCT_QNA.REQUEST,
  DELETE_PRODUCT_QNA.SUCCESS,
  DELETE_PRODUCT_QNA.FAILURE,
)<DeleteQnaProductType, DeleteQnaProductType, AxiosError>();
// ****** 상품 문의 수정 액션 설정 완료 ******

// ****** 상품 상세 링크 공유 액션 설정 ******
export const SHARE_PRODUCT_DETAIL_LINK = {
  REQUEST: "product/SHARE_PRODUCT_DETAIL_LINK_REQUEST",
  SUCCESS: "product/SHARE_PRODUCT_DETAIL_LINK_SUCCESS",
  FAILURE: "product/SHARE_PRODUCT_DETAIL_LINK_FAILURE",
};
export type ShareProductDetailLinkRequestType = {
  productId: number;
};
export const shareProductDetailLink = createAsyncAction(
  SHARE_PRODUCT_DETAIL_LINK.REQUEST,
  SHARE_PRODUCT_DETAIL_LINK.SUCCESS,
  SHARE_PRODUCT_DETAIL_LINK.FAILURE,
)<ShareProductDetailLinkRequestType, undefined, AxiosError>();
// ****** 상품 상세 링크 공유 액션 설정 완료 ******

// ****** A/S 정보 요청 액션 설정 ******
export const GET_AS_INFO = {
  REQUEST: "product/GET_AS_INFO_REQUEST",
  SUCCESS: "product/GET_AS_INFO_SUCCESS",
  FAILURE: "product/GET_AS_INFO_FAILURE",
};
type GetAsInfoRequestType = {
  productId: number;
  storeId: number;
};
export const getAsInfo = createAsyncAction(GET_AS_INFO.REQUEST, GET_AS_INFO.SUCCESS, GET_AS_INFO.FAILURE)<
  GetAsInfoRequestType,
  OrderReturnExchangeDeliveryStateType,
  AxiosError
>();
// ****** A/S 정보 요청 액션 설정 완료 ******

// ****** 상품 후기 리스트 요청 액션 설정 ******
export const GET_PRODUCT_REVIEW_LIST = {
  REQUEST: "product/GET_PRODUCT_REVIEW_LIST_REQUEST",
  SUCCESS: "product/GET_PRODUCT_REVIEW_LIST_SUCCESS",
  FAILURE: "product/GET_PRODUCT_REVIEW_LIST_FAILURE",
};
export type GetProductReviewListRequestType = GetProductDetailAdditionalSectionRequestType & {
  // 포토후기만 불러올지 여부 (0: 전체후기, 1: 포토후기)
  isPhotoReview: 0 | 1;
};
export const getProductReviewListAsync = createAsyncAction(
  GET_PRODUCT_REVIEW_LIST.REQUEST,
  GET_PRODUCT_REVIEW_LIST.SUCCESS,
  GET_PRODUCT_REVIEW_LIST.FAILURE,
)<GetProductReviewListRequestType, ProductReviewListType, AxiosError>();
// ****** 상품 후기 리스트 요청 액션 설정 완료 ******

// ****** 후기 상세 액션 설정 ******
export const GET_PRODUCT_REVIEW_DETAIL = {
  REQUEST: "product/GET_PRODUCT_REVIEW_DETAIL_REQUEST",
  SUCCESS: "product/GET_PRODUCT_REVIEW_DETAIL_SUCCESS",
  FAILURE: "product/GET_PRODUCT_REVIEW_DETAIL_FAILURE",
};
type GetProductReviewDetailSuccessType = {
  status: number;
  data: ProductReviewDetailStateType;
};
export const getProductReviewDetailAsync = createAsyncAction(
  GET_PRODUCT_REVIEW_DETAIL.REQUEST,
  GET_PRODUCT_REVIEW_DETAIL.SUCCESS,
  GET_PRODUCT_REVIEW_DETAIL.FAILURE,
)<ReviewIdType, GetProductReviewDetailSuccessType, AxiosError>();
// ****** 후기 상세 액션 설정 완료 ******

// ****** 후기 작성 액션 설정 ******
export const INSERT_PRODUCT_REVIEW = {
  REQUEST: "product/INSERT_PRODUCT_REVIEW_REQUEST",
  SUCCESS: "product/INSERT_PRODUCT_REVIEW_SUCCESS",
  FAILURE: "product/INSERT_PRODUCT_REVIEW_FAILURE",
};
type InsertProductReviewSuccessType = {
  status: number;
  reviewId: number | null;
};
export const insertProductReviewAsync = createAsyncAction(
  INSERT_PRODUCT_REVIEW.REQUEST,
  INSERT_PRODUCT_REVIEW.SUCCESS,
  INSERT_PRODUCT_REVIEW.FAILURE,
)<FormData, InsertProductReviewSuccessType, AxiosError | number>();
// ****** 후기 작성 액션 설정 완료 ******
