// amondz 로그 (너비: 134) 이미지 경로
export const AMONDZ_LOGO_W_134_IMAGE_PATH = "/static/images/common/amondz_logo_w134.svg";

// amondz 로그 라운드(너비: 192) 이미지 경로
export const AMODZ_LOGO_ROUND_W_192_IMAGE_PATH = "/static/images/common/amondz_logo_round_w192.png";

// 오른쪽 화살표 이미지 경로
export const ARROW_RIGHT_BTN_IMAGE_PATH = "/static/images/common/arrow_right_btn.png";

// 공지 정보 이미지 경로
export const NOTI_INFO_IMAGE_PATH = "/static/images/common/noti_info.png";

// 공지 정보 이미지(너비: 24) 경로
export const NOTI_INFO_24_IMAGE_PATH = "/static/images/common/noti_info_24.png";

// 왼쪽 방향 화살표 아웃라인 블랙 이미지 경로
export const ARROW_LEFT_OUTLINE_BLACK_IMAGE_PATH = "/static/images/common/arrow_left_outline_black_btn.png";

// 왼쪽 방향 화살표 아웃라인 화이트 이미지 경로
export const ARROW_LEFT_OUTLINE_WHITE_IMAGE_PATH = "/static/images/common/arrow_left_outline_white_btn.png";

// 왼쪽 방향 화살표 아웃라인 화이트 이미지 경로
export const ARROW_LEFT_OUTLINE_GRAY_IMAGE_PATH = "/static/images/common/arrow_left_outline_gray_btn.png";

// 오른쪽 방향 화살표 원형 버튼 이미지 경로
export const ARROW_LEFT_ROUND_IMAGE_PATH = "/static/images/common/arrow_left_round_btn.png";

// 오른쪽 방향 화살표 아웃라인 블랙 이미지 경로
export const ARROW_RIGHT_OUTLINE_BLACK_IMAGE_PATH = "/static/images/common/arrow_right_outline_black_btn.png";

// 오른쪽 방향 화살표 아웃라인 블랙(너비 16) 이미지 경로
export const ARROW_RIGHT_OUTLINE_BLACK_W16_IMAGE_PATH = "/static/images/common/arrow_right_outline_black_w16_btn.png";

// 오른쪽 방향 화살표 아웃라인 화이트 이미지 경로
export const ARROW_RIGHT_OUTLINE_WHITE_IMAGE_PATH = "/static/images/common/arrow_right_outline_white_btn.png";

// 오른쪽 방향 화살표 아웃라인 그레이 이미지 경로
export const ARROW_RIGHT_OUTLINE_GRAY_IMAGE_PATH = "/static/images/common/arrow_right_outline_gray_btn.png";

// 오른쪽 방향 화살표 원형 버튼 이미지 경로
export const ARROW_RIGHT_ROUND_IMAGE_PATH = "/static/images/common/arrow_right_round_btn.png";

// 윗 방향 화살표 아웃라인 블랙 이미지 경로
export const ARROW_TOP_OUTLINE_BLACK_IMAGE_PATH = "/static/images/common/arrow_top_outline_black_btn.png";

// 윗 방향 화살표 아웃라인 그레이 이미지 경로
export const ARROW_TOP_OUTLINE_GRAY_IMAGE_PATH = "/static/images/common/arrow_top_outline_gray_w16_icon.png";

// 아래 방향 화살표 이미지 경로
export const ARROW_BOTTOM_IMAGE_PATH = "/static/images/common/arrow_bottom_btn.png";

// 윗 방향 화살표 아웃라인 블랙(너비 16) 이미지 경로
export const ARROW_TOP_OUTLINE_BLACK_W16_IMAGE_PATH = "/static/images/common/arrow_top_outline_black_w16_btn.png";

// 윗 방향 화살표 아웃라인 블랙(너비 24) 이미지 경로
export const ARROW_TOP_OUTLINE_BLACK_W24_IMAGE_PATH = "/static/images/common/arrow_top_outline_black_w24_btn.png";

// 아래 방향 화살표 아웃라인 블랙 이미지 경로
export const ARROW_BOTTOM_OUTLINE_BLACK_IMAGE_PATH = "/static/images/common/arrow_bottom_outline_black_btn.png";

// 아래 방향 화살표 아웃라인 블랙(너비 16) 이미지 경로
export const ARROW_BOTTOM_OUTLINE_BLACK_W16_IMAGE_PATH = "/static/images/common/arrow_bottom_outline_black_w16_btn.png";

// 아래 방향 화살표 아웃라인 블랙(너비 24) 이미지 경로
export const ARROW_BOTTOM_OUTLINE_BLACK_W24_IMAGE_PATH = "/static/images/common/arrow_bottom_outline_black_w24_btn.png";

// 아래 방향 화살표 아웃라인 화이트 이미지 경로
export const ARROW_BOTTOM_OUTLINE_WHITE_IMAGE_PATH = "/static/images/common/arrow_bottom_outline_white_btn.png";

// 아래 방향 화살표 아웃라인 disabled 이미지 경로
export const ARROW_BOTTOM_OUTLINE_DISABLED_IMAGE_PATH = "/static/images/common/arrow_bottom_outline_disabled_btn.png";

// 아래 방향 화살표 아웃라인 회색 이미지 경로
export const ARROW_BOTTOM_OUTLINE_GRAY_IMAGE_PATH = "/static/images/common/arrow_bottom_outline_gray_w16_icon.png";

// 오른쪽 단계 화살표 이미지 경로
export const STEP_RIGHT_ARROW_IMAGE_PATH = "/static/images/common/step-right-arrow.png";

// 오른쪽 단계 화살표 이미지 경로 비활성화
export const STEP_RIGHT_ARROW_INACTIVE_IMAGE_PATH = "/static/images/common/step-right-arrow-inactive.png";

// 아래 단계 화살표 이미지 경로
export const STEP_BOTTOM_ARROW_IMAGE_PATH = "/static/images/common/step-bottom-arrow.png";

// 오른쪽 방향 삼각형 그레이 버튼 이미지 경로
export const TRIANGLE_LEFT_GRAY_BTN_IMAGE_PATH = "/static/images/common/triangle-left-gray-btn.png";

// 라디오 버튼 on 이미지 경로
export const RADIO_BTN_ON_IMAGE_PATH = "/static/images/common/radio-btn-on.png";

// 라디오 버튼 off 이미지 경로
export const RADIO_BTN_OFF_IMAGE_PATH = "/static/images/common/radio-btn-off.png";

// 모달 닫기 버튼 이미지 경로
export const MODAL_CLOSE_BTN_IMAGE_PATH = "/static/images/common/modal_close_btn.png";

// 앱 스토어 로고 이미지 경로
export const APP_STORE_LOGO_IMAGE_PATH = "/static/images/common/app_store_logo.png";

// 앱 스토어 화이트 로고 이미지 경로
export const APP_STORE_WHITE_LOGO_IMAGE_PATH = "/static/images/common/app_store_white_logo.png";

// 구글 플레이 스토어 로고 이미지 경로
export const GOOGLE_PLAY_STORE_LOGO_IMAGE_PATH = "/static/images/common/google_play_store_logo.png";

// 구글 플레이 스토어 화이트 로고 이미지 경로
export const GOOGLE_PLAY_STORE_WHITE_LOGO_IMAGE_PATH = "/static/images/common/google_play_store_white_logo.png";

// 데이트 피커 아이콘 이미지 경로
export const DATE_PICKER_ICON_IMAGE_PATH = "/static/images/common/date_picker_icon.png";

// 소셜 인스타 화이트 버튼 이미지 경로
export const SOCIAL_INSTA_BTN_IMAGE_PATH = "/static/images/common/social_insta_btn.png";

// 소셜 인스타 화이트 24 버튼 이미지 경로
export const SOCIAL_INSTA_BTN_24_IMAGE_PATH = "/static/images/common/social_insta_btn_24.png";

// 소셜 인스타 그레이 버튼 이미지 경로
export const SOCIAL_INSTA_GRAY_BTN_IMAGE_PATH = "/static/images/common/social_insta_gray_btn.png";

// 소셜 페이스북 버튼 이미지 경로
export const SOCIAL_FACEBOOK_BTN_IMAGE_PATH = "/static/images/common/social_facebook_btn.png";

// 소셜 카카오 버튼 이미지 경로
export const SOCIAL_KAKAO_BTN_IMAGE_PATH = "/static/images/common/social_kakao_btn.png";

// 소셜 유투브 버튼 이미지 경로
export const SOCIAL_YOUTUBE_BTN_IMAGE_PATH = "/static/images/common/social_youtube_btn.png";

// 유효한 입력 체크 아이콘 이미지 경로
export const VALID_INPUT_CHECK_ICON_PATH = "/static/images/common/valid-input-check-icon.png";

// input 초기화 버튼 이미지 경로
export const CLEAR_INPUT_BTN_IMAGE_PATH = "/static/images/common/clear_input_btn.png";

// 삭제 버튼 이미지 경로
export const DELETE_BTN_IMAGE_PATH = "/static/images/common/delete_btn.png";

// 삭제 버튼 이미지 경로
export const DELETE_WHITE_BTN_IMAGE_PATH = "/static/images/common/delete_white_btn.png";

// 확장 버튼 (블랙) 이미지 경로
export const EXPAND_BLACK_BTN_IMAGE_PATH = "/static/images/common/expand_black_btn.png";

// 확장 버튼 (화이트) 이미지 경로
export const EXPAND_WHITE_BTN_IMAGE_PATH = "/static/images/common/expand_white_btn.png";

// 필터 토글 버튼 이미지 경로
export const FILTER_TOGGLE_BTN_IMAGE_PATH = "/static/images/common/filter_toggle_btn.png";

// 축소 버튼 이미지 경로
export const COLLAPSE_BTN_IMAGE_PATH = "/static/images/common/collapse_btn.png";

// 로케이션 아이콘 이미지 경로
export const LOCATION_ICON_IMAGE_PATH = "/static/images/common/location_icon.png";

// 다시선택 아이콘 이미지 경로
export const RESELECT_ICON_PATH = "/static/images/common/reselect_icon.png";

// 체크 아이콘 이미지 경로
export const CHECK_ICON_PATH = "/static/images/common/check_icon.png";

// 체크 라운드 아이콘 이미지 경로
export const CHECK_ROUND_ICON_PATH = "/static/images/common/check_round_icon.png";

// 체크 라운드 (퍼플 )아이콘 이미지 경로
export const CHECK_ROUND_PURPLE_ICON_PATH = "/static/images/common/check_round_purple_icon.png";

// 언체크 라운드 아이콘 이미지 경로
export const UNCHECK_ROUND_ICON_PATH = "/static/images/common/uncheck_round_icon.png";

// 상품 필터 리셋 버튼 이미지 경로
export const FILTER_RESET_BTN_IMAGE_PATH = "/static/images/common/filter_reset_btn.png";

// 검색 버튼 이미지 경로
export const SEARCH_BTN_IMAGE_PATH = "/static/images/common/search_btn.png";

// 검색 버튼 (회색) 이미지 경로
export const SEARCH_GRAY_BTN_IMAGE_PATH = "/static/images/common/search_gray_btn.png";

// 검색 버튼 24 (회색) 이미지 경로
export const SEARCH_GRAY_BTN_24_IMAGE_PATH = "/static/images/common/search_gray_btn_24.png";

// 헤더 매거진 방향 버튼 이미지 경로
export const HEADER_MAGAZINE_ARROW_WHITE_BTN_IMAGE_PATH =
  "/static/images/base/header/header_magazine_arrow_white_btn.png";

// 헤더 매거진 방향 버튼 (블랙) 이미지 경로
export const HEADER_MAGAZINE_ARROW_BLACK_BTN_IMAGE_PATH =
  "/static/images/base/header/header_magazine_arrow_black_btn.png";

// 헤더 메뉴 버튼 이미지 경로
export const HEADER_MENU_BLACK_BTN_IMAGE_PATH = "/static/images/base/header/header_menu_black_btn.png";

// 헤더 메뉴 버튼 (화이트) 이미지 경로
export const HEADER_MENU_WHITE_BTN_IMAGE_PATH = "/static/images/base/header/header_menu_white_btn.png";

// 헤더 마이 페이지 버튼 이미지 경로
export const HEADER_MY_PAGE_BTN_IMAGE_PATH = "/static/images/base/header/header_my_page_btn.png";

// 헤더 검색 버튼 (블랙) 이미지 경로
export const HEADER_SEARCH_BLACK_BTN_IMAGE_PATH = "/static/images/base/header/header_search_black_btn.png";

// 헤더 검색 버튼 (화이트) 이미지 경로
export const HEADER_SEARCH_WHITE_BTN_IMAGE_PATH = "/static/images/base/header/header_search_white_btn.png";

// 헤더 뒤로가기 버튼 이미지 경로
export const HEADER_BACK_BTN_IMAGE_PATH = "/static/images/base/header/header_back_btn.png";

// 헤더 장바구니 버튼 이미지 경로
export const HEADER_CART_BTN_IMAGE_PATH = "/static/images/base/header/header_cart_btn.svg";

// 푸터 소셜 인스타 버튼 이미지 경로
export const FOOTER_SOCIAL_INSTA_BTN_IMAGE_PATH = "/static/images/base/footer/footer_social_insta_btn.svg";

// 푸터 소셜 유투브 버튼 이미지 경로
export const FOOTER_SOCIAL_YOUTUBE_BTN_IMAGE_PATH = "/static/images/base/footer/footer_social_youtube_btn.svg";

// 푸터 소셜 페이스북 버튼 이미지 경로
export const FOOTER_SOCIAL_FACEBOOK_BTN_IMAGE_PATH = "/static/images/base/footer/footer_social_facebook_btn.svg";

// 푸터 소셜 카카오 버튼 이미지 경로
export const FOOTER_SOCIAL_KAKAO_BTN_IMAGE_PATH = "/static/images/base/footer/footer_social_kakao_btn.svg";

// 404 에러 정보 이미지 경로
export const ERROR_404_INFO_IMAGE_PATH = "/static/images/base/error/error_404_info.png";

// 상품 좋아요 off 버튼 이미지 경로
export const PRODUCT_LIKE_OFF_BTN_IMAGE_PATH = "/static/images/product/product_like_off_btn.png";

// 상품 좋아요 on 버튼 이미지 경로
export const PRODUCT_LIKE_ON_BTN_IMAGE_PATH = "/static/images/product/product_like_on_btn.png";

// 상품 후기 스타 off 버튼 이미지 경로
export const PRODUCT_REVIEW_STAR_OFF_IMAGE_PATH = "/static/images/product/product_review_star_off.png";

// 상품 후기 스타 on 버튼 이미지 경로
export const PRODUCT_REVIEW_STAR_ON_IMAGE_PATH = "/static/images/product/product_review_star_on.png";

// 상품 수량 증가 버튼 이미지 경로
export const PRODUCT_QUANTITY_PLUS_IMAGE_PATH = "/static/images/product/product_quantity_plus_btn.png";

// 상품 수량 감소 버튼 이미지 경로
export const PRODUCT_QUANTITY_MINUS_IMAGE_PATH = "/static/images/product/product_quantity_minus_btn.png";

// 상품 수량 증가 라운드 버튼 이미지 경로
export const PRODUCT_QUANTITY_PLUS_ROUND_IMAGE_PATH = "/static/images/product/product_quantity_plus_round_btn.png";

// 상품 수량 감소 라운드 버튼 이미지 경로
export const PRODUCT_QUANTITY_MINUS_ROUND_IMAGE_PATH = "/static/images/product/product_quantity_minus_round_btn.png";

// 상품 공유 버튼 이미지 경로
export const PRODUCT_SHARE_BTN_IMAGE_PATH = "/static/images/product/product_share_btn.png";

// 상품 가격 필터 버튼 이미지 경로
export const PRODUCT_PRICE_FILTER_OPEN_BTN_IMAGE_PATH = "/static/images/product/product_price_filter_open_btn.png";
// 상품 가격 필터 버튼 이미지 경로
export const PRODUCT_PRICE_FILTER_CLOSE_BTN_IMAGE_PATH = "/static/images/product/product_price_filter_close_btn.png";

// 상품 가격 필터 삭제 버튼 이미지 경로
export const PRODUCT_PRICE_FILTER_DELETE_BTN_IMAGE_PATH = "/static/images/product/product_price_filter_delete_btn.png";

// 신규 회원 20% 할인 쿠폰 이미지 경로
export const AUTH_WELCOME_COUPON_IMAGE_PATH = "/static/images/auth/welcome_coupon.png";
// apple 로그인 버튼 이미지 경로
export const AUTH_SOCIAL_APPLE_W72_BTN_PATH = "/static/images/auth/auth_social_apple_w72_btn.png";

// 페이스북 로그인 버튼 이미지 경로
export const AUTH_SOCIAL_FACEBOOK_W72_BTN_PATH = "/static/images/auth/auth_social_facebook_w72_btn.png";

// 카카오 로그인 버튼 이미지 경로
export const AUTH_SOCIAL_KAKAO_W72_BTN_PATH = "/static/images/auth/auth_social_kakao_w72_btn.png";

// 네이버 로그인 버튼 이미지 경로
export const AUTH_SOCIAL_NAVER_W72_BTN_PATH = "/static/images/auth/auth_social_naver_w72_btn.png";

// apple 로그인 버튼 이미지 경로
export const AUTH_SOCIAL_APPLE_ROUND_BTN_PATH = "/static/images/auth/auth_social_apple_round_btn.svg";

// 페이스북 로그인 버튼 이미지 경로
export const AUTH_SOCIAL_FACEBOOK_ROUND_BTN_PATH = "/static/images/auth/auth_social_facebook_round_btn.svg";

// 카카오 로그인 버튼 이미지 경로
export const AUTH_SOCIAL_KAKAO_ROUND_BTN_PATH = "/static/images/auth/auth_social_kakao_round_btn.svg";

// 네이버 로그인 버튼 이미지 경로
export const AUTH_SOCIAL_NAVER_ROUND_BTN_PATH = "/static/images/auth/auth_social_naver_round_btn.svg";

// 좋아요한 상품 빈 화면 이미지 경로
export const LIKE_PRODUCT_EMPTY_TABLET_PATH = "/static/images/mypage/like_product_empty_tablet.png";

// 좋아요한 상품 빈 화면 이미지 경로
export const LIKE_PRODUCT_EMPTY_PHONE_PATH = "/static/images/mypage/like_product_empty_phone.png";

// 좋아요한 브랜드 빈 화면 이미지 경로
export const LIKE_BRAND_EMPTY_PATH = "/static/images/mypage/like_brand_empty.png";

// 선물 상품 아이콘 이미지 경로
export const GIFT_ICON_PATH = "/static/images/mypage/gift_icon.png";

// 오른쪽 화살표 이미지 경로
export const ARROW_RIGHT_BTN_24_IMAGE_PATH = "/static/images/mypage/arrow_right_btn_24.png";

// 오른쪽 회색 화살표 이미지 경로
export const ARROW_RIGHT_GRAY_BTN_16_IMAGE_PATH = "/static/images/common/arrow_right_gray_btn_16.png";

// 오른쪽 회색 화살표 이미지 경로
export const ARROW_RIGHT_GRAY_BTN_24_IMAGE_PATH = "/static/images/common/arrow_right_gray_btn_24.png";

// 사진있음 이미지 경로
export const WITH_IMAGE_PATH = "/static/images/mypage/with_image.png";

// 카카오 페이 정보 이미지 경로
export const KAKAO_PAY_INFO_IMAGE_PATH = "/static/images/payment/kakao_pay_info.png";

// 네이버 페이 정보 이미지 경로
export const NAVER_PAY_INFO_IMAGE_PATH = "/static/images/payment/naver_pay_info.png";

// 선물내역 페이지 배너 배경 이미지 경로
export const GIFT_PAGE_BANNER_BACKGROUND_IMAGE_PATH = "/static/images/gift/gift_page_banner_background.png";

// 선물내역 페이지 배너 배경 이미지 경로
export const GIFT_PAGE_BANNER_TOP_LETTER_IMAGE_PATH = "/static/images/gift/gift_page_banner_top_letter.png";

// 선물내역 페이지 배너 배경 이미지 경로
export const GIFT_PAGE_BANNER_BOTTOM_LETTER_IMAGE_PATH = "/static/images/gift/gift_page_banner_bottom_letter.png";

// 선물내역 페이지 배너 수락 이미지 경로
export const GIFT_PAGE_BANNER_ACCEPT_LETTER_IMAGE_PATH = "/static/images/gift/gift_page_banner_accept_letter.png";

// 선물내역 페이지 배너 거절 이미지 경로
export const GIFT_PAGE_BANNER_REJECT_LETTER_IMAGE_PATH = "/static/images/gift/gift_page_banner_reject_letter.png";

// 팝업을 열 수 있는 이미지 경로
export const POPUP_INFO_IMAGE_PATH = "/static/images/payment/popup_info.png";

// 브랜드 리스트 페이지에서 브랜드 이름 앞에 들어가는 이미지 경로
export const BRAND_NAME_ICON = "/static/images/brand/brand_name_icon.png";

// 오른쪽 방향 둥그런 화살표 이미지 경로
export const ARROW_RIGHT_CIRCLE_BTN_IMAGE_PATH = "/static/images/common/arrow_right_circle_btn.png";

// 브랜드 default 이미지 경로
export const BRAND_DEFAULT_IMAGE_PATH = "/static/images/brand/brand_default_image.png";

// 브랜드 default 이미지 경로 (사각형)
export const BRAND_DEFAULT_IMAGE_SQUARE_PATH = "/static/images/brand/brand_default_image_square.png";

// 접기 아이콘 이미지 경로
export const ARROW_UP_PATH = "/static/images/common/icons-16-arrow-up.svg";

export const ARROW_DOWN_PATH = "/static/images/common/icons-16-arrow-down.svg";

export const INACTIVE_CHECK_ICON_PATH = "/static/images/common/inactive_check_icon.png";

//추가 아이콘
export const PLUS_ICON_PATH = "/static/images/common/plus-icon.svg";

// 다운로드 아이콘
export const DOWNLOAD_ICON_PATH = "/static/images/common/amondz_download_icon.svg";

// 쿠폰 발급 완료 아이콘
export const COUPON_DOWNLOAD_CHECK_ICON_PATH = "/static/images/common/amondz_download_check_icon.svg";

// 상품 상세 내 쿠폰 보기 아이콘
export const PRODUCT_DETAIL_COUPON_DOWNLOAD_CHECK_ICON_PATH = "/static/images/common/amondz_download_check_icon_dark_gray.svg";

// 센션 확장 아이콘
export const SECTION_EXPAND_ICON_PATH = "/static/images/common/arrow_bottom_ouline_gray_w24_icon.svg"

// 상품 상세 구매혜택 섹션 닫기 아이콘
export const SECTION_SHRINK_ICON_PATH = "/static/images/common/arrow_top_ouline_gray_w24_icon.svg"

// 아몬즈 비밀 콘텐츠 표기 아이콘
export const SECRET_CONTENTS_ICON_PATH = "/static/images/common/amonz_secret_contents_icon.svg"