// 상품 필터
export enum PRODUCT_FILTER_TYPE {
  STORE, // 브랜드
  CATEGORY, // 1차 카테고리
  SUB_CATEGORY, // 2차 카테고리
  BASE_MATERIAL, // 베이스 소재
  SHAPE, // 모양
  GEMSTONE, // 원석
  POST_EARRING_MATERIAL, // 귀걸이 침소재
  CHAIN_SHAPE, // 체인 모양
  COLOR, // 컬러
  PRICE, // 가격
  DISCOUNT, // 할인
}

// 상품 정럴 필터 리스트
export enum PRODUCT_SORT_TYPE {
  RECOMMENDATION, // 트렌드순
  POPULAR, // 인기순
  RECENT, // 최신순
  CHEAP, // 낮은가격순
  EXPENSIVE, // 높은가격순
}

// 상품 상태 리스트
export enum PRODUCT_STATUS {
  CONFIRM_READ, // 승인대기
  CONFIRM_REJECT, // 승인 거절
  NOW_ON_SALE, // 판매중
  SOLD_OUT, // 품절
  SALES_CLOSE, // 판매종료
  SALES_PROHIBIT, // 판매금지
  SALES_READY, // 판매대기
  SALES_STOP, // 판매중지
  DELETE, // 삭제
}

// 상품 옵션 상태 리스트
export enum PRODUCT_OPTION_STATUS {
  DEACTIVATE, // 비활성 (노출 안함)
  ACTIVATE, // 활성 (노출 함)
  SOLD_OUT, // 품절 (노출 함)
  DELETE, // 삭제 (노출 안함)
}

// 상품 상세에서 노출되는 배송안내 타입 리스트
export enum PRODUCT_DETAIL_DELIVERY_TYPE {
  NORMAL, // 일반배송
  ORDER_MADE, // 주문 시 제작
  IMMEDIATELY, // 바로 배송
  TODAY_DELIVERY, // 오늘출발
}

// 상품 이미지 타입 리스트
export enum PRODUCT_ATTACHMENT_TYPE {
  IMG_MAIN, // 대표 이미지
  IMG_SUB, // 미리보기 추가 이미지
  IMG_DETAIL, // 상세 컨텐츠 이미지
  VIDEO_DETAIL = 100, // 상세 컨텐츠 동영상
}

// 상품 모달명 리스트
export enum PRODUCT_MODAL_NAME_TYPE {
  PRODUCT_DELIVERY_INFO_MODAL = "productDeliveryInfoModal", // 상품 배송 안내 모달
  ORDER_RETURN_EXCHANGE_DELIVERY_INFO_MODAL = "orderReturnExchangeDeliveryInfoModal", // 주문 반품, 교환 배송 안내 모달
  AS_INFO_MODAL = "asInfoModal", // A/S 안내 모달
}

// 상품 구매 수량 버튼 타입 리스트
export enum PRODUCT_PURCHASE_COUNT_BTN_TYPE {
  DECREASE, // 감소
  INCREASE, // 증가
}

// 가격 할인 타입 리스트
export enum DISCOUNT_TYPE {
  AMOUNT, // 금액할인
  RATE, // 정률할인
}
