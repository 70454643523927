import TagManager from "react-gtm-module";
import { GTM_EVENT, GTMProductType } from "../../../types";

export interface GTMbeginCheckoutEventType {
  price: number;
  products: GTMProductType[];
}

/**
 * 결제 시작하기 이벤트 설정
 *
 * https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#begin_checkout
 */
export const beginCheckout = (data: GTMbeginCheckoutEventType): void => {
  const { price, products} = data;

  TagManager.dataLayer({
    dataLayer: {
      event: GTM_EVENT.BEGIN_CHECKOUT,
      currency: "KRW",
      value: price,
      items: products,
    },
  });
};
