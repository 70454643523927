import baseStyled, { css, ThemedStyledInterface } from "styled-components";
import { Z_INDEX } from "@amondz/constants";

export enum sizes {
  desktop = 1920,
  tablet = 768,
  phone = 480,
}

const media = {
  desktop: (...props: any) => undefined,
  tablet: (...props: any) => undefined,
  phone: (...props: any) => undefined,
};

Object.keys(sizes).reduce((acc: any, label: any) => {
  acc[label] = (...args: any) => css`
    @media (max-width: ${sizes[label]}px) {
      ${css(args.shift(), ...args)}
    }
  `;
  return acc;
}, media);

const color = {
  gray_01: "#9b9b9b",
  gray_02: "#bcbcbc",
  gray_03: "#d9d9d9",
  gray_04: "#dddddd",
  gray_05: "#e1e1e1",
  gray_06: "#e5e5e5",
  gray_07: "#ededed",
  gray_08: "#f7f7f7",
  gray_09: "#fafafa",
  gray_10: "#faf9f7",
  temp_gray_01: "#c3c3c3",
  temp_gray_02: "#b2b2b2",
  temp_gray_03: "#666666",
  temp_gray_04: "#f6f7f8",
  temp_gray_05: "#d8d8d8",
  temp_gray_06: "#f8f8f8",
  temp_gray_07: "#333",
  amondz_white: "#ffffff",
  amondz_black: "#000000",
  amondz_purple: "#6200f0",
  amondz_orange: "#fe4f00",
} as const;

const fonts = {
  barlow: "Barlow",
  notoSansCJKkr: "NotoSansCJKkr",
} as const;

const zIndex = {
  viewWrap: Z_INDEX.VIEW_WRAP,
  filterGroupKey: Z_INDEX.FILTER_GROUP_KEY,
  opacity: Z_INDEX.OPACITY,
  productFilter: Z_INDEX.PRODUCT_FILTER,
  storeDictionaryIndexList: Z_INDEX.STORE_DICTIONARY_INDEX_LIST,
  filterFooterSection: Z_INDEX.FILTER_FOOTER_SECTION,
  inputAddOn: Z_INDEX.INPUT_ADD_ON,
  productBuyInfoFixedBtn: Z_INDEX.PRODUCT_BUY_INFO_FIXED_BTN,
  header: Z_INDEX.HEADER,
  tab: Z_INDEX.TAB,
  topBottomButton: Z_INDEX.TOP_BOTTOM_BUTTON,
  bottomFixedArea: Z_INDEX.BOTTOM_FIXED_AREA,
  modalBackdrop: Z_INDEX.MODAL_BACK_DROP,
  dropDown: Z_INDEX.DROP_DOWN,
  fullScreenView: Z_INDEX.FULL_SCREEN_VIEW,
  modalBox: Z_INDEX.MODAL_BOX,
  loadingSpinner: Z_INDEX.LOADING_SPINNER,
  notifyToast: Z_INDEX.NOTIFY_TOAST,
  arrowButton: Z_INDEX.ARROW_BUTTON,
} as const;

// 말줄임표 설명 스타일
const ellipsis = css`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

// 글자 선택 못하게 하는 스타일
const noSelection = css`
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
`;

// 스크롤 비노출 하는 스타일
const invisibleScroll = css`
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`;

const theme = {
  color,
  media,
  fonts,
  ellipsis,
  zIndex,
  noSelection,
  invisibleScroll,
};

export type Theme = typeof theme;
export const styled = baseStyled as ThemedStyledInterface<Theme>;
export default theme;
