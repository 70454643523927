import { DeviceSize } from "@styles/themedComponents";

// 이미지 컴포넌트 퀄리티 값
export const IMG_QUALITY = 100;

// 쿠기 만료 기간 기본값
export const COOKIE_EXPIRE_DAY_DEFAULT = 10;

// 디바이스 타입 리스트
export const DEVICE_TYPE = {
  PC: 2,
  MOBILE: 3,
};

// 유저 웹 버전
export const WEB_SERVICE_VERSION_CODE = "1.0";

// 오늘출발 여부 타입
export const TODAY_DELIVERY_FILTER = {
  // 전체
  ENTIRE: 0,
  // 오늘출발
  TODAY_DELIVERY: 1,
} as const;
export type TODAY_DELIVERY_FILTER_TYPE = typeof TODAY_DELIVERY_FILTER[keyof typeof TODAY_DELIVERY_FILTER];
// 오늘출발 여부 타입
export const GIFT_FILTER = {
  // 전체
  ENTIRE: 0,
  // 선물하기
  GIFT: 1,
} as const;
export type GIFT_FILTER_TYPE = typeof GIFT_FILTER[keyof typeof GIFT_FILTER];

export const LIKE_EVENT_THROTTLE_SPEED = 500;

export const LIKE_STATUS = {
  UN_LIKE: 0,
  LIKE: 1,
} as const;
export type LIKE_STATUS_TYPE = typeof LIKE_STATUS[keyof typeof LIKE_STATUS];

export const LIKE_RESULT_CODE = {
  // 실패
  FAIL: 0,
  // insert 성공
  INSERT_SUCCESS: 1,
  // delete 성공
  DELETE_SUCCESS: 2,
} as const;

// 리스트 페이지네이션 노출 개수 (페이지 번호 사이의 개수)
export const LIST_PAGE_RANGE_DISPLAYED = {
  [DeviceSize.SSR]: 7,
  [DeviceSize.DESKTOP]: 7,
  [DeviceSize.TABLET]: 2,
  [DeviceSize.PHONE]: 2,
};

// 카테고리 페이지 상품 카드 컬럼 갯수
export const PRODUCT_LIST_PAGE_VERTICAL_CARD_COLUMN = 3;

// 브랜드 상세 페이지 상품 카드 컬럼 갯수
export const BRAND_DETAIL_PAGE_VERTICAL_CARD_COLUMN = 4;

// 상품 카드 row gap
export const VERTICAL_CARD_ROW_GAP = 60;

// 상품 카드 column gap
export const VERTICAL_CARD_COLUMN_GAP = 16;
