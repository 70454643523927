import { InternalAxiosRequestConfig, AxiosResponse } from "axios";
import { isNil } from "lodash";

import superFetchV2 from "@amondz/apis-v2";
import { getHeaderData } from "@services/fetchUtils";
import { ErrorType } from "@store/modules/common/types";
import { errorHandlerV2 } from "@services/errorHandlers";

const baseURL = process.env.NEXT_PUBLIC_API_URL_V2;

superFetchV2.interceptors.request.use((req: InternalAxiosRequestConfig) => {
  console.log("request base url: ", baseURL);
  console.log("api url: ", req.url);

  if (!req.headers) {
    return req;
  }

  const { userKey, uuid, pageView, deviceType, os, version } = getHeaderData(req);
  console.log("device_type: ", deviceType);
  req.headers["Authorization"] = userKey;
  req.headers["platform-type"] = deviceType;
  req.headers["os"] = os;
  req.headers["api-version"] = version;
  if (typeof req.headers["page-view"] !== "number" && !isNil(pageView)) req.headers["page-view"] = pageView;

  // CSR 에서 UUID 설정
  if (typeof window !== "undefined") req.headers["uuid"] = uuid;
  else delete req.headers.prevUrlPath;

  console.log("req.headers: ", req.headers);

  console.log("------- 서버 요청 -------");
  console.log(req.data);
  console.log(req);
  return req;
});

superFetchV2.interceptors.response.use(
  (res: AxiosResponse) => {
    console.log("------- 서버 응답 -------");
    console.log(res.data);
    return res.data;
  },
  (error: ErrorType) => {
    if (error?.response?.data?.errors?.length > 0) {
      errorHandlerV2(error.response.data.errors[0]);
    }

    return Promise.reject(error);
  },
);
