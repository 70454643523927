export const initChannelTalk = () => {
  var w = window as any;
  if (w.ChannelIO) {
    return ((window as any).console.error || (window as any).console.log || function () {})(
      "ChannelIO script included twice.",
    );
  }
  var ch: any = function () {
    ch.c(arguments);
  };
  ch.q = [];
  ch.c = function (args: any) {
    ch.q.push(args);
  };
  w.ChannelIO = ch;
  function l() {
    if (w.ChannelIOInitialized) {
      return;
    }
    w.ChannelIOInitialized = true;
    var s = document.createElement("script");
    s.type = "text/javascript";
    s.async = true;
    s.src = "https://cdn.channel.io/plugin/ch-plugin-web.js";
    var x: any = document.getElementsByTagName("script")[0];
    x.parentNode.insertBefore(s, x);
  }
  if (document.readyState === "complete") {
    l();
  } else if ((window as any).attachEvent) {
    (window as any).attachEvent("onload", l);
  } else {
    window.addEventListener("DOMContentLoaded", l, false);
    window.addEventListener("load", l, false);
  }
};
