/**
 * stickyTop - 스크롤 시 상단 sticky한 영역은 제외하고 스크롤을 해야 사용자가 정상적인 화면을 볼 수 있기때문에 정확한 스크롤 위치 파악시 사용
 * scrollTo - sticky한 요소를 제외한 영역으로 스크롤 이동
 * */
import {useEffect, useRef, useState} from "react";

export const SCROLL_STATUS = {
  BEFORE:'before',
  CURRENT:'current',
  AFTER:'after'
};

export function useScroll() {
  const [isScrollingTo,setIsScrollingTo] = useState(false);
  const [stickyTop, setStickyTop] = useState(0);
  const scrollTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const scrollTo = (target: HTMLElement | number, options: ScrollToOptions & { additionalBaseTop?: number } = {}) => {
    const { additionalBaseTop = 0, ...scrollToOptions } = options;
    setIsScrollingTo(true);
    if(scrollTimeoutRef.current!==null) clearTimeout(scrollTimeoutRef.current);
    scrollTimeoutRef.current = setTimeout(() => setIsScrollingTo(false),700)
    window.scrollTo({
      top: typeof target === "number" ? target : target.offsetTop - stickyTop - additionalBaseTop,
      left: 0,
      behavior: "smooth",
      ...scrollToOptions,
    });
  };

  //dom 요소 기준으로 스크롤 위치 파악
  const getScrollStatus = (element:HTMLElement,additionalBaseTop:number = 0) => {
    const currentScroll = window.scrollY + stickyTop + additionalBaseTop;
    const scrollRangeFrom = element.offsetTop;
    const scrollRangeTo = element.offsetTop + element.clientHeight;

    if(currentScroll < scrollRangeFrom) return SCROLL_STATUS.BEFORE;
    if(currentScroll >= scrollRangeFrom && currentScroll <= scrollRangeTo) return SCROLL_STATUS.CURRENT;
    if(currentScroll > scrollRangeTo) return SCROLL_STATUS.AFTER;

  }

  useEffect(() => {
    setStickyTop(document.querySelector("header")?.offsetHeight || 0);
  }, []);

  return {
    isScrollingTo,
    stickyTop,
    scrollTo,
    getScrollStatus
  };
}
