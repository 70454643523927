import React from "react";
import { ads_color } from "../../../tokens";
import { IconProps } from "../types";

export const ArrowRight = (props: IconProps) => {
  const { className, rotate, size = 24, fill = ads_color.black, stroke = ads_color.black } = props;
  const style = rotate ? { transform: `rotate(${rotate}deg)` } : {};
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.25 8.00002L6.25 11.3334L6.25 4.66669L10.25 8.00002Z"
        fill="black"
      />
    </svg>
  );
};
