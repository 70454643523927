export const TAB_SIZE = {
  SMALL: "small",
  MEDIUM: "medium",
  LARGE: "large",
} as const;

export const TAB_ACTION_TYPE = {
  NORMAL: "normal",
  SCROLL: "scroll",
  SWIPE: "swipe",
} as const;

export const TAB_TYPE = {
  //컨테이너 너비에 따라 균등하게 각 탭의 너비를 설정
  FULL: "full",
  //각 탭이 일정 너비를 가지며 컨테이너 너비를 초과시 스크롤 발생
  FLEX: "flex",
};
