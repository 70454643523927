import styled from "../../style/themeComponents";
import { AVATAR_SHAPE, AVATAR_SIZE } from "./constants";

interface AvatarStyleProps {
  size: "large" | "normal" | "small" | number;
  shape: "round" | "round-square" | "square";
}
export const AvatarStyle = styled.div<AvatarStyleProps>`
  width: ${({ size }) => (typeof size === "number" ? size : AVATAR_SIZE[size])}px;
  height: ${({ size }) => (typeof size === "number" ? size : AVATAR_SIZE[size])}px;
  border-radius: ${({ shape }) => AVATAR_SHAPE[shape]};
  overflow: hidden;

  img {
    width: ${({ size }) => (typeof size === "number" ? size : AVATAR_SIZE[size])}px;
    height: ${({ size }) => (typeof size === "number" ? size : AVATAR_SIZE[size])}px;
  }
`;
