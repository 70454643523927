export const LUCKY_DRAW_ERROR_CODE = {
  LUCKYDRAW_001: 'LUCKYDRAW001',
  LUCKYDRAW_002: 'LUCKYDRAW002',
  LUCKYDRAW_003: 'LUCKYDRAW003',
}

export const LUCKY_DRAW_ERROR_MESSAGES = {
  [LUCKY_DRAW_ERROR_CODE.LUCKYDRAW_001]: '일시적으로 오류가 발생하였습니다.', // luckyDrawCode를 입력해주세요.
  [LUCKY_DRAW_ERROR_CODE.LUCKYDRAW_002]: '일시적으로 오류가 발생하였습니다.', // luckyDrawCode는 string 입니다.
  [LUCKY_DRAW_ERROR_CODE.LUCKYDRAW_003]: '이미 응모 하셨습니다.',
}