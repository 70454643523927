import superFetchV2, { MemberSummaryDto } from "@amondz/apis-v2";

const MEMBER_SUMMARY_END_POINT = "members-summary";

export const mypageApiV2 = {
  membersSummary: async (): Promise<MemberSummaryDto> =>
    // FIXME: 보성
    // @ts-expect-error superFetchV2의 인터셉터 문제를 고쳐야합니다.
    superFetchV2.get<Promise<MemberSummaryDto>>(MEMBER_SUMMARY_END_POINT),
};
