// 버튼 컬러 리스트
export enum BUTTON_COLOR {
  WHITE = "white",
  GRAY = "gray",
  BLACK = "black",
}

// 버튼 사이즈 리스트
export enum BUTTON_SIZE {
  LARGE = "large",
  MEDIUM = "medium",
  SMALL = "small",
}

// 스크롤 방향 타입 리스트
export enum SCROLL_DIRECTION {
  UP = "up",
  DOWN = "down",
}

// 디바이스 OS 타입 리스트
export enum DEVICE_OS_TYPE {
  BROWSER,
  ANDROID,
  IOS,
}

// 베이스 모달명 리스트
export enum BASE_MODAL_NAME_TYPE {
  APP_INSTALL_INFO_MODAL = "appInstallInfoModal", // 앱 설치 안내 모달
}

// NODE ENV 환경변수 리스트
export enum NODE_ENV_MODE_TYPE {
  PRODUCTION = "production", // 상용 모드
  DEVELOPMENT = "development", // 개발 모드
}

// 단위 타입 리스트
export enum UNIT_TYPE {
  WON = "원",
  PERCENT = "%",
}

// 상품 문의 서버 요청 타입
export enum PRODUCT_QNA_REQUEST_TYPE {
  EDIT = 0,
  DELETE = 1,
}

// 회원 정보 수정 요청 타입
export enum USER_MODIFY_REQUEST_TYPE {
  PASSWORD = 0,
  PROFILE = 1,
}

// 토스트 메시지 타입
export enum NOTIFY_TOAST_TYPE {
  INFO = "info",
  ERROR = "error",
  SUCCESS = "success",
}

// 페이지뷰
export enum PAGE_VIEW_TYPE {
  // 홈 페이지
  HOME_PAGE = 1,
  // 상품 리스트 페이지
  PRODUCT_LIST_PAGE = 2,
  // 브랜드 리스트 페이지
  BRAND_LIST_PAGE = 4,
  // 상품 좋아요 페이지
  PRODUCT_LIKE_PAGE = 5,
  // 브랜드 좋아요 페이지
  BRAND_LIKE_PAGE = 6,
  // 위젯 상세 페이지
  WIDGET_DETAIL_PAGE = 7,
  // 상품 상세 페이지
  PRODUCT_DETAIL_PAGE = 8,
  // 브랜드 상세 페이지
  BRAND_DETAIL_PAGE = 9,
  // 기획전 상세 페이지
  EVENT_DETAIL_PAGE = 10,
  // 장바구니 페이지
  CART_PAGE = 11,
  // 주문서 작성 페이지
  PAYMENT_CHECKOUT_PAGE = 12,
  // 주문완료 페이지
  PAYMENT_COMPLETE_PAGE = 13,
  // 주문내역 목록 페이지
  ORDER_LIST_PAGE = 14,
  // 주문 상세 내역 페이지
  ORDER_DETAIL_PAGE = 15,
  // QnA 목록 페이지
  QNA_LIST_PAGE = 16,
  // 후기 목록 페이지
  REVIEW_LIST_PAGE = 17,
  // 친구코드(레퍼럴) 공유 페이지 - 레퍼럴 상세
  REFERRAL_DETAIL_PAGE = 18,
  // 태그 페이지
  TAG_PAGE = 19,
  // 내가 팔로잉한 태그 페이지
  TAG_FOLLOWING_PAGE = 20,
  // 추천 태그 페이지
  RECOMMEND_TAG_PAGE = 21,
  // 태그 상세 페이지 (갤러리)
  TAG_DETAIL_GALLERY_PAGE = 22,
  // 태그 상세 페이지 (피드)
  TAG_DETAIL_FEED_PAGE = 23,
  // 검색전 페이지
  BEFORE_SEARCH_PAGE = 24,
  // 상품 구매후기 > 상품후기 쓰기
  PRODUCT_PURCHASE_REVIEW_WRITING = 25,
  // 상품 구매후기 > 작성 내역
  PRODUCT_PURCHASE_REVIEW_LIST = 26,
  // 상품 구매후기 > 좋아요한 후기
  PRODUCT_PURCHASE_REVIEW_LIKE_LIST = 27,
  // 후기 상세 페이지
  REVIEW_DETAIL_PAGE = 28,
  // 검색중 페이지
  SEARCHING_PAGE = 29,
  // 통합검색 결과 페이지
  TOTAL_SEARCH_RESULT_PAGE = 30,
  // 상품 상세 > 이 상품의 후기 목록
  PRODUCT_DETAIL_REVIEW_LIST = 31,
  // 상품검색 결과 페이지
  PRODUCT_SEARCH_RESULT_PAGE = 32,
  // 태그검색 결과 페이지
  TAG_SEARCH_RESULT_PAGE = 33,
  // 브랜드검색 결과 페이지
  BRAND_SEARCH_RESULT_PAGE = 34,
  // 상품후기검색 결과 페이지
  REVIEW_SEARCH_RESULT_PAGE = 35,
  // 기획전검색 결과 페이지
  EVENT_SEARCH_RESULT_PAGE = 36,
  // 마이 > 쿠폰 > 적용 가능 상품 페이지
  MY_COUPON_APPLICABLE_PRODUCT_PAGE = 37,
  // 마이 > 쿠폰 > 적용 가능 브랜드 페이지
  MY_COUPON_APPLICABLE_BRAND_PAGE = 38,
  // 홈 서브 페이지
  HOME_SUB_PAGE = 50,
}

export enum CHIP_COLOR {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  GRAY = 'gray'
}

