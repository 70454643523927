import React, { FC } from "react";

import { TwoButtonModalType } from "@store/modules/base";
import { CANCEL_TEXT, CONFIRM_TEXT } from "@constants/service/common/text";
import ModalCover from "@components/base/modal/ModalCover";
import { TwoModalLayoutStyle } from "@components/base/layout/modal/TwoButtonModalLayout/style";
import Button from "@components/common/Button/Button";
import { BUTTON_COLOR, BUTTON_SIZE } from "@constants/enum/baseEnums";

interface ITwoButtonModalLayoutProps extends TwoButtonModalType {
  onCloseBtn: () => void;
}

const TwoButtonModal: FC<ITwoButtonModalLayoutProps> = (props: ITwoButtonModalLayoutProps) => {
  const {
    visible,
    isCloseBtn,
    onCloseBtn,
    msg,
    subMsg,
    leftButtonText = CANCEL_TEXT,
    onClickLeftBtn,
    rightButtonText = CONFIRM_TEXT,
    onClickRightBtn,
  } = props;

  return (
    <ModalCover visible={visible} isCloseBtn={isCloseBtn} onCloseBtn={onCloseBtn}>
      <TwoModalLayoutStyle>
        <div className={`modal-body ${subMsg ? "is-sub-msg" : ""}`}>
          <div className="msg">{msg}</div>
          {subMsg && <div className="sub-msg">{subMsg}</div>}
        </div>

        <div className="modal-footer">
          <Button color={BUTTON_COLOR.WHITE} size={BUTTON_SIZE.LARGE} onClick={onClickLeftBtn} text={leftButtonText} />

          <Button
            color={BUTTON_COLOR.BLACK}
            size={BUTTON_SIZE.LARGE}
            onClick={onClickRightBtn}
            text={rightButtonText}
          />
        </div>
      </TwoModalLayoutStyle>
    </ModalCover>
  );
};

export default TwoButtonModal;
