/* tslint:disable */
/* eslint-disable */
/**
 * AMONDZ-USER-BACKEND-V2
 * AMONDZ-USER-BACKEND-V2 API 문서입니다.
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { LikeProductMainAttachmentDto } from './like-product-main-attachment-dto';
// May contain unused imports in some cases
// @ts-ignore
import { ProductCategoryDto } from './product-category-dto';
// May contain unused imports in some cases
// @ts-ignore
import { ProductMainCategoryDto } from './product-main-category-dto';
// May contain unused imports in some cases
// @ts-ignore
import { StoreDto } from './store-dto';

/**
 * 
 * @export
 * @interface LikeProductDto
 */
export interface LikeProductDto {
    /**
     * 상품 식별 id
     * @type {number}
     * @memberof LikeProductDto
     */
    'id': number;
    /**
     * 상품명
     * @type {string}
     * @memberof LikeProductDto
     */
    'name': string;
    /**
     * 오늘 출발 여부
     * @type {boolean}
     * @memberof LikeProductDto
     */
    'isTodayDelivery': boolean;
    /**
     * 선물하기 여부
     * @type {boolean}
     * @memberof LikeProductDto
     */
    'isGift': boolean;
    /**
     * 상품 판매가 (원)
     * @type {number}
     * @memberof LikeProductDto
     */
    'originPrice': number;
    /**
     * 상품 실제 판매 가격  (원)
     * @type {number}
     * @memberof LikeProductDto
     */
    'salesPrice': number;
    /**
     * 상품 할인 비율 (%) (할인중일때만 포함)
     * @type {number}
     * @memberof LikeProductDto
     */
    'discountRate'?: number;
    /**
     * 상품 할인 금액 (원) (할인중일때만 포함)
     * @type {number}
     * @memberof LikeProductDto
     */
    'discountAmount'?: number;
    /**
     * 상품 상태
     * @type {string}
     * @memberof LikeProductDto
     */
    'status': LikeProductDtoStatusEnum;
    /**
     * 
     * @type {LikeProductMainAttachmentDto}
     * @memberof LikeProductDto
     */
    'productMainAttachment': LikeProductMainAttachmentDto;
    /**
     * 
     * @type {StoreDto}
     * @memberof LikeProductDto
     */
    'store': StoreDto;
    /**
     * 
     * @type {ProductMainCategoryDto}
     * @memberof LikeProductDto
     */
    'productMainCategory': ProductMainCategoryDto;
    /**
     * 상품 카테고리 리스트
     * @type {Array<ProductCategoryDto>}
     * @memberof LikeProductDto
     */
    'productCategories': Array<ProductCategoryDto>;
}

export const LikeProductDtoStatusEnum = {
    NowOnSale: 'nowOnSale',
    SoldOut: 'soldOut'
} as const;

export type LikeProductDtoStatusEnum = typeof LikeProductDtoStatusEnum[keyof typeof LikeProductDtoStatusEnum];


