import React, { JSX } from "react";
import { BrandName, LikeButton, LikeCount, PriceInfo, ProductName } from "./atoms";
import { GiftBadge, TodayDeliveryBadge } from "../../badges";
import Row from "./Row";
import { BasicCard } from "../types";
import { ads_color } from "../../../tokens";

const ProductInfo = (props: BasicCard): JSX.Element => {
  const {
    brandName,
    productName,
    status,
    salesPrice,
    originPrice,
    discountRate,
    likeCount,
    isLike,
    onClickLike,
    isGift,
    isTodayDelivery,
  } = props;

  const hasBadgeArea = isTodayDelivery || isGift;
  return (
    <>
      {brandName ? (
        <Row style={{ maxWidth: typeof isLike !== "undefined" ? "calc(100% - 28px)" : "100%" }}>
          <BrandName value={brandName} />
        </Row>
      ) : null}
      <Row style={{ maxWidth: typeof isLike !== "undefined" ? "calc(100% - 28px)" : "100%", marginTop: "2px" }}>
        <ProductName value={productName} />
      </Row>
      {salesPrice ? (
        <Row style={{ marginTop: "4px" }}>
          <PriceInfo type={status} discountRate={discountRate} salesPrice={salesPrice} originPrice={originPrice} />
        </Row>
      ) : null}
      {hasBadgeArea ? (
        <Row style={{ gap: isTodayDelivery ? "4px" : 0, marginTop: "4px", height: "18px" }}>
          {isTodayDelivery ? (
            <TodayDeliveryBadge color={status === "soldOut" ? ads_color.gray_07 : ads_color.purple_04} />
          ) : null}
          {isGift ? <GiftBadge /> : null}
        </Row>
      ) : null}
      {likeCount ? (
        <Row style={{ marginTop: "4px" }}>
          <LikeCount count={likeCount} />
        </Row>
      ) : null}
      {typeof isLike !== "undefined" && typeof onClickLike !== "undefined" ? (
        <Row style={{ position: "absolute", top: 0, right: 0 }}>
          <LikeButton isLike={isLike} onClick={onClickLike} />
        </Row>
      ) : null}
    </>
  );
};
export default ProductInfo;
