import { ValueOf } from "@amondz/types";
import { ads_fonts } from "./font";

export type AdsTypographyKey =
  | "displayXL"
  | "displayL"
  | "displayM"
  | "displayS"
  | "headingXL"
  | "headingL"
  | "headingM"
  | "headingS"
  | "subHeadingM"
  | "subHeadingS"
  | "bodyXL"
  | "bodyL"
  | "bodyM"
  | "bodyS"
  | "detailXL"
  | "detailL"
  | "detailM"
  | "detailS";

export type AdsTypographyValue = {
  fontSize: string;
  lineHeight: string;
  letterSpacing: string;
  fontWeight: "bold" | "normal";
  fontFamily: ValueOf<typeof ads_fonts>;
};

export type AdsTypography = {
  [key in AdsTypographyKey]: AdsTypographyValue;
};

export const ads_typography: { pc: AdsTypography; mo: AdsTypography } = {
  mo: {
    displayXL: {
      fontSize: "36px",
      lineHeight: "48px",
      letterSpacing: "-0.4px",
      fontWeight: "bold",
      fontFamily: `${ads_fonts.pretendard}`,
    },
    displayL: {
      fontSize: "32px",
      lineHeight: "44px",
      letterSpacing: "-0.4px",
      fontWeight: "bold",
      fontFamily: `${ads_fonts.pretendard}`,
    },
    displayM: {
      fontSize: "28px",
      lineHeight: "40px",
      letterSpacing: "-0.4px",
      fontWeight: "bold",
      fontFamily: `${ads_fonts.pretendard}`,
    },
    displayS: {
      fontSize: "24px",
      lineHeight: "36px",
      letterSpacing: "-0.4px",
      fontWeight: "bold",
      fontFamily: `${ads_fonts.pretendard}`,
    },
    headingXL: {
      fontSize: "20px",
      lineHeight: "32px",
      letterSpacing: "-0.4px",
      fontWeight: "bold",
      fontFamily: `${ads_fonts.pretendard}`,
    },
    headingL: {
      fontSize: "18px",
      lineHeight: "30px",
      letterSpacing: "-0.4px",
      fontWeight: "bold",
      fontFamily: `${ads_fonts.pretendard}`,
    },
    headingM: {
      fontSize: "16px",
      lineHeight: "28px",
      letterSpacing: "-0.4px",
      fontWeight: "bold",
      fontFamily: `${ads_fonts.pretendard}`,
    },
    headingS: {
      fontSize: "15px",
      lineHeight: "26px",
      letterSpacing: "-0.4px",
      fontWeight: "bold",
      fontFamily: `${ads_fonts.pretendard}`,
    },
    subHeadingM: {
      fontSize: "16px",
      lineHeight: "28px",
      letterSpacing: "-0.4px",
      fontWeight: "normal",
      fontFamily: `${ads_fonts.pretendard}`,
    },
    subHeadingS: {
      fontSize: "15px",
      lineHeight: "26px",
      letterSpacing: "-0.4px",
      fontWeight: "normal",
      fontFamily: `${ads_fonts.pretendard}`,
    },
    bodyXL: {
      fontSize: "14px",
      lineHeight: "24px",
      letterSpacing: "-0.4px",
      fontWeight: "bold",
      fontFamily: `${ads_fonts.pretendard}`,
    },
    bodyL: {
      fontSize: "13px",
      lineHeight: "20px",
      letterSpacing: "-0.4px",
      fontWeight: "bold",
      fontFamily: `${ads_fonts.pretendard}`,
    },
    bodyM: {
      fontSize: "12px",
      lineHeight: "18px",
      letterSpacing: "-0.4px",
      fontWeight: "bold",
      fontFamily: `${ads_fonts.pretendard}`,
    },
    bodyS: {
      fontSize: "10px",
      lineHeight: "16px",
      letterSpacing: "-0.4px",
      fontWeight: "bold",
      fontFamily: `${ads_fonts.pretendard}`,
    },
    detailXL: {
      fontSize: "14px",
      lineHeight: "24px",
      letterSpacing: "-0.4px",
      fontWeight: "normal",
      fontFamily: `${ads_fonts.pretendard}`,
    },
    detailL: {
      fontSize: "13px",
      lineHeight: "20px",
      letterSpacing: "-0.4px",
      fontWeight: "normal",
      fontFamily: `${ads_fonts.pretendard}`,
    },
    detailM: {
      fontSize: "12px",
      lineHeight: "18px",
      letterSpacing: "-0.4px",
      fontWeight: "normal",
      fontFamily: `${ads_fonts.pretendard}`,
    },
    detailS: {
      fontSize: "10px",
      lineHeight: "16px",
      letterSpacing: "-0.4px",
      fontWeight: "normal",
      fontFamily: `${ads_fonts.pretendard}`,
    },
  },
  pc: {
    displayXL: {
      fontSize: "40px",
      lineHeight: "52px",
      letterSpacing: "-0.4px",
      fontWeight: "bold",
      fontFamily: `${ads_fonts.pretendard}`,
    },
    displayL: {
      fontSize: "36px",
      lineHeight: "48px",
      letterSpacing: "-0.4px",
      fontWeight: "bold",
      fontFamily: `${ads_fonts.pretendard}`,
    },
    displayM: {
      fontSize: "32px",
      lineHeight: "44px",
      letterSpacing: "-0.4px",
      fontWeight: "bold",
      fontFamily: `${ads_fonts.pretendard}`,
    },
    displayS: {
      fontSize: "28px",
      lineHeight: "40px",
      letterSpacing: "-0.4px",
      fontWeight: "bold",
      fontFamily: `${ads_fonts.pretendard}`,
    },
    headingXL: {
      fontSize: "24px",
      lineHeight: "36px",
      letterSpacing: "-0.4px",
      fontWeight: "bold",
      fontFamily: `${ads_fonts.pretendard}`,
    },
    headingL: {
      fontSize: "20px",
      lineHeight: "32px",
      letterSpacing: "-0.4px",
      fontWeight: "bold",
      fontFamily: `${ads_fonts.pretendard}`,
    },
    headingM: {
      fontSize: "18px",
      lineHeight: "30px",
      letterSpacing: "-0.4px",
      fontWeight: "bold",
      fontFamily: `${ads_fonts.pretendard}`,
    },
    headingS: {
      fontSize: "16px",
      lineHeight: "28px",
      letterSpacing: "-0.4px",
      fontWeight: "bold",
      fontFamily: `${ads_fonts.pretendard}`,
    },
    subHeadingM: {
      fontSize: "18px",
      lineHeight: "30px",
      letterSpacing: "-0.4px",
      fontWeight: "normal",
      fontFamily: `${ads_fonts.pretendard}`,
    },
    subHeadingS: {
      fontSize: "16px",
      lineHeight: "28px",
      letterSpacing: "-0.4px",
      fontWeight: "normal",
      fontFamily: `${ads_fonts.pretendard}`,
    },
    bodyXL: {
      fontSize: "15px",
      lineHeight: "26px",
      letterSpacing: "-0.4px",
      fontWeight: "bold",
      fontFamily: `${ads_fonts.pretendard}`,
    },
    bodyL: {
      fontSize: "14px",
      lineHeight: "24px",
      letterSpacing: "-0.4px",
      fontWeight: "bold",
      fontFamily: `${ads_fonts.pretendard}`,
    },
    bodyM: {
      fontSize: "13px",
      lineHeight: "20px",
      letterSpacing: "-0.4px",
      fontWeight: "bold",
      fontFamily: `${ads_fonts.pretendard}`,
    },
    bodyS: {
      fontSize: "12px",
      lineHeight: "18px",
      letterSpacing: "-0.4px",
      fontWeight: "bold",
      fontFamily: `${ads_fonts.pretendard}`,
    },
    detailXL: {
      fontSize: "15px",
      lineHeight: "26px",
      letterSpacing: "-0.4px",
      fontWeight: "normal",
      fontFamily: `${ads_fonts.pretendard}`,
    },
    detailL: {
      fontSize: "14px",
      lineHeight: "24px",
      letterSpacing: "-0.4px",
      fontWeight: "normal",
      fontFamily: `${ads_fonts.pretendard}`,
    },
    detailM: {
      fontSize: "13px",
      lineHeight: "20px",
      letterSpacing: "-0.4px",
      fontWeight: "normal",
      fontFamily: `${ads_fonts.pretendard}`,
    },
    detailS: {
      fontSize: "12px",
      lineHeight: "18px",
      letterSpacing: "-0.4px",
      fontWeight: "normal",
      fontFamily: `${ads_fonts.pretendard}`,
    },
  },
} as const;
