import * as React from "react";
import { FC } from "react";

import { NOTIFY_TOAST_TYPE } from "@constants/enum/baseEnums";
import { NotifyToastMessageStyle } from "./style";

export interface INotifyToastMessageProps {
  type: NOTIFY_TOAST_TYPE;
  msg: string;
}
const NotifyToastMessage: FC<INotifyToastMessageProps> = (props: INotifyToastMessageProps) => {
  const { type, msg } = props;

  return (
    <NotifyToastMessageStyle className={`${type}`}>
      <div className="msg">{msg}</div>
    </NotifyToastMessageStyle>
  );
};

export default NotifyToastMessage;
