/**
 * 사업자 등록번호를 지정된 형식으로 검증하고 포맷합니다.
 *
 * @param {string} [bizNumber] 변환할 사업자 등록번호
 * @returns {string} "-"이 포함된 사업자 등록번호
 */
export const formatBusinessLicenseNumber = (bizNumber?: string): string => {
  // 사업자 등록번호 패턴
  const REX_BUSINESS_LICENSE = /^([0-9]{3})-?([0-9]{2})-?([0-9]{5})$/;

  if (!bizNumber || !REX_BUSINESS_LICENSE.test(bizNumber)) {
    return "-";
  }

  // 이미 형식에 맞게 입력된 경우 그대로 반환
  if (bizNumber.includes("-")) {
    return bizNumber;
  }

  // 하이픈 없는 경우 새로운 포맷 적용
  return bizNumber.replace(REX_BUSINESS_LICENSE, "$1-$2-$3");
};
