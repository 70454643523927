import { ProductCouponList } from "@amondz/types";

export const mock_fixedDate_coupon = {
  id: 1,
  createDate: "2023-09-01T20:34:47.000Z",
  coupon: {
    id: 1,
    name: "금 골드바 1/2돈 할인",
    description: "10만 원 이상 제품 구매 시 1만 원 할인",
    periodType: "fixedDate",
    date: {
      startDate: "2023-09-01T20:34:47.000Z",
      endDate: "2023-09-10T20:34:47.000Z",
    },
    discountType: "amount",
    discountAmount: 10000,
    availableCount: 3,
    canDownload: true,
  },
};

export const mock_generatedDate_coupon = {
  id: 2,
  createDate: "2023-09-01T20:34:47.000Z",
  coupon: {
    id: 2,
    name: "VIP 50% 할인",
    description: "VIP 50% 할인 설명",
    periodType: "generatedDate",
    dayPlus: 14,
    discountType: "rate",
    discountAmount: 50,
    maxDiscountAmount: 30000,
    availableCount: 0,
    canDownload: true,
  },
};

export const mock_infinityDate_coupon = {
  id: 3,
  createDate: "2023-09-01T20:34:47.000Z",
  coupon: {
    id: 3,
    name: "VIP 50% 할인",
    description: "VIP 50% 할인 설명",
    periodType: "infinity",
    discountType: "rate",
    discountAmount: 50,
    maxDiscountAmount: 30000,
    availableCount: 1,
    canDownload: false,
  }
};

export const mock_coupons: ProductCouponList = [
  {
    appliedDiscountAmount: 1000,
    coupon: {
      id: 1,
      name: "금 골드바 1/2돈 할인",
      description: "10만 원 이상 제품 구매 시 1만 원 할인",
      periodType: "fixedDate",
      date: {
        startDate: "2023-09-01T20:34:47.000Z",
        endDate: "2023-09-10T20:34:47.000Z",
      },
      discountType: "amount",
      discountAmount: 10000,
      availableCount: 3,
      canDownload: true,
    }
  },
  {
    appliedDiscountAmount: 15000,
    coupon: {
      id: 2,
      name: "[발급일 기준] VIP 50% 할인",
      description: "VIP 50% 할인 설명",
      periodType: "generatedDate",
      dayPlus: 14,
      discountType: "rate",
      discountAmount: 50,
      maxDiscountAmount: 30000,
      availableCount: 0,
      canDownload: true,
      latestAvailableCouponGenerated: {
        id: 111,
        startDate: "2023-11-13T20:34:47.000Z",
        endDate: "2023-11-13T20:34:47.000Z",
      }
    }
  },
  {
    appliedDiscountAmount: 16000,
    coupon:  {
      id: 3,
      name: "VIP 50% 할인",
      description: "VIP 50% 할인 설명",
      periodType: "infinity",
      discountType: "rate",
      discountAmount: 50,
      maxDiscountAmount: 30000,
      availableCount: 1,
      canDownload: false,
    },
  }
];
