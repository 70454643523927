import React from "react";
import { BrandName, PriceInfo, ProductName } from "./atoms";
import { TodayDeliveryBadge } from "../../badges";
import Row from "./Row";
import { WideCard } from "../types";
import { ads_color } from "../../../tokens";

const WideProductInfo = (props: WideCard) => {
  const { brandName, productName, status, salesPrice, originPrice, discountRate, priceSubText, isTodayDelivery } =
    props;
  return (
    <>
      <Row>
        <BrandName value={brandName} />
      </Row>
      <Row style={{ marginTop: "2px" }}>
        <ProductName value={productName} />
      </Row>
      {!!salesPrice ? (
        <Row style={{ marginTop: "2px" }}>
          <PriceInfo
            size="large"
            type={status}
            discountRate={discountRate}
            salesPrice={salesPrice}
            originPrice={originPrice}
            subText={priceSubText}
          />
        </Row>
      ) : null}

      <Row style={{ marginTop: "2px", height: "14px" }}>
        {isTodayDelivery ? (
          <TodayDeliveryBadge color={status === "soldOut" ? ads_color.gray_07 : ads_color.purple_04} />
        ) : null}
      </Row>
    </>
  );
};
export default WideProductInfo;
