import * as React from "react";
import { FC, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import TwoButtonModalLayout from "@components/base/layout/modal/TwoButtonModalLayout";
import { RootStateType } from "@store/modules";
import { BaseModalStateType, twoButtonModalToggle } from "@store/modules/base";

const TwoButtonModal: FC = () => {
  const { twoButtonModal } = useSelector<RootStateType, BaseModalStateType>(
    (state: RootStateType) => state.base.baseModalState,
  );
  const { onCloseBtn, onClickLeftBtn, onClickRightBtn, ...props } = twoButtonModal;
  const dispatch = useDispatch();

  // 모달 닫기
  const toggleModal = useCallback(
    () =>
      dispatch(
        twoButtonModalToggle({
          visible: false,
        }),
      ),
    [],
  );

  return (
    <TwoButtonModalLayout
      {...props}
      onCloseBtn={() => {
        onCloseBtn && onCloseBtn();
        toggleModal();
      }}
      onClickLeftBtn={() => {
        onClickLeftBtn && onClickLeftBtn();
        toggleModal();
      }}
      onClickRightBtn={() => {
        onClickRightBtn && onClickRightBtn();
        toggleModal();
      }}
    />
  );
};

export default TwoButtonModal;
