import styled from "@styles/themedComponents";

export const TwoModalLayoutStyle = styled.div`
  width: 320px;

  .modal-body {
    margin: 44px 32px 24px;
    letter-spacing: -0.5px;
    text-align: center;

    .msg {
      padding-bottom: 8px;
      line-height: 24px;
      font-size: 16px;
    }

    &.is-sub-msg {
      .msg {
        font-weight: 500;
      }

      .sub-msg {
        margin-top: 4px;
        font-size: 13px;
        line-height: 19px;
        color: ${(props) => props.theme.color.gray_01};
      }
    }
  }

  .modal-footer {
    margin: 0 24px 24px;

    button {
      width: 130px;
      height: 40px;
      cursor: pointer;

      &:first-child {
        margin-right: 12px;
        border-color: ${(props) => props.theme.color.amondz_black};
      }
    }
  }
`;
