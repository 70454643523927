/* tslint:disable */
/* eslint-disable */
/**
 * AMONDZ-USER-BACKEND-V2
 * AMONDZ-USER-BACKEND-V2 API 문서입니다.
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface ProductAttachmentResponseDto
 */
export interface ProductAttachmentResponseDto {
    /**
     * 상품 첨부파일 식별 id
     * @type {number}
     * @memberof ProductAttachmentResponseDto
     */
    'id': number;
    /**
     * 상품 첨부파일 너비
     * @type {number}
     * @memberof ProductAttachmentResponseDto
     */
    'width': number;
    /**
     * 상품 첨부파일 높이
     * @type {number}
     * @memberof ProductAttachmentResponseDto
     */
    'height': number;
    /**
     * 상품 첨부파일 url
     * @type {string}
     * @memberof ProductAttachmentResponseDto
     */
    'url': string;
    /**
     * 상품 첨부파일 타입 ( mainImg : 메인 이미지 , subImg : 추가 이미지 , detailImg : 상세 이미지 , video : 영상 )
     * @type {string}
     * @memberof ProductAttachmentResponseDto
     */
    'type': ProductAttachmentResponseDtoTypeEnum;
}

export const ProductAttachmentResponseDtoTypeEnum = {
    MainImg: 'mainImg',
    SubImg: 'subImg'
} as const;

export type ProductAttachmentResponseDtoTypeEnum = typeof ProductAttachmentResponseDtoTypeEnum[keyof typeof ProductAttachmentResponseDtoTypeEnum];


