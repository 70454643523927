import * as utils from "./utils";

export { default as ClickGtmEventLogging } from "./ClickGtmEventLogging";
export * from "./useGtmEventLogging";
export * from "./GtmContext";
export * from "./types";

const gtm = {
  ...utils,
};
export default gtm;
