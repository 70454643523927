import GraphemeSplitter from "grapheme-splitter";

export const splitString = (s: string) => {
  const splitter = new GraphemeSplitter();
  return splitter.splitGraphemes(s);
};

export const sliceString = (s: string, start: number, end?: number) => {
  return splitString(s).slice(start, end).join("");
};

export const countString = (s: string): number => {
  return splitString(s).length;
};

export const filteringOrderNameForPg = function (str: string) {
  const filteredString = str.replace(/[&'%]/gi, "");
  if (filteredString.length > 0) {
    return filteredString;
  }

  return "amondz";
};

export const countStringWithoutNewlines = (s: string): number => {
  return countString(s?.replace(/\n/g, ""));
};

export const valueOrDash = (value: string | undefined): string => value || "-";

export const matchPath = (template: string, path: string): boolean => {
  const regex = new RegExp("^" + template.replace(/\[\w+\]/g, "\\w+") + "$");
  return regex.test(path);
};
