import { superFetch } from "../superFetch";
import { ReviewIdType, ReviewMutationResponseType } from "./type";

export const reviewAPI = {
  // 상품 후기 좋아요 요청 API
  likeReview: async (data: ReviewIdType): Promise<ReviewMutationResponseType> =>
    superFetch.post("/like/review/click/web/v1", data),
  deleteReview: async (data: ReviewIdType): Promise<ReviewMutationResponseType> =>
    superFetch.post("/mypage/review/delete/web/v1", data),
};
