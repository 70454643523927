import {
  IAMPORT_PAY_METHOD_WITH_STRING_KEY_TYPE,
  PAY_MEHTOD_NUMBER_TYPE,
  PAY_MEHTOD_STRING_TYPE,
  PAY_METHOD_TEXT_WITH_NUMBER_KEY_TYPE,
  PAY_METHOD_TEXT_WITH_STRING_KEY_TYPE,
} from "@amondz/types";

export * from "./text";

// 결제 방법 리스트
export const PAY_METHOD: PAY_MEHTOD_NUMBER_TYPE = {
  CARD: 0, // 신용카드
  TRANS: 1, // 실시간 계좌이체
  VBANK: 2, // 가상계좌
  PHONE: 3, // 휴대폰 소액결제
  POINT: 4, // 포인트 (카카오페이머니)
  KAKAO: 5, // 카카오 페이
  NAVER: 6, // 네이버 페이
  PAYCO: 7, // 페이코
  TOSS: 8, // 토스페이
} as const;

// 결제 방법 리스트
export const PAY_METHOD_STRING: PAY_MEHTOD_STRING_TYPE = {
  CARD: "card", // 신용카드
  TRANS: "trans", // 실시간 계좌이체
  VBANK: "vbank", // 가상계좌
  PHONE: "phone", // 휴대폰 소액결제
  POINT: "point", // 포인트 (카카오페이머니)
  KAKAO: "kakaopay", // 카카오 페이
  NAVER: "naverpay", // 네이버 페이
  PAYCO: "payco", // 페이코
  TOSS: "tosspay", // 토스페이
} as const;

/** 결제 방법 선택 텍스트
 * 신용카드 결제 방법 텍스트 같은 경우 default 값을 신용카드/간편결제로 함
 * 일부 카드만 결제 가능할 경우 컴포넌트에서 해당 텍스트를 신용카드로 바꿔서 사용
 */
export const PAY_METHOD_TEXT: PAY_METHOD_TEXT_WITH_NUMBER_KEY_TYPE = {
  [PAY_METHOD.CARD]: "신용/체크카드",
  [PAY_METHOD.TRANS]: "실시간 계좌이체",
  [PAY_METHOD.VBANK]: "가상계좌",
  [PAY_METHOD.PHONE]: "휴대폰 소액결제",
  [PAY_METHOD.POINT]: "포인트 머니(카카오 & 네이버)",
  [PAY_METHOD.KAKAO]: "카카오페이",
  [PAY_METHOD.NAVER]: "네이버페이",
  [PAY_METHOD.PAYCO]: "페이코",
  [PAY_METHOD.TOSS]: "토스페이",
};
export const PAY_METHOD_TEXT_WITH_STRING_KEY: PAY_METHOD_TEXT_WITH_STRING_KEY_TYPE = {
  [PAY_METHOD_STRING.CARD]: "신용/체크카드",
  [PAY_METHOD_STRING.TRANS]: "실시간 계좌이체",
  [PAY_METHOD_STRING.VBANK]: "가상계좌",
  [PAY_METHOD_STRING.PHONE]: "휴대폰 소액결제",
  [PAY_METHOD_STRING.POINT]: "포인트 머니(카카오 & 네이버)",
  [PAY_METHOD_STRING.KAKAO]: "카카오페이",
  [PAY_METHOD_STRING.NAVER]: "네이버페이",
  [PAY_METHOD_STRING.PAYCO]: "페이코",
  [PAY_METHOD_STRING.TOSS]: "토스페이",
};

// 결제 방법 타입의 아임포트 맵핑
export const IAMPORT_PAY_METHOD: IAMPORT_PAY_METHOD_WITH_STRING_KEY_TYPE = {
  [PAY_METHOD_STRING.CARD]: "card", // 신용카드
  [PAY_METHOD_STRING.VBANK]: "vbank", // 가상계좌
  [PAY_METHOD_STRING.TRANS]: "trans", // 실시간 계좌이체
  [PAY_METHOD_STRING.POINT]: "point", // 카카오/네이버페이
  [PAY_METHOD_STRING.KAKAO]: "kakaopay", // 카카오페이
  [PAY_METHOD_STRING.NAVER]: "naverpay", // 네이버페이
  [PAY_METHOD_STRING.PAYCO]: "payco", // 페이코
  [PAY_METHOD_STRING.TOSS]: "tosspay", // 토스페이
};

//카드사,계좌이체,가상계좌의 경우 부수적인 정보가 있어서 서버에서 내려주는 payInform 텍스트 노출
export const SHOW_INFORM_PAY_METHOD_LIST = [PAY_METHOD.CARD, PAY_METHOD.TRANS, PAY_METHOD.VBANK];
