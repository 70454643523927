import * as React from "react";
import { useEffect } from "react";

import { NOTIFY_TOAST_TYPE } from "@constants/enum/baseEnums";
import NotifyToastMessage from "../NotifyToastMessage";
import { NotifyToastStyle } from "./style";

interface INotifyToastProps {
  type: NOTIFY_TOAST_TYPE;
  msg: string;
  visible: boolean;
  onClose: () => void;
}
const NotifyToast = (props: INotifyToastProps): JSX.Element => {
  const { type, msg, visible, onClose } = props;

  useEffect(() => {
    if (visible) {
      const setTime = setTimeout(() => {
        onClose();
      }, 3000);

      return () => clearTimeout(setTime);
    }
  }, [visible]);

  return (
    <NotifyToastStyle>
      <NotifyToastMessage type={type} msg={msg} />
    </NotifyToastStyle>
  );
};

export default NotifyToast;
