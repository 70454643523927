import * as React from "react";
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootStateType } from "@store/modules";
import { CoreStateType, notifyToastToggle } from "@store/modules/base";
import NotifyToastMessage from "../NotifyToastMessage";
import { NotifyToastStyle } from "./style";

const NotifyToast: FC = () => {
  const { notifyToast } = useSelector<RootStateType, CoreStateType>((state: RootStateType) => state.base.coreState);
  const { type, msg, visible } = notifyToast;
  const [autoHideTimeout, setAutoHideTimeout] = useState<any>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (notifyToast.visible) {
      if (autoHideTimeout) {
        clearTimeout(autoHideTimeout);
        setAutoHideTimeout(null);
      }
      setAutoHideTimeout(
        setTimeout(() => {
          dispatch(
            notifyToastToggle({
              visible: false,
            }),
          );
          setAutoHideTimeout(null);
        }, 3000),
      );
    }
  }, [visible]);

  if (!type || !visible || !msg) {
    return null;
  }

  return (
    <NotifyToastStyle>
      <NotifyToastMessage type={type} msg={msg} />
    </NotifyToastStyle>
  );
};

export default NotifyToast;
