import { put, select, takeEvery, throttle } from "redux-saga/effects";
import Router from "next/router";
import { sumBy } from "lodash";
import axios from "axios";
import marketingService, { eventType } from "@amondz/marketing-service";

import productAPI from "@services/apis/productAPI";
import mypageAPI from "@services/apis/mypageAPI";
import baseAPI from "@services/apis/baseAPI";
import { ApiHelperResponseType } from "@store/modules/common/types";
import { RootStateType } from "@store/modules";
import { AuthStateType } from "@store/modules/auth";
import { STATUS_CODE_COMMON } from "@constants/status/code/statusCodeCommon";
import asyncApiHelper from "@lib/utility/apiHelper";
import gtm, { setProductWishList } from "@lib/utility/gtm";
import { PAYMENT_CART_URL_PATH } from "@constants/url/internalUrlConstants";
import {
  ADD_CART_SUCCESS_INFO_MODAL_CONTENTS,
  INVALID_ORDER_RETURN_EXCHANGE_DELIVERY_INFO_MODAL_CONTENTS,
} from "@constants/service/product/modal";
import { LIKE_EVENT_THROTTLE_SPEED, LIKE_STATUS } from "@constants/service/common/common";
import { oneButtonModalToggle, twoButtonModalToggle } from "../base";
import { updateCartCount } from "../auth";
import {
  GET_PRODUCT_CATEGORY_TREE,
  getProductCategoryAsync,
  GET_PRODUCT_LIST,
  getProductListAsync,
  GET_BRAND_FILTER_TREE,
  getBrandFilterTreeAsync,
  GET_PRODUCT_DETAIL,
  getProductDetailAsync,
  LIKE_PRODUCT,
  likeProductAsync,
  LIKE_STORE,
  likeStoreAsync,
  LIKE_PRODUCT_REVIEW,
  likeProductReviewAsync,
  INSERT_PRODUCT_CART,
  insertProductCart,
  INSERT_PRODUCT_OPTION_CART,
  insertProductOptionCart,
  INSERT_PRODUCT_QNA,
  insertProductQna,
  GET_PRODUCT_QNA_LIST,
  getProductQnaList,
  UPDATE_PRODUCT_QNA,
  updateProductQna,
  DELETE_PRODUCT_QNA,
  deleteProductQna,
  SHARE_PRODUCT_DETAIL_LINK,
  shareProductDetailLink,
  getAsInfo,
  GET_AS_INFO,
  GET_PRODUCT_REVIEW_DETAIL,
  getProductReviewDetailAsync,
  getProductReviewListAsync,
  GET_PRODUCT_REVIEW_LIST,
  INSERT_PRODUCT_REVIEW,
  insertProductReviewAsync,
} from "./actions";

/**
 * 상품 카테고리 트리 요청 SAGA
 */
function* getProductCategoryTreeSaga() {
  try {
    // 서버 요청 헬퍼
    const result: ApiHelperResponseType = yield asyncApiHelper(productAPI.getProductCategoryTree);

    yield put(
      getProductCategoryAsync.success({
        productCategoryTree: result.data.categoryTree,
      }),
    );
  } catch (e) {
    if (axios.isAxiosError(e)) {
      yield put(getProductCategoryAsync.failure(e));
    } else {
      console.error(e);
    }
  }
}

/**
 * 상품 리스트 요청 SAGA
 */
function* getProductListSaga(action: ReturnType<typeof getProductListAsync.request>) {
  try {
    // 서버 요청 헬퍼
    const result: ApiHelperResponseType = yield asyncApiHelper(productAPI.getProductList, action.payload);

    yield put(
      getProductListAsync.success({
        allCount: result.data.allCount,
        productList: result.data.productList || [],
        productCategoryTree: result.data.categoryTree,
        status: result.status,
      }),
    );
  } catch (e) {
    if (axios.isAxiosError(e)) {
      yield put(getProductListAsync.failure(e));
    } else {
      console.error(e);
    }
  }
}

/**
 * 브랜드 필터 트리 요청 SAGA
 */
function* getBrandFilterTreeSaga() {
  try {
    // 서버 요청 헬퍼
    const result: ApiHelperResponseType = yield asyncApiHelper(productAPI.getBrandFilterTree);

    yield put(
      getBrandFilterTreeAsync.success({
        brandTree: result.data.brandTree,
      }),
    );
  } catch (e) {
    if (axios.isAxiosError(e)) {
      yield put(getBrandFilterTreeAsync.failure(e));
    } else {
      console.error(e);
    }
  }
}

/**
 * 상품 상세 데이터 불러오기 SAGA
 */
function* getProductDetailSaga(action: ReturnType<typeof getProductDetailAsync.request>) {
  try {
    // 서버 요청 헬퍼
    const result: ApiHelperResponseType = yield asyncApiHelper(productAPI.getProductDetail, action.payload);

    yield put(
      getProductDetailAsync.success({
        productDetail: result.data,
        status: result.status,
      }),
    );
  } catch (e) {
    if (axios.isAxiosError(e)) {
      yield put(getProductDetailAsync.failure(e));
    } else {
      console.error(e);
    }
  }
}

/**
 * 상품 좋아요 SAGA
 */
function* likeProductSaga(action: ReturnType<typeof likeProductAsync.request>) {
  try {
    // 서버 요청 헬퍼
    const result: ApiHelperResponseType = yield asyncApiHelper(baseAPI.likeProduct, action.payload);

    const { product } = action.payload;
    const { userAuthState }: AuthStateType = yield select((state: RootStateType) => state.auth);
    result.data.code === LIKE_STATUS.LIKE
      ? marketingService(eventType.likeProduct, {
          userId: userAuthState.data?.userId,
          productId: product.productId,
          productName: product.productName,
        })
      : marketingService(eventType.unLikeProduct, {
          userId: userAuthState.data?.userId,
          productId: product.productId,
          productName: product.productName,
        });
    setProductWishList(result.data.code, product);

    yield put(
      likeProductAsync.success({
        productId: action.payload.productId,
        targetIndex: action.payload.targetIndex,
        code: result.data.code,
      }),
    );
  } catch (e) {
    if (axios.isAxiosError(e)) {
      yield put(likeProductAsync.failure(e));
    } else {
      console.error(e);
    }
  }
}

/**
 * 브랜드 좋아요 SAGA
 */
function* likeStoreSaga(action: ReturnType<typeof likeStoreAsync.request>) {
  try {
    // 서버 요청 헬퍼
    const result: ApiHelperResponseType = yield asyncApiHelper(baseAPI.likeStore, action.payload);

    const { userAuthState }: AuthStateType = yield select((state: RootStateType) => state.auth);
    result.data.code === LIKE_STATUS.LIKE
      ? marketingService(eventType.likeBrand, {
          userId: userAuthState.data?.userId,
          brandId: action.payload.storeId,
          brandName: action.payload.storeName,
        })
      : marketingService(eventType.unLikeBrand, {
          userId: userAuthState.data?.userId,
          brandId: action.payload.storeId,
          brandName: action.payload.storeName,
        });
    if (result.data.code === LIKE_STATUS.LIKE) {
      // braze - 브랜드 좋아요 시
      gtm.addToBrandWishlist({
        id: action.payload.storeId,
        name: action.payload.storeName,
      });
    }

    yield put(
      likeStoreAsync.success({
        code: result.data.code,
      }),
    );
  } catch (e) {
    if (axios.isAxiosError(e)) {
      yield put(likeStoreAsync.failure(e));
    } else {
      console.error(e);
    }
  }
}

/**
 * 상품 후기 좋아요 SAGA
 */
function* likeProductReviewSaga(action: ReturnType<typeof likeProductReviewAsync.request>) {
  try {
    // 서버 요청 헬퍼
    const result: ApiHelperResponseType = yield asyncApiHelper(baseAPI.likeReview, action.payload);

    yield put(
      likeProductReviewAsync.success({
        reviewId: action.payload.reviewId,
        targetIndex: action.payload.targetIndex,
        code: result.data.code,
      }),
    );
  } catch (e) {
    if (axios.isAxiosError(e)) {
      yield put(likeProductReviewAsync.failure(e));
    } else {
      console.error(e);
    }
  }
}

/**
 * 상품 장바구니 SAGA
 */
function* insertProductCartSaga(action: ReturnType<typeof insertProductCart.request>) {
  try {
    // 서버 요청 헬퍼
    yield asyncApiHelper(productAPI.insertProductCart, action.payload);

    // 장바구니 개수 없데이트
    yield put(
      updateCartCount({
        addCount: action.payload.count,
      }),
    );

    // 장바구니 담기 성공 정보 모달 노출
    yield put(
      twoButtonModalToggle({
        visible: true,
        msg: ADD_CART_SUCCESS_INFO_MODAL_CONTENTS.MSG,
        subMsg: ADD_CART_SUCCESS_INFO_MODAL_CONTENTS.SUB_MSG,
        leftButtonText: ADD_CART_SUCCESS_INFO_MODAL_CONTENTS.LEFT_BTN_TEXT,
        rightButtonText: ADD_CART_SUCCESS_INFO_MODAL_CONTENTS.RIGHT_BTN_TEXT,
        onClickRightBtn: () => Router.push(PAYMENT_CART_URL_PATH),
      }),
    );
  } catch (e) {
    if (axios.isAxiosError(e)) {
      yield put(insertProductCart.failure(e));
    } else {
      console.error(e);
    }
  }
}

/**
 * 상품 옵션 장바구니 SAGA
 */
function* insertProductOptionCartSaga(action: ReturnType<typeof insertProductOptionCart.request>) {
  try {
    // 서버 요청 헬퍼
    yield asyncApiHelper(productAPI.insertProductOptionCart, action.payload);

    // 장바구니 개수 없데이트
    yield put(
      updateCartCount({
        addCount: sumBy(action.payload.productOptionList, "count"),
      }),
    );

    // 장바구니 담기 성공 정보 모달 노출
    yield put(
      twoButtonModalToggle({
        visible: true,
        msg: ADD_CART_SUCCESS_INFO_MODAL_CONTENTS.MSG,
        subMsg: ADD_CART_SUCCESS_INFO_MODAL_CONTENTS.SUB_MSG,
        leftButtonText: ADD_CART_SUCCESS_INFO_MODAL_CONTENTS.LEFT_BTN_TEXT,
        rightButtonText: ADD_CART_SUCCESS_INFO_MODAL_CONTENTS.RIGHT_BTN_TEXT,
        onClickRightBtn: () => Router.push(PAYMENT_CART_URL_PATH),
      }),
    );
  } catch (e) {
    if (axios.isAxiosError(e)) {
      yield put(insertProductOptionCart.failure(e));
    } else {
      console.error(e);
    }
  }
}

/**
 * 상품 후기 리스트 요청 SAGA
 */
function* getProductReviewListSaga(action: ReturnType<typeof getProductReviewListAsync.request>) {
  try {
    // 서버 요청 헬퍼
    const result: ApiHelperResponseType = yield asyncApiHelper(productAPI.getProductReviewList, action.payload);

    yield put(getProductReviewListAsync.success(result.data));
  } catch (e) {
    if (axios.isAxiosError(e)) {
      yield put(getProductReviewListAsync.failure(e));
    } else {
      console.error(e);
    }
  }
}

/**
 * 상품 구매 후기 상세 SAGA
 */
function* getProductReviewDetailSaga(action: ReturnType<typeof getProductReviewDetailAsync.request>) {
  try {
    // 서버 요청 헬퍼
    const result: ApiHelperResponseType = yield asyncApiHelper(productAPI.getProductReviewDetail, action.payload);

    yield put(getProductReviewDetailAsync.success(result));
  } catch (e) {
    if (axios.isAxiosError(e)) {
      yield put(getProductReviewDetailAsync.failure(e));
    } else {
      console.error(e);
    }
  }
}

/**
 * 상품 구매 후기 작성 SAGA
 */
function* insertProductReviewSaga(action: ReturnType<typeof insertProductReviewAsync.request>) {
  try {
    // 서버 요청 헬퍼
    const { status, data } = yield asyncApiHelper(productAPI.insertProductReview, action.payload);

    yield put(
      insertProductReviewAsync.success({
        status,
        reviewId: data.id,
      }),
    );
  } catch (e) {
    if (axios.isAxiosError(e)) {
      yield put(insertProductReviewAsync.failure(e));
    } else {
      console.error(e);
    }
  }
}

/**
 * 상품 문의 리스트 요청 SAGA
 */
function* getProductQnaListSaga(action: ReturnType<typeof getProductQnaList.request>) {
  try {
    // 서버 요청 헬퍼
    const result: ApiHelperResponseType = yield asyncApiHelper(productAPI.getProductQnaList, action.payload);

    yield put(getProductQnaList.success(result.data));
  } catch (e) {
    if (axios.isAxiosError(e)) {
      yield put(getProductQnaList.failure(e));
    } else {
      console.error(e);
    }
  }
}

/**
 * 상품 문의 작성 요청 SAGA
 */
function* insertProductQnaSaga(action: ReturnType<typeof insertProductQna.request>) {
  try {
    // 서버 요청 헬퍼
    const result: ApiHelperResponseType = yield asyncApiHelper(productAPI.insertProductQna, action.payload);

    yield put(
      insertProductQna.success({
        status: result.status,
        data: {
          id: result.data.id,
          account: result.data.account,
          question: action.payload.question,
          date: action.payload.date,
        },
      }),
    );
  } catch (e) {
    if (axios.isAxiosError(e)) {
      yield put(insertProductQna.failure(e));
    } else {
      console.error(e);
    }
  }
}

/**
 * 상품 문의 수정 요청 SAGA
 */
function* updateProductQnaSaga(action: ReturnType<typeof updateProductQna.request>) {
  try {
    // 서버 요청 헬퍼
    const result: ApiHelperResponseType = yield asyncApiHelper(mypageAPI.updateProductQna, action.payload);

    yield put(updateProductQna.success({ ...action.payload, ...result }));
  } catch (e) {
    if (axios.isAxiosError(e)) {
      yield put(updateProductQna.failure(e));
    } else {
      console.error(e);
    }
  }
}

/**
 * 상품 문의 삭제 요청 SAGA
 */
function* deleteProductQnaSaga(action: ReturnType<typeof deleteProductQna.request>) {
  try {
    // 서버 요청 헬퍼
    const result: ApiHelperResponseType = yield asyncApiHelper(mypageAPI.deleteProductQna, action.payload);

    yield put(deleteProductQna.success({ ...action.payload, ...result }));
  } catch (e) {
    if (axios.isAxiosError(e)) {
      yield put(deleteProductQna.failure(e));
    } else {
      console.error(e);
    }
  }
}

/**
 * 상품 상세 링크 공유 SAGA
 */
function* shareProductDetailLinkSaga(action: ReturnType<typeof shareProductDetailLink.request>) {
  try {
    // 서버 요청 헬퍼
    yield asyncApiHelper(productAPI.shareProductDetailLink, action.payload);
  } catch (e) {
    console.log(e);
  }
}

/**
 * A/S 정보 요청 요청 SAGA
 */
function* getAsInfoSaga(action: ReturnType<typeof getAsInfo.request>) {
  try {
    // 서버 요청 헬퍼
    const result: ApiHelperResponseType = yield asyncApiHelper(productAPI.getAsInfo, action.payload);

    if (result.status === STATUS_CODE_COMMON.SUCCESS) {
      yield put(getAsInfo.success(result.data));
    } else {
      yield put(
        oneButtonModalToggle({
          visible: true,
          isCloseBtn: false,
          msg: INVALID_ORDER_RETURN_EXCHANGE_DELIVERY_INFO_MODAL_CONTENTS.MSG,
        }),
      );
    }
  } catch (e) {
    console.log(e);
  }
}

export function* productSaga() {
  yield takeEvery(GET_PRODUCT_CATEGORY_TREE.REQUEST, getProductCategoryTreeSaga);
  yield takeEvery(GET_PRODUCT_LIST.REQUEST, getProductListSaga);
  yield takeEvery(GET_BRAND_FILTER_TREE.REQUEST, getBrandFilterTreeSaga);
  yield takeEvery(GET_PRODUCT_DETAIL.REQUEST, getProductDetailSaga);
  yield throttle(LIKE_EVENT_THROTTLE_SPEED, LIKE_PRODUCT.REQUEST, likeProductSaga);
  yield throttle(LIKE_EVENT_THROTTLE_SPEED, LIKE_STORE.REQUEST, likeStoreSaga);
  yield takeEvery(INSERT_PRODUCT_CART.REQUEST, insertProductCartSaga);
  yield takeEvery(INSERT_PRODUCT_OPTION_CART.REQUEST, insertProductOptionCartSaga);

  // 상품 후기
  yield takeEvery(GET_PRODUCT_REVIEW_LIST.REQUEST, getProductReviewListSaga);
  yield takeEvery(GET_PRODUCT_REVIEW_DETAIL.REQUEST, getProductReviewDetailSaga);
  yield takeEvery(INSERT_PRODUCT_REVIEW.REQUEST, insertProductReviewSaga);
  yield throttle(LIKE_EVENT_THROTTLE_SPEED, LIKE_PRODUCT_REVIEW.REQUEST, likeProductReviewSaga);

  // 상품 qna
  yield takeEvery(GET_PRODUCT_QNA_LIST.REQUEST, getProductQnaListSaga);
  yield takeEvery(INSERT_PRODUCT_QNA.REQUEST, insertProductQnaSaga);
  yield takeEvery(UPDATE_PRODUCT_QNA.REQUEST, updateProductQnaSaga);
  yield takeEvery(DELETE_PRODUCT_QNA.REQUEST, deleteProductQnaSaga);

  // 상품 상세 공유
  yield takeEvery(SHARE_PRODUCT_DETAIL_LINK.REQUEST, shareProductDetailLinkSaga);
  yield takeEvery(GET_AS_INFO.REQUEST, getAsInfoSaga);
}
