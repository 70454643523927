import * as rc from "firebase/remote-config";

import { useContext } from "react";
import { FirebaseContext } from "./FirebaseProvider";

export const useFirebaseRemoteConfig = () => {
  const context = useContext(FirebaseContext);

  if (context === null) {
    throw new Error("useRemoteConfig is only available within FirebaseProvider.");
  }

  const getAll = () => {
    if (context.remoteConfig === null) return;
    return rc.getAll(context.remoteConfig);
  };

  const getValue = (key: string) => {
    if (context.remoteConfig === null) return "";
    return rc.getValue(context.remoteConfig, key);
  };

  const getJson = (key: string) => {
    if (context.remoteConfig === null) return {};
    /**
     * JSON 구조가 아닌 값을 사용할 경우 에러 발생 주의
     */
    try {
      if (!(rc.getValue(context.remoteConfig, key) as any)["_value"]) return {};
      return JSON.parse((rc.getValue(context.remoteConfig, key) as any)["_value"]);
    } catch (e) {
      console.error(e);
      return {};
    }
  };

  const getString = (key: string) => {
    if (context.remoteConfig === null) return "";
    return rc.getString(context.remoteConfig, key);
  };

  const getNumber = (key: string) => {
    if (context.remoteConfig === null) return 0;
    return rc.getNumber(context.remoteConfig, key);
  };

  const getBoolean = (key: string) => {
    if (context.remoteConfig === null) return false;
    return rc.getBoolean(context.remoteConfig, key);
  };

  return {
    ...rc,
    getAll,
    getValue,
    getJson,
    getString,
    getNumber,
    getBoolean,
  };
};
