import superFetch from "@amondz/apis-v1";
import { GetHomeDetailRequestType, ShareEventDetailLinkRequestType } from "@store/modules/home";

const homeAPI = {
  // 홈 리스트 요청 API
  getHomeList: async () => {
    return superFetch.post("home/list/web/v2");
  },

  // 위젯 상세 요청 API
  getWidgetDetail: async (data: Omit<GetHomeDetailRequestType, "eventId">) => {
    return superFetch.post("home/detail/pagination/web/v2", data);
  },

  // 기획전 상세 링크 공유 API
  shareEventDetailLink: async (data: ShareEventDetailLinkRequestType) => {
    return superFetch.post("share/event/web/v1", data);
  },
};

export default homeAPI;
