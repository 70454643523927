export const DELIVERY_TYPE = {
  ORDER_MADE: "orderMade",
  TODAY: "today",
  TODAY_ARRIVAL: "todayArrival",
  GENERAL: "general",
  DIRECT: "direct",
  DIRECT_GENERAL: "directGeneral",
} as const;

export type DeliveryAreas = {
  //배송 대상 main 지역
  mainArea: string;
  //배송 대상 sub 지역
  subAreas: string[];
};

export type TodayDeliveryInfoType = {
  type: typeof DELIVERY_TYPE.TODAY;
  deliveryTimeLimit: string;
};

export type TodayArrivalInfoType = {
  type: typeof DELIVERY_TYPE.TODAY_ARRIVAL;
  deliveryTimeLimit: string;
  deliveryAreas: DeliveryAreas[];
};

export type OrderMadeInfoType = {
  type: typeof DELIVERY_TYPE.ORDER_MADE;
  deliveryPeriod: number;
  transitPeriod: number;
  madePeriod: number;
};

export type DirectInfoType = {
  type: typeof DELIVERY_TYPE.DIRECT;
  deliveryTimeLimit: string;
};

//바로배송상품이 기준시간을 초과했을 경우
export type DirectGeneralInfoType = {
  type: typeof DELIVERY_TYPE.DIRECT_GENERAL;
  deliveryPeriod: string;
};

export type GeneralInfoType = {
  type: typeof DELIVERY_TYPE.GENERAL;
  deliveryPeriod: number;
};

export type NormalDeliveryInfoType = GeneralInfoType | DirectInfoType | OrderMadeInfoType | DirectGeneralInfoType;

export type DeliveryInfoListType =
  | [TodayDeliveryInfoType, TodayArrivalInfoType] //모든 옵션이 오늘출발일 경우
  | [TodayDeliveryInfoType, TodayArrivalInfoType, NormalDeliveryInfoType] //일부 옵션만 오늘출발일 경우
  | [NormalDeliveryInfoType]; // 오늘출발이 아닌 상품

export type Address = {
  type: "brand" | "origin" | "return";
  mainAddress: string;
  detailAddress: string;
  postCode: string;
};
