import { GTM_EVENT, GTMCommerceEventProperty } from "../../../types";

export interface IViewItemEventProperty extends Omit<GTMCommerceEventProperty, "event"> {
  event: GTM_EVENT.VIEW_ITEM;
}

export interface IPurchaseEventProperty extends Omit<GTMCommerceEventProperty, "event"> {
  event: GTM_EVENT.PURCHASE;
}

export interface IAddToCartEventProperty extends Omit<GTMCommerceEventProperty, "event"> {
  event: GTM_EVENT.ADD_TO_CART;
}
