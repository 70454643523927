import { STATUS_CODE_COMMON } from "../code/statusCodeCommon";

export const STATUS_COMMON_MESSAGE = {
  // exception 이 발생한 경우
  [STATUS_CODE_COMMON.SERVER_ERROR]: "* 일시적인 오류가 발생했습니다. 다시 시도해주세요.",

  // 매개변수가 제대로 전달되지 않은 경우
  [STATUS_CODE_COMMON.INCOMPLETE_PARAM]: "잘못된 접근입니다.",

  // 회원 정보가 유효하지 않을 경우
  [STATUS_CODE_COMMON.INVALID_MEMBER.CODE_200]: "회원정보가 유효하지 않습니다. 다시 로그인해주세요.",
};
