import { AxiosError } from "axios";
import { createAction, createAsyncAction } from "typesafe-actions";

import { BrandCouponStateType, CouponItemStateType, CouponListStateType, ProductCouponStateType } from "@amondz/types";
import { InsertProductQnaRequestType, InsertProductQnaSuccessType } from "@store/modules/product";
import { ApiHelperResponseType } from "@store/modules/common/types";
import {
  BankType,
  MemberManageStateType,
  OrderCancelStateType,
  OrderDeliveryTrackingStateType,
  OrderDetailStateType,
  OrderReturnExchangeCommonType,
  OrderItemShippingStateType,
  OrderListStateType,
  OrderReturnStateType,
  PointListStateType,
  QnaProductListStateType,
  ResultOrderCancelStateType,
  ResultOrderExchangeStateType,
  ResultOrderReturnStateType,
  ShippingDelayReasonStateType,
  SubmitOrderCancelStateType,
  SubmitOrderExchangeStateType,
  SubmitOrderReturnStateType,
  OrderShippingType,
  RefundBankType,
} from "./types";
import { LikeProductCommonTypeForBraze } from "../base";

type GetRequestCommonType = {
  startIndex: number;
  exceptAllCount: boolean;
};

// 마이페이지 메인화면 요청
export const GET_MY_PAGE_MAIN = {
  REQUEST: "mypage/GET_MY_PAGE_MAIN_REQUEST",
  SUCCESS: "mypage/GET_MY_PAGE_MAIN_SUCCESS",
  FAILURE: "mypage/GET_MY_PAGE_MAIN_FAILURE",
};
export const getMyPageMainAsync = createAsyncAction(
  GET_MY_PAGE_MAIN.REQUEST,
  GET_MY_PAGE_MAIN.SUCCESS,
  GET_MY_PAGE_MAIN.FAILURE,
)<undefined, ApiHelperResponseType, AxiosError>();

/**
 *
 * 쿠폰
 *
 */
// 사용 가능한 쿠폰 리스트 요청
export const GET_COUPON_LIST = {
  REQUEST: "mypage/GET_COUPON_LIST_REQUEST",
  SUCCESS: "mypage/GET_COUPON_LIST_SUCCESS",
  FAILURE: "mypage/GET_COUPON_LIST_FAILURE",
};
export const getCouponListAsync = createAsyncAction(
  GET_COUPON_LIST.REQUEST,
  GET_COUPON_LIST.SUCCESS,
  GET_COUPON_LIST.FAILURE,
)<undefined, CouponListStateType, AxiosError>();
// 사용 가능한 쿠폰 리스트 요청 완료

// 쿠폰 발급 요청
export const GENERATE_COUPON = {
  REQUEST: "mypage/GENERATE_COUPON_REQUEST",
  SUCCESS: "mypage/GENERATE_COUPON_SUCCESS",
  FAILURE: "mypage/GENERATE_COUPON_FAILURE",
};
export type GenerateCouponRequestType = {
  code: string;
};
export type GenerateCouponSuccessType = {
  status: number;
  msg: string;
  data: CouponItemStateType;
};
export const generateCouponAsync = createAsyncAction(
  GENERATE_COUPON.REQUEST,
  GENERATE_COUPON.SUCCESS,
  GENERATE_COUPON.FAILURE,
)<GenerateCouponRequestType, ApiHelperResponseType, AxiosError>();
// 쿠폰 발급 요청 완료

// 쿠폰 적용가능 상품 요청
export const GET_PRODUCT_COUPON = {
  REQUEST: "mypage/GET_PRODUCT_COUPON_REQUEST",
  SUCCESS: "mypage/GET_PRODUCT_COUPON_SUCCESS",
  FAILURE: "mypage/GET_PRODUCT_COUPON_FAILURE",
};
export type GetProductCouponRequestType = GetRequestCommonType & {
  couponId: number;
};
export type GetProductCouponSuccessType = {
  status: number;
  data: ProductCouponStateType;
};
export const getProductCouponAsync = createAsyncAction(
  GET_PRODUCT_COUPON.REQUEST,
  GET_PRODUCT_COUPON.SUCCESS,
  GET_PRODUCT_COUPON.FAILURE,
)<GetProductCouponRequestType, GetProductCouponSuccessType, AxiosError>();
// 쿠폰 적용가능 상품 요청 완료

// 쿠폰 적용가능 브랜드 요청
export const GET_BRAND_COUPON = {
  REQUEST: "mypage/GET_BRAND_COUPON_REQUEST",
  SUCCESS: "mypage/GET_BRAND_COUPON_SUCCESS",
  FAILURE: "mypage/GET_BRAND_COUPON_FAILURE",
};
export type GetBrandCouponRequestType = GetRequestCommonType & {
  couponId: number;
};
export type GetBrandCouponSuccessType = {
  status: number;
  data: BrandCouponStateType;
};
export const getBrandCouponAsync = createAsyncAction(
  GET_BRAND_COUPON.REQUEST,
  GET_BRAND_COUPON.SUCCESS,
  GET_BRAND_COUPON.FAILURE,
)<GetBrandCouponRequestType, GetBrandCouponSuccessType, AxiosError>();
// 쿠폰 적용가능 브랜드 요청 완료

/**
 *
 * 포인트
 *
 */
// 포인트 내역 요청
export const GET_POINT_LIST = {
  REQUEST: "mypage/GET_POINT_LIST_REQUEST",
  SUCCESS: "mypage/GET_POINT_LIST_SUCCESS",
  FAILURE: "mypage/GET_POINT_LIST_FAILURE",
};
export type GetPointListRequestType = GetRequestCommonType;
export const getPointListAsync = createAsyncAction(
  GET_POINT_LIST.REQUEST,
  GET_POINT_LIST.SUCCESS,
  GET_POINT_LIST.FAILURE,
)<GetPointListRequestType, PointListStateType, AxiosError>();
// 포인트 내역 요청 완료

// 상품 좋아요 요청
export const SET_LIKE_PRODUCT_COUPON = {
  REQUEST: "mypage/SET_LIKE_PRODUCT_COUPON_REQUEST",
  SUCCESS: "mypage/SET_LIKE_PRODUCT_COUPON_SUCCESS",
  FAILURE: "mypage/SET_LIKE_PRODUCT_COUPON_FAILURE",
};
export const setLikeProductCouponAsync = createAsyncAction(
  SET_LIKE_PRODUCT_COUPON.REQUEST,
  SET_LIKE_PRODUCT_COUPON.SUCCESS,
  SET_LIKE_PRODUCT_COUPON.FAILURE,
)<LikeProductCommonTypeForBraze, ApiHelperResponseType, AxiosError>();

/**
 * 페이지네이션 요청 타입
 * startIndex : 더보기 시작 index
 * exceptAllCount : 전체 개수 제외할지 flag
 */
export type PaginationRequestType = GetRequestCommonType;
// 마이페이지 좋아요한 상품 요청
export const GET_LIKE_PRODUCT = {
  REQUEST: "mypage/GET_LIKE_PRODUCT_REQUEST",
  SUCCESS: "mypage/GET_LIKE_PRODUCT_SUCCESS",
  FAILURE: "mypage/GET_LIKE_PRODUCT_FAILURE",
};
export const getLikeProductAsync = createAsyncAction(
  GET_LIKE_PRODUCT.REQUEST,
  GET_LIKE_PRODUCT.SUCCESS,
  GET_LIKE_PRODUCT.FAILURE,
)<PaginationRequestType, ApiHelperResponseType, AxiosError>();
// 마이페이지 좋아요한 상품 제거
export const REMOVE_LIKE_PRODUCT = "mypage/REMOVE_LIKE_PRODUCT";
export const removeLikeProduct = createAction(REMOVE_LIKE_PRODUCT)();

/**
 *
 * 주문 정보
 *
 */
// 주문 정보 내역 요청
export const GET_ORDER_LIST = {
  REQUEST: "mypage/GET_ORDER_LIST_REQUEST",
  SUCCESS: "mypage/GET_ORDER_LIST_SUCCESS",
  FAILURE: "mypage/GET_ORDER_LIST_FAILURE",
};
export const getOrderListAsync = createAsyncAction(
  GET_ORDER_LIST.REQUEST,
  GET_ORDER_LIST.SUCCESS,
  GET_ORDER_LIST.FAILURE,
)<undefined, OrderListStateType[], AxiosError>();
// 주문 정보 내역 요청 완료
// 주문 상세 내역 요청
export const GET_ORDER_DETAIL = {
  REQUEST: "mypage/GET_ORDER_DETAIL_REQUEST",
  SUCCESS: "mypage/GET_ORDER_DETAIL_SUCCESS",
  FAILURE: "mypage/GET_ORDER_DETAIL_FAILURE",
};
export type GetOrderDetailRequestType = {
  orderId: number;
};
type GetOrderDetailSuccessType = {
  status: number;
  data: OrderDetailStateType;
};
export const getOrderDetailAsync = createAsyncAction(
  GET_ORDER_DETAIL.REQUEST,
  GET_ORDER_DETAIL.SUCCESS,
  GET_ORDER_DETAIL.FAILURE,
)<GetOrderDetailRequestType, GetOrderDetailSuccessType, AxiosError>();
// 주문 상세 내역 요청 완료
// 주문 취소 내역 요청
export const GET_ORDER_CANCEL = {
  REQUEST: "mypage/GET_ORDER_CANCEL_REQUEST",
  SUCCESS: "mypage/GET_ORDER_CANCEL_SUCCESS",
  FAILURE: "mypage/GET_ORDER_CANCEL_FAILURE",
};
export type GetOrderCancelRequestType = Pick<OrderExchangeRequestType, "orderItemId">;
type GetOrderCancelSuccessType = {
  status: number;
  data: OrderCancelStateType;
};
export const getOrderCancelAsync = createAsyncAction(
  GET_ORDER_CANCEL.REQUEST,
  GET_ORDER_CANCEL.SUCCESS,
  GET_ORDER_CANCEL.FAILURE,
)<GetOrderCancelRequestType, GetOrderCancelSuccessType, AxiosError>();
// 주문 취소 내역 요청 완료

// 주문 반품 내역 요청
export const GET_ORDER_RETURN = {
  REQUEST: "mypage/GET_ORDER_RETURN_REQUEST",
  SUCCESS: "mypage/GET_ORDER_RETURN_SUCCESS",
  FAILURE: "mypage/GET_ORDER_RETURN_FAILURE",
};
export type GetOrderReturnRequestType = Pick<OrderReturnRequestType, "orderItemId">;

type GetOrderReturnSuccessType = {
  status: number;
  data: OrderReturnStateType;
};
export const getOrderReturnAsync = createAsyncAction(
  GET_ORDER_RETURN.REQUEST,
  GET_ORDER_RETURN.SUCCESS,
  GET_ORDER_RETURN.FAILURE,
)<GetOrderReturnRequestType, GetOrderReturnSuccessType, AxiosError>();
// 주문 반품 내역 요청 완료

// 주문 교환 내역 요청
export const GET_ORDER_EXCHANGE = {
  REQUEST: "mypage/GET_ORDER_EXCHANGE_REQUEST",
  SUCCESS: "mypage/GET_ORDER_EXCHANGE_SUCCESS",
  FAILURE: "mypage/GET_ORDER_EXCHANGE_FAILURE",
};
type GetOrderExchangeRequestType = Pick<OrderExchangeRequestType, "orderItemId">;

type GetOrderExchangeSuccessType = {
  status: number;
  data: OrderReturnExchangeCommonType;
};
export const getOrderExchangeAsync = createAsyncAction(
  GET_ORDER_EXCHANGE.REQUEST,
  GET_ORDER_EXCHANGE.SUCCESS,
  GET_ORDER_EXCHANGE.FAILURE,
)<GetOrderExchangeRequestType, GetOrderExchangeSuccessType, AxiosError>();
// 주문 교환 내역 요청 완료

// 주문 상품 배송지 내역 요청
export const GET_ORDER_ITEM_SHIPPING_INFO = {
  REQUEST: "mypage/GET_ORDER_ITEM_SHIPPING_INFO_REQUEST",
  SUCCESS: "mypage/GET_ORDER_ITEM_SHIPPING_INFO_SUCCESS",
  FAILURE: "mypage/GET_ORDER_ITEM_SHIPPING_INFO_FAILURE",
};
export type GetOrderItemShippingInfoRequestType = Pick<OrderExchangeRequestType, "orderItemId">;
export const getOrderItemShippingInfoAsync = createAsyncAction(
  GET_ORDER_ITEM_SHIPPING_INFO.REQUEST,
  GET_ORDER_ITEM_SHIPPING_INFO.SUCCESS,
  GET_ORDER_ITEM_SHIPPING_INFO.FAILURE,
)<GetOrderItemShippingInfoRequestType, OrderItemShippingStateType, AxiosError>();
// 주문 상품 배송지 내역 요청 완료
// 주문 상품 배송지 변경 요청
export const UPDATE_ORDER_ITEM_SHIPPING_INFO = {
  REQUEST: "mypage/UPDATE_ORDER_ITEM_SHIPPING_INFO_REQUEST",
  SUCCESS: "mypage/UPDATE_ORDER_ITEM_SHIPPING_INFO_SUCCESS",
  FAILURE: "mypage/UPDATE_ORDER_ITEM_SHIPPING_INFO_FAILURE",
};
export type UpdateOrderItemShippingInfoRequestType = OrderShippingType & Pick<OrderExchangeRequestType, "orderItemId">;
export const updateOrderItemShippingInfoAsync = createAsyncAction(
  UPDATE_ORDER_ITEM_SHIPPING_INFO.REQUEST,
  UPDATE_ORDER_ITEM_SHIPPING_INFO.SUCCESS,
  UPDATE_ORDER_ITEM_SHIPPING_INFO.FAILURE,
)<UpdateOrderItemShippingInfoRequestType, number, AxiosError>();
// 주문 상품 배송지 변경 요청 완료
// 모달 언마운트
export const UNMOUNT_ORDER_MODAL = "mypage/UNMOUNT_ORDER_MODAL";
export const unmountOrderModal = createAction(UNMOUNT_ORDER_MODAL)<string>();
// 모달 언마운트 완료
// 배송 추적 내역 요청
export const GET_ORDER_DELIVERY_TRACKING = {
  REQUEST: "mypage/GET_ORDER_DELIVERY_TRACKING_REQUEST",
  SUCCESS: "mypage/GET_ORDER_DELIVERY_TRACKING_SUCCESS",
  FAILURE: "mypage/GET_ORDER_DELIVERY_TRACKING_FAILURE",
};
export type GetOrderDeliveryTrackingRequestType = Pick<OrderExchangeRequestType, "orderItemId"> & {
  deliveryId: number | null;
};
type GetOrderDeliveryTrackingSuccessType = {
  status: number;
  data: OrderDeliveryTrackingStateType;
};
export const getOrderDeliveryTrackingAsync = createAsyncAction(
  GET_ORDER_DELIVERY_TRACKING.REQUEST,
  GET_ORDER_DELIVERY_TRACKING.SUCCESS,
  GET_ORDER_DELIVERY_TRACKING.FAILURE,
)<GetOrderDeliveryTrackingRequestType, GetOrderDeliveryTrackingSuccessType, AxiosError>();
// 배송 추적 내역 요청 완료
// 배송 지연 사유 요청
export const SHIPPING_DELAY_REASON = {
  REQUEST: "mypage/SHIPPING_DELAY_REASON_REQUEST",
  SUCCESS: "mypage/SHIPPING_DELAY_REASON_SUCCESS",
  FAILURE: "mypage/SHIPPING_DELAY_REASON_FAILURE",
};
export type ShippingDelayReasonRequestType = Pick<OrderExchangeRequestType, "orderItemId"> & {
  delayId: number;
};
type ShippingDelayReasonSuccessType = {
  status: number;
  data: ShippingDelayReasonStateType;
};
export const shippingDelayReasonAsync = createAsyncAction(
  SHIPPING_DELAY_REASON.REQUEST,
  SHIPPING_DELAY_REASON.SUCCESS,
  SHIPPING_DELAY_REASON.FAILURE,
)<ShippingDelayReasonRequestType, ShippingDelayReasonSuccessType, AxiosError>();
// 배송 지연 사유 요청 완료

// 주문 취소 & 반품 & 교환 요청 타입
type SubmitOrderRequestType = Pick<OrderExchangeRequestType, "orderItemId"> & {
  type: number;
  reason: string;
};

// 후기 작성 - 주문 내역
export const AFTER_SUBMIT_ORDER_LIST_REVIEW = "mypage/AFTER_SUBMIT_ORDER_LIST_REVIEW";
export type AfterSubmitOrderListReviewType = {
  orderId: number;
  orderItemId: number;
  reviewId: number;
};
export const afterSubmitOrderListReview =
  createAction(AFTER_SUBMIT_ORDER_LIST_REVIEW)<AfterSubmitOrderListReviewType>();

// 후기 작성 - 주문 상세
export const AFTER_SUBMIT_ORDER_DETAIL_REVIEW = "mypage/AFTER_SUBMIT_ORDER_DETAIL_REVIEW";
export type AfterSubmitOrderDetailReviewType = {
  orderItemId: number;
  reviewId: number;
};
export const afterSubmitOrderDetailReview = createAction(
  AFTER_SUBMIT_ORDER_DETAIL_REVIEW,
)<AfterSubmitOrderDetailReviewType>();

/**
 *
 * 주문 취소
 *
 */
// 주문 취소 요청
export const SUBMIT_ORDER_CANCEL = {
  REQUEST: "mypage/SUBMIT_ORDER_CANCEL_REQUEST",
  SUCCESS: "mypage/SUBMIT_ORDER_CANCEL_SUCCESS",
  FAILURE: "mypage/SUBMIT_ORDER_CANCEL_FAILURE",
};
export type SubmitOrderCancelRequestType = SubmitOrderRequestType & {
  bankId?: number;
  bankCode?: string;
  bankName?: string;
  bankNumber?: string;
  bankHolder?: string;
};
type SubmitOrderCancelSuccessType = {
  status: number;
  data: SubmitOrderCancelStateType;
};
export const submitOrderCancelAsync = createAsyncAction(
  SUBMIT_ORDER_CANCEL.REQUEST,
  SUBMIT_ORDER_CANCEL.SUCCESS,
  SUBMIT_ORDER_CANCEL.FAILURE,
)<SubmitOrderCancelRequestType, SubmitOrderCancelSuccessType, AxiosError>();
// 주문 취소 요청 완료

// 주문 취소 내역 요청
export const RESULT_ORDER_CANCEL = {
  REQUEST: "mypage/RESULT_ORDER_CANCEL_REQUEST",
  SUCCESS: "mypage/RESULT_ORDER_CANCEL_SUCCESS",
  FAILURE: "mypage/RESULT_ORDER_CANCEL_FAILURE",
};
export type ResultOrderCancelRequestType = Pick<OrderExchangeRequestType, "orderItemId"> & {
  cancelId: number;
};
type ResultOrderCancelSuccessType = {
  status: number;
  data: ResultOrderCancelStateType;
};
export const resultOrderCancelAsync = createAsyncAction(
  RESULT_ORDER_CANCEL.REQUEST,
  RESULT_ORDER_CANCEL.SUCCESS,
  RESULT_ORDER_CANCEL.FAILURE,
)<ResultOrderCancelRequestType, ResultOrderCancelSuccessType, AxiosError>();
// 주문 취소 내역 요청 완료

// 주문 취소 철회 요청
export const WITHDRAW_ORDER_CANCEL = {
  REQUEST: "mypage/WITHDRAW_ORDER_CANCEL_REQUEST",
  SUCCESS: "mypage/WITHDRAW_ORDER_CANCEL_SUCCESS",
  FAILURE: "mypage/WITHDRAW_ORDER_CANCEL_FAILURE",
};
export type WithdrawOrderCancelRequestType = Pick<OrderExchangeRequestType, "orderItemId"> & {
  cancelId: number;
};
type WithdrawOrderSuccessType = {
  status: number;
  data: Pick<OrderExchangeRequestType, "orderItemId"> & {
    orderItemStatus: number;
  };
};
export const withdrawOrderCancelAsync = createAsyncAction(
  WITHDRAW_ORDER_CANCEL.REQUEST,
  WITHDRAW_ORDER_CANCEL.SUCCESS,
  WITHDRAW_ORDER_CANCEL.FAILURE,
)<WithdrawOrderCancelRequestType, WithdrawOrderSuccessType, AxiosError>();
// 주문 취소 철회 요청 완료

/**
 *
 * 주문 반품
 *
 */
// 주문 반품에 공통적으로 사용하는 요청 타입

export type OrderReturnRequestType = Pick<OrderExchangeRequestType, "orderItemId"> & {
  returnId: number;
};
// 주문 반품 요청
export const SUBMIT_ORDER_RETURN = {
  REQUEST: "mypage/SUBMIT_ORDER_RETURN_REQUEST",
  SUCCESS: "mypage/SUBMIT_ORDER_RETURN_SUCCESS",
  FAILURE: "mypage/SUBMIT_ORDER_RETURN_FAILURE",
};
type SubmitOrderReturnSuccessType = {
  status: number;
  data: SubmitOrderReturnStateType;
};
export const submitOrderReturnAsync = createAsyncAction(
  SUBMIT_ORDER_RETURN.REQUEST,
  SUBMIT_ORDER_RETURN.SUCCESS,
  SUBMIT_ORDER_RETURN.FAILURE,
)<FormData, SubmitOrderReturnSuccessType, AxiosError>();
// 주문 반품 요청 완료
// 주문 반품 내역 요청
export const RESULT_ORDER_RETURN = {
  REQUEST: "mypage/RESULT_ORDER_RETURN_REQUEST",
  SUCCESS: "mypage/RESULT_ORDER_RETURN_SUCCESS",
  FAILURE: "mypage/RESULT_ORDER_RETURN_FAILURE",
};
type ResultOrderReturnSuccessType = {
  status: number;
  data: ResultOrderReturnStateType;
};
export const resultOrderReturnAsync = createAsyncAction(
  RESULT_ORDER_RETURN.REQUEST,
  RESULT_ORDER_RETURN.SUCCESS,
  RESULT_ORDER_RETURN.FAILURE,
)<OrderReturnRequestType, ResultOrderReturnSuccessType, AxiosError>();
// 주문 반품 내역 요청 완료
// 주문 반품 철회 요청
export const WITHDRAW_ORDER_RETURN = {
  REQUEST: "mypage/WITHDRAW_ORDER_RETURN_REQUEST",
  SUCCESS: "mypage/WITHDRAW_ORDER_RETURN_SUCCESS",
  FAILURE: "mypage/WITHDRAW_ORDER_RETURN_FAILURE",
};
export const withdrawOrderReturnAsync = createAsyncAction(
  WITHDRAW_ORDER_RETURN.REQUEST,
  WITHDRAW_ORDER_RETURN.SUCCESS,
  WITHDRAW_ORDER_RETURN.FAILURE,
)<OrderReturnRequestType, WithdrawOrderSuccessType, AxiosError>();
// 주문 반품 철회 요청 완료

// 가상계좌 환불 정보 조회 요청
export const SELECT_ORDER_RETURN_VBANK = {
  REQUEST: "mypage/SELECT_ORDER_RETURN_VBANK_REQUEST",
  SUCCESS: "mypage/SELECT_ORDER_RETURN_VBANK_SUCCESS",
  FAILURE: "mypage/SELECT_ORDER_RETURN_VBANK_FAILURE",
};
type SelectOrderReturnVBankSuccessType = {
  status: number;
  data: {
    bankId: number;
    bankNumber: string;
    bankHolder: string;
    bankList: BankType[];
  };
};
export const selectOrderReturnVbankAsync = createAsyncAction(
  SELECT_ORDER_RETURN_VBANK.REQUEST,
  SELECT_ORDER_RETURN_VBANK.SUCCESS,
  SELECT_ORDER_RETURN_VBANK.FAILURE,
)<OrderReturnRequestType, SelectOrderReturnVBankSuccessType, AxiosError>();
// 가상계좌 환불 정보 조회 완료

// 가상계좌 환불 정보 수정 요청
export const UPDATE_ORDER_RETURN_VBANK = {
  REQUEST: "mypage/UPDATE_ORDER_RETURN_VBANK_REQUEST",
  SUCCESS: "mypage/UPDATE_ORDER_RETURN_VBANK_SUCCESS",
  FAILURE: "mypage/UPDATE_ORDER_RETURN_VBANK_FAILURE",
};
export type UpdateOrderReturnVbankRequestType = OrderReturnRequestType & RefundBankType & { bankName?: string };
export type UpdateOrderReturnVbankResponseType = ApiHelperResponseType & { payInformList: string[] };

export const updateOrderReturnVbankAsync = createAsyncAction(
  UPDATE_ORDER_RETURN_VBANK.REQUEST,
  UPDATE_ORDER_RETURN_VBANK.SUCCESS,
  UPDATE_ORDER_RETURN_VBANK.FAILURE,
)<UpdateOrderReturnVbankRequestType, UpdateOrderReturnVbankResponseType, ApiHelperResponseType>();

export const RESET_UPDATE_ORDER_RETURN_VBANK_STATUS = "mypage/RESET_UPDATE_ORDER_RETURN_VBANK_STATUS";
export const resetUpdateOrderReturnVbankStatus = createAction(RESET_UPDATE_ORDER_RETURN_VBANK_STATUS)();

// 구매 확정 요청
export const CONFIRM_PURCHASE = {
  REQUEST: "mypage/CONFIRM_PURCHASE_REQUEST",
  SUCCESS: "mypage/CONFIRM_PURCHASE_SUCCESS",
  FAILURE: "mypage/CONFIRM_PURCHASE_FAILURE",
};
export type ConfirmPurchaseRequestType = Pick<OrderExchangeRequestType, "orderItemId">;

export const confirmPurchaseAsync = createAsyncAction(
  CONFIRM_PURCHASE.REQUEST,
  CONFIRM_PURCHASE.SUCCESS,
  CONFIRM_PURCHASE.FAILURE,
)<ConfirmPurchaseRequestType, number, AxiosError>();
// 구매 확정 요청 완료

/**
 *
 * 주문 교환
 *
 */
// 주문 반품에 공통적으로 사용하는 요청 타입
export type OrderExchangeRequestType = {
  orderItemId: number;
  exchangeId: number;
};

// 주문 교환 요청
export const SUBMIT_ORDER_EXCHANGE = {
  REQUEST: "mypage/SUBMIT_ORDER_EXCHANGE_REQUEST",
  SUCCESS: "mypage/SUBMIT_ORDER_EXCHANGE_SUCCESS",
  FAILURE: "mypage/SUBMIT_ORDER_EXCHANGE_FAILURE",
};
type SubmitOrderExchangeSuccessType = {
  status: number;
  data: SubmitOrderExchangeStateType;
};
export const submitOrderExchangeAsync = createAsyncAction(
  SUBMIT_ORDER_EXCHANGE.REQUEST,
  SUBMIT_ORDER_EXCHANGE.SUCCESS,
  SUBMIT_ORDER_EXCHANGE.FAILURE,
)<FormData, SubmitOrderExchangeSuccessType, AxiosError>();
// 주문 교환 요청 완료
// 주문 교환 내역 요청
export const RESULT_ORDER_EXCHANGE = {
  REQUEST: "mypage/RESULT_ORDER_EXCHANGE_REQUEST",
  SUCCESS: "mypage/RESULT_ORDER_EXCHANGE_SUCCESS",
  FAILURE: "mypage/RESULT_ORDER_EXCHANGE_FAILURE",
};
type ResultOrderExchangeSuccessType = {
  status: number;
  data: ResultOrderExchangeStateType;
};
export const resultOrderExchangeAsync = createAsyncAction(
  RESULT_ORDER_EXCHANGE.REQUEST,
  RESULT_ORDER_EXCHANGE.SUCCESS,
  RESULT_ORDER_EXCHANGE.FAILURE,
)<OrderExchangeRequestType, ResultOrderExchangeSuccessType, AxiosError>();
// 주문 교환 내역 요청 완료
// 주문 반품 철회 요청
export const WITHDRAW_ORDER_EXCHANGE = {
  REQUEST: "mypage/WITHDRAW_ORDER_EXCHANGE_REQUEST",
  SUCCESS: "mypage/WITHDRAW_ORDER_EXCHANGE_SUCCESS",
  FAILURE: "mypage/WITHDRAW_ORDER_EXCHANGE_FAILURE",
};
export const withdrawOrderExchangeAsync = createAsyncAction(
  WITHDRAW_ORDER_EXCHANGE.REQUEST,
  WITHDRAW_ORDER_EXCHANGE.SUCCESS,
  WITHDRAW_ORDER_EXCHANGE.FAILURE,
)<OrderExchangeRequestType, WithdrawOrderSuccessType, AxiosError>();
// 주문 반품 철회 요청 완료

/**
 *
 * 상품 문의내역
 *
 */
// 상품 문의 내역 요청
export const GET_QNA_PRODUCT_LIST = {
  REQUEST: "mypage/GET_QNA_PRODUCT_LIST_REQUEST",
  SUCCESS: "mypage/GET_QNA_PRODUCT_LIST_SUCCESS",
  FAILURE: "mypage/GET_QNA_PRODUCT_LIST_FAILURE",
};
export type GetQnaProductListRequestType = GetRequestCommonType;
export const getQnaProductListAsync = createAsyncAction(
  GET_QNA_PRODUCT_LIST.REQUEST,
  GET_QNA_PRODUCT_LIST.SUCCESS,
  GET_QNA_PRODUCT_LIST.FAILURE,
)<GetQnaProductListRequestType, QnaProductListStateType, AxiosError>();
// 상품 문의 내역 요청 완료

// ****** 상품 문의 작성 액션 설정 ******
export const INSERT_PRODUCT_QNA = {
  REQUEST: "mypage/INSERT_PRODUCT_QNA_REQUEST",
  SUCCESS: "mypage/INSERT_PRODUCT_QNA_SUCCESS",
  FAILURE: "mypage/INSERT_PRODUCT_QNA_FAILURE",
};

export const insertProductQna = createAsyncAction(
  INSERT_PRODUCT_QNA.REQUEST,
  INSERT_PRODUCT_QNA.SUCCESS,
  INSERT_PRODUCT_QNA.FAILURE,
)<
  Omit<InsertProductQnaRequestType, "orderItemId"> & { orderItemId: number },
  Pick<InsertProductQnaSuccessType, "status">,
  AxiosError
>();
// ****** 상품 문의 작성 액션 설정 완료 ******

// 상품 문의 수정 요청
export const UPDATE_QNA_PRODUCT = {
  REQUEST: "mypage/UPDATE_QNA_PRODUCT_REQUEST",
  SUCCESS: "mypage/UPDATE_QNA_PRODUCT_SUCCESS",
  FAILURE: "mypage/UPDATE_QNA_PRODUCT_FAILURE",
};
export type UpdateQnaProductType = {
  qnaId: number;
  question: string;
  status?: number;
};
export const updateQnaProductAsync = createAsyncAction(
  UPDATE_QNA_PRODUCT.REQUEST,
  UPDATE_QNA_PRODUCT.SUCCESS,
  UPDATE_QNA_PRODUCT.FAILURE,
)<UpdateQnaProductType, UpdateQnaProductType, AxiosError>();
// 상품 문의 수정 요청 완료

// 상품 문의 삭제 요청
export const DELETE_QNA_PRODUCT = {
  REQUEST: "mypage/DELETE_QNA_PRODUCT_REQUEST",
  SUCCESS: "mypage/DELETE_QNA_PRODUCT_SUCCESS",
  FAILURE: "mypage/DELETE_QNA_PRODUCT_FAILURE",
};
export type DeleteQnaProductType = {
  qnaId: number;
  status?: number;
};
export const deleteQnaProductAsync = createAsyncAction(
  DELETE_QNA_PRODUCT.REQUEST,
  DELETE_QNA_PRODUCT.SUCCESS,
  DELETE_QNA_PRODUCT.FAILURE,
)<DeleteQnaProductType, DeleteQnaProductType, AxiosError>();
// 상품 문의 삭제 요청 완료

/**
 *
 * 상품 구매후기
 *
 */
export type ReviewIdType = {
  // 상품 후기 식별 id
  reviewId: number;
  // 위젯 식별 id
  widgetId?: number;
};

/**
 *
 * 회원 정보 수정
 *
 */
// ****** 회원 정보 수정 상태 초기화 액션 설정 ******
export const INIT_MEMBER_MANAGE = "payment/INIT_MEMBER_MANAGE";
export const initMemberManage = createAction(INIT_MEMBER_MANAGE)();
// ****** 회원 정보 수정 상태 초기화 액션 설정 ******

// 이메일 비밀번호 재확인 요청
export const CHECK_EMAIL_MEMBER = {
  REQUEST: "mypage/CHECK_EMAIL_MEMBER_REQUEST",
  SUCCESS: "mypage/CHECK_EMAIL_MEMBER_SUCCESS",
  FAILURE: "mypage/CHECK_EMAIL_MEMBER_FAILURE",
};
export type CheckEmailMemberRequestType = {
  account: string;
  password: string;
};
export const checkEmailMemberAsync = createAsyncAction(
  CHECK_EMAIL_MEMBER.REQUEST,
  CHECK_EMAIL_MEMBER.SUCCESS,
  CHECK_EMAIL_MEMBER.FAILURE,
)<CheckEmailMemberRequestType, ApiHelperResponseType, AxiosError>();
// SNS 회원 정보 불러오기 요청
export const GET_SNS_MEMBER = {
  REQUEST: "mypage/GET_SNS_MEMBER_REQUEST",
  SUCCESS: "mypage/GET_SNS_MEMBER_SUCCESS",
  FAILURE: "mypage/GET_SNS_MEMBER_FAILURE",
};
export type GetSnsMemberRequestType = {
  loginType: number;
};
export const getSnsMemberAsync = createAsyncAction(
  GET_SNS_MEMBER.REQUEST,
  GET_SNS_MEMBER.SUCCESS,
  GET_SNS_MEMBER.FAILURE,
)<GetSnsMemberRequestType, ApiHelperResponseType, AxiosError>();
// 이메일 비밀번호 수정 요청
export const UPDATE_PASSWORD_EMAIL_MEMBER = {
  REQUEST: "mypage/UPDATE_PASSWORD_EMAIL_MEMBER_REQUEST",
  SUCCESS: "mypage/UPDATE_PASSWORD_EMAIL_MEMBER_SUCCESS",
  FAILURE: "mypage/UPDATE_PASSWORD_EMAIL_MEMBER_FAILURE",
};
export type UpdatePasswordEmailMemberRequestType = {
  account: string;
  cellPhone: string;
  oldPassword: string;
  newPassword: string;
  checkPassword: string;
};
export type UpdatePasswordEmailMemberSuccessType = {
  status: number;
  password: string;
};
export const updatePasswordEmailMemberAsync = createAsyncAction(
  UPDATE_PASSWORD_EMAIL_MEMBER.REQUEST,
  UPDATE_PASSWORD_EMAIL_MEMBER.SUCCESS,
  UPDATE_PASSWORD_EMAIL_MEMBER.FAILURE,
)<UpdatePasswordEmailMemberRequestType, number, AxiosError>();
// 회원 정보 수정 요청
export const UPDATE_MEMBER_INFO = {
  REQUEST: "mypage/UPDATE_MEMBER_INFO_REQUEST",
  SUCCESS: "mypage/UPDATE_MEMBER_INFO_SUCCESS",
  FAILURE: "mypage/UPDATE_MEMBER_INFO_FAILURE",
};
export type UpdateMemberInfoSuccessType = {
  status: number;
  data: MemberManageStateType;
};
export const updateMemberInfoAsync = createAsyncAction(
  UPDATE_MEMBER_INFO.REQUEST,
  UPDATE_MEMBER_INFO.SUCCESS,
  UPDATE_MEMBER_INFO.FAILURE,
)<MemberManageStateType, UpdateMemberInfoSuccessType, AxiosError>();
// 회원 정보 탈퇴 요청
export const REMOVE_MEMBER_INFO = {
  REQUEST: "mypage/REMOVE_MEMBER_INFO_REQUEST",
  SUCCESS: "mypage/REMOVE_MEMBER_INFO_SUCCESS",
  FAILURE: "mypage/REMOVE_MEMBER_INFO_FAILURE",
};
export type RemoveMemberInfoSuccessType = {
  status: number;
};
export const removeMemberInfoAsync = createAsyncAction(
  REMOVE_MEMBER_INFO.REQUEST,
  REMOVE_MEMBER_INFO.SUCCESS,
  REMOVE_MEMBER_INFO.FAILURE,
)<object, number, AxiosError>();
// 회원 정보 탈퇴 모달
export const REMOVE_MEMBER_MODAL_TOGGLE = "mypage/REMOVE_MEMBER_MODAL_TOGGLE";
export const removeMemberModalToggle = createAction(REMOVE_MEMBER_MODAL_TOGGLE)();

// 회원 정보 수정 상태 리셋
export const RESET_UPDATE_MEMBER_INFO_STATUS = "mypage/RESET_UPDATE_MEMBER_INFO_STATUS";
export const resetUpdateMemberInfoStatus = createAction(RESET_UPDATE_MEMBER_INFO_STATUS)();
