import superFetch from "@amondz/apis-v1";
import { CartInfoStateType, DeleteCartListRequestType } from "@store/modules/payment";

interface IAPIResult {
  status: number;
  msg?: string;
}

const paymentAPI = {
  // 장바구니 리스트 데이터 요청 API
  getCartList: async (data: {}) => {
    return superFetch.post<CartInfoStateType>("cart/list/web/v2", data);
  },

  deleteCartMulti: async (data: DeleteCartListRequestType) => {
    return superFetch.post<IAPIResult>("cart/delete/multi/web/v1", data);
  },

  // 장바구니 상품 수량 변경 요청 API
  updateCartCount: async (data: any) => {
    return superFetch.post<IAPIResult>("cart/update/normal/web/v1", data);
  },

  updateCartOption: async (data: any) => {
    return superFetch.post<IAPIResult>("cart/update/option/web/v1", data);
  },

  // 결제 준비 요청 API
  orderPreparation: async (data: any) => {
    return superFetch.post("/order/preparation/web/v4", data);
  },

  // 쿠폰 리스트 요청 API
  couponList: async (data: any) => {
    return superFetch.post("/coupon/available/list/web/v1", data);
  },

  // 배송지 리스트 요청 API
  deliveryAddressList: async (data: any) => {
    return superFetch.post("/order/address/select/web/v1", data);
  },

  // 배송지 등록 요청 API
  insertDeliveryAddress: async (data: any) => {
    return superFetch.post("/order/address/insert/web/v1", data);
  },

  // 결제 진행 요청 API
  orderPay: async (data: any) => {
    return superFetch.post("/order/form/insert/web/v4", data);
  },

  // 결제 진행 결과 API
  payResult: async (data: any) => {
    return superFetch.post("/payment/complete/web/v1", data);
  },

  // 추가 배송비 요금 요청 API
  deliveryExtraFee: async (data: any) => {
    return superFetch.post("/delivery/fee/extra/web/v1", data);
  },
};

export default paymentAPI;
