import TagManager from "react-gtm-module";
import { GTM_EVENT, GTMProductType } from "../../../types";

export interface GTMremoveFromCartEventType {
  price: number;
  products: Pick<GTMProductType, "item_id" | "item_name">[];
}
/**
 * 장바구니 아이템 삭제 이벤트
 *
 * https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#remove_from_cart
 */
export const removeFromCart = (data: GTMremoveFromCartEventType): void => {
  const { price, products } = data;

  TagManager.dataLayer({
    dataLayer: {
      event: GTM_EVENT.REMOVE_FROM_CART,
      currency: "KRW",
      value: price,
      items: products.map((product) => ({
        ...product,
      })),
    },
  });
};
