// 단위 텍스트
export const UNIT_TEXT = {
  // 정액 단위 텍스트
  AMOUNT: "원",
  // 정률 단위 텍스트
  UNIT: "%",
};

// 영역 더 보기 토글 열기 텍스트
export const VIEW_MORE_TOGGLE_OPEN_TEXT = "더 보기";

// 영역 더 보기 토글 닫기 텍스트
export const VIEW_MORE_TOGGLE_CLOSE_TEXT = "접기";

// 수정 버튼 텍스트
export const MODIFY_TEXT = "수정";

// 삭제 버튼 텍스트
export const DELETE_TEXT = "삭제";

// 취소 버튼 텍스트
export const CANCEL_TEXT = "취소";

// 확인 버튼 텍스트
export const CONFIRM_TEXT = "확인";

// 등록 버튼 텍스트
export const REGISTER_TEXT = "등록";

// 변경 버튼 텍스트
export const CHANGE_TEXT = "변경";

// 다음 버튼 텍스트
export const NEXT_TEXT = "다음";
export const PREV_TEXT = "이전";

// 비밀번호 텍스트
export const PASSWORD_TEXT = "비밀번호";

// 로그아웃 텍스트
export const LOG_OUT_TEXT = "로그아웃";

// 좋아요 텍스트
export const LIKE_TEXT = "좋아요";

// 쇼핑하러가기 텍스트
export const GO_SHOPPING_TEXT = "쇼핑하러 가기";

// 쿠폰함 바로가기 텍스트
export const GO_COUPON_LIST_TEXT = "쿠폰함 바로가기";

// 쿠폰 발급 완료 텍스트
export const SUCCESS_GENERATE_COUPON = "쿠폰 발급 완료";
