import { ForwardedRef, useEffect, useRef, useState } from "react";
import { SCROLL_STATUS, useScroll } from "@amondz/use-scroll";

interface UseScrollTabViewModelProps {
  additionalBaseTop?: number;
  currentActive: number | null;
  onChangeTab: (tabId: number | null) => void;
}

interface UseScrollTabViewModelReturnType {
  handleScroll: (index: number) => void;
  scrollSectionRef: (idx: number) => ForwardedRef<HTMLElement | null>;
}

export const useScrollTabViewModel = (props: UseScrollTabViewModelProps): UseScrollTabViewModelReturnType => {
  const { currentActive, onChangeTab, additionalBaseTop = 0 } = props;

  const scrollRefs = useRef<(HTMLElement | null)[]>([]);
  const { isScrollingTo, scrollTo, getScrollStatus } = useScroll();
  const getSectionScrollStatus = (element: HTMLElement) => getScrollStatus(element, additionalBaseTop);

  /**
   * 스크롤 위치에 따라 현재 활성화된 탭이 무엇인지 파악하는 로직
   * - 스크롤 위치가 첫번째 섹션보다 이전일 경우 첫번째 탭 활성화
   * - 스크롤 위치가 마지막 세션보다 이후일 경우 마지막 탭 활성화
   * - 섹션에 스크롤이 위치할 경우 해당 섹션에 맞는 탭 활성화
   * */
  const getCurrentActiveTab = () => {
    const firstSection = scrollRefs.current[0];
    const lastSection = scrollRefs.current[scrollRefs.current.length - 1];

    if (firstSection && getSectionScrollStatus(firstSection) === SCROLL_STATUS.BEFORE) return 0;
    if (lastSection && getSectionScrollStatus(lastSection) === SCROLL_STATUS.AFTER)
      return scrollRefs.current.length - 1;

    return scrollRefs.current.findIndex((element) => {
      if (!element) return null;
      return getSectionScrollStatus(element) === SCROLL_STATUS.CURRENT;
    });
  };

  const handleScrollEvent = () => {
    const nextActive = getCurrentActiveTab();
    if (currentActive === nextActive || isScrollingTo) return;
    onChangeTab(nextActive);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScrollEvent);
    return () => window.removeEventListener("scroll", handleScrollEvent);
  }, [currentActive, isScrollingTo, getCurrentActiveTab]);

  useEffect(() => {
    //헤더가 렌더링 되면 additionalBaseTop 상태값이 변경되어 스크롤 위치 반영
    handleScrollEvent();
  }, [additionalBaseTop]);

  const handleScroll = (index: number) => {
    const target = scrollRefs.current[index];
    if (!target) return;
    onChangeTab(index);
    scrollTo(target, { additionalBaseTop: additionalBaseTop - 3, behavior: "smooth" });
  };

  const scrollSectionRef: (idx: number) => ForwardedRef<HTMLElement> = (idx) => (ref) => {
    scrollRefs.current[idx] = ref;
  };

  return { handleScroll, scrollSectionRef };
};
