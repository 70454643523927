export const EVENT_DEEP_LINK_KEY = "deeplink_url";
export const COUPON_DEEP_LINK_BASE_VALUE = "amondz://coupon/";
export const EVENTAPPLY_DEEP_LINK_BASE_VALUE = "amondz://eventapply/";

export const LUCKY_DRAW_MSG = {
  CONFIRM_WITH_MARKETING_PUSH_TITLE: "이벤트 참여 하시겠습니까?",
  CONFIRM_WITH_MARKETING_PUSH_DESCRIPTION: "확인 버튼을 누르시면, 마케팅 푸시 수신에 동의하신 것으로 간주됩니다.",
  SUCCESS: "이벤트 참여가 완료 되었습니다.",
  ENABLE_MARKETING_PUSH_COMPLETE: "마케팅 푸시 수신 동의가 완료되었습니다.",
  ALREADY_DONE: "이미 응모 하셨습니다.",
  NO_LUCKY_DRAW_CODE: "종료된 이벤트 입니다.",
} as const;

// 해당 문자열로 시작되는 럭키드로우 코드는 마케팅 푸시 상태를 ON으로 변경함(마케팅 푸시 알림 고지 필요)
export const APPLY_LUCKY_DRAW_WITH_MARKETING_PUSH_ENABLE_STARTING_CODE = "P";

export const EVENT_SECTION_INDEX = {
  IMAGE: 0,
  VIDEO: 1,
  ANIMATION: 2,
  SWIPE: 3,
  TEXT: 4,
} as const;
export const IS_INFINITY = {
  TRUE: 1,
  FALSE: 0,
} as const;
