import { createWrapper } from "next-redux-wrapper";
import { applyMiddleware, legacy_createStore as createStore } from "redux";
import { composeWithDevTools } from "@redux-devtools/extension";
import createSagaMiddleware from "redux-saga";
import { enableES5 } from "immer";

import { NODE_ENV_MODE_TYPE } from "@constants/enum/baseEnums";
import { rootReducer, rootSaga } from "./modules";

// IE 지원
enableES5();

let store: any;
export const configureStore = () => {
  const sagaMiddleware = createSagaMiddleware();
  const middlewares = [sagaMiddleware];
  const enhancer = composeWithDevTools(applyMiddleware(...middlewares));
  store = createStore(rootReducer, enhancer);
  store.sagaTask = sagaMiddleware.run(rootSaga);

  return store;
};

export { store };

const wrapper = createWrapper(configureStore, {
  debug: process.env.NODE_ENV === NODE_ENV_MODE_TYPE.DEVELOPMENT,
});

export default wrapper;
