import { ProductBenefits } from "@amondz/types";

export const maxDiscountAmountTypeCouponBenefit: ProductBenefits = {
  discountedPriceBenefit: {
    lowestPrice: 40000,
    lowestPriceDiscountRate: 10,
    maxDiscountCoupon: {
      appliedDiscountAmount: 4000,
      coupon: {
        id: 1,
        name: '5000원 할인 쿠폰',
        description: '5000원 할인 쿠폰 설명',
        periodType: 'fixedDate',
        date: {
          startDate: '2022-07-26T08:39:02.274Z',
          endDate: '2022-08-10T08:39:02.274Z',
        },
        dayPlus: 7,
        discountType: 'amount',
        discountAmount: 5000,
        maxDiscountAmount: 4000,
        availableCount: 0,
      },
    },
    usablePoint: 3000,
  },
  earnablePoint: 500,
};