import React from "react";
import * as styledComponents from "styled-components";

import { AdsTheme } from "@amondz/design-system";
import { Theme } from "./theme";

enum DeviceSize {
  PHONE = "phone",
  TABLET = "tablet",
  DESKTOP = "desktop",
  SSR = "SSR",
}

enum DeviceSizeBreakPoint {
  PHONE = 480,
  TABLET = 768,
  DESKTOP = 1920,
}

type StyledFunction<T> = styledComponents.ThemedStyledFunction<any, Theme & AdsTheme>;

function withProps<T, U extends HTMLElement = HTMLElement>(
  styledFunction: StyledFunction<React.HTMLProps<U>>,
): StyledFunction<T & React.HTMLProps<U>> {
  return styledFunction;
}

const {
  default: styled,
  css,
  createGlobalStyle,
  keyframes,
  ThemeProvider,
  ServerStyleSheet,
  ThemeConsumer,
} = styledComponents as unknown as styledComponents.ThemedStyledComponentsModule<Theme & AdsTheme>;

export {
  css,
  createGlobalStyle,
  keyframes,
  ThemeProvider,
  withProps,
  ServerStyleSheet,
  ThemeConsumer,
  DeviceSize,
  DeviceSizeBreakPoint,
};

export default styled;
