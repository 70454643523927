import { BrazeCAEvent } from "./index";
import { eventType, eventValue } from "../type";

/**
 * 클라이언트에서 발생하는 이벤트를 gtm 이벤트로 맵핑
 * @param type
 * @param value
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function brazeCAEventChannel<EventType extends eventType>(type: EventType, value: eventValue[EventType]) {
  const event = new BrazeCAEvent();
  switch (type) {
    case eventType.likeProduct: {
      const { productId, productName } = value as eventValue[eventType.likeProduct];
      event.addProductWishlist(String(productId), productName as string);
      return;
    }
    case eventType.unLikeProduct: {
      const { productId, productName } = value as eventValue[eventType.likeProduct];
      event.removeProductWishlist(String(productId), productName as string);
      return;
    }
  }
}

export default brazeCAEventChannel;
