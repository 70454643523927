import styled from "@styles/themedComponents";

export const ModalCoverStyle = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  font-family: ${(props) => props.theme.fonts.notoSansCJKkr};
  background-color: rgba(0, 0, 0, 0.3);
  z-index: ${(props) => props.theme.zIndex.modalBackdrop};

  .modal-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: ${(props) => props.theme.color.amondz_white};
    border-radius: 2px;
    z-index: ${(props) => props.theme.zIndex.modalBox};

    &.mobile-full-screen {
      width: 100%;
      height: 100%;

      .modal-close-btn {
        position: static;
        margin: 18px 24px 14px;
      }
    }

    .modal-close-btn {
      width: 24px;
      height: 24px;
      position: absolute;
      top: 12px;
      right: 12px;
      cursor: pointer;
      display: inline-block;
    }
  }
`;
