async function initMocks() {
  const { server } = require("./server");
  server.listen();
  if (typeof window !== "undefined") {
    const { worker } = require("./browser");
    worker.start({
      onUnhandledRequest: "bypass",
    });
  }
}

initMocks();

export {};
