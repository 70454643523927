import styled from "../../../style/themeComponents";

export const ScrollTabWrapperStyle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .scroll-tab-body {
    width: 100%;
  }
`;
