import { FromType } from "../type";

type ProductDetailFromPage = {
  getFrom: () => FromType | null;
  setFrom: (from: FromType) => void;
  removeFrom: () => void;
};

export const useProductDetailFromPage = (): ProductDetailFromPage => {
  const getFrom = (): FromType | null => {
    return sessionStorage.getItem("productDetailFromPage") as FromType | null;
  };

  const setFrom = (from: FromType): void => {
    if (Object.values(FromType).includes(from)) {
      sessionStorage.setItem("productDetailFromPage", from);
    } else {
      console.error("'from' 값으로 유효하지 않은 값이 전달되고 있습니다.'");
    }
  };

  const removeFrom = (): void => {
    sessionStorage.removeItem("productDetailFromPage");
  };

  return {
    getFrom,
    setFrom,
    removeFrom,
  };
};
