import React from "react";
import { ads_color } from "../../../tokens";
import { IconProps } from "../types";

export const ArrowHeadLined = (props: IconProps) => {
  const { className, rotate, size = 24, fill = ads_color.black, stroke = ads_color.black } = props;
  const style = rotate ? { transform: `rotate(${rotate}deg)` } : {};
  return (
    <svg
      className={className}
      width={size}
      height={size}
      style={style}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9 19L16 12C13.6667 9.66667 9 5 9 5" stroke={stroke} strokeWidth="1.4" />
    </svg>
  );
};
