/* tslint:disable */
/* eslint-disable */
/**
 * AMONDZ-USER-BACKEND-V2
 * AMONDZ-USER-BACKEND-V2 API 문서입니다.
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { ProductReviewsFindResponseReviewItemAttachmentDto } from './product-reviews-find-response-review-item-attachment-dto';
// May contain unused imports in some cases
// @ts-ignore
import { ProductReviewsFindResponseReviewItemProductDto } from './product-reviews-find-response-review-item-product-dto';
// May contain unused imports in some cases
// @ts-ignore
import { ProductReviewsFindResponseReviewItemTagDto } from './product-reviews-find-response-review-item-tag-dto';
// May contain unused imports in some cases
// @ts-ignore
import { ProductReviewsFindResponseReviewItemWriterDto } from './product-reviews-find-response-review-item-writer-dto';
// May contain unused imports in some cases
// @ts-ignore
import { ReviewsCreateResponseReviewItemReviewCriteriaDto } from './reviews-create-response-review-item-review-criteria-dto';

/**
 * 
 * @export
 * @interface ProductReviewsFindResponseReviewItemDto
 */
export interface ProductReviewsFindResponseReviewItemDto {
    /**
     * 리뷰 식별 id
     * @type {number}
     * @memberof ProductReviewsFindResponseReviewItemDto
     */
    'id': number;
    /**
     * 리뷰 평점 
     * @type {number}
     * @memberof ProductReviewsFindResponseReviewItemDto
     */
    'rate': ProductReviewsFindResponseReviewItemDtoRateEnum;
    /**
     * 리뷰 본문 내용 
     * @type {string}
     * @memberof ProductReviewsFindResponseReviewItemDto
     */
    'content': string;
    /**
     * 리뷰 생성 일자
     * @type {string}
     * @memberof ProductReviewsFindResponseReviewItemDto
     */
    'createDate': string;
    /**
     * 리뷰 수정 일자
     * @type {string}
     * @memberof ProductReviewsFindResponseReviewItemDto
     */
    'updateDate': string;
    /**
     * 
     * @type {ProductReviewsFindResponseReviewItemWriterDto}
     * @memberof ProductReviewsFindResponseReviewItemDto
     */
    'writer': ProductReviewsFindResponseReviewItemWriterDto;
    /**
     * 
     * @type {ProductReviewsFindResponseReviewItemProductDto}
     * @memberof ProductReviewsFindResponseReviewItemDto
     */
    'product': ProductReviewsFindResponseReviewItemProductDto;
    /**
     * 상품 후기 첨부 이미지
     * @type {Array<ProductReviewsFindResponseReviewItemAttachmentDto>}
     * @memberof ProductReviewsFindResponseReviewItemDto
     */
    'attachments': Array<ProductReviewsFindResponseReviewItemAttachmentDto>;
    /**
     * 상품 후기의 태그
     * @type {Array<ProductReviewsFindResponseReviewItemTagDto>}
     * @memberof ProductReviewsFindResponseReviewItemDto
     */
    'tags': Array<ProductReviewsFindResponseReviewItemTagDto>;
    /**
     * 
     * @type {Array<ReviewsCreateResponseReviewItemReviewCriteriaDto>}
     * @memberof ProductReviewsFindResponseReviewItemDto
     */
    'reviewCriteria': Array<ReviewsCreateResponseReviewItemReviewCriteriaDto>;
    /**
     * 상품 후기 좋아요 수
     * @type {number}
     * @memberof ProductReviewsFindResponseReviewItemDto
     */
    'likeCount': number;
    /**
     * 상품 후기 좋아요 여부
     * @type {boolean}
     * @memberof ProductReviewsFindResponseReviewItemDto
     */
    'likeFlag': boolean;
}

export const ProductReviewsFindResponseReviewItemDtoRateEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5
} as const;

export type ProductReviewsFindResponseReviewItemDtoRateEnum = typeof ProductReviewsFindResponseReviewItemDtoRateEnum[keyof typeof ProductReviewsFindResponseReviewItemDtoRateEnum];


