import styled from "../../style/themeComponents";

export const CardStyle = styled.div<{ imageHeight: number }>`
  .card-image {
    display: flex;

    & > div {
      position: relative;
      width: 100%;
      height: ${(props) => props.imageHeight}px;
      overflow: hidden;
    }

    img {
      border-radius: 2px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .card-info {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: 8px;
  }
`;
