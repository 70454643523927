// 마이페이지 메뉴 리스트
export enum MAY_PAGE_MENU {
  BENEFIT = "benefit", // 혜택 정보
  COUPON = "coupon", // 쿠폰
  POINT = "point", // 포인트
  MY_ACTIVITY = "myActivity", // 내 활동
  ORDER_INFO = "order", // 주문내역
  LIKE_PRODUCT = "like/product", // 좋아요한 상품
  LIKE_BRAND = "like/brand", // 좋아요한 브랜드
  REVIEW = "review", // 상품 구매 후기
  QNA_LIST = "qna/product", // 상품 문의내역
  CS = "cs", // 고객센터
  FAQ = "faq", // 자주 묻는 질문
  QNA = "qna/amondz", // 아문즈에 문의하기
  SETTING = "setting", // 설정
  USER_MODIFY = "manage", // 회원 정보 수정
  // NOTIFICATION // 알림 설정
}

export enum SUB_MENU_LINK_TYPE {
  INTERNAL = 'internal',
  EXTERNAL = 'external',
  CHANNEL_TALK = 'channelTalk',
}

// 레퍼럴 이미지 타입
export enum REFERRAL_IMG_TYPE {
  MOBILE = "phone",
  TABLET = "tablet",
  DESKTOP = "desktop",
  APP = "app",
}

// 마이페이지 문의 타입
export enum QNA {
  PRODUCT = "product",
  AMONDZ = "amondz",
  FAQ = "faq",
}
