import React, { createContext, PropsWithChildren, useContext, useEffect, ReactElement } from "react";
import { useRouter } from "next/router";
import { matchPath } from "@amondz/utils";
import { GtmStoreState, useGtmStore } from "@amondz/store";
import { PAGE_URL } from "./constants/pageUrl";

const validRoutes: Record<string, string[]> = {
  [PAGE_URL.EVENT_DETAIL]: [PAGE_URL.PRODUCT_DETAIL],
  [PAGE_URL.PRODUCT_DETAIL]: [PAGE_URL.PAYMENT_CART, PAGE_URL.PAYMENT_CHECKOUT, PAGE_URL.SIGN_IN],
  [PAGE_URL.PAYMENT_CART]: [PAGE_URL.PRODUCT_DETAIL, PAGE_URL.PAYMENT_CHECKOUT],
  [PAGE_URL.PAYMENT_CHECKOUT]: [PAGE_URL.PRODUCT_DETAIL, PAGE_URL.PAYMENT_COMPLETE],
  [PAGE_URL.PAYMENT_COMPLETE]: [],
};

const defaultState: GtmStoreState = {
  promotionData: { id: "", name: "" },
  itemListData: { id: "", name: "" },
  isPromotionValid: () => false,
  isItemListValid: () => false,
  setPromotionData: () => {},
  setItemListData: () => {},
  clearPromotionData: () => {},
  clearItemListData: () => {},
  clearGtmVariable: () => {},
};

const GtmContext = createContext<GtmStoreState>(defaultState);

export const GtmProvider: React.FC<PropsWithChildren<NonNullable<unknown>>> = ({ children }): ReactElement => {
  const store = useGtmStore();
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (url: string): void => {
      const nextPath = url.split("?")[0];
      const currentTemplate = router.pathname;
      const validNextRoutes = validRoutes[currentTemplate] || [];
      const isValidRoute = validNextRoutes.some((template) => matchPath(template, nextPath));

      if (!isValidRoute) {
        store.clearPromotionData();
      }
    };

    router.events.on("routeChangeStart", handleRouteChange);
    return (): void => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, [router, store.clearPromotionData]);

  return <GtmContext.Provider value={store}>{children}</GtmContext.Provider>;
};

export const useGtmVariable = (): GtmStoreState => useContext(GtmContext);
