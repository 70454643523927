import { v4 as uuidv4 } from "uuid";

/**
 * cookie 에서 amondz uuid 꺼내오기
 *
 * cookie 에 amondz_uuid key 속성으로 저장이 되어 있으므로
 * 해당 key 속성으로 클라이언트의 uuid 를 꺼내오도록 함
 *
 * @returns {string} token
 */
export const setAmondzUuid = (): string => {
  return uuidv4();
};
