import { ValueOf } from "@amondz/types";

export type Payload = { [key: string]: string | number | boolean | unknown[] };
export type User = {
  userId: number | null;
  userName: string | null;
};

type SortType = "추천순" | "트렌드순" | "인기순" | "신상품순" | "낮은가격순" | "높은가격순";
type WidgetType = "상품" | "브랜드" | "배너" | "기획전" | "타임딜" | "숏폼";

export const EVENT_LOCATION = {
  CART: "cart",
  BRAND: "brand",
  LIKE_PRODUCT: "like_product",
  PRODUCT_DETAIL: "product_detail",
} as const;

export type EventLocationType = ValueOf<typeof EVENT_LOCATION>;

interface IEventPositionInfo {
  // 이벤트가 발생하는 페이지(ex. eventDetail)
  pageName?: string;
  // 이벤트가 발생하는 위치(ex. eventSection)
  atPage?: string;
  //데이터명
  contentType?: string;
}

interface FunnelCommonDataType {
  screenName: string;
  contentType: string;
}

export interface IMenu extends IEventPositionInfo {
  itemIndex: number;
}

export interface IWidgetItem extends IEventPositionInfo {
  // 위젯 아이템 id
  itemId?: number | null;
  // 위젯 아이템 이름
  itemName: string;
  // 위젯 아이템 순서
  itemIndex: number;
  // 위젯 id
  groupId?: number | null;
  // 위젯 이름
  groupName?: string;
  // 위젯 순서
  groupIndex?: number;
  // 위젯 타입
  groupType?: WidgetType;
}

export interface IWidgetMore extends IEventPositionInfo {
  // 위젯 id
  groupId: number;
  // 위젯 이름
  groupName: string;
  // 위젯 순서
  groupIndex: number;
  // 위젯 타입
  groupType: WidgetType;
  // 버튼 명
  buttonText: string;
}

export interface IEventLink extends IEventPositionInfo {
  // 연결된 URL
  content: string;
  // 기획전 id
  groupId: number;
}

export interface IProductItem extends IEventPositionInfo {
  // 상품 id
  productId: number;
  // 상품 이름
  productName: string;
  // 상품 순서
  productIndex: number;
  // 상품 리스트가 있는 상세 페이지의 id (ex. 기획전 상세 - 기획전 id)
  groupId?: number;
}

export interface ISortFilter extends IEventPositionInfo {
  sortType: SortType;
}

export interface IReview extends IEventPositionInfo {
  itemName: string;
}

export interface ICouponDownloadButton extends IEventPositionInfo {
  groupType: "owned" | "download";
  itemId: number;
}

export interface ICouponDownload extends IEventPositionInfo {
  groupType: "disable" | "enable";
}

export interface ICouponDownloadSingle extends IEventPositionInfo {
  itemId: number;
  itemName: string;
  groupType: "disable" | "enable";
  itemIndex: number;
  quantity: number;
}

export interface IEventFilter extends IEventPositionInfo {
  itemName: "filter";
  itemListName: "가격" | "할인율" | "오늘출발";
  groupId: number;
}

export interface IAddProductWishLike {
  brandId: number;
  brandName: string;
  productId: number;
  productName: string;
  price: number;
  deliveryType: 0 | 1;
  isGift: boolean;
  discountRate: number;
  categoryId: number;
  categoryName: string;
  subCategoryName: string;
}

interface IClickPhotoReviewPreview extends FunnelCommonDataType {
  screenName: "product_detail" | "review_photo_gallery";
  contentType: "review_gallery_more" | "review_photo";
  productId?: number;
}

interface IClickProductReviewItem extends FunnelCommonDataType {
  itemType: "no_photo" | "exist_photo";
  productId: number;
}

type IClickProductReviewLikeType = IReview | FunnelCommonDataType;

interface ISelectLog extends FunnelCommonDataType {
  itemName?: string;
  itemIndex?: number;
  itemType?: string;
  itemId?: number | string;
}

// GA 전자상거래 GTM 이벤트 상품 타입
export type GTMProductType = {
  // 상품 식별 id
  item_id: string;
  // 상품명
  item_name: string;
  // 브랜드명
  item_brand: string;
  // 상품 카테고리
  item_category: string;
  // 상품 가격
  price: number;
  // 수량 (선택 사항)
  quantity?: number;
  // 옵션 (선택 사항)
  item_variant?: string;
  coupon?: string;
  // 아이템 그룹 ex) 기획전 id
  item_list_id?: string;
  //  아이템 그룹 이름 ex) 기획전 제목
  item_list_name?: string;
  promotion_id?: string;
  promotion_name?: string;
  index?: number;
};

// GA 전사상거래 프로모션 이벤트 아이템 타입
export interface GTMPromotionsItemType extends GTMProductType {
  creative_name?: string;
  creative_slot?: string;
}
export interface GTMCommerceEventProperty {
  currency: "KRW";
  value: number;
  items: GTMProductType[];
}

export interface GTMPromotionEventProperty {
  //The name of the promotional creative. Ignored if set at the item-level. ex.summer_banner
  creative_name?: string;
  // The name of the promotional creative slot associated with the event. Ignored if set at the item-level. ex.featured_app_1
  creative_slot?: string;
  //The ID of the promotion associated with the event. Ignored if set at the item-level. ex.P_12345
  promotion_id?: string;
  // The name of the promotion associated with the event. Ignored if set at the item-level.
  promotion_name?: string;
  items?: GTMPromotionsItemType[];
}

export interface GTMPurchaseEventProperty extends GTMCommerceEventProperty {
  transaction_id: string;
}

export interface BrazeChangeLogEventProperty {
  attribute_key: string;
  attribute_value: string | string[];
}

export enum GTM_CONTENT_TYPE {
  PRODUCT_ITEM = "product_item",
  PRODUCT_LIKE = "product_like",
  FILTER_SORT_ITEM = "filter_sort_item",
  WIDGET_ITEM = "widget_item",
  WIDGET_ITEM_LIKE = "widget_item_like",
  WIDGET_MORE = "widget_more",
}

export enum GTM_EVENT {
  CHANGE_USER = "changeUser",
  LOGOUT = "logout",
  CLICK_MENU = "clickMenu",
  CLICK_WIDGET_ITEM = "clickWidgetItem",
  CLICK_WIDGET_ITEM_LIKE = "clickWidgetItemLike",
  CLICK_WIDGET_MORE = "clickWidgetMore",
  CLICK_EVENT_LINK = "clickEventLink",
  CLICK_PRODUCT_ITEM = "clickProductItem",
  CLICK_PRODUCT_LIKE = "clickProductItemLike",
  CLICK_SORT_FILTER = "clickSortFilter",
  CLICK_SHARE_EVENT = "clickShareEvent",
  CLICK_SEARCH = "clickSearch",
  CLICK_REVIEW_ITEM_LIKE = "clickReviewItemLike",
  CLICK_REVIEW_RELATED_ITEM = "clickReviewRelateItem",
  CLICK_REVIEW_GUIDE = "clickReviewGuide",
  CLICK_REVIEW_SUGGESTION_PHOTO_BUTTON = "clickReviewSuggestionPhotoButton",
  CLICK_REVIEW_SUGGESTION_SUMMIT_BUTTON = "clickReviewSuggestionSummitButton",
  ClICK_REVIEW_SUGGESTION_CLOSE = "clickReviewSuggestionClose",
  CLICK_PRODUCT_COUPON_DOWNLOAD_BUTTON = "clickCouponListOpen",
  CLICK_PRODUCT_COUPON_DOWNLOAD_SINGlE = "clickCouponDownloadSingle",
  CLICK_PRODUCT_COUPON_DOWNLOAD_ALL = "clickCouponDownloadAll",
  CLICK_PRODUCT_COUPON_MODAL_CLOSE = "clickClose",
  CLICK_EVENT_FILTER = "clickEventFilter",
  ADD_TO_PRODUCT_WISHLIST = "addToProductWishlist",
  CLICK_PHOTO_REVIEW_PREVIEW = "clickPhotoReviewPreview",
  CLICK_PRODUCT_DETAIL_TAB = "clickProductDetailTab",
  CLICK_PRODUCT_REVIEW_ITEM = "clickReviewItem",
  TOGGLE_PHOTO_REVIEW_OPTION = "togglePhotoReviewOption",
  SWIPE_PREVIEW_NAVIGATION = "swipePreviewNavigation",
  SlIDE_REVIEW_MODAL = "slideReviewModal",
  CLICK_TO_REVIEW_SECTION = "clickToReviewSection",
  LIKE_BRAND = "like_brand",
  UNLIKE_BRAND = "unlike_brand",
  SELECT_LOG = "selectLog",
  // 전자상거래 이벤트 상품 조회하기
  VIEW_ITEM = "view_item",
  // 전자상거래 상품 결제 하기
  BEGIN_CHECKOUT = "begin_checkout",
  // 전자 상거래 이벤트 상품 결제 완료
  PURCHASE = "purchase",
  // 전자상거래 이벤트 상품 장바구니 추가하기
  ADD_TO_CART = "add_to_cart",
  // 전자상거래 이벤트 기획전 조회하기
  VIEW_PROMOTION = "view_promotion",
  // 전자상거래 이벤트 기획전 내 아이템 선택
  SELECT_PROMOTION = "select_promotion",
  // brazeSetAttribute, 문자열 CA 전송
  SET_CUSTOM_ATTRIBUTE = "setCustomUserAttribute",
  // brazeSetAttributeArray, 배열형식 CA 전송
  ADD_TO_CUSTOM_ATTRIBUTE_ARRAY = "addToCustomAttributeArray",
  // 전자상거리 이벤트 아이템 선택
  SELECT_ITEM = "select_item",
  // 전자상거래 이벤트 아이템 목록 조회
  VIEW_ITEM_LIST = "view_item_list",
  // 전자상거래 이벤트 상품 장바구니 삭제
  REMOVE_FROM_CART = "remove_from_cart",
}

export type GTM_EVENT_PROPERTY = {
  [GTM_EVENT.CHANGE_USER]: User;
  [GTM_EVENT.LOGOUT]: NonNullable<unknown>;
  [GTM_EVENT.CLICK_MENU]: IMenu;
  [GTM_EVENT.CLICK_WIDGET_ITEM]: IWidgetItem;
  [GTM_EVENT.CLICK_WIDGET_MORE]: IWidgetMore;
  [GTM_EVENT.CLICK_WIDGET_ITEM_LIKE]: IWidgetItem & { status: boolean };
  [GTM_EVENT.CLICK_EVENT_LINK]: IEventLink;
  [GTM_EVENT.CLICK_PRODUCT_ITEM]: IProductItem;
  [GTM_EVENT.CLICK_PRODUCT_LIKE]: IProductItem & { status: boolean };
  [GTM_EVENT.CLICK_SORT_FILTER]: ISortFilter;
  [GTM_EVENT.CLICK_SHARE_EVENT]: NonNullable<unknown>;
  [GTM_EVENT.CLICK_SEARCH]: NonNullable<unknown>;
  [GTM_EVENT.CLICK_REVIEW_ITEM_LIKE]: IClickProductReviewLikeType;
  [GTM_EVENT.CLICK_REVIEW_RELATED_ITEM]: IReview;
  [GTM_EVENT.CLICK_REVIEW_GUIDE]: IReview;
  [GTM_EVENT.CLICK_REVIEW_SUGGESTION_PHOTO_BUTTON]: IReview;
  [GTM_EVENT.CLICK_REVIEW_SUGGESTION_SUMMIT_BUTTON]: IReview;
  [GTM_EVENT.ClICK_REVIEW_SUGGESTION_CLOSE]: IReview;
  [GTM_EVENT.CLICK_PRODUCT_COUPON_DOWNLOAD_BUTTON]: ICouponDownloadButton;
  [GTM_EVENT.CLICK_PRODUCT_COUPON_DOWNLOAD_SINGlE]: ICouponDownloadSingle;
  [GTM_EVENT.CLICK_PRODUCT_COUPON_DOWNLOAD_ALL]: ICouponDownload;
  [GTM_EVENT.CLICK_PRODUCT_COUPON_MODAL_CLOSE]: IEventPositionInfo;
  [GTM_EVENT.CLICK_EVENT_FILTER]: IEventFilter;
  [GTM_EVENT.ADD_TO_PRODUCT_WISHLIST]: IAddProductWishLike;
  [GTM_EVENT.CLICK_PHOTO_REVIEW_PREVIEW]: IClickPhotoReviewPreview;
  [GTM_EVENT.CLICK_PRODUCT_DETAIL_TAB]: FunnelCommonDataType;
  [GTM_EVENT.CLICK_PRODUCT_REVIEW_ITEM]: IClickProductReviewItem;
  [GTM_EVENT.TOGGLE_PHOTO_REVIEW_OPTION]: FunnelCommonDataType;
  [GTM_EVENT.SWIPE_PREVIEW_NAVIGATION]: FunnelCommonDataType;
  [GTM_EVENT.SlIDE_REVIEW_MODAL]: FunnelCommonDataType;
  [GTM_EVENT.CLICK_TO_REVIEW_SECTION]: FunnelCommonDataType;
  [GTM_EVENT.SELECT_LOG]: ISelectLog;
  [GTM_EVENT.LIKE_BRAND]: { brandId: string; brandName: string };
  [GTM_EVENT.UNLIKE_BRAND]: { brandId: string; brandName: string };
  [GTM_EVENT.SET_CUSTOM_ATTRIBUTE]: BrazeChangeLogEventProperty;
  [GTM_EVENT.ADD_TO_CUSTOM_ATTRIBUTE_ARRAY]: BrazeChangeLogEventProperty;
  [GTM_EVENT.VIEW_ITEM]: NonNullable<unknown>;
  [GTM_EVENT.BEGIN_CHECKOUT]: NonNullable<unknown>;
  [GTM_EVENT.PURCHASE]: NonNullable<unknown>;
  [GTM_EVENT.ADD_TO_CART]: NonNullable<unknown>;
  [GTM_EVENT.VIEW_PROMOTION]: NonNullable<unknown>;
  [GTM_EVENT.SELECT_PROMOTION]: NonNullable<unknown>;
  [GTM_EVENT.SELECT_ITEM]: NonNullable<unknown>;
  [GTM_EVENT.VIEW_ITEM_LIST]: NonNullable<unknown>;
  [GTM_EVENT.REMOVE_FROM_CART]: NonNullable<unknown>;
};
