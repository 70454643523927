import { ValueOf } from "@amondz/types";

export const INVALID_PAGE_URL_PATH = "/404";

export const LIKE_STATUS = {
  UN_LIKE: 0,
  LIKE: 1,
} as const;
export type LIKE_STATUS_TYPE = (typeof LIKE_STATUS)[keyof typeof LIKE_STATUS];

export const STATUS_CODE_COMMON = {
  SERVER_ERROR: 0, // exception 이 발생한 경우

  SUCCESS: 1, // 성공

  MAINTENANCE: 10, // 점검중 or 공사중인 상태

  INCOMPLETE_PARAM: 100, // 매개변수가 제대로 전달되지 않은 경우,

  INVALID_MEMBER: {
    // 회원 정보가 유효하지 않을 경우
    CODE_200: 200, // 회원 정보처리중 exception 발생한 경우
    CODE_201: 201,
    CODE_202: 202,
    CODE_203: 203,
    CODE_204: 204,
  },

  FORCE_LOGOUT: 300, // 강제 로그아웃 시켜야할 경우

  FORCE_CLOSE: 310, // 앱을 강제로 종료 시켜야 하는 경우

  FORCE_VERSION_CHECK: 320, // 버전 체크를 해야하는 경우
} as const;

export const Z_INDEX = {
  VIEW_WRAP: 1,
  FILTER_GROUP_KEY: 1,
  OPACITY: 1,
  PRODUCT_FILTER: 5,
  STORE_DICTIONARY_INDEX_LIST: 6,
  FILTER_FOOTER_SECTION: 10,
  INPUT_ADD_ON: 10,
  ARROW_BUTTON: 10,
  TAB: 89,
  PRODUCT_BUY_INFO_FIXED_BTN: 90,
  HEADER: 90,
  TOP_BOTTOM_BUTTON: 90,
  BOTTOM_FIXED_AREA: 100,
  MODAL_BACK_DROP: 100,
  DROP_DOWN: 100,
  FULL_SCREEN_VIEW: 100,
  MODAL_BOX: 102,
  LOADING_SPINNER: 1000,
  NOTIFY_TOAST: 1000,
};

export const DATE_FORMAT = {
  FULL_DASH_FORMAT: "yyyy-MM-dd HH:mm:ss",
  YMD_DASH_FORMAT: "yyyy-MM-dd",
  YMD_DOT_FORMAT: "yyyy.MM.dd",
} as const;
export type DATE_FORMAT = ValueOf<typeof DATE_FORMAT>;
