/**
 * 배열이 없는 쿼리 객체로 변환
 * 쿼리 값이 배열일 경우 첫번째 값만 사용
 *
 * @param obj
 */
export const convertQueryWithoutArray = (
  obj: Record<string, string | string[] | undefined>,
): Record<string, string | undefined> => {
  return Object.keys(obj).reduce((acc, key) => {
    return {
      ...acc,
      [key]: Array.isArray(obj[key]) ? obj[key]?.[0] : obj[key],
    };
  }, {});
};
