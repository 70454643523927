import { subCategoryName } from "../utils";
import { beginPayment, submitPayment } from "../../type";

export const beginPaymentInfoDto = ({ hasCoupon, productBundleList }: beginPayment) => {
  let orderInfo: any = {
    brandId: [],
    brandName: [],
    productId: [],
    productName: [],
    imgUrl: [],
    price: [],
    deliveryType: [],
    isGift: [],
    quantity: [],
    optionId: [],
    option: [],
    categoryId: [],
    categoryName: [],
    subCategoryName: [],
    totalPrice: 0,
    totalCount: 0,
  };
  if (hasCoupon) {
    orderInfo.couponId = [];
    orderInfo.coupon = [];
  }
  let totalPrice = 0;
  let totalCount = 0;
  productBundleList?.reduce((a, c) => {
    c.productList.forEach((item) => {
      a.brandId.push(c.storeId);
      a.brandName.push(c.storeName);
      a.categoryId.push(item.categoryId);
      a.categoryName.push(item.categoryName);
      a.subCategoryName.push(...item.productCategoryList);
      a.productId.push(item.productId);
      a.productName.push(item.productName);
      a.imgUrl.push(item.productImg);
      a.price.push(item.salesPrice);
      a.deliveryType.push(item.isTodayDelivery || (0 as const));
      // todo: 선물하기 출시 후 수정
      a.isGift.push(false);
      a.quantity.push(item.count);
      totalPrice += item.totalPrice;
      totalCount += item.count;

      if (item.option) {
        a.optionId.push(item.option.id);
        a.option.push(item.option.optionText);
      } else {
        a.optionId.push(0);
        a.option.push("");
      }

      if (hasCoupon && item.coupon) {
        a.couponId.push(item.coupon.id);
        a.coupon.push(item.coupon.name!);
      }
    });
    return a;
  }, orderInfo);
  orderInfo.totalCount = totalCount;
  orderInfo.totalPrice = totalPrice;
  orderInfo.subCategoryName = subCategoryName(orderInfo.subCategoryName);

  return orderInfo;
};

export const submitPaymentInfoDto = ({ hasCoupon, productBundleList, point, paymentMethod }: submitPayment) => {
  const beginPaymentInfo = beginPaymentInfoDto({ hasCoupon, productBundleList });
  return { ...beginPaymentInfo, point, paymentMethod };
};
