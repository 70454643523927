import Modal from "@components/common/Modal";
import styled from "@styles/themedComponents";

export const AppInstallInfoModalStyle = styled(Modal)`
  .modal-box {
    width: 320px;

    .modal-header {
      margin: 12px 12px 8px;
    }

    .modal-body {
      margin: 0 32px 24px;
      font-size: 16px;
      letter-spacing: -0.5px;
      text-align: center;

      .modal-contents {
        .main-text {
          margin-bottom: 12px;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
        }

        .sub-text {
          margin-bottom: 16px;
          font-size: 13px;
          line-height: 19px;
          color: ${(props) => props.theme.color.gray_01};

          & > div:not(:last-child) {
            margin-bottom: 2px;
          }
        }
      }
    }

    .modal-footer {
      margin: 0 24px 24px;

      button {
        width: 130px;
        cursor: pointer;
        font-size: 13px;
        font-weight: 500;
        letter-spacing: -0.3px;
        border-color: ${(props) => props.theme.color.amondz_black};

        img {
          width: 24px;
          height: 24px;
          margin-right: 7px;
          vertical-align: middle;
        }

        &:first-child {
          margin-right: 12px;
        }

        &.close-btn {
          color: #b2b2b2;
        }
      }
    }
  }
`;
