import ZaiClient, {
  LikeEvent,
  PageViewEvent,
  ProductDetailViewEvent,
  CustomEvent,
  Event,
  CartaddEvent,
  SearchEvent,
  PurchaseEvent,
} from "zaiclient";
import { EventLoggerResponse } from "zaiclient/dist/entity";

const clientId = process.env.STORYBOOK_PUBLIC_ZAI_CLIENT_ID || process.env.NEXT_PUBLIC_ZAI_CLIENT_ID || "";
const clientSecret = process.env.STORYBOOK_PUBLIC_ZAI_CLIENT_ID || process.env.NEXT_PUBLIC_ZAI_CLIENT_SECRET || "";

/**
 * 자이 이벤트를 관리하는 클래스
 * ZaiEvent.getInstance() 로 사용
 */
class ZaiEvent {
  private static instance: ZaiEvent;
  private readonly zaiClient: ZaiClient;
  private readonly MAX_RETRIES = 2;
  private readonly RETRY_DELAY = 1000;

  private constructor() {
    // zaiClient 는 싱글톤으로 관리
    this.zaiClient = new ZaiClient(clientId, clientSecret, { customEndpoint: "amondz" });
  }

  public static getInstance(): ZaiEvent {
    if (!ZaiEvent.instance) {
      ZaiEvent.instance = new ZaiEvent();
    }

    return ZaiEvent.instance;
  }

  private async retryableAddEvent<T extends Event>(
    event: T,
    retries = this.MAX_RETRIES,
  ): Promise<EventLoggerResponse | void> {
    try {
      return await this.zaiClient.addEventLog(event);
    } catch (error) {
      if (retries > 0) {
        await new Promise((resolve) => setTimeout(resolve, this.RETRY_DELAY));
        return this.retryableAddEvent(event, retries - 1);
      }

      console.error(error);
    }
  }

  addEvent<T extends Event>(event: T): Promise<EventLoggerResponse | void> {
    return this.retryableAddEvent(event, this.MAX_RETRIES);
  }

  viewProductDetail(userId: string, productId: string, from?: string): Promise<EventLoggerResponse | void> {
    const productDetailViewEvent = new ProductDetailViewEvent(userId, productId, from);
    return this.addEvent(productDetailViewEvent);
  }

  viewBrandDetail(userId: string, brandId: string): Promise<EventLoggerResponse | void> {
    const brandDetailViewEvent = new CustomEvent(userId, brandId, "brand_click", "null");
    return this.addEvent(brandDetailViewEvent);
  }

  viewPage(userId: string, pageType: string): Promise<EventLoggerResponse | void> {
    const pageViewEvent = new PageViewEvent(userId, pageType);
    return this.addEvent(pageViewEvent);
  }

  likeProduct(userId: string, productId: string): Promise<EventLoggerResponse | void> {
    const likeProductEvent = new LikeEvent(userId, productId);
    return this.addEvent(likeProductEvent);
  }

  likeBrand(userId: string, brandId: string): Promise<EventLoggerResponse | void> {
    const likeBrandEvent = new CustomEvent(userId, brandId, "brand_like", "null");
    return this.addEvent(likeBrandEvent);
  }

  addCart(userId: string, productId: string): Promise<EventLoggerResponse | void> {
    const cartaddEvent = new CartaddEvent(userId, productId);
    return this.addEvent(cartaddEvent);
  }

  purchase(
    userId: string,
    productId: string | string[],
    price: number | number[],
  ): Promise<EventLoggerResponse | void> {
    const purchaseEvent = new PurchaseEvent(userId, productId, price);
    return this.addEvent(purchaseEvent);
  }

  search(userId: string, search: string): Promise<EventLoggerResponse | void> {
    const searchEvent = new SearchEvent(userId, search);
    return this.addEvent(searchEvent);
  }
}

export default ZaiEvent;
