import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import { GtmStoreState, ItemListData, PromotionData } from "./type";

export const useGtmStore = create<GtmStoreState>()(
  devtools(
    persist(
      (set, get) => ({
        promotionData: { id: "", name: "" },
        itemListData: { id: "", name: "" },
        isPromotionValid: (): boolean => {
          const { promotionData } = get();
          return promotionData.id !== "" && promotionData.name !== "";
        },
        isItemListValid: (): boolean => {
          const { itemListData } = get();
          return itemListData.id !== "" && itemListData.name !== "";
        },
        setPromotionData: (data: PromotionData): void => set({ promotionData: data }),
        setItemListData: (data: ItemListData): void => set({ itemListData: data }),
        clearPromotionData: (): void => set({ promotionData: { id: "", name: "" } }),
        clearItemListData: (): void => set({ itemListData: { id: "", name: "" } }),
        clearGtmVariable: (): void =>
          set({
            promotionData: { id: "", name: "" },
            itemListData: { id: "", name: "" },
          }),
      }),
      {
        name: "GtmStore",
        storage: createJSONStorage(() => sessionStorage),
      },
    ),
    { name: "GtmStore" },
  ),
);
