import TagManager from "react-gtm-module";
import { GTM_EVENT, GTMProductType } from "../../../types";

export interface GTMAddToCartEventType {
  price: number;
  products: GTMProductType[];
}

/**
 * 장바구니 추가 이벤트 설정
 *
 * https://developers.google.com/analytics/devguides/collection/ga4/reference/events?sjid=15760346114663595559-AP&client_type=gtag#add_to_cart
 */
export const addToCart = (data: GTMAddToCartEventType): void => {
  const { price, products} = data;

  TagManager.dataLayer({
    dataLayer: {
      event: GTM_EVENT.ADD_TO_CART,
      currency: "KRW",
      value: price,
      items: products,
    },
  });
};
