import TagManager from "react-gtm-module";
import { EVENT_LOCATION, EventLocationType, GTM_EVENT, GTMProductType } from "../../../types";

export interface GTMViewItemListEventType {
  locationType: EventLocationType;
  id?: string | number;
  name?: string;
  items: Pick<GTMProductType, "item_id" | "item_name" | "index">[];
}

const createItemListDetails = ({
  locationType,
  id,
  name,
  items,
}: GTMViewItemListEventType): {
  item_list_id: string;
  item_list_name: string;
  items: Pick<GTMProductType, "item_id" | "item_name" | "index">[];
} => {
  if (locationType === EVENT_LOCATION.CART || locationType === EVENT_LOCATION.LIKE_PRODUCT) {
    return {
      item_list_id: locationType,
      item_list_name: locationType,
      items,
    };
  }
  return {
    item_list_id: `${locationType}=${id}`,
    item_list_name: `${locationType}=${name}`,
    items,
  };
};

/**
 * 상품 목록 조회
 *
 * https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#view_item_list
 */
export const viewItemList = (data: GTMViewItemListEventType): void => {
  const itemListDetails = createItemListDetails(data);

  const dataLayer = {
    event: GTM_EVENT.VIEW_ITEM_LIST,
    ...itemListDetails,
  };

  TagManager.dataLayer({
    dataLayer,
  });
};
