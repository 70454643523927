import * as React from "react";
import { FC, MouseEvent, MouseEventHandler, useState, useCallback, memo, useEffect } from "react";

interface IconProps {
  className?: string;
  src?: string;
  alt?: string;
  disabled?: boolean;
  hoverSrc?: string;
  style?: any;
  onClick?: MouseEventHandler;
}

const Icon: FC<IconProps> = (props: IconProps) => {
  const { className, src, alt = "", disabled, hoverSrc, style, onClick } = props;
  const [imageSrc, setImageSrc] = useState<string | undefined>(src);

  useEffect(() => {
    setImageSrc(src);
  }, [src]);

  const mouseOver = useCallback(() => {
    if (hoverSrc) {
      setImageSrc(hoverSrc);
    }
  }, []);

  const mouseOut = useCallback(() => {
    if (hoverSrc) {
      setImageSrc(src);
    }
  }, []);

  const handleClick = (e: MouseEvent) => {
    if (!onClick) return;
    if (!disabled) {
      onClick(e);
    }
  };

  return (
    <img
      className={className}
      alt={alt}
      src={imageSrc}
      style={style}
      onMouseOver={mouseOver}
      onMouseOut={mouseOut}
      onClick={handleClick}
    />
  );
};

export default memo(Icon);
