/**
 * sdk 로드하기
 *
 * library 또는 sdk 를 동적으로 불어올 수 있는 기능
 */
export const loadSdk = (id: string, url: string, options?: { integrity: string; crossOrigin: string }) => {
  return new Promise((resolve) => {
    const js: HTMLScriptElement = document.createElement("script");

    js.id = id;
    js.src = url;
    js.onload = resolve;

    if (options?.integrity) {
      js.integrity = options.integrity;
    }

    if (options?.crossOrigin) {
      js.crossOrigin = options.crossOrigin;
    }

    document.body.appendChild(js);
  });
};
