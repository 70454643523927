import { ReviewRewardsConfigType, ReviewRewardsType } from "@amondz/types";
import { numberWithCommas } from "../common";

export const applyCommasToReviewRewards = (data: ReviewRewardsConfigType): ReviewRewardsType => {
  const result: ReviewRewardsType = {};
  for (const key in data) {
    const value = data[key];
    if (Number(value) === 0) {
      result[key] = "";
    } else {
      result[key] = numberWithCommas(value);
    }
  }
  return result;
};
