import {
  mock_fixedDate_coupon,
  mock_generatedDate_coupon,
  mock_infinityDate_coupon
} from "@test/mocks/product/couponList/mockData";
import { mockWithParams, mockWithArrayParams } from "@services/msw/utils";

const couponExtractor = (req: any) => {
  return req.body.couponId;
}

const couponBulkExtractor = (req: any) => {
  return req.body.couponIdObjects;
}

export const couponMock = mockWithParams({
  1: mock_fixedDate_coupon,
  2: mock_generatedDate_coupon,
  3: mock_infinityDate_coupon
}, couponExtractor, 'couponGenerated');

export const couponBulkMock = mockWithArrayParams(
  {
    1: mock_fixedDate_coupon,
    2: mock_generatedDate_coupon,
    3: mock_infinityDate_coupon
  },
  couponBulkExtractor, 'couponGenerateds', 'couponId',
);