import { isAndroid, isIOS, isBrowser } from "@amondz/detect-device";
import { DEVICE_OS_TYPE } from "@constants/enum/baseEnums";
import { DEVICE_TYPE } from "@constants/service/common/common";

/**
 * @returns {DEVICE_OS_TYPE} 디바이스 OS 타입
 */
export const detectDeviceOs = (): DEVICE_OS_TYPE => {
  // browser
  if (isBrowser) {
    return DEVICE_OS_TYPE.BROWSER;
  }

  // android
  if (isAndroid) {
    return DEVICE_OS_TYPE.ANDROID;
  }

  // ios
  if (isIOS) {
    return DEVICE_OS_TYPE.IOS;
  }

  return DEVICE_OS_TYPE.BROWSER;
};

export const getDeviceOption = (userAgent?: string): { deviceType: number; os: string } => {
  const targetUserAgent: string = userAgent || navigator?.userAgent;

  // os 찾기
  let os = "unknown";
  if (/windows/.test(targetUserAgent.toLowerCase())) {
    os = "Windows";
  } else if (/mac/.test(targetUserAgent.toLowerCase())) {
    os = "Mac";
  } else if (/x11/.test(targetUserAgent.toLowerCase())) {
    os = "Unix";
  }

  return {
    deviceType: DEVICE_TYPE.PC,
    os,
  };
};
