import { LikeBrandResponseType, LikeStoreList, LikeStoreListRequest } from "./type";
import { superFetchV2 } from "../superFetchV2";
import { BrandsFindOneResponseDto } from "../types";

export const brandApiV2 = {
  likeStoreList: ({ pageNumber, pageSize }: LikeStoreListRequest): Promise<LikeStoreList> =>
    superFetchV2.get(`/like-stores?page=${pageNumber}&pageSize=${pageSize}`),
  // 브랜드 좋아요 요청 api
  likeBrand: (brandId: number): Promise<LikeBrandResponseType> => superFetchV2.put(`/like-brands/${brandId}`),
  // 브랜드 좋아요 해제 요청 api
  unlikeBrand: (brandId: number): Promise<LikeBrandResponseType> => superFetchV2.delete(`/like-brands/${brandId}`),
  getDetail: (brandId: number): Promise<BrandsFindOneResponseDto> => superFetchV2.get(`/brands/${brandId}`),
};
