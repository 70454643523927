import { AxiosError } from "axios";

enum HttpErrorStatusCode {
  /**
   * 서버가 명백한 클라이언트 오류(예: 잘못된 요청 구문, 너무 큰 크기, 잘못된 요청 메시지 프레이밍, 또는 기만적인 요청 라우팅)로 인해 요청을 처리할 수 없거나 처리하지 않을 때.
   */
  BAD_REQUEST = 400,

  /**
   * 403 Forbidden과 유사하지만, 인증이 필요하며 실패했거나 아직 제공되지 않은 경우에 사용됩니다.
   * 응답에는 요청된 리소스에 적용 가능한 챌린지를 포함하는 WWW-Authenticate 헤더 필드가 포함되어야 합니다.
   * 기본 접근 인증 및 다이제스트 접근 인증을 참조하십시오. 401은 "인증되지 않음"을 의미합니다.
   * 즉, 사용자가 필요한 자격 증명을 가지고 있지 않습니다.
   */
  UNAUTHORIZED = 401,

  /**
   * 미래 사용을 위해 예약되었습니다. 원래 의도는 이 코드가 디지털 현금 또는 마이크로 결제 시스템의 일부로 사용될 수 있다는 것이었지만,
   * 이는 실현되지 않았고 이 코드는 일반적으로 사용되지 않습니다.
   * Google Developers API는 특정 개발자가 일일 요청 한도를 초과한 경우 이 상태를 사용합니다.
   */
  PAYMENT_REQUIRED = 402,

  /**
   * 요청은 유효하지만 서버가 조치를 거부합니다.
   * 사용자가 리소스에 대한 필요한 권한을 가지고 있지 않을 수 있습니다.
   */
  FORBIDDEN = 403,

  /**
   * 요청된 리소스를 찾을 수 없으며, 미래에 이용 가능할 수도 있습니다.
   * 클라이언트의 후속 요청은 허용됩니다.
   */
  NOT_FOUND = 404,

  /**
   * 요청된 리소스에 대해 요청 메서드가 지원되지 않습니다;
   * 예를 들어, POST를 통해 데이터가 제출되어야 하는 양식에 대한 GET 요청이거나, 읽기 전용 리소스에 대한 PUT 요청.
   */
  METHOD_NOT_ALLOWED = 405,

  /**
   * 요청된 리소스가 요청에서 보낸 Accept 헤더에 따라 생성할 수 있는 콘텐츠가 없습니다.
   */
  NOT_ACCEPTABLE = 406,

  /**
   * 클라이언트가 먼저 프록시에서 인증을 받아야 합니다.
   */
  PROXY_AUTHENTICATION_REQUIRED = 407,

  /**
   * 서버가 요청을 기다리다가 타임아웃되었습니다.
   * HTTP 사양에 따르면:
   * "클라이언트가 서버가 기다릴 준비가 된 시간 내에 요청을 생성하지 않았습니다. 클라이언트는 나중에 요청을 수정하지 않고 반복할 수 있습니다."
   */
  REQUEST_TIMEOUT = 408,

  /**
   * 요청에 충돌이 있어 처리할 수 없음을 나타냅니다.
   * 예를 들어, 여러 동시 업데이트 간의 편집 충돌.
   */
  CONFLICT = 409,

  /**
   * 요청된 리소스가 더 이상 제공되지 않으며 다시 제공되지 않을 것입니다.
   * 이는 리소스가 의도적으로 제거되었으며 리소스를 정리해야 함을 나타내는 데 사용되어야 합니다.
   * 410 상태 코드를 받은 후 클라이언트는 미래에 리소스를 요청해서는 안 됩니다.
   * 검색 엔진과 같은 클라이언트는 리소스를 인덱스에서 제거해야 합니다.
   * 대부분의 사용 사례는 클라이언트와 검색 엔진이 리소스를 정리할 필요가 없으며 대신 "404 Not Found"를 사용할 수 있습니다.
   */
  GONE = 410,

  /**
   * 요청된 리소스에 필요한 콘텐츠 길이를 요청에 지정하지 않았습니다.
   */
  LENGTH_REQUIRED = 411,

  /**
   * 서버가 요청자가 요청에 대해 설정한 사전 조건 중 하나를 충족하지 않습니다.
   */
  PRECONDITION_FAILED = 412,

  /**
   * 요청이 서버가 처리할 수 없을 만큼 크거나 처리할 수 없는 크기임을 나타냅니다. 이전에는 "Request Entity Too Large"로 불렸습니다.
   */
  PAYLOAD_TOO_LARGE = 413,

  /**
   * 제공된 URI가 서버가 처리하기에 너무 깁니다. 종종 GET 요청의 쿼리 문자열에 너무 많은 데이터가 인코딩된 결과이며,
   * 이 경우 POST 요청으로 변환되어야 합니다. 이전에는 "Request-URI Too Long"으로 불렸습니다.
   */
  URI_TOO_LONG = 414,

  /**
   * 요청된 엔티티의 미디어 타입을 서버 또는 리소스가 지원하지 않습니다.
   * 예를 들어, 클라이언트가 이미지를 image/svg+xml 형식으로 업로드했지만 서버는 다른 형식을 요구합니다.
   */
  UNSUPPORTED_MEDIA_TYPE = 415,

  /**
   * 클라이언트가 파일의 일부를 요청했지만 서버가 해당 부분을 제공할 수 없습니다.
   * 예를 들어, 클라이언트가 파일의 끝 부분을 넘어서는 부분을 요청한 경우.
   * 이전에는 "Requested Range Not Satisfiable"로 불렸습니다.
   */
  RANGE_NOT_SATISFIABLE = 416,

  /**
   * 서버가 Expect 요청 헤더 필드의 요구 사항을 충족할 수 없습니다.
   */
  EXPECTATION_FAILED = 417,

  /**
   * 이 코드는 1998년 전통적인 IETF 만우절 장난 중 하나인 RFC 2324, 하이퍼 텍스트 커피 포트 제어 프로토콜로 정의되었으며,
   * 실제 HTTP 서버에 의해 구현될 것으로 예상되지 않습니다. RFC는 이 코드가 커피를 끓이는 것을 요청받은
   * 티포트에 의해 반환되어야 한다고 명시합니다. 이 HTTP 상태는 Google.com을 포함한 일부 웹사이트에서 이스터 에그로 사용됩니다.
   */
  I_AM_A_TEAPOT = 418,

  /**
   * 요청이 응답을 생성할 수 없는 서버로 전달되었습니다(예: 연결 재사용으로 인해).
   */
  MISDIRECTED_REQUEST = 421,

  /**
   * 요청이 잘 형성되었지만 구문 오류로 인해 따를 수 없습니다.
   */
  UNPROCESSABLE_ENTITY = 422,

  /**
   * 접근하려는 리소스가 잠겨 있습니다.
   */
  LOCKED = 423,

  /**
   * 이전 요청의 실패로 인해 요청이 실패했습니다(예: PROPPATCH).
   */
  FAILED_DEPENDENCY = 424,

  /**
   * 클라이언트가 Upgrade 헤더 필드에 지정된 다른 프로토콜(TLS/1.0 등)로 전환해야 합니다.
   */
  UPGRADE_REQUIRED = 426,

  /**
   * 원본 서버가 요청을 조건부로 처리해야 합니다.
   * "클라이언트가 리소스 상태를 GET하고 수정한 후 서버에 다시 PUT할 때,
   * 그 동안 제3자가 서버의 상태를 수정하여 충돌을 일으키는 '갱신 손실' 문제를 방지하려는 의도입니다."
   */
  PRECONDITION_REQUIRED = 428,

  /**
   * 사용자가 주어진 시간 내에 너무 많은 요청을 보냈습니다. 속도 제한 체계에서 사용하기 위한 것입니다.
   */
  TOO_MANY_REQUESTS = 429,

  /**
   * 서버가 요청을 처리할 수 없을 정도로 개별 헤더 필드 또는 모든 헤더 필드가 너무 큽니다.
   */
  REQUEST_HEADER_FIELDS_TOO_LARGE = 431,

  /**
   * 서버 운영자가 리소스 또는 요청된 리소스를 포함하는 리소스 집합에 대한 접근을 거부하라는 법적 요구를 받았습니다.
   * 코드 451은 소설 "화씨 451도"를 참조하기 위해 선택되었습니다.
   */
  UNAVAILABLE_FOR_LEGAL_REASONS = 451,

  /**
   * 예기치 않은 조건이 발생하여 보다 구체적인 메시지가 적합하지 않은 경우 주어지는 일반 오류 메시지입니다.
   */
  INTERNAL_SERVER_ERROR = 500,

  /**
   * 서버가 요청 방법을 인식하지 못하거나 요청을 이행할 수 있는 능력이 없습니다.
   * 일반적으로 이는 미래의 가용성을 암시합니다(예: 웹 서비스 API의 새로운 기능).
   */
  NOT_IMPLEMENTED = 501,

  /**
   * 서버가 게이트웨이 또는 프록시로 동작 중이며 상위 서버에서 잘못된 응답을 수신했습니다.
   */
  BAD_GATEWAY = 502,

  /**
   * 서버가 현재 사용 불가능합니다(과부하 또는 유지 관리로 인해).
   * 일반적으로 이 상태는 일시적입니다.
   */
  SERVICE_UNAVAILABLE = 503,

  /**
   * 서버가 게이트웨이 또는 프록시로 동작 중이며 상위 서버로부터 적시에 응답을 받지 못했습니다.
   */
  GATEWAY_TIMEOUT = 504,

  /**
   * 서버가 요청에 사용된 HTTP 프로토콜 버전을 지원하지 않습니다.
   */
  HTTP_VERSION_NOT_SUPPORTED = 505,

  /**
   * 요청의 투명한 콘텐츠 협상이 순환 참조를 초래했습니다.
   */
  VARIANT_ALSO_NEGOTIATES = 506,

  /**
   * 서버가 요청을 완료하는 데 필요한 표현을 저장할 수 없습니다.
   */
  INSUFFICIENT_STORAGE = 507,

  /**
   * 서버가 요청을 처리하는 동안 무한 루프를 감지했습니다.
   */
  LOOP_DETECTED = 508,

  /**
   * 서버가 요청을 이행하기 위해 추가 확장이 필요합니다.
   */
  NOT_EXTENDED = 510,

  /**
   * 클라이언트가 네트워크 액세스를 얻기 위해 인증해야 합니다.
   * 네트워크에 대한 접근을 제어하는 인터셉트 프록시에 의해 사용하기 위한 것입니다(예: Wi-Fi 핫스팟을 통해
   * 전체 인터넷 접근을 제공하기 전에 서비스 약관에 동의하도록 요구하는 "캡티브 포털").
   */
  NETWORK_AUTHENTICATION_REQUIRED = 511,
}

export type ErrorResponse<ErrorCode> = {
  status: HttpErrorStatusCode;
  timestamp: string;
  errors: { code: ErrorCode; message: string }[];
};

export type CustomAxiosError<ErrorCode> = AxiosError<ErrorResponse<ErrorCode>>;

/**
 * 공통 에러 코드
 */
export const COMMON_ERROR_CODE = {
  COMMON_001: "COMMON001",
  COMMON_002: "COMMON002",
  COMMON_003: "COMMON003",
  COMMON_004: "COMMON004",
  COMMON_005: "COMMON005",
  COMMON_006: "COMMON006",
  COMMON_009: "COMMON009",
} as const;
