import React from "react";
import { ads_color } from "../../../tokens";
import { IconProps } from "../types";

const HeartLined = (props: IconProps) => {
  const { className, rotate, size = 24, stroke = ads_color.black } = props;
  const style = rotate ? { transform: `rotate(${rotate}deg)` } : {};
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <g id="Icon=heart_lined" clipPath="url(#clip0_1898_1121)">
        <path
          id="Combined-Shape-Copy-14"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.9602 12.6209C19.2308 13.7712 16.9745 16.089 13.1913 19.5744C12.5845 20.1335 11.6672 20.1429 11.0501 19.5958C7.61765 16.5529 5.35432 14.3232 4.26001 12.9067C4.17757 12.807 4.09852 12.7039 4.02306 12.5975C3.38097 11.6902 3 10.5525 3 9.31629C3 6.38018 5.14903 4 7.8 4C9.60722 4 11.1812 5.08017 12 6.71454C12.8188 5.08017 14.3928 4 16.2 4C18.851 4 21 6.38018 21 9.31629C21 10.117 20.8402 10.8763 20.5539 11.5574C20.5448 11.5863 20.5305 11.6159 20.5158 11.6459C20.3624 11.9939 20.1757 12.3206 19.9602 12.6209Z"
          stroke={stroke}
          strokeWidth="1.2"
        />
      </g>
      <defs>
        <clipPath id="clip0_1898_1121">
          <rect width={size} height={size} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default HeartLined;
