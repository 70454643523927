import { createGlobalStyle } from "styled-components";
import { normalize } from "styled-normalize";

export const GlobalStyle = createGlobalStyle`
	${normalize}

	@font-face {
		font-family: "Barlow";
		font-weight: bold;
		//noinspection CssUnknownTarget
		src: url("/fonts/Barlow/Barlow-Bold.woff2") format("woff2"),
		url("/fonts/Barlow/Barlow-Bold.woff") format("woff")
	}

	@font-face {
		font-family: "Barlow";
		font-weight: 600;
		//noinspection CssUnknownTarget
		src: url("/fonts/Barlow/Barlow-SemiBold.woff2") format("woff2"),
		url("/fonts/Barlow/Barlow-SemiBold.woff") format("woff")
	}

	@font-face {
		font-family: "Barlow";
		font-weight: 500;
		//noinspection CssUnknownTarget
		src: url("/fonts/Barlow/Barlow-Medium.woff2") format("woff2"),
		url("/fonts/Barlow/Barlow-Medium.woff") format("woff")
	}

	@font-face {
		font-family: "Barlow";
		font-weight: normal;
		//noinspection CssUnknownTarget
		src: url("/fonts/Barlow/Barlow-Regular.woff2") format("woff2"),
		url("/fonts/Barlow/Barlow-Regular.woff") format("woff")
	}

	@font-face {
		font-family: "NotoSansCJKkr";
		font-weight: bold;
		//noinspection CssUnknownTarget
		src: local("NotoSansCJKkr"), url("/fonts/NotoSansKr/NotoSansKR-Bold.woff2") format("woff2"),
		url("/fonts/NotoSansKr/NotoSansKR-Bold.woff") format("woff")
	}

	@font-face {
		font-family: "NotoSansCJKkr";
		font-weight: 500;
		//noinspection CssUnknownTarget
		src: local("NotoSansCJKkr"), url("/fonts/NotoSansKr/NotoSansKR-Medium.woff2") format("woff2"),
		url("/fonts/NotoSansKr/NotoSansKR-Medium.woff") format("woff")
	}

	@font-face {
		font-family: "NotoSansCJKkr";
		font-weight: normal;
		//noinspection CssUnknownTarget
		src: local("NotoSansCJKkr"), url("/fonts/NotoSansKr/NotoSansKR-Regular.woff2") format("woff2"),
		url("/fonts/NotoSansKr/NotoSansKR-Regular.woff") format("woff")
	}

	@font-face {
		font-family: "Pretendard";
		font-weight: bold;
		font-display: block;
		//noinspection CssUnknownTarget
		src: url("/fonts/Pretendard/Pretendard-Bold.woff2") format("woff2"),
		url("/fonts/Pretendard/Pretendard-Bold.woff2") format("woff")
	}

	@font-face {
		font-family: "Pretendard";
		font-weight: normal;
		font-display: block;
		//noinspection CssUnknownTarget
		src: url("/fonts/Pretendard/Pretendard-Regular.woff2") format("woff2"),
		url("/fonts/Pretendard/Pretendard-Regular.woff2") format("woff")
	}
	
	*,
	*:before,
	*:after {
	    box-sizing: inherit;
	}
	
	a {
	    text-decoration: none;
	    color: inherit;
	}
	
	html,
	body {
		  font-family: "NotoSansCJKkr", sans-serif;
	    height: 100%;
	  	margin: 0;
	    box-sizing: border-box;
      	-webkit-tap-highlight-color: transparent;
	}
	
	body {
		min-width: 1025px;
	}
	
	ul {
	    margin: 0;
	    list-style: none;
	}
	
	ul, li, p, button {
	    padding: 0;
	}
	
	p {
		margin: 0;
	}
	
	input {
	    font-family: inherit;
		appearance: none;
		-webkit-appearance: none;
		-webkit-border-radius: 0;
	}

    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    
	textarea {
		outline: none;
		-webkit-box-shadow: none;
		-moz-box-shadow: none;
		box-shadow: none;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
      	-webkit-border-radius: 0;
    }
	
	#shoplive-shortform .csl-sf.csl-TemplateShorts.grid-2.horizontal {
		overflow-x: hidden;
	}
`;
