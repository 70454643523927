// isFilter 타입
import { CSSProperties } from "react";
import { ValueOf } from "./utils";

export const IS_FILTER = {
  // 미사용
  UNUSED: 0,
  // 사용
  USED: 1,
} as const;

export type Sort<T> = `${"" | "-"}T`;

export type NumBoolean = 0 | 1;

export type Column<T> = {
  key: keyof T;
  title: string;
};

export type UploadImage = {
  isLoading: boolean;
  src: string;
};

export type ImageResponseType = {
  imageUrl: string;
  width: number;
  height: number;
};

export const ERROR_TYPE = {
  404: 1,
  ERROR_TOAST: 2,
  NORMAL_TOAST: 3,
  ONE_BUTTON_MODAL: 4,
  TWO_BUTTON_MODAL: 5,
} as const;

export type ErrorHandlerType =
  | { type: (typeof ERROR_TYPE)["404"] }
  | { type: (typeof ERROR_TYPE)["ERROR_TOAST"]; msg: string }
  | { type: (typeof ERROR_TYPE)["NORMAL_TOAST"]; msg: string }
  | {
      type: (typeof ERROR_TYPE)["ONE_BUTTON_MODAL"];
      msg: string;
      subMsg?: string;
      btnText?: string;
      onClickBtn?: () => void;
    }
  | {
      type: (typeof ERROR_TYPE)["TWO_BUTTON_MODAL"];
      msg: string;
      subMsg?: string;
      leftButtonText?: string;
      onClickLeftBtn?: () => void;
      rightButtonText?: string;
      onClickRightBtn?: () => void;
    }
  | Function;

export type Response<T> = {
  status: number;
  data?: T;
};

export const OG_TYPES = {
  WEBSITE: "website",
  PRODUCT: "product",
  ARTICLE: "article",
  BOOK: "book",
  PROFILE: "profile",
} as const;

export type OgType = ValueOf<typeof OG_TYPES>;

export interface IBaseIconProps {
  className?: string;
  width?: number;
  height?: number;
  fill?: string;
  style?: CSSProperties;
}

