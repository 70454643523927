import axios from "axios";
import { takeEvery, put, throttle, select } from "redux-saga/effects";
import marketingService, { eventType } from "@amondz/marketing-service";

import homeAPI from "@services/apis/homeAPI";
import { ApiHelperResponseType } from "@store/modules/common/types";
import { AuthStateType } from "@store/modules/auth";
import { RootStateType } from "@store/modules";
import asyncApiHelper from "@lib/utility/apiHelper";
import { setProductWishList } from "@lib/utility/gtm";
import baseAPI from "@services/apis/baseAPI";
import { LIKE_EVENT_THROTTLE_SPEED, LIKE_STATUS } from "@constants/service/common/common";
import brazeAttribute from "@lib/utility/braze";
import {
  DETAIL_LIKE_PRODUCT,
  detailLikeProductAsync,
  GET_WIDGET_DETAIL,
  getWidgetDetailAsync,
  SHARE_EVENT_DETAIL_LINK,
  shareEventDetailLink,
} from "./actions";

/**
 * 위젯 상세 SAGA
 */
function* getWidgetDetailSaga(action: ReturnType<typeof getWidgetDetailAsync.request>) {
  try {
    // 서버 요청 헬퍼
    const result: ApiHelperResponseType = yield asyncApiHelper(homeAPI.getWidgetDetail, action.payload);

    yield put(getWidgetDetailAsync.success(result));
  } catch (e) {
    if (axios.isAxiosError(e)) {
      yield put(getWidgetDetailAsync.failure(e));
    } else {
      console.error(e);
    }
  }
}

/**
 * 홈 상세 좋아요 버튼 클릭 SAGA
 */
function* detailLikeProductSaga(action: ReturnType<typeof detailLikeProductAsync.request>) {
  try {
    // 서버 요청 헬퍼
    const result: ApiHelperResponseType = yield asyncApiHelper(baseAPI.likeProduct, action.payload);

    const { product } = action.payload;
    const { userAuthState }: AuthStateType = yield select((state: RootStateType) => state.auth);
    result.data.code === LIKE_STATUS.LIKE
      ? marketingService(eventType.likeProduct, {
          userId: userAuthState.data?.userId,
          productId: product.productId,
          productName: product.productName,
        })
      : marketingService(eventType.unLikeProduct, {
          userId: userAuthState.data?.userId,
          productId: product.productId,
          productName: product.productName,
        });
    setProductWishList(result.data.code, product);

    yield put(
      detailLikeProductAsync.success({
        productId: action.payload.productId,
        targetIndex: action.payload.targetIndex,
        code: result.data.code,
      }),
    );
  } catch (e) {
    if (axios.isAxiosError(e)) {
      yield put(detailLikeProductAsync.failure(e));
    } else {
      console.error(e);
    }
  }
}

/**
 * 기획전 상세 링크 공유 SAGA
 */
function* shareEventDetailLinkSaga(action: ReturnType<typeof shareEventDetailLink.request>) {
  try {
    // 서버 요청 헬퍼
    yield asyncApiHelper(homeAPI.shareEventDetailLink, action.payload);
  } catch (e) {
    console.log(e);
  }
}

export function* homeSaga() {
  // 위젯 상세
  yield takeEvery(GET_WIDGET_DETAIL.REQUEST, getWidgetDetailSaga);

  // 홈 상세 좋아요 버튼 클릭
  yield throttle(LIKE_EVENT_THROTTLE_SPEED, DETAIL_LIKE_PRODUCT.REQUEST, detailLikeProductSaga);

  // 기획전 상세 링크 공유
  yield takeEvery(SHARE_EVENT_DETAIL_LINK.REQUEST, shareEventDetailLinkSaga);
}
