import React, { CSSProperties, useState } from "react";
import DefaultAvatarImg from "./DefaultAvatarImg";
import { AvatarStyle } from "./style";

interface AvatarProps {
  className?: string;
  alt?: string;
  src?: string;
  srcSet?: string;
  size?: "large" | "normal" | "small" | number;
  shape?: "round" | "round-square" | "square";
  style?: CSSProperties;
}

const Avatar = ({ className, alt, src, srcSet, size = "normal", shape = "round-square", style }: AvatarProps) => {
  const [error, setError] = useState(false);
  const handleImageError = () => {
    setError(true);
  };
  return (
    <AvatarStyle className={className} size={size} shape={shape} style={style}>
      {src && !error ? <img alt={alt} src={src} srcSet={srcSet} onError={handleImageError} /> : <DefaultAvatarImg />}
    </AvatarStyle>
  );
};

export default Avatar;
