import {
  CouponGeneratedsCreateBulkResponseDto,
  CouponGeneratedsCreateByIdRequestBodyDto,
  CouponGeneratedsCreateOneResponseDto,
} from "@amondz/apis-v2";

import { superFetchV2 } from "../superFetchV2";

export const couponApiV2 = {
  // 단일 쿠폰 발급 요청
  downloadSingleCoupon: (data: CouponGeneratedsCreateByIdRequestBodyDto): Promise<CouponGeneratedsCreateOneResponseDto> => superFetchV2.post(`/coupon-generateds`, {
    couponId: data.couponId,
  }),
  // 다중 쿠폰 발급 요청
  downloadBulkCoupons : (data: CouponGeneratedsCreateByIdRequestBodyDto[]): Promise<CouponGeneratedsCreateBulkResponseDto> => superFetchV2.post(`/coupon-generateds/bulk`, data),
};
