import TagManager from "react-gtm-module";
import { GTM_EVENT, Payload, User } from "../types";

export function initialize(userId: number | null, userName: string | null) {
  if (!process.env.NEXT_PUBLIC_GTM_ID) throw new Error("환경변수에 gtm id가 존재하지 않습니다");

  TagManager.initialize({
    gtmId: process.env.NEXT_PUBLIC_GTM_ID,
    dataLayer: { userId: userId !== null ? String(userId) : null, userName: userName },
  });
}

export function dataLayer(event: string, payload?: Payload) {
  let newPayload: Payload = { ...payload };
  if (payload) {
    for (const key in payload) {
      if (Array.isArray(payload[key])) {
        newPayload[`${key}Str`] = payload[key].toString();
      }
    }
  }

  TagManager.dataLayer({
    dataLayer: {
      event,
      payload: newPayload,
    },
  });
}

export function changeUser({ userId, userName }: User) {
  const findLastUserId = (): string | null => {
    if (!Array.isArray(window.dataLayer)) return null;
    return window.dataLayer.reduce((a, c) => {
      if (c.userId) return c.userId;
      else return a;
    }, null);
  };

  const lastUserId = findLastUserId();
  const strUserId = String(userId);
  if (strUserId === lastUserId) return;

  TagManager.dataLayer({
    dataLayer: {
      event: GTM_EVENT.CHANGE_USER,
      userId: userId !== null ? strUserId : null,
      userName,
    },
  });
}

export function logout() {
  TagManager.dataLayer({
    dataLayer: {
      event: GTM_EVENT.LOGOUT,
    },
  });
}
