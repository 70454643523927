import { AxiosError } from "axios";
import { createAsyncAction, createAction } from "typesafe-actions";

import { ApiHelperResponseType } from "@store/modules/common/types";
import { ALREADY_JOIN_TYPE, LOGIN_TYPE } from "@constants/enum/authEnums";
import { UserProfileStateType, UserAuthStateType, AuthStateType, ErrorStateType } from "./types";

// ******** Auth Common Type **********
// SNS 인증 공통 타입
type SocialAuthCommonType = {
  id: string;
  email?: string | null;
  name?: string | null;
  socialLoginAccessToken?: string;
};

// sms 인증 요청 공통 타입
export type SmsAuthRequestCommonType = {
  account: string;
  cellPhone: string;
  authCode: string;
};

// sms 인증 응답 공통 타입
type SmsAuthResponseCommonType = {
  isSendSmsAuthCode: boolean;
  status: number;
};
// ***********************************

// ****** 강제 로그아웃 액션 설정 ******
export const FORCE_LOGOUT = "auth/FORCE_LOGOUT";
export type ForceLogoutType = Pick<AuthStateType, "isForceLogout">;
export const forceLogout = createAction(FORCE_LOGOUT)<ForceLogoutType>();
// ****** 강제 로그아웃 액션 설정 완료 ******

export const SET_AUTH = "auth/SET_AUTH";
export type AuthType = Pick<AuthStateType, "isLoggedIn">;
export const setAuth = createAction(SET_AUTH)<AuthType>();

// SNS 가입 & 로그인
export const SOCIAL_LOGIN_AND_JOIN = {
  REQUEST: "auth/SOCIAL_LOGIN_AND_JOIN_REQUEST",
  SUCCESS: "auth/SOCIAL_LOGIN_AND_JOIN_SUCCESS",
  FAILURE: "auth/SOCIAL_LOGIN_AND_JOIN_FAILURE",
};

export type SocialLoginAndJoinRequestType = SocialAuthCommonType & {
  loginType: LOGIN_TYPE;
  cellPhone?: string;
  birthday?: Date | string | null;
  gender?: number;
  referralCode?: string | null;
  smsReceive?: number;
  emailReceive?: number;
  rtnUrl?: string;
  isJoinRequest?: boolean;
};

export type SocialLoginAndJoinSuccessType = UserProfileStateType & {
  alreadyJoin: ALREADY_JOIN_TYPE; // 이미 가입한 유저 여부 (0: 신규 가입, 1: 기가입자)
  isNewMember: number; // 신규 가입 여부 (0: 재가입, 1: 신규 가입)
};

export const socialLoginAndJoinAsync = createAsyncAction(
  SOCIAL_LOGIN_AND_JOIN.REQUEST,
  SOCIAL_LOGIN_AND_JOIN.SUCCESS,
  SOCIAL_LOGIN_AND_JOIN.FAILURE,
)<SocialLoginAndJoinRequestType, SocialLoginAndJoinSuccessType, AxiosError>();

// ****** SNS 가입 준비 액션 설정 ******
export const SOCIAL_JOIN_PREPARATION = "auth/SOCIAL_JOIN_PREPARATION";
export type SocialJoinPreparationType = SocialAuthCommonType & {
  snsLoginType?: LOGIN_TYPE | null;
};
export const socialJoinPreparation = createAction(SOCIAL_JOIN_PREPARATION)<SocialJoinPreparationType>();

export const SOCIAL_JOIN_PREPARATION_RESET = "auth/SOCIAL_JOIN_PREPARATION_RESET";
export const socialJoinPreparationReset = createAction(SOCIAL_JOIN_PREPARATION_RESET)();
// ****** SNS 가입 준비 액션 설정 완료 ******

// ****** 이메일 로그인 액션 설정 ******
export const EMAIL_LOGIN = {
  REQUEST: "auth/EMAIL_LOGIN_REQUEST",
  SUCCESS: "auth/EMAIL_LOGIN_SUCCESS",
  FAILURE: "auth/EMAIL_LOGIN_FAILURE",
};

export type EmailLoginRequestType = {
  email: string;
  password: string;
  isLogInRemember: boolean;
  rtnUrl?: string;
};

export const emailLoginAsync = createAsyncAction(EMAIL_LOGIN.REQUEST, EMAIL_LOGIN.SUCCESS, EMAIL_LOGIN.FAILURE)<
  EmailLoginRequestType,
  ApiHelperResponseType,
  AxiosError
>();
// ****** 이메일 로그인 액션 설정 완료 ******

// ****** 로그아웃 액션 설정 ******
export const LOG_OUT = {
  REQUEST: "auth/LOG_OUT_REQUEST",
  SUCCESS: "auth/LOG_OUT_SUCCESS",
  FAILURE: "auth/LOG_OUT_FAILURE",
};

export type LogOutRequestType = {
  isApiRequest?: boolean;
};

export const LogOutAsync = createAsyncAction(LOG_OUT.REQUEST, LOG_OUT.SUCCESS, LOG_OUT.FAILURE)<
  LogOutRequestType,
  undefined,
  AxiosError
>();
// export const LOG_OUT = "auth/LOG_OUT";
// export const logOut = createAction(LOG_OUT.SUCCESS)();
// ****** 로그아웃 액션 설정 완료 ******

// ****** 계정 찾기 SMS 인증 번호 요청 액션 설정 *****
export const SMS_AUTH_ID = {
  REQUEST: "auth/SMS_AUTH_ID_REQUEST",
  SUCCESS: "auth/SMS_AUTH_ID_SUCCESS",
  FAILURE: "auth/SMS_AUTH_ID_FAILURE",
};

export type SmsAuthIdRequestType = Pick<SmsAuthRequestCommonType, "cellPhone">;

export const smsAuthIdAsync = createAsyncAction(SMS_AUTH_ID.REQUEST, SMS_AUTH_ID.SUCCESS, SMS_AUTH_ID.FAILURE)<
  SmsAuthIdRequestType,
  SmsAuthResponseCommonType,
  AxiosError
>();
// ****** 계정 찾기 SMS 인증 번호 요청 액션 설정 완료 *****

// ****** 계정 찾기 SMS 코드 인증 요청 액션 설정 *****
export const SMS_AUTH_ID_CONFIRM = {
  REQUEST: "auth/SMS_AUTH_ID_CONFIRM_REQUEST",
  SUCCESS: "auth/SMS_AUTH_ID_CONFIRM_SUCCESS",
  FAILURE: "auth/SMS_AUTH_ID_CONFIRM_FAILURE",
};

export type SmsCodeAuthRequestType = Omit<SmsAuthRequestCommonType, "account">;

export type SmsCodeAuthSuccessType = Pick<SmsAuthResponseCommonType, "status"> & {
  loginType: number | null;
  account: number | null;
};

export const smsCodeAuthAsync = createAsyncAction(
  SMS_AUTH_ID_CONFIRM.REQUEST,
  SMS_AUTH_ID_CONFIRM.SUCCESS,
  SMS_AUTH_ID_CONFIRM.FAILURE,
)<SmsCodeAuthRequestType, SmsCodeAuthSuccessType, AxiosError>();
// ****** 계정 찾기 SMS 코드 인증 요청 액션 설정 완료 *****

// ****** 비밀번호 찾기 SMS 인증 번호 요청 액션 설정 *****
export const SMS_AUTH_PASSWORD = {
  REQUEST: "auth/SMS_AUTH_PASSWORD_REQUEST",
  SUCCESS: "auth/SMS_AUTH_PASSWORD_SUCCESS",
  FAILURE: "auth/SMS_AUTH_PASSWORD_FAILURE",
};

export type SmsAuthPasswordRequestType = Omit<SmsAuthRequestCommonType, "authCode">;

export const smsAuthPasswordAsync = createAsyncAction(
  SMS_AUTH_PASSWORD.REQUEST,
  SMS_AUTH_PASSWORD.SUCCESS,
  SMS_AUTH_PASSWORD.FAILURE,
)<SmsAuthPasswordRequestType, SmsAuthResponseCommonType, AxiosError>();
// ****** 비밀번호 찾기 SMS 인증 번호 요청 액션 설정 완료 *****

// ****** 비밀번호 찾기 SMS 코드 인증 요청 액션 설정 *****
export const SMS_AUTH_PASSWORD_CONFIRM = {
  REQUEST: "auth/SMS_AUTH_PASSWORD_CONFIRM_REQUEST",
  SUCCESS: "auth/SMS_AUTH_PASSWORD_CONFIRM_SUCCESS",
  FAILURE: "auth/SMS_AUTH_PASSWORD_CONFIRM_FAILURE",
};

export type SmsAuthPasswordConfirmSuccessType = Pick<SmsAuthResponseCommonType, "status"> & {
  account: string | null;
  cellPhone: string | null;
  authCode: string | null;
};

export const smsAuthPasswordConfirmAsync = createAsyncAction(
  SMS_AUTH_PASSWORD_CONFIRM.REQUEST,
  SMS_AUTH_PASSWORD_CONFIRM.SUCCESS,
  SMS_AUTH_PASSWORD_CONFIRM.FAILURE,
)<SmsAuthRequestCommonType, SmsAuthPasswordConfirmSuccessType, AxiosError>();
// ****** 비밀번호 찾기 SMS 코드 인증 요청 액션 설정 완료 *****

// ****** 비밀번호 변경 요청 액션 설정 *****
export const MEMBER_PASSWORD_UPDATE = {
  REQUEST: "auth/MEMBER_PASSWORD_UPDATE_REQUEST",
  SUCCESS: "auth/MEMBER_PASSWORD_UPDATE_SUCCESS",
  FAILURE: "auth/MEMBER_PASSWORD_UPDATE_FAILURE",
};

export type MemberPasswordUpdateRequestType = SmsAuthRequestCommonType & {
  password: string;
};

export type MemberPasswordUpdateSuccessType = Pick<SmsAuthResponseCommonType, "status">;

export const memberPasswordUpdateAsync = createAsyncAction(
  MEMBER_PASSWORD_UPDATE.REQUEST,
  MEMBER_PASSWORD_UPDATE.SUCCESS,
  MEMBER_PASSWORD_UPDATE.FAILURE,
)<MemberPasswordUpdateRequestType, MemberPasswordUpdateSuccessType, AxiosError>();
// ****** 비밀번호 변경 요청 액션 설정 완료 *****

// ****** 회원가입 SMS 인증 액션 설정 *****
export const SMS_AUTH_JOIN = {
  REQUEST: "auth/SMS_AUTH_JOIN_REQUEST",
  SUCCESS: "auth/SMS_AUTH_JOIN_SUCCESS",
  FAILURE: "auth/SMS_AUTH_JOIN_FAILURE",
};

export type SmsAuthJoinRequestType = Omit<SmsAuthRequestCommonType, "account" | "authCode">;

export type SmsAuthJoinSuccessType = SmsAuthResponseCommonType & {
  loginType: number | null;
};

export const smsAuthJoinAsync = createAsyncAction(SMS_AUTH_JOIN.REQUEST, SMS_AUTH_JOIN.SUCCESS, SMS_AUTH_JOIN.FAILURE)<
  SmsAuthJoinRequestType,
  SmsAuthJoinSuccessType,
  AxiosError
>();
// ****** 회원가입 SMS 인증 액션 설정 완료 *****

// ****** 회원가입 SMS 코드 인증 액션 설정 *****
export const SMS_AUTH_JOIN_CONFIRM = {
  REQUEST: "auth/SMS_AUTH_JOIN_CONFIRM_REQUEST",
  SUCCESS: "auth/SMS_AUTH_JOIN_CONFIRM_SUCCESS",
  FAILURE: "auth/SMS_AUTH_JOIN_CONFIRM_FAILURE",
};

export type SmsAuthJoinConfirmRequestType = Omit<SmsAuthRequestCommonType, "account"> & {
  email?: string;
};

export type SmsAuthJoinConfirmSuccessType = {
  currentLoginType: typeof LOGIN_TYPE | null;
  email?: string | null;
  cellPhone: string | null;
};

export const smsAuthJoinConfirmAsync = createAsyncAction(
  SMS_AUTH_JOIN_CONFIRM.REQUEST,
  SMS_AUTH_JOIN_CONFIRM.SUCCESS,
  SMS_AUTH_JOIN_CONFIRM.FAILURE,
)<SmsAuthJoinConfirmRequestType, SmsAuthJoinConfirmSuccessType, AxiosError>();
// ****** 회원가입 SMS 코드 인증 액션 설정 완료 *****

// ****** 회원가입 약관 동의 ******
export const AUTH_JOIN_AGREE = "auth/AUTH_JOIN_AGREE";
export const authJoinAgree = createAction(AUTH_JOIN_AGREE)();
// ****** 회원가입 약관 동의 액션 설정 완료 ******

// ****** 회원가입 비밀번호 설정 액션 설정 ******
export const AUTH_JOIN_PASSWORD = "auth/AUTH_JOIN_PASSWORD";
export type AuthJoinPasswordType = {
  password: string;
};
export const authJoinPassword = createAction(AUTH_JOIN_PASSWORD)<AuthJoinPasswordType>();
// ****** 회원가입 비밀번호 설정 액션 설정 완료 ******

// ****** 이메일 회원가입 액션 설정 ******
export const MEMBER_EMAIL_JOIN = {
  REQUEST: "auth/MEMBER_EMAIL_JOIN_REQUEST",
  SUCCESS: "auth/MEMBER_EMAIL_JOIN_SUCCESS",
  FAILURE: "auth/MEMBER_EMAIL_JOIN_FAILURE",
};

export type MemberEmailJoinRequestType = Pick<SmsAuthRequestCommonType, "cellPhone"> & {
  email: string;
  password: string;
  birthday: Date | string | null;
  gender: number;
  smsReceive: number;
  emailReceive: number;
  referralCode: null;
};

export type MemberEmailJoinSuccessType = Pick<SmsAuthResponseCommonType, "status">;

export const memberEmailJoinAsync = createAsyncAction(
  MEMBER_EMAIL_JOIN.REQUEST,
  MEMBER_EMAIL_JOIN.SUCCESS,
  MEMBER_EMAIL_JOIN.FAILURE,
)<MemberEmailJoinRequestType, MemberEmailJoinSuccessType, AxiosError>();
// ****** 이메일 회원가입 액션 설정 완료 ******

// 유저 인증
export const USER_AUTH = {
  REQUEST: "auth/USER_AUTH_REQUEST",
  SUCCESS: "auth/USER_AUTH_SUCCESS",
  FAILURE: "auth/USER_AUTH_FAILURE",
};

export const userAuthAsync = createAsyncAction(USER_AUTH.REQUEST, USER_AUTH.SUCCESS, USER_AUTH.FAILURE)<
  undefined,
  UserAuthStateType,
  AxiosError
>();

// ****** 장바구니 개수 업데이트 액션 설정 ******
export const UPDATE_CART_COUNT = "auth/UPDATE_CART_COUNT";
export type UpdateCartCountType = {
  addCount: number;
};
export const updateCartCount = createAction(UPDATE_CART_COUNT)<UpdateCartCountType>();
// ****** 장바구니 개수 업데이트 액션 설정 완료 ******

// ****** 유저 인증 에러 상태 액션 설정 ******
export const MEMBER_ERROR_UPDATE = "auth/MEMBER_ERROR_UPDATE";
export type MemberErrorUpdateType = ErrorStateType;
export const memberErrorUpdate = createAction(MEMBER_ERROR_UPDATE)<MemberErrorUpdateType>();

export const MEMBER_ERROR_RESET = "auth/MEMBER_ERROR_RESET";
export const memberErrorReset = createAction(MEMBER_ERROR_RESET)();
// ****** 유저 인증 에러 상태 액션 설정 완료 ******
