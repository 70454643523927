import superFetch from "@amondz/apis-v1";
import superFetchV2 from "@amondz/apis-v2";
import {
  SocialLoginAndJoinRequestType,
  EmailLoginRequestType,
  SmsAuthIdRequestType,
  SmsCodeAuthRequestType,
  SmsAuthPasswordRequestType,
  SmsAuthJoinRequestType,
  MemberPasswordUpdateRequestType,
  SmsAuthJoinConfirmRequestType,
  MemberEmailJoinRequestType,
  SmsAuthRequestCommonType,
} from "@store/modules/auth";

const authAPI = {
  // sns 로그인 & 가입 요청 API
  snsLoginAndJoin: async (data: SocialLoginAndJoinRequestType) => {
    return superFetchV2.post("/members/sns", data);
  },

  // email 로그인 요청 API
  emailLogin: async (data: EmailLoginRequestType) => {
    return superFetch.post("/member/email/login/web/v1", data);
  },

  // 로그아웃 요청 API
  logOut: async (data: EmailLoginRequestType) => {
    return superFetch.post("/member/logout/web/v1", data);
  },

  // 계정 찾기 SMS 인증 요청 API
  smsAuthRequest: async (data: SmsAuthIdRequestType) => {
    return superFetch.post("/sms/auth/account/request/web/v1", data);
  },

  // 계정 찾기 SMS 코드 인증 요청 API
  smsCodeAuthRequest: async (data: SmsCodeAuthRequestType) => {
    return superFetch.post("/sms/auth/account/confirm/web/v1", data);
  },

  // 비밀번호 찾기 SMS 인증 요청 API
  smsAuthPasswordRequest: async (data: SmsAuthPasswordRequestType) => {
    return superFetch.post("/sms/auth/password/request/web/v1", data);
  },

  // 비밀번호 찾기 SMS 코드 인증 요청 API
  smsAuthPasswordConfirm: async (data: SmsAuthRequestCommonType) => {
    return superFetch.post("/sms/auth/password/confirm/web/v1", data);
  },

  // 비밀번호 변경 요청 API
  memberPasswordUpdate: async (data: MemberPasswordUpdateRequestType) => {
    return superFetch.post("/member/email/password/update/web/v2", data);
  },

  // 회원가입 SMS 인증 요청 API
  smsAuthJoin: async (data: SmsAuthJoinRequestType) => {
    return superFetchV2.post("/members/certification/join/sms/request", data);
  },

  // 회원가입 SMS 코드 인증 요청 API
  smsAuthJoinConfirm: async (data: SmsAuthJoinConfirmRequestType) => {
    return superFetchV2.post("/members/certification/join/sms/confirm", data);
  },

  // 이메일 회원가입 요청
  memberEmailJoin: async (data: MemberEmailJoinRequestType) => {
    return superFetch.post("/member/email/join/web/v2", data);
  },

  // 유저 인증 요청
  userAuth: async () => {
    return superFetch.post("/member/profile/web/v1");
  },
};

export default authAPI;
