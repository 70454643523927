// TODO: 작업과 무관하지만, 타입에러가 발생해 임시적으로 주석으로 린트 룰 무효화, 다른 브랜치에서 작업
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useRouter } from "next/router";

import { dataLayer } from "./utils";
import { GTM_EVENT, GTM_EVENT_PROPERTY } from "./types";
import { PAGE_NAME } from "./constants/pageName";

/**
 * 트리거 조건에 맞춰 gtm dataLayer 를 push 하는 hook
 */
export const useGtmEventLogging = () => {
  const router = useRouter();
  const pageName = PAGE_NAME[router?.route];

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const trigger = <GtmEvent extends GTM_EVENT>(event: GtmEvent, property?: GTM_EVENT_PROPERTY[GtmEvent]) => {
    dataLayer(event, { pageName, ...(property ?? {}) });
  };

  return {
    trigger,
  };
};
