import React from "react";
import { ads_color } from "../../../tokens";
import { IconProps } from "../types";

export const ArrowHeadLined2 = (props: IconProps) => {
  const { className, rotate, size = 16, fill = ads_color.black, stroke = ads_color.black } = props;
  const style = rotate ? { transform: `rotate(${rotate}deg)` } : {};
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.02939 6L4 6.97699L8 11L12 6.97699L10.9706 6L8 8.9877L5.02939 6Z" fill={fill} />
    </svg>
  );
};
