/* tslint:disable */
/* eslint-disable */
/**
 * AMONDZ-USER-BACKEND-V2
 * AMONDZ-USER-BACKEND-V2 API 문서입니다.
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { ProductAttachmentResponseDto } from './product-attachment-response-dto';
// May contain unused imports in some cases
// @ts-ignore
import { ProductFindOneResponseBrandDto } from './product-find-one-response-brand-dto';

/**
 * 
 * @export
 * @interface ProductFindOneResponseProductDto
 */
export interface ProductFindOneResponseProductDto {
    /**
     * 상품 식별 id
     * @type {number}
     * @memberof ProductFindOneResponseProductDto
     */
    'id': number;
    /**
     * 상품명
     * @type {string}
     * @memberof ProductFindOneResponseProductDto
     */
    'name': string;
    /**
     * 오늘 출발 여부
     * @type {boolean}
     * @memberof ProductFindOneResponseProductDto
     */
    'isTodayDelivery': boolean;
    /**
     * 선물하기 여부
     * @type {boolean}
     * @memberof ProductFindOneResponseProductDto
     */
    'isGift': boolean;
    /**
     * 상품 실제 판매 가격  (원)
     * @type {number}
     * @memberof ProductFindOneResponseProductDto
     */
    'salesPrice': number;
    /**
     * 상품 판매가 (원)
     * @type {number}
     * @memberof ProductFindOneResponseProductDto
     */
    'originPrice': number;
    /**
     * 상품 금액 할인률 타입( amount : 금액 할인 , percent : % 할인 )
     * @type {string}
     * @memberof ProductFindOneResponseProductDto
     */
    'discountType': ProductFindOneResponseProductDtoDiscountTypeEnum;
    /**
     * 상품 할인 비율 (%)
     * @type {number}
     * @memberof ProductFindOneResponseProductDto
     */
    'discountRate'?: number;
    /**
     * 상품 할인 금액 (원)
     * @type {number}
     * @memberof ProductFindOneResponseProductDto
     */
    'discountAmount'?: number;
    /**
     * 상품 상태 ( nowOnSale : 판매중 , soldOut : 품절 )
     * @type {string}
     * @memberof ProductFindOneResponseProductDto
     */
    'status': ProductFindOneResponseProductDtoStatusEnum;
    /**
     * 상품 이미지
     * @type {Array<ProductAttachmentResponseDto>}
     * @memberof ProductFindOneResponseProductDto
     */
    'attachments': Array<ProductAttachmentResponseDto>;
    /**
     * 유저의 상품 좋아요 여부
     * @type {boolean}
     * @memberof ProductFindOneResponseProductDto
     */
    'isLike': boolean;
    /**
     * 상품 좋아요 수
     * @type {number}
     * @memberof ProductFindOneResponseProductDto
     */
    'likeCount': number;
    /**
     * 
     * @type {ProductFindOneResponseBrandDto}
     * @memberof ProductFindOneResponseProductDto
     */
    'brand': ProductFindOneResponseBrandDto;
}

export const ProductFindOneResponseProductDtoDiscountTypeEnum = {
    Amount: 'amount',
    Percent: 'percent',
    Null: 'null'
} as const;

export type ProductFindOneResponseProductDtoDiscountTypeEnum = typeof ProductFindOneResponseProductDtoDiscountTypeEnum[keyof typeof ProductFindOneResponseProductDtoDiscountTypeEnum];
export const ProductFindOneResponseProductDtoStatusEnum = {
    NowOnSale: 'nowOnSale',
    SoldOut: 'soldOut'
} as const;

export type ProductFindOneResponseProductDtoStatusEnum = typeof ProductFindOneResponseProductDtoStatusEnum[keyof typeof ProductFindOneResponseProductDtoStatusEnum];


