import {useEffect, useRef, useState} from "react";
import {SCROLL_STATUS, useScroll} from "@amondz/use-scroll";

//페이지 스크롤이 탭 영역 보다 아래로 내려갔는지 체크하는 훅
export const useScrollOverTabStatus = () => {
    const tabRef = useRef<HTMLDivElement>(null);
    const { getScrollStatus } = useScroll();
    const [isScrollOverTab, setIsScrollOverTab] = useState(false);

    useEffect(() => {
        const handleScrollEvent = () => {
            if(tabRef.current) setIsScrollOverTab(getScrollStatus(tabRef.current) === SCROLL_STATUS.AFTER)
        };

        window.addEventListener("scroll", handleScrollEvent);
        return () => window.removeEventListener("scroll", handleScrollEvent);
    }, [isScrollOverTab]);

    return {isScrollOverTab,tabRef}
}
