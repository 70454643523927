import TagManager from "react-gtm-module";

import { OrderInfoType } from "./types";

type payloadWithoutArr = { [key: string]: string | number | boolean | Date };

class GtmEvent {
  constructor() {}

  /**
   * gtm 이벤트를 관리하는 함수
   * @param event
   * @param payload
   */
  private tagManager(event: string, payload?: any) {
    let payloadWithoutArr: payloadWithoutArr = {};
    if (payload) {
      for (const key in payload) {
        if (Array.isArray(payload[key])) {
          payloadWithoutArr[key] = payload[key].toString();
        } else {
          payloadWithoutArr[key] = payload[key];
        }
      }
    }

    setTimeout(() => {
      TagManager.dataLayer({
        dataLayer: {
          event,
          payload: payloadWithoutArr,
        },
      });
    }, 0);
  }

  viewHome(isLoggedIn: boolean, userId?: number | null) {
    this.tagManager("viewMainHome", { isLoggedIn, userId });
  }

  beginPayment(params: OrderInfoType) {
    this.tagManager("beginPayment", params);
  }

  // braze - 결제하기 버튼 클릭 시(나이스페이 진입)
  submitPayment(params: OrderInfoType) {
    this.tagManager("submitPayment", params);
  }

  // 참여형이벤트 응모 완료
  applyEvent(code: string) {
    this.tagManager("applyEvent", { code });
  }
}

export default GtmEvent;
