import cookies from "@lib/utility/cookies";

// Expired Date(current: 1 Day)를 설정하여 CookieName을 이름으로 하는  쿠키 생성
export const setCookie = (cookieName: string) => {
  const now = new Date();
  const ExpiredDate = new Date();

  ExpiredDate.setHours(now.getHours() + 24);
  cookies.set(cookieName, 1, { expires: ExpiredDate });
};

// CookieName을 이름으로 가지는 쿠키 반환
export const getCookie = (cookieName: string) => {
  return cookies.get(cookieName);
};
