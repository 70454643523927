class BrazeCAEvent {
  constructor() {}

  private addCustomAttribute(key: string, value: string) {
    if (window?.appboy) {
      window.appboy.getUser().addToCustomAttributeArray(key, value);
    }
  }
  private removeCustomAttribute(key: string, value: string) {
    if (window?.appboy) {
      window.appboy.getUser().removeFromCustomAttributeArray(key, value);
    }
  }
  private setCustomAttributeList(key: string, value: string[]) {
    if (window?.appboy) {
      window.appboy.getUser().setCustomUserAttribute(key, value);
    }
  }

  /**
   * brand_wishlist, brand_wishlist_by_name - CA array 에 추가
   * - 브랜드 상세 좋아요
   * - 브랜드 리스트 좋아요
   * - 상품 상세 하단 좋아요
   * - 좋아요 리스트>브랜드 탭 좋아요
   * @param id
   * @param name
   */
  addBrandWishlist(id: string, name: string) {
    this.addCustomAttribute("brand_wishlist", id);
    this.addCustomAttribute("brand_wishlist_by_name", name);
  }
  /**
   * brand_wishlist, brand_wishlist_by_name - CA array 에 제거
   * - 브랜드 상세 좋아요 해제
   * - 브랜드 리스트 좋아요 해제
   * - 상품 상세 하단 좋아요 해제
   * - 좋아요 리스트>브랜드 탭 좋아요 해제
   * @param id
   * @param name
   */
  removeBrandWishlist(id: string, name: string) {
    this.removeCustomAttribute("brand_wishlist", id);
    this.removeCustomAttribute("brand_wishlist_by_name", name);
  }
  /**
   * product_wishlist, product_wishlist_by_name - CA array 에 추가
   * - 기획전 내 상품 좋아요
   * - 브랜드 상세 내 상품 좋아요
   * - 쿠폰 적용 가능 상품 내 상품 좋아요
   * - 상품상세 내 상품 좋아요
   * - 상품상세>대표이미지 확대 좋아요
   * - 홈 위젯 내 상품 좋아요
   * - 위젯 상세 내 상품 좋아요
   * - 검색>상품 탭>상품리스트 좋아요
   * - 좋아요 리스트 내 상품 좋아요
   * @param id
   * @param name
   */
  addProductWishlist(id: string, name: string) {
    this.addCustomAttribute("product_wishlist", id);
    this.addCustomAttribute("product_wishlist_by_name", name);
  }
  /**
   * product_wishlist, product_wishlist_by_name - CA array 에 제거
   * - 기획전 내 상품 좋아요 해제
   * - 브랜드 상세 내 상품 좋아요 해제
   * - 쿠폰 적용 가능 상품 내 상품 좋아요 해제
   * - 상품상세 내 상품 좋아요 해제
   * - 상품상세>대표이미지 확대 좋아요 해제
   * - 홈 위젯 내 상품 좋아요 해제
   * - 위젯 상세 내 상품 좋아요 해제
   * - 검색>상품 탭>상품리스트 좋아요 해제
   * - 좋아요 리스트 내 상품 좋아요 해제
   * @param id
   * @param name
   */
  removeProductWishlist(id: string, name: string) {
    this.removeCustomAttribute("product_wishlist", id);
    this.removeCustomAttribute("product_wishlist_by_name", name);
  }
}

export default BrazeCAEvent;
