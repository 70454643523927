import { HomeListStateType, Response, SubHomeListStateType } from "@amondz/types";
import { superFetch } from "../superFetch";

export const homeApi = {
  getMainHomeList: (): Promise<Response<HomeListStateType>> => {
    return superFetch.post("home/list/main/web/v1");
  },
  getSubHomeList: (homeId: number): Promise<Response<SubHomeListStateType>> => {
    return superFetch.post("home/list/sub/web/v1", { homeId });
  },
};
