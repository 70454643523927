import { ImageResponseType } from "@amondz/types";
import superFetch from "@amondz/apis-v1";
import superFetchV2 from "@amondz/apis-v2";
import { LikeProductCommonType, LikeBrandRequestType } from "@store/modules/base";
import { ReviewIdType } from "@store/modules/mypage";

const baseAPI = {
  // 상품 좋아요 요청 API
  likeProduct: async (data: LikeProductCommonType) => {
    return superFetch.post("/like/product/click/web/v2", data);
  },

  // 브랜드 좋아요 요청 API
  likeStore: async (data: LikeBrandRequestType) => {
    return superFetch.post("/like/brand/click/web/v1", data);
  },

  // 상품 후기 좋아요 요청 API
  likeReview: async (data: ReviewIdType) => {
    return superFetch.post("/like/review/click/web/v1", data);
  },

  //이미지 업로드 API
  getImageUrl: (image: Blob): Promise<ImageResponseType> => {
    const formData = new FormData();
    formData.set("file", image);
    return superFetchV2.post("/uploads/square-images", formData);
  },
};

export default baseAPI;
