import { LOGIN_TYPE } from "@constants/enum/authEnums";
import { LOGIN_TYPE_TEXT } from "@constants/service/auth/auth";

/**
 * 회원가입 인증 에러 코드
 */
export const MEMBER_ERROR_CODE = {
  MEMBER_001: "MEMBER001",
  MEMBER_002: "MEMBER002",
  MEMBER_003: "MEMBER003",
  MEMBER_004: "MEMBER004",
  MEMBER_005: "MEMBER005",
  MEMBER_006: "MEMBER006",
  MEMBER_007: "MEMBER007",
  MEMBER_008: "MEMBER008",
  MEMBER_009: "MEMBER009",
  MEMBER_010: "MEMBER010",
  MEMBER_011: "MEMBER011",
  MEMBER_012: "MEMBER012",
  MEMBER_013: "MEMBER013",
  MEMBER_014: "MEMBER014",
  MEMBER_015: "MEMBER015",
  MEMBER_016: "MEMBER016",
  MEMBER_017: "MEMBER017",
  MEMBER_018: "MEMBER018",
  MEMBER_019: "MEMBER019",
  MEMBER_020: "MEMBER020",
  MEMBER_201: "MEMBER201",
  MEMBER_301: "MEMBER301",
  MEMBER_302: "MEMBER302",
  MEMBER_303: "MEMBER303",
  MEMBER_304: "MEMBER304",
  MEMBER_401: "MEMBER401",
  MEMBER_501: "MEMBER501",
};

/**
 * 회원가입 인증 에러 메세지
 */
export const MEMBER_ERROR_MESSAGES = {
  [MEMBER_ERROR_CODE.MEMBER_001]: "이메일 형식이 올바르지 않습니다.",
  [MEMBER_ERROR_CODE.MEMBER_002]: "이메일은 최대 80자까지만 입력이 가능합니다.",
  [MEMBER_ERROR_CODE.MEMBER_003]: "이름은 최대 20자까지만 입력이 가능합니다.",
  [MEMBER_ERROR_CODE.MEMBER_004]: "이름은 필수값입니다.",
  [MEMBER_ERROR_CODE.MEMBER_005]: "날짜 형식이 올바르지 않습니다.",
  [MEMBER_ERROR_CODE.MEMBER_006]: "성별 정보를 다시 확인해주세요.",
  [MEMBER_ERROR_CODE.MEMBER_007]: "성별은 필수값입니다.",
  [MEMBER_ERROR_CODE.MEMBER_008]: "휴대폰 번호 형식이 올바르지 않습니다.",
  [MEMBER_ERROR_CODE.MEMBER_009]: "휴대폰 번호는 필수값입니다.",
  [MEMBER_ERROR_CODE.MEMBER_010]: "SMS 수신 여부를 다시 확인해주세요.",
  [MEMBER_ERROR_CODE.MEMBER_011]: "SMS 수신 여부를 선택해주세요.",
  [MEMBER_ERROR_CODE.MEMBER_012]: "이메일 수신 여부를 다시 확인해주세요.",
  [MEMBER_ERROR_CODE.MEMBER_013]: "이메일 수신 여부를 선택해주세요.",
  [MEMBER_ERROR_CODE.MEMBER_014]: `이미 ${LOGIN_TYPE_TEXT[LOGIN_TYPE.EMAIL]}로 가입하셨습니다.`,
  [MEMBER_ERROR_CODE.MEMBER_015]: `이미 ${LOGIN_TYPE_TEXT[LOGIN_TYPE.KAKAO]}로 가입하셨습니다.`,
  [MEMBER_ERROR_CODE.MEMBER_016]: `이미 ${LOGIN_TYPE_TEXT[LOGIN_TYPE.NAVER]}로 가입하셨습니다.`,
  [MEMBER_ERROR_CODE.MEMBER_017]: `이미 ${LOGIN_TYPE_TEXT[LOGIN_TYPE.FACEBOOK]}으로 가입하셨습니다.`,
  [MEMBER_ERROR_CODE.MEMBER_018]: `이미 ${LOGIN_TYPE_TEXT[LOGIN_TYPE.APPLE]}로 가입하셨습니다.`,
  [MEMBER_ERROR_CODE.MEMBER_019]: "오류가 발생했습니다.\n다시 시도해주세요.",
  [MEMBER_ERROR_CODE.MEMBER_020]: "오류가 발생했습니다.\n다시 시도해주세요.",
  [MEMBER_ERROR_CODE.MEMBER_201]: "회원정보가 유효하지 않습니다.\n다시 로그인해주세요.",
  [MEMBER_ERROR_CODE.MEMBER_301]: "유효하지 않은 인증번호입니다.",
  [MEMBER_ERROR_CODE.MEMBER_302]: "인증번호는 필수값입니다.",
  [MEMBER_ERROR_CODE.MEMBER_303]: "하루 요청 가능 횟수를 초과했습니다.\n내일 다시 시도해주세요.",
  [MEMBER_ERROR_CODE.MEMBER_304]: "중복된 인증번호가 존재합니다.\n다시 시도해 주세요.",
  [MEMBER_ERROR_CODE.MEMBER_401]: "일시적인 오류가 발생했습니다.\n다시 시도해 주세요.",
  [MEMBER_ERROR_CODE.MEMBER_501]: "추천코드가 유효하지 않습니다.",
};

/**
 * 회원가입 인증 에러 서브 메세지
 */
export const MEMBER_ERROR_SUB_MESSAGES = {
  [MEMBER_ERROR_CODE.MEMBER_014]: `${
    LOGIN_TYPE_TEXT[LOGIN_TYPE.EMAIL]
  } 계정으로 시작할 수 있습니다. 로그인 문제\n가 해결되지 않는 경우 아몬즈에 문의해주세요.`,
  [MEMBER_ERROR_CODE.MEMBER_015]: `${
    LOGIN_TYPE_TEXT[LOGIN_TYPE.KAKAO]
  } 계정으로 시작할 수 있습니다. 로그인 문제\n가 해결되지 않는 경우 아몬즈에 문의해주세요.`,
  [MEMBER_ERROR_CODE.MEMBER_016]: `${
    LOGIN_TYPE_TEXT[LOGIN_TYPE.NAVER]
  } 계정으로 시작할 수 있습니다. 로그인 문제\n가 해결되지 않는 경우 아몬즈에 문의해주세요.`,
  [MEMBER_ERROR_CODE.MEMBER_017]: `${
    LOGIN_TYPE_TEXT[LOGIN_TYPE.FACEBOOK]
  } 계정으로 시작할 수 있습니다. 로그인 문제\n가 해결되지 않는 경우 아몬즈에 문의해주세요.`,
  [MEMBER_ERROR_CODE.MEMBER_018]: `${
    LOGIN_TYPE_TEXT[LOGIN_TYPE.APPLE]
  } 계정으로 시작할 수 있습니다. 로그인 문제\n가 해결되지 않는 경우 아몬즈에 문의해주세요.`,
};

/**
 * 에러 폼 이름
 */
export const MEMBER_ERROR_FORM_NAME = {
  [MEMBER_ERROR_CODE.MEMBER_001]: "userEmail",
  [MEMBER_ERROR_CODE.MEMBER_002]: "userEmail",
  [MEMBER_ERROR_CODE.MEMBER_003]: "userName",
  [MEMBER_ERROR_CODE.MEMBER_004]: "userName",
  [MEMBER_ERROR_CODE.MEMBER_005]: "userBirthday",
  [MEMBER_ERROR_CODE.MEMBER_007]: "userGender",
  [MEMBER_ERROR_CODE.MEMBER_008]: "cellPhone",
  [MEMBER_ERROR_CODE.MEMBER_009]: "cellPhone",
  [MEMBER_ERROR_CODE.MEMBER_301]: "authCode",
  [MEMBER_ERROR_CODE.MEMBER_302]: "authCode",
};

/**
 * 이미 가입한 계정이 있을 시 노출되는 문의하기 문구
 */
export const QNA_ERROR_MESSAGE = "문의하러 가기";
