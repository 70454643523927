/* tslint:disable */
/* eslint-disable */
/**
 * AMONDZ-USER-BACKEND-V2
 * AMONDZ-USER-BACKEND-V2 API 문서입니다.
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { CouponFixedDateDto } from './coupon-fixed-date-dto';

/**
 * 
 * @export
 * @interface CouponDto
 */
export interface CouponDto {
    /**
     * 쿠폰 식별 id
     * @type {number}
     * @memberof CouponDto
     */
    'id': number;
    /**
     * 쿠폰 이름
     * @type {string}
     * @memberof CouponDto
     */
    'name': string;
    /**
     * 기간 타입( fixedDate : 날짜 지정 , generatedDate : 발급일 기준 , infinity : 무기한 )
     * @type {string}
     * @memberof CouponDto
     */
    'periodType': CouponDtoPeriodTypeEnum;
    /**
     * 할인 타입( amount : 금액 , rate : 할인율 )
     * @type {string}
     * @memberof CouponDto
     */
    'discountType': CouponDtoDiscountTypeEnum;
    /**
     * 할인 타입에 따른 혜택 수치(금액, 할인률 등)
     * @type {number}
     * @memberof CouponDto
     */
    'discountAmount': number;
    /**
     * 쿠폰에 대한 설명
     * @type {string}
     * @memberof CouponDto
     */
    'description': string;
    /**
     * 발급일로부터 날짜 수
     * @type {number}
     * @memberof CouponDto
     */
    'dayPlus'?: number;
    /**
     * 
     * @type {CouponFixedDateDto}
     * @memberof CouponDto
     */
    'date'?: CouponFixedDateDto;
    /**
     * 최대 할인 금액
     * @type {number}
     * @memberof CouponDto
     */
    'maxDiscountAmount'?: number;
}

export const CouponDtoPeriodTypeEnum = {
    FixedDate: 'fixedDate',
    GeneratedDate: 'generatedDate',
    Infinity: 'infinity'
} as const;

export type CouponDtoPeriodTypeEnum = typeof CouponDtoPeriodTypeEnum[keyof typeof CouponDtoPeriodTypeEnum];
export const CouponDtoDiscountTypeEnum = {
    Amount: 'amount',
    Rate: 'rate'
} as const;

export type CouponDtoDiscountTypeEnum = typeof CouponDtoDiscountTypeEnum[keyof typeof CouponDtoDiscountTypeEnum];


