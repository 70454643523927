export const REVIEW_TITLE_TEXT = "상품 구매후기";

export const REVIEW_MAX_POINT_GUIDE_TEXT = "상품 구매 후 후기를 작성하면 \n**최대 1,000 포인트**를 드려요";

export const PHOTO_REVIEW_BTN_TEXT = "포토후기만 보기";

export const MY_REVIEW_TEXT = "*내가 작성한 후기";

export const WRITE_REVIEW_BTN_TEXT = "후기작성";

export const READ_REVIEW_BTN_TEXT = "후기보기";
