/* tslint:disable */
/* eslint-disable */
/**
 * AMONDZ-USER-BACKEND-V2
 * AMONDZ-USER-BACKEND-V2 API 문서입니다.
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { ProductAttachmentDto } from './product-attachment-dto';

/**
 * 
 * @export
 * @interface ProductDto
 */
export interface ProductDto {
    /**
     * 상품 식별 id
     * @type {number}
     * @memberof ProductDto
     */
    'id': number;
    /**
     * 상품명
     * @type {string}
     * @memberof ProductDto
     */
    'name': string;
    /**
     * 오늘 출발 여부
     * @type {boolean}
     * @memberof ProductDto
     */
    'isTodayDelivery': boolean;
    /**
     * 선물하기 여부
     * @type {boolean}
     * @memberof ProductDto
     */
    'isGift': boolean;
    /**
     * 상품 판매가 (원)
     * @type {number}
     * @memberof ProductDto
     */
    'originPrice': number;
    /**
     * 상품 실제 판매 가격  (원)
     * @type {number}
     * @memberof ProductDto
     */
    'salesPrice': number;
    /**
     * 상품 금액 할인률 타입
     * @type {string}
     * @memberof ProductDto
     */
    'discountType': ProductDtoDiscountTypeEnum;
    /**
     * 상품 할인 비율 (%)
     * @type {number}
     * @memberof ProductDto
     */
    'discountRate'?: number;
    /**
     * 상품 할인 금액 (원)
     * @type {number}
     * @memberof ProductDto
     */
    'discountAmount'?: number;
    /**
     * 상품 상태
     * @type {string}
     * @memberof ProductDto
     */
    'status': ProductDtoStatusEnum;
    /**
     * 상품 이미지
     * @type {Array<ProductAttachmentDto>}
     * @memberof ProductDto
     */
    'attachments': Array<ProductAttachmentDto>;
    /**
     * 유저의 상품 좋아요 여부
     * @type {boolean}
     * @memberof ProductDto
     */
    'isLike': boolean;
    /**
     * 상품 좋아요 수
     * @type {number}
     * @memberof ProductDto
     */
    'likeCount': number;
}

export const ProductDtoDiscountTypeEnum = {
    Amount: 'amount',
    Percent: 'percent',
    Null: 'null'
} as const;

export type ProductDtoDiscountTypeEnum = typeof ProductDtoDiscountTypeEnum[keyof typeof ProductDtoDiscountTypeEnum];
export const ProductDtoStatusEnum = {
    ConfirmReady: 'confirmReady',
    ConfirmReject: 'confirmReject',
    NowOnSale: 'nowOnSale',
    SoldOut: 'soldOut',
    SalesClose: 'salesClose',
    SalesProhibit: 'salesProhibit',
    SalesReady: 'salesReady',
    SalesStop: 'salesStop',
    Delete: 'delete'
} as const;

export type ProductDtoStatusEnum = typeof ProductDtoStatusEnum[keyof typeof ProductDtoStatusEnum];


