/* tslint:disable */
/* eslint-disable */
/**
 * AMONDZ-USER-BACKEND-V2
 * AMONDZ-USER-BACKEND-V2 API 문서입니다.
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface BrandsFindOneResponseBrandSnsDto
 */
export interface BrandsFindOneResponseBrandSnsDto {
    /**
     * 브랜드 sns 종류명 ( instagram : 인스타그램 , facebook : 페이스북 , blog : 블로그 , etc : 기타 )
     * @type {string}
     * @memberof BrandsFindOneResponseBrandSnsDto
     */
    'type': BrandsFindOneResponseBrandSnsDtoTypeEnum;
    /**
     * 브랜드 sns url 주소
     * @type {string}
     * @memberof BrandsFindOneResponseBrandSnsDto
     */
    'url': string;
}

export const BrandsFindOneResponseBrandSnsDtoTypeEnum = {
    Instagram: 'instagram',
    Facebook: 'facebook',
    Blog: 'blog',
    Etc: 'etc'
} as const;

export type BrandsFindOneResponseBrandSnsDtoTypeEnum = typeof BrandsFindOneResponseBrandSnsDtoTypeEnum[keyof typeof BrandsFindOneResponseBrandSnsDtoTypeEnum];


