// 결제 페이지 단계 리스트
export enum PAYMENT_PAGE_STEP {
  CART, // 장바구니
  PAYMENT, // 결제하기
  COMPLETION, // 주문완료
}

// 핸드폰 결제 가능 여부 리스트
export enum CELL_PHONE_PAY_AVAILABILITY {
  IMPOSSIBLE, // 불가능
  POSSIBLE, // 가능
}

// 결제 수단 사용 가능 여부
export enum PAY_METHOD_AVAILABILITY {
  IMPOSSIBLE, // 불가능
  POSSIBLE, // 가능
  // 결제 수단이 신용카드일 때만 해당하는 옵션
  PARTIAL, // 일부 카드만 사용 가능
}

// 기본 배송지 여부 리스트
export enum DEFAULT_SHIPPING_ADDRESS {
  NOT_SET, // 안함
  SET, // 기본 배송지로 설정
}

// 아임포트 PG 공급사 리스트
export enum IAMPORT_PG_PROVIDER {
  JTNET = "jtnet", // 제이티넷
  NICE = "nice", // 나이스 페이
}

// 결제 모달명 리스트
export enum PAYMENT_MODAL_NAME_TYPE {
  BUYER_INFO_MODAL = "buyerInfoModal", // 주문자 정보 수정 모달
  COUPON_LIST_MODAL = "couponListModal", // 쿠폰 리스트 수정 모달
  DELIVERY_INFO_MODAL = "deliveryInfoModal", // 배송지 정보 모달
  WARNING_INFO_MODAL = "warningInfoModal", // 결제 경고 정보 모달
}

// 배송 요청 사항 타입 리스트
export enum DELIVERY_MESSAGE_TYPE {
  PRE_CONTACT, // 배송 전에 미리 연락 바랍니다.
  SECURITY_OFFICE, // 부재시 경비실에 맡겨주세요.
  CONTACT_OR_MESSAGE, // 부재시 전화주시거나 문자 남겨주세요.
  DIRECT_INPUT, // 직접입력
  CANCEL, // 요청사항 취소
}

// 배송지 정보 모달 탭 타입 리스트
export enum DELIVERY_INFO_MODAL_TAB_TYPE {
  DELIVERY_ADDRESS_LIST, // 배송지 목록
  NEW_DELIVERY_ADDRESS, // 새로 입력
}

// 결제 페이지로 이동하기 전 페이지 리스트
export enum CHECKOUT_PAGE_FROM_PAGE_TYPE {
  PRODUCT_DETAIL, // 상품 상세
  CART, // 장바구니
}
