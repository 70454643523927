import { STATUS_CODE_COMMON } from "@amondz/constants";
import { Response } from "@amondz/types";
import {
  brandDetailRequestType,
  brandDetailType,
  brandInfoRequestType,
  brandListRequestType,
  LikeBrandRequest,
  brandListDataType,
  brandInfoType,
  LikeBrandResponse,
} from "./type";
import { superFetch } from "../superFetch";

export const brandAPI = {
  // 브랜드 리스트 요청 API
  brandList: (data: brandListRequestType): Promise<Response<brandListDataType>> =>
    superFetch.post("/brand/list/pagination/web/v1", data),
  // 브랜드 상세 요청 API
  brandDetail: (data: brandDetailRequestType): Promise<brandDetailType> =>
    superFetch.post("/brand/detail/pagination/web/v1", data),
  // 브랜드 정보 요청 API
  brandInfo: (data: brandInfoRequestType): Promise<brandInfoType> => superFetch.post("/brand/detail/info/web/v1", data),
  //브랜드 좋아요 요청 API
  likeBrand: async (data: LikeBrandRequest): Promise<LikeBrandResponse> => {
    const res = await superFetch.post("/like/brand/click/web/v1", data);
    if (res.status !== STATUS_CODE_COMMON.SUCCESS) {
      throw new Error("좋아요가 실패했습니다. 잠시 후 다시 시도해주세요");
    }
    return res;
  },
};
