export type AsyncState<T, E = any> = {
  loading: boolean;
  data: T | null;
  status: number | null;
  error: E | null;
};

interface IAsyncStateSuccessData<T> {
  data?: T;
  status?: number;
}

/**
 * reducer 에서 비동기 데이터를 저장하는데 사용할 state 설정
 */
export const asyncState = {
  /**
   * 비동기 데이터 초기 상태
   * loading: false
   * data: initialData || null
   * status: null
   * error: null
   */
  initial: <T, E = any>(initialData?: T): AsyncState<T, E> => ({
    loading: false,
    data: initialData || null,
    status: null,
    error: null,
  }),
  /**
   * 비동기 데이터 로드 상태
   * loading: true
   * data: data || null
   * status: null
   * error: null
   */
  load: <T, E = any>(data?: T): AsyncState<T, E> => ({
    loading: true,
    data: data || null,
    status: null,
    error: null,
  }),
  /**
   * 비동기 데이터 로드 성공
   * loading: false
   * data: data?.data || null
   * status: data?.status || null,
   * error: null
   */
  success: <T, E = any>(data?: IAsyncStateSuccessData<T>): AsyncState<T, E> => ({
    loading: false,
    data: data?.data || null,
    status: data?.status || null,
    error: null,
  }),
  /**
   * 비동기 데이터 로드 실패
   * loading: false
   * data: null
   * status: null
   * error: error
   */
  error: <T, E>(error: E): AsyncState<T, E> => ({
    loading: false,
    data: null,
    status: null,
    error,
  }),
};
