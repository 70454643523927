import * as React from "react";
import { FC, useCallback, useEffect, useState, memo } from "react";
import { useDispatch, useSelector } from "react-redux";

import Button from "@components/common/Button";
import Icon from "@components/common/Icon";
import { RootStateType } from "@store/modules";
import { BaseModalStateType, baseModalToggle } from "@store/modules/base";
import { detectDeviceOs } from "@lib/utility/deviceDetect";
import { BUTTON_COLOR, BUTTON_SIZE, BASE_MODAL_NAME_TYPE, DEVICE_OS_TYPE } from "@constants/enum/baseEnums";
import { APP_STORE_LOGO_IMAGE_PATH, GOOGLE_PLAY_STORE_LOGO_IMAGE_PATH } from "@constants/static/images";
import { AMONDZ_APP_DOWNLOAD_DEEP_LINK_URL } from "@constants/url/externalUrlConstants";
import { AppInstallInfoModalStyle } from "./style";

const AppInstallInfoModal: FC = () => {
  const { appInstallInfoModal } = useSelector<RootStateType, BaseModalStateType>(
    (state: RootStateType) => state.base.baseModalState,
  );
  const [deviceOsType, setDeviceOsType] = useState(DEVICE_OS_TYPE.BROWSER);
  const dispatch = useDispatch();

  useEffect(() => {
    setDeviceOsType(detectDeviceOs());
  });

  const toggleModal = useCallback(
    () =>
      dispatch(
        baseModalToggle({
          targetModal: BASE_MODAL_NAME_TYPE.APP_INSTALL_INFO_MODAL,
        }),
      ),
    [],
  );

  return (
    <AppInstallInfoModalStyle visible={appInstallInfoModal.visible} toggleModal={toggleModal}>
      <div className="modal-body">
        <div className="modal-contents">
          <div className="main-text">조금만 기다려주세요 🙇</div>
          <div className="sub-text">
            웹에서는 아직 오픈 준비중인 기능입니다.. 😭 <br />
            앱을 다운로드 받아서 이용해 주세요! <br />
            웹에서도 빠른 시일 내에 오픈 예정입니다. <br />
            조금만 기다려주세요 ☺️
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <>
          <Button
            className="pc-btn app-store-list-btn"
            color={BUTTON_COLOR.WHITE}
            size={BUTTON_SIZE.LARGE}
            onClick={() => window.open(AMONDZ_APP_DOWNLOAD_DEEP_LINK_URL)}
            text={
              <div>
                <Icon src={APP_STORE_LOGO_IMAGE_PATH} />
                <span>App store</span>
              </div>
            }
          />
          <Button
            className="pc-btn"
            color={BUTTON_COLOR.WHITE}
            size={BUTTON_SIZE.LARGE}
            onClick={() => window.open(AMONDZ_APP_DOWNLOAD_DEEP_LINK_URL)}
            text={
              <div>
                <Icon src={GOOGLE_PLAY_STORE_LOGO_IMAGE_PATH} />
                <span>Play store</span>
              </div>
            }
          />
        </>
      </div>
    </AppInstallInfoModalStyle>
  );
};

export default memo(AppInstallInfoModal);
