import { ZaiEvent } from "./index";
import { eventType, eventValue } from "../type";

/**
 * 클라이언트에서 발생하는 이벤트를 자이 이벤트로 맵핑
 * @param type
 * @param value
 */
function zaiEventChannel<EventType extends eventType>(type: EventType, value: eventValue[EventType]): void {
  const event = ZaiEvent.getInstance();
  switch (type) {
    case eventType.viewProductDetail: {
      const { userId, productId, from } = value as eventValue[eventType.viewProductDetail];
      if (!userId) return;
      event.viewProductDetail(String(userId), String(productId), from);
      break;
    }
    case eventType.viewHome: {
      const { userId } = value as eventValue[eventType.viewHome];
      if (!userId) return;
      event.viewPage(String(userId), "home");
      break;
    }
    case eventType.viewProductList: {
      const { userId } = value as eventValue[eventType.viewProductList];
      if (!userId) return;
      event.viewPage(String(userId), "category");
      break;
    }
    case eventType.viewBrandDetail: {
      const { userId, brandId } = value as eventValue[eventType.viewBrandDetail];
      if (!userId) return;
      event.viewPage(String(userId), "brand-detail");
      event.viewBrandDetail(String(userId), String(brandId));
      break;
    }
    case eventType.viewSearchResult: {
      const { userId } = value as eventValue[eventType.viewSearchResult];
      if (!userId) return;
      event.viewPage(String(userId), "search");
      break;
    }
    case eventType.viewWidgetDetail: {
      const { userId } = value as eventValue[eventType.viewWidgetDetail];
      if (!userId) return;
      event.viewPage(String(userId), "widget-detail");
      break;
    }
    case eventType.viewEventDetail: {
      const { userId } = value as eventValue[eventType.viewEventDetail];
      if (!userId) return;
      event.viewPage(String(userId), "event-detail");
      break;
    }
    case eventType.likeProduct: {
      const { userId, productId } = value as eventValue[eventType.likeProduct];
      if (!userId) return;
      event.likeProduct(String(userId), String(productId));
      break;
    }
    case eventType.likeBrand: {
      const { userId, brandId } = value as eventValue[eventType.likeBrand];
      if (!userId) return;
      event.likeBrand(String(userId), String(brandId));
      break;
    }
    case eventType.addCart: {
      const { userId, productId } = value as eventValue[eventType.addCart];
      if (!userId) return;
      event.addCart(String(userId), String(productId));
      break;
    }
    case eventType.completePayment: {
      const { userId, productId, price } = value as eventValue[eventType.completePayment];
      if (!userId) return;
      const pId = Array.isArray(productId) ? productId.map((id) => String(id)) : String(productId);
      event.purchase(String(userId), pId, price);
      break;
    }
    case eventType.search: {
      const { userId, search } = value as eventValue[eventType.search];
      if (!userId) return;
      event.search(String(userId), search);
      break;
    }
  }
}

export default zaiEventChannel;
