import { UNIT_TYPE } from "@amondz/types";
import { isNumeric } from "./number";
import { addUnit } from "./unit";


export const convertToKoreanUnits = (price: number): string => {
  if (!isNumeric(price)) return '0원';

  const tenThousands = Math.floor(price / 10000);
  const thousands = Math.floor((price % 10000) / 1000);

  let formattedNumber = "";

  if (tenThousands > 0) {
    formattedNumber += `${tenThousands}만 `;
  }

  if (thousands > 0) {
    formattedNumber += `${thousands}천`;
  }
  return addUnit(formattedNumber.trim(), UNIT_TYPE.WON);
};