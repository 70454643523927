import {
  GIFT_FILTER,
  LIKE_STATUS,
  PRODUCT_DETAIL_DELIVERY_TYPE,
  PRODUCT_STATUS_TYPE,
  TODAY_DELIVERY_FILTER_TYPE,
} from "@amondz/constants";
import { ProductFindOneResponseProductDto } from "@amondz/apis-v2";
import { ProductCustomerServiceTermType } from "@amondz/apis-v1";
import { ReviewDetailCriterionDto } from "./review";
import { Optional } from "./utils";
import { BrandInfo } from "./brand";

export type productCategoryListType = {
  parentId?: number | null;
  id: number;
  name: string;
}[];

export type ProductField = {
  // 카테고리 식별 id
  categoryId: number;
  // 카테고리명
  categoryName: string;
  // 제품의 서브카테고리 명
  productCategoryList: productCategoryListType;
  // 상품 식별 id
  productId: number;
  // 상품 이미지
  productImg: string;
  // 상품명
  productName: string;
  // 상품 구매 수량
  count: number;
  // 상품 판매 가격
  salesPrice: number;
  // 상품총 가격
  totalPrice: number;
  // 오늘출발 여부
  isTodayDelivery?: TODAY_DELIVERY_FILTER_TYPE;
  // 상품 옵션
  option?: {
    id: number;
    optionText: string;
    price: number;
  };
  coupon?: {
    id: number;
    name?: string;
    discountAmount: number;
    discountPrice: number;
  };
  // 판매자가 입력한 상품 주문 메시지에 대한 안내문
  orderExampleMessage: string | null;
};

export type ProductBundleItemType = {
  // 브랜드 식별 id
  storeId: number;
  // 브랜드명
  storeName: string;
  // 총 상품 가격
  sumSalesPrice: number;
  // 총 추가 배송비
  sumDeliveryExtraFee: number;
  // 총 쿠폰 가격
  sumCouponPrice: number;
  // 총 가격 (상품 - 쿠폰 + 총 추가 배송비)
  sumTotalPrice: number;
  // 해당 브랜드의 상품 리스트
  productList: ProductField[];
};

// 상품 카드 상태
export type ProductCardStateType = {
  /** 제품인식 index id */
  id: number;
  /** 제품의 카테고리 인식 index id */
  categoryId: number;
  /** 제품의 카테고리 명 */
  categoryName: string;
  /** 제품의 서브카테고리 명 */
  productCategoryList: productCategoryListType;
  /** 제품 대표 이미지 url */
  imgUrl: string;
  /** 제품 이름 */
  name: string;
  /** 판매점 인식 index id */
  storeId: number;
  /** 제품을 소유한 매장명 */
  storeName: string;
  /** 제품 상태 */
  status?: number;
  /** 실제 판매 가격 */
  salesPrice?: number;
  /** 기존 판매가 */
  originPrice?: number;
  /** 할인율 */
  discountRate?: number;
  /** 좋아요 갯수 */
  likeCount?: number;
  /** 좋아요 여부 */
  likeFlag?: number;
  /** 오늘출발 여부 */
  isTodayDelivery?: TODAY_DELIVERY_FILTER_TYPE;
  /** 선물하기 여부 */
  isGift?: GIFT_FILTER_TYPE;
};

// 제품 아이템 공통 타입
export type ProductItemCommonType = {
  // 제품 인식 index id
  id: number;
  // 제품의 카테고리 인식 index id
  categoryId: number;
  // 제품의 카테고리 명
  categoryName: string;
  // 제품의 서브카테고리 명
  productCategoryList: productCategoryListType;
  // 제품 이름
  productName: string;
  // 제품 대표 이미지 url
  imgUrl: string;
  // 오늘 출발 여부
  isTodayDelivery: TODAY_DELIVERY_FILTER_TYPE;
  // 선물하기 여부
  isGift?: GIFT_FILTER_TYPE;
};

// 상품 정보 상태
export type ProductItemType = ProductItemCommonType & {
  // 판매점 인식 index id
  storeId: number;
  // 제품을 소유한 매장명
  storeName: string;
  // 기존 판매가
  originPrice: number;
  // 할인율
  discountRate: number;
  // 실제 판매 가격
  salesPrice: number;
  // 제품 상태 (2: 판매중, 3: 품절)
  status: number;
  // 좋아요 갯수
  likeCount: number;
  // 좋아요 (0: 좋아요 안함, 1: 좋아요 함)
  likeFlag?: number;
};

// 좋아요한 상품 관련
export type LikeProductItemType = {
  // 상품 인식 index id
  id: number;
  // 상품명
  name: string;
  // 오늘출발 여부
  isTodayDelivery?: boolean;
  // 선물하기 여부
  isGift?: boolean;
  //좋아요 여부
  likeFlag: boolean;
  // 기존 판매가
  originPrice: number;
  // 실제 판매 가격
  salesPrice: number;
  // 할인율
  discountRate?: number;
  //할인 금액
  discountAmount?: number;
  // 제품 상태 (nowOnSale: 판매중, soldOut: 품절)
  status: PRODUCT_STATUS_TYPE;
  productMainAttachment: {
    // 상품 대표 이미지 식별 id
    id: number;
    // 상품 대표 이미지 url
    url: string;
    // 상품 이미지 가로 크기(px)
    width: number;
    // 상품 이미지 세로 크기(px)
    height: number;
  };
  store: {
    // 브랜드 식별 아이디
    id: number;
    // 브랜드 이름
    name: string;
  };
  // 제품의 서브카테고리 명
  productCategories: productCategoryListType;
};

export type GIFT_FILTER_TYPE = (typeof GIFT_FILTER)[keyof typeof GIFT_FILTER];

export type CouponListItemType = {
  appliedDiscountAmount: number;
  id: number;
  name: string;
  description: string;
  periodType: "fixedDate" | "generatedDate" | "infinity";
  date?: {
    startDate: string;
    endDate: string;
  };
  dayPlus?: number;
  discountType: "amount" | "rate";
  discountAmount: number;
  maxDiscountAmount?: number;
  availableCount?: number;
  canDownload?: boolean;
  latestAvailableCouponGenerated?: {
    id: number;
    startDate: string;
    endDate: string;
  };
};

export type ProductBenefits = {
  discountedPriceBenefit?: {
    lowestPrice: number;
    lowestPriceDiscountRate: number;
    maxDiscountCoupon?: {
      appliedDiscountAmount: number;
      coupon: {
        id: number;
        name: string;
        description: string;
        periodType: "fixedDate" | "generatedDate" | "infinity";
        // 기간 타입이 'fixedDate' 인 경우에만 존재
        date?: {
          startDate: string;
          endDate: string;
        };
        // 발급일로부터 날짜 수 (기간 타입이 'generatedDate' 인 경우에만 존재)
        dayPlus?: number;
        discountType: "amount" | "rate";
        discountAmount: number;
        // 할인 타입이 'percent' 인 경우 최대 할인 가능 금액
        maxDiscountAmount?: number;
        availableCount?: number;
      };
    };
    productDiscount?: {
      discountRate: number;
      discountAmount: number;
    };
    usablePoint?: number;
  };
  earnablePoint: number;
};

export type LIKE_STATUS_TYPE = (typeof LIKE_STATUS)[keyof typeof LIKE_STATUS];

export type GetProductDetailAdditionalSectionRequestType = {
  productId: number;
  startIndex: number;
  exceptAllCount: boolean;
};

// 상품 배송 타입
export type ProductDeliveryType = {
  // 오늘출발
  todayDelivery?: {
    contents: string;
  };
  // 일반
  general?: {
    // 제품 배송 타입
    type: PRODUCT_DETAIL_DELIVERY_TYPE;
    // 배송 기간
    period: number;
    // 바로 배송 안내 문구
    contents: string;
    // 바로배송 시간 기준 문구
    timeLimitText?: string;
    // 바로배송 시간 숫자
    timeLimit: number;
  };
};

export type RecommendListCommonType = {
  // 추천 리스트 id
  id: number;
  // 추천 리스트 이름
  name: string;
  // 추천 리스트 이미지 url
  imgUrl: string;
  // 제품의 카테고리 인식 index id
  categoryId: number;
  // 기존 판매가
  originPrice: number;
  // 할인율
  discountRate: number;
  // 실제 판매 가격
  salesPrice: number;
  // 제품 상태
  status: number;
  // 브랜드 이름
  storeName: string;
};

export type BaseReviewType = {
  reviewId: number;
  rate: 1 | 2 | 3 | 4 | 5;
  account: string;
  date: string;
  contents: string;
  likeCount: number;
  likeFlag: LIKE_STATUS_TYPE;
  imgUrlList: string[];
  reviewCriterionList: ReviewDetailCriterionDto[];
};

export type ProductCategoryCommonType = Pick<RecommendListCommonType, "id" | "name">;

// 상품 옵션 타입
export type ProductOptionItemType = ProductCategoryCommonType & {
  // 옵션선택에 따른 가격 변동값
  price: number;
  // 옵션의 상태 (1: 활성, 2: 품절)
  status: number;
  // 오늘출발 여부
  isTodayDelivery?: TODAY_DELIVERY_FILTER_TYPE;
  // 선택 가능 여부
  disabled?: boolean;
};

type ProductContentListCommonType = {
  // 컨텐츠 타입
  type: number;
  // 컨텐츠 가로 사이즈
  width: number;
  // 컨텐츠 세로 사이즈
  height: number;
  // 컨텐츠 url
  url: string;
};

// 상품 후기 리스트 타입
export type ProductReviewListType = {
  allCount: number;
  reviewList: BaseReviewType[];
  rateAverage: 1 | 2 | 3 | 4 | 5;
};

// 상품 문의 타입
export type ProductQnaItemType = {
  //  상품 문의 삭제 이벤트
  id?: number;
  // 구매자 계정
  account: string;
  // 구매자 질문
  question: string;
  // 구매자가 질문을 작성한 날짜
  date: string;
  // 판매재 답변
  answer?: string;
  // 판매자가 답변을 작성한 날짜
  answerDate?: string;
};

export type ProductQnaListType = {
  status: number | null;
  loading: boolean;
  allCount: number;
  myQnaList: ProductQnaItemType[];
  qnaList: ProductQnaItemType[];
};

// 상품 태그 정보
export type ProductTagMaterial = {
  // 카테고리
  category: string;
  // 소재/베이스
  base: string;
  // 소재/기타
  etc: string;
  // 소재/도금
  plating: string;
  // 소재/스톤
  stone: string;
  // 컬러/패턴
  color: string;
  // 모양
  shape: string;
  // 체인모양
  chainShape: string;
  // 침소재
  postEarring: string;
};

export type ProductDetailFieldType = Pick<
  RecommendListCommonType,
  "categoryId" | "name" | "originPrice" | "discountRate" | "salesPrice" | "status"
> & {
  categoryName: string;
  // 제품의 서브카테고리 명
  productCategoryList: productCategoryListType;
  // 사이즈
  size: string;
  // 중량
  weight: string;
  // 판매자가 입력한 상품 주문 메세지에 대한 안내문
  orderExampleMessage: string;
  // 구매가능한 최저가
  minimumPurchasePrice: number;
  // 선물하기 여부
  isGift?: GIFT_FILTER_TYPE;
};

export type ProductOptionsFieldType = {
  // 제품 옵션 key의 명칭
  key?: string;
  // 제품 옵션 선택지
  itemList?: ProductOptionItemType[];
};

export type ProductDetailItemType = {
  like: {
    flag: number;
    count: number;
  };
  // 제품 상세정보
  // type: 제품 상태 (2: 판매중, 3: 품절)
  productDetail: ProductDetailFieldType;
  // 제품 옵션
  productOption?: ProductOptionsFieldType;
  // 상단 대표 + 추가 이미지 리스트 type: 이미지 타입 (0: 대표이미지, 1: 추가이미지)
  topImgList: ProductContentListCommonType[];
  // 상세 컨텐츠 리스트 type: 컨텐츠 타입 (2: 상세이미지, 100: 영상)
  detailContentsList: ProductContentListCommonType[];
  // 포인트 정보
  point?: {
    // 사용 가능한 포인트
    canUsePoint: number;
    // 예상 적립 가능 포인트
    canSavePoint: number;
  };
  // 배송안내 정보
  delivery?: ProductDeliveryType;
  // 브랜드에서 알리는 공지사항 정보
  notice?: {
    // 공지사항 내용
    content: string;
  };
  // 상품 리뷰 내역 정보
  review: ProductReviewListType;
  // 상품 문의 내역 정보
  qna?: ProductQnaListType;
  // 브랜드 정보
  brand: Pick<RecommendListCommonType, "id" | "name" | "imgUrl"> & {
    // 브랜드 좋아요 총 개수
    likeCount: number;
    // 로그인한 유저가 브랜드 좋아요 눌렀는지 여부 (0: false, 1: true)
    likeFlag: LIKE_STATUS_TYPE;
    // 브랜드의 제품 목록
    productList: Pick<RecommendListCommonType, "id" | "imgUrl">[];
  };
  // 상품 상세정보, 관련태그에 노출될 태그 정보
  tag: {
    // 상품 상세 정보에서 노출될 소재들 태그 정보
    material: ProductTagMaterial;
    // 상품에 연결된 모든 태그 목록
    tagList: Pick<RecommendListCommonType, "id" | "name">[];
  };
  // 상품 상세 하단에 붙는 추천 상품 리스트
  recommendList: RecommendListCommonType[];
};

// 옵션 타입 정의
export interface OptionType {
  id: number;
  name: string;
  price: number;
  count: number;
  isTodayDelivery: TODAY_DELIVERY_FILTER_TYPE;
}

// purchaseInfo 타입 정의
export interface PurchaseInfoType {
  optionList: OptionType[];
  count: number;
  totalPrice: number;
}

export interface ProductQnaType {
  id: number;
  isSecret: boolean;
  createDate: string;
  user: {
    account: string;
  };
  isMyQna?: boolean;
  question?: string;
  answer?: string;
  answerDate: string;
}

export interface ProductQnaListResponseType {
  totalCount: number;
  productQnas: ProductQnaType[];
  myQnas?: ProductQnaType[];
}

export const QnaEditType = {
  CREATE: "CREATE",
  MODIFY: "MODIFY",
  DELETE: "DElETE",
} as const;

export type CombinedProductInfo = ProductFindOneResponseProductDto & {
  productDetail: ProductDetailFieldType;
  productOption?: ProductOptionsFieldType;
  notice?: {
    content: string;
  };
  brand: Pick<RecommendListCommonType, "id" | "name" | "imgUrl"> & {
    likeCount: number;
    likeFlag: LIKE_STATUS_TYPE;
    productList: Pick<RecommendListCommonType, "id" | "imgUrl">[];
  };
  tag: {
    material: ProductTagMaterial;
    tagList: Pick<RecommendListCommonType, "id" | "name">[];
  };
  recommendList: RecommendListCommonType[];
  detailContentsList: ProductContentListCommonType[];
};

export type CustomerServiceInfo = {
  returnExchangeInfo: ProductCustomerServiceTermType;
  brandInfo: Optional<BrandInfo>;
};
