import { call, put } from "redux-saga/effects";
import Router from "next/router";
import { includes } from "lodash";

import { STATUS_CODE_COMMON } from "@constants/status/code/statusCodeCommon";
import { notifyToastToggle, oneButtonModalToggle } from "@store/modules/base";
import { ApiHelperResponseType } from "@store/modules/common/types";
import { STATUS_COMMON_MESSAGE } from "@constants/status/msg/statusCommonMessage";
import { NOTIFY_TOAST_TYPE } from "@constants/enum/baseEnums";
import { INTRO_PAGE_URL_PATH } from "@constants/url/internalUrlConstants";
import { AMONDZ_LOGIN_TOKEN_KEY } from "@constants/service/auth/auth";
import { STATUS_CODE_API } from "@constants/status/code/statusCodeApi";
import { INVALID_USER_INFO_MODAL_CONTENTS } from "@constants/service/auth/modal";
import cookies from "./cookies";

/**
 * api 요청 후 response
 *
 * @param {any} data 서버 response 데이터
 */
function* apiResponseHelper(data: ApiHelperResponseType) {
  if (!data) {
    return;
  }

  // 공용 exception 상태 메시지
  let commonExceptionStatusMsg = null;
  // 토스트 메시지 필요 여부
  let isNeedNotifyToastMsg = false;

  if (data.status === STATUS_CODE_COMMON.SERVER_ERROR) {
    // 서버 exception 이 발생할 경우
    commonExceptionStatusMsg = STATUS_COMMON_MESSAGE[STATUS_CODE_COMMON.SERVER_ERROR];
    isNeedNotifyToastMsg = true;
  } else if (data.status === STATUS_CODE_COMMON.INCOMPLETE_PARAM) {
    // 매개변수가 제대로 전달되지 않은 경우,
    commonExceptionStatusMsg = STATUS_COMMON_MESSAGE[STATUS_CODE_COMMON.INCOMPLETE_PARAM];
    isNeedNotifyToastMsg = true;

    // 홈 으로 이동
    Router.push(INTRO_PAGE_URL_PATH);
  } else if (
    includes(
      [
        STATUS_CODE_COMMON.INVALID_MEMBER.CODE_200,
        STATUS_CODE_COMMON.INVALID_MEMBER.CODE_201,
        STATUS_CODE_COMMON.INVALID_MEMBER.CODE_202,
        STATUS_CODE_COMMON.INVALID_MEMBER.CODE_203,
        STATUS_CODE_COMMON.INVALID_MEMBER.CODE_204,
        STATUS_CODE_API.USER_PROFILE.INVALID_USER_KEY,
      ],
      data.status,
    )
  ) {
    // 회원 정보가 유효하지 않을 경우
    commonExceptionStatusMsg = STATUS_COMMON_MESSAGE[STATUS_CODE_COMMON.INVALID_MEMBER.CODE_200];

    // 저장되어 있는 유저 인증 토큰 삭제
    yield cookies.remove(AMONDZ_LOGIN_TOKEN_KEY, { path: "/" });

    yield put(
      oneButtonModalToggle({
        visible: true,
        isCloseBtn: false,
        msg: INVALID_USER_INFO_MODAL_CONTENTS.MSG,
        isLogOut: true,
      }),
    );
  }

  if (commonExceptionStatusMsg) {
    if (isNeedNotifyToastMsg) {
      yield put(
        notifyToastToggle({
          type: NOTIFY_TOAST_TYPE.ERROR,
          msg: commonExceptionStatusMsg,
          visible: true,
        }),
      );
    }

    throw new Error(commonExceptionStatusMsg);
  }
}

/**
 * 서버 요청 헬퍼
 *
 * @param {any} api
 * @param {any} args
 *
 * @returns {any} 서버 응답
 */
export default function* asyncApiHelper(api: any, ...args: any[]) {
  // api 호출
  const result: ApiHelperResponseType = yield call(api, args.length === 1 ? args[0] : args);

  // 서버 응답 헬퍼
  yield apiResponseHelper(result);

  return result;
}
