import React, { useContext, useEffect, useMemo, useRef, useState } from "react";

import { OverlayContext } from "./OverlayProvider";
import { OverlayController, OverlayControlRef } from "./OverlayController";
import { OverlayElementType } from "./types";

let elementId = 1;

interface Options {
  exitOnUnmount?: boolean;
}

/**
 * open - dom에 UI를 렌더링 후 open을 true로 변경
 * close - open을 false로 변경(-> 사용하는 UI에서 단순히 display:none 처리 가능)
 * exit - UI자체를 언마운트 시킴
 * */
export const useOverlay = ({ exitOnUnmount = true }: Options = {}) => {
  const context = useContext(OverlayContext);

  if (context == null) {
    throw new Error("useOverlay is only available within OverlayProvider.");
  }

  const { mount, unmount } = context;
  const [id] = useState(() => String(elementId++));

  const overlayRef = useRef<OverlayControlRef | null>(null);

  useEffect(() => {
    return () => {
      if (exitOnUnmount) {
        unmount(id);
      }
    };
  }, [exitOnUnmount, id, unmount]);

  return useMemo(
    () => ({
      open: (overlayElement: OverlayElementType) => {
        mount(
          id,
          <OverlayController
            // NOTE: state should be reset every time we open an overlay
            key={Date.now()}
            ref={overlayRef}
            overlayElement={overlayElement}
            onExit={() => {
              unmount(id);
            }}
          />,
        );
      },
      close: () => {
        overlayRef.current?.close();
      },
      exit: () => {
        unmount(id);
      },
    }),
    [id, mount, unmount],
  );
};
