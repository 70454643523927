import qs from "qs";

import { superFetchV2 } from "../superFetchV2";
import { EventProductsFindRequestDTO } from "./type";
import { EventProductsFindResponseBodyDto } from "../types";

export const eventApiV2 = {
  getEventItemList: async (payload: EventProductsFindRequestDTO): Promise<EventProductsFindResponseBodyDto> => {
    const { eventId, ...rest } = payload;
    const queryParams = qs.stringify({ ...rest }, { skipNulls: true });
    return superFetchV2.get(`/events/${eventId}/products?${queryParams}`);
  },
};
