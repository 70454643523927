import { includes } from "lodash";
import {
  DELIVERY_TRACKING,
  DELIVERY_TRACKING_TEXT,
  DELIVERY_TRACKING_TYPE,
  ORDER_RETURN_EXCHANGE_RESPONSIBILITY_TYPE,
} from "./mypage";

// 마이페이지 메인 최근 주문 내역
export const MYPAGE_MAIN_RECENT_ORDER_HEAD_TEXT = "최근 주문";
export const MYPAGE_MAIN_RECENT_ORDER_DATE_TEXT = "주문일";
export const MYPAGE_MAIN_RECENT_ORDER_CONTENT_TEXT = "주문내역";
export const MYPAGE_MAIN_RECENT_ORDER_NUMBER_TEXT = "주문번호";
export const MYPAGE_MAIN_RECENT_ORDER_PRICE_TEXT = "결제금액";
export const MYPAGE_MAIN_RECENT_ORDER_EMPTY_TEXT = "주문하신 상품 내역이 없습니다.";

// 마이페이지 메인 내활동
export const MYPAGE_MY_ACTIVITY_HEAD_TEXT = "내 활동";

// 마이페이지 알림(구매확정, 후기작성)
export const MYPAGE_NOTIFICATION_PURCHASE_CONFIRM_HEAD_TEXT = "구매확정 요청";
export const MYPAGE_NOTIFICATION_PURCHASE_CONFIRM_CONTENT_TEXT = [
  "주문하신 상품을 잘 받으셨나요?",
  "구매확정 후, 후기작성 시 포인트를 드려요!",
];
export const MYPAGE_NOTIFICATION_PURCHASE_CONFIRM_BTN_TEXT = "구매확정 하기";
export const MYPAGE_NOTIFICATION_WRITE_REVIEW_HEAD_TEXT = "후기작성 요청";
export const MYPAGE_NOTIFICATION_WRITE_REVIEW_CONTENT_TEXT = [
  "구매하신 상품이 마음에 드셨나요?",
  "구매후기를 남겨주시면 ",
  "p를 드려요!",
];

// 마이페이지 쿠폰
export const MYPAGE_COUPON_HEAD_TEXT = "쿠폰";
export const MYPAGE_COUPON_REGISTRATION_LABEL_TEXT = "쿠폰 등록";
export const MYPAGE_COUPON_REGISTRATION_PLACEHOLDER_TEXT = "쿠폰 코드를 입력해 주세요";
export const MYPAGE_COUPON_REGISTRATION_SUBMIT_INFO_TEXT = "쿠폰이 등록되었습니다.";
export const MYPAGE_COUPON_TABLE_CAPTION_TEXT = "나의 쿠폰 ";
export const MYPAGE_COUPON_TABLE_CAPTION_AMOUNT_TEXT = "개";
export const MYPAGE_COUPON_TABLE_HEAD_TEXT = ["할인", "쿠폰 정보", "적용가능 기간"];
export const MYPAGE_COUPON_DISCOUNT_TYPE_TEXT = ["원 할인", "% 할인"];
export const MYPAGE_COUPON_DATE_INFINITE_TEXT = "무기한";
export const MYPAGE_COUPON_AVAILABLE_TYPE_TEXT = ["전상품 적용가능", "적용상품 보기", "적용브랜드 보기"];
export const MYPAGE_COUPON_EMPTY_TEXT = "적용가능한 쿠폰이 없습니다.";
export const MYPAGE_COUPON_INFO_LIST_TITLE_TEXT = "쿠폰 유의사항";
export const MYPAGE_COUPON_INFO_LIST_CONTENT_TEXT = [
  "할인중인 상품에도 쿠폰 적용이 가능합니다.",
  "쿠폰은 주문 시, 전체 주문의 결제금액이 아닌, 개별 상품에만 적용이 가능합니다.",
  "할인쿠폰의 적용대상은 쿠폰마다 다르며, 대상 상품은 변경될 수 있습니다.",
  "할인쿠폰은 정해진 기간에 따라 사전고지 없이 변경 또는 종료 될 수 있습니다.",
];
// 마이페이지 쿠폰 적용 가능 상품
export const MYPAGE_PRODUCT_COUPON_HEAD_TEXT = "적용가능 상품";
// 마이페이지 쿠폰 적용 가능 브랜드
export const MYPAGE_BRAND_COUPON_HEAD_TEXT = "적용가능 브랜드";

// 마이페이지 포인트
export const MYPAGE_POINT_STATUS_TEXT = "포인트 현황";
export const MYPAGE_POINT_STATUS_CREDIT_TEXT = "현재포인트";
export const MYPAGE_POINT_STATUS_USED_CREDIT_TEXT = "사용한 포인트";
export const MYPAGE_POINT_TABLE_TEXT = "포인트 내역";
export const MYPAGE_POINT_TABLE_HEAD_TEXT = ["포인트", "내용", "적용일자"];
export const MYPAGE_POINT_EMPTY_TEXT = "사용가능한 포인트가 없습니다.";
export const MYPAGE_POINT_INFO_LIST_TITLE_TEXT = "포인트 사용기준 및 사용기한 ";
export const MYPAGE_POINT_INFO_LIST_CONTENT_TEXT = [
  "포인트는 현금처럼 사용 가능하며, 1원단위까지 사용 가능합니다.",
  "포인트는 부여된 순서로 사용됩니다.",
  "포인트 적립 내역은 배송 완료 이후 확인할 수 있습니다.",
];

// 마이페이지 좋아요한 상품
export const MYPAGE_MY_ACTIVITY_LIKE_PRODUCT_TEXT = "좋아요한 상품";
export const MYPAGE_MY_ACTIVITY_LIKE_PRODUCT_EMPTY_TEXT = "좋아요한 상품이 없습니다.";
export const MYPAGE_MY_ACTIVITY_LIKE_PRODUCT_EMPTY_COMPONENT_TEXT = [
  "아직 좋아요한 상품이 없습니다.",
  "마음에 드는 상품은 ♡를 눌러 좋아요할 수 있어요!",
];
export const MYPAGE_MY_ACTIVITY_LIKE_PRODUCT_EMPTY_BTN_TEXT = "추천 상품 보러가기";

// 마이페이지 좋아요한 브랜드
export const MYPAGE_MY_ACTIVITY_LIKE_BRAND_TEXT = "좋아요한 브랜드";
export const MYPAGE_MY_ACTIVITY_LIKE_BRAND_NEW_ITEM_TEXT = ["개의 ", "신상품"];
export const MYPAGE_MY_ACTIVITY_LIKE_BRAND_EMPTY_TEXT = "좋아요한 브랜드가 없습니다.";
export const MYPAGE_MY_ACTIVITY_LIKE_BRAND_EMPTY_COMPONENT_TEXT = [
  "아직 좋아요한 브랜드가 없습니다.",
  "마음에 드는 브랜드는 ♡를 눌러 좋아요할 수 있어요!",
];
export const MYPAGE_MY_ACTIVITY_LIKE_BRAND_EMPTY_BTN_TEXT = "추천 브랜드 보러가기";
export const MYPAGE_MY_ACTIVITY_LIKE_BRAND_EMPTY_ITEM_TEXT = "브랜드에서 상품을 준비중입니다.";

// 마이페이지 주문내역
export const MYPAGE_ORDER_LIST_HEAD_TEXT = "주문내역";
export const MYPAGE_ORDER_DETAIL_HEAD_TEXT = "주문상세내역";
export const MYPAGE_ORDER_CANCEL_HEAD_TEXT = "주문취소";
export const MYPAGE_ORDER_CANCEL_REQ_HEAD_TEXT = "주문취소요청";
export const MYPAGE_ORDER_RETURN_HEAD_TEXT = "반품요청";
export const MYPAGE_ORDER_EXCHANGE_HEAD_TEXT = "교환요청";
export const MYPAGE_ORDER_INFO_GIFT_TEXT = "선물 상품";
export const MYPAGE_ORDER_INFO_DATE_TEXT = "주문일";
export const MYPAGE_ORDER_INFO_ID_TEXT = "주문번호";
export const MYPAGE_ORDER_LIST_INFO_LINK_TEXT = "주문상세보기";
export const MYPAGE_ORDER_CONFIRM_BTN_TEXT = "구매확정";
export const MYPAGE_ORDER_GIFT_TITLE_TEXT = "선물 받는 분 정보";
export const MYPAGE_ORDER_GIFT_RECIPIENT_TEXT = "선물 받는 분";
export const MYPAGE_ORDER_GIFT_INFO_TEXT = "선물 받는 분이 7일 이내 배송지 미입력 시 주문이 자동 취소됩니다.";
export const MYPAGE_ORDER_SHIPPING_TITLE_TEXT = "배송지 정보";
export const MYPAGE_ORDER_SHIPPING_SUB_TITLE_TEXT = "* 최초 결제 시 입력한 배송지 정보입니다.";
export const MYPAGE_ORDER_SHIPPING_RECIPIENT_TEXT = "받는 사람";
export const MYPAGE_ORDER_SHIPPING_CELLPHONE_TEXT = "연락처";
export const MYPAGE_ORDER_SHIPPING_ADDRESS_TEXT = "배송주소";
export const MYPAGE_ORDER_SHIPPING_MESSAGE_TEXT = "배송시 요청사항";
export const MYPAGE_ORDER_PAYMENT_TITLE_TEXT = "결제 정보";
export const MYPAGE_ORDER_PAYMENT_ORDER_DATE_TEXT = "주문일시";
export const MYPAGE_ORDER_PAYMENT_PAID_DATE_TEXT = "결제일시";
export const MYPAGE_ORDER_PRICE_TITLE_TEXT = "주문금액 확인";
export const MYPAGE_ORDER_PRICE_TOTAL_ITEM_PRICE_TEXT = "주문상품 금액";
export const MYPAGE_ORDER_PRICE_TOTAL_DELIVERY_PRICE_TEXT = "배송비";
export const MYPAGE_ORDER_PRICE_TOTAL_USED_COUPON_PRICE_TEXT = "쿠폰 사용";
export const MYPAGE_ORDER_PRICE_TOTAL_USED_POINT_TEXT = "포인트 사용";
export const MYPAGE_ORDER_PRICE_TOTAL_PAID_PRICE_TEXT = "총 결제금액";
export const MYPAGE_ORDER_INFO_ITEM_OPTION_NULL_TEXT = "옵션 : 없음";
export const MYPAGE_ORDER_INFO_ITEM_DISCOUNT_DETAIL_BTN_TEXT = "할인내역";
export const MYPAGE_ORDER_INFO_ITEM_CONFIRM_PURCHASE_BTN_TEXT = "구매확정";
export const MYPAGE_ORDER_INFO_ITEM_SHIPPING_DELAY_REASON_BTN_TEXT = "배송지연사유";
export const MYPAGE_ORDER_INFO_ITEM_CANCEL_ORDER_BTN_TEXT = "주문취소";
export const MYPAGE_ORDER_INFO_ITEM_CANCEL_ORDER_REQ_BTN_TEXT = "주문취소요청";
export const MYPAGE_ORDER_INFO_ITEM_UPDATE_ORDER_DESTINATION_BTN_TEXT = "배송지변경";
export const MYPAGE_ORDER_INFO_ITEM_SELECT_SHIPPING_BTN_TEXT = "배송조회";
export const MYPAGE_ORDER_INFO_ITEM_REQUEST_RETURN_EXCHANGE_BTN_TEXT = "교환/반품요청";
export const MYPAGE_ORDER_INFO_ITEM_CANCEL_REQUEST_REASON_BTN_TEXT = "취소요청사유";
export const MYPAGE_ORDER_INFO_ITEM_CANCEL_REJECT_REASON_BTN_TEXT = "취소거부사유";
export const MYPAGE_ORDER_INFO_ITEM_CANCEL_REASON_BTN_TEXT = "취소사유";
export const MYPAGE_ORDER_INFO_ITEM_SELLER_CANCEL_REASON_BTN_TEXT = "판매자취소사유";
export const MYPAGE_ORDER_INFO_ITEM_RETURN_REQUEST_REASON_BTN_TEXT = "반품요청사유";
export const MYPAGE_ORDER_INFO_ITEM_RETURN_REJECT_REASON_BTN_TEXT = "반품거부사유";
export const MYPAGE_ORDER_INFO_ITEM_RETURN_CONFIRM_REASON_BTN_TEXT = "반품승인사유";
export const MYPAGE_ORDER_INFO_ITEM_RETURN_DONE_REASON_BTN_TEXT = "반품사유";
export const MYPAGE_ORDER_INFO_ITEM_EXCHANGE_REQUEST_REASON_BTN_TEXT = "교환요청사유";
export const MYPAGE_ORDER_INFO_ITEM_EXCHANGE_REJECT_REASON_BTN_TEXT = "교환거부사유";
export const MYPAGE_ORDER_INFO_ITEM_EXCHANGE_DONE_REASON_BTN_TEXT = "교환사유";

// 주문 상세 모달
export const MYPAGE_ORDER_DISCOUNT_DETAIL_MODAL_TITLE_TEXT = "할인상세내역";
export const MYPAGE_ORDER_DISCOUNT_DETAIL_MODAL_PRODUCT_PRICE_TEXT = "주문상품 금액";
export const MYPAGE_ORDER_DISCOUNT_DETAIL_MODAL_USED_COUPON_TEXT = "쿠폰사용";
export const MYPAGE_ORDER_DISCOUNT_DETAIL_MODAL_COUPON_NAME_TEXT = "쿠폰명";
export const MYPAGE_ORDER_DISCOUNT_DETAIL_MODAL_USED_POINT_TEXT = "포인트 사용";
export const MYPAGE_ORDER_DISCOUNT_DETAIL_MODAL_TOTAL_PRICE_TEXT = "결제 금액";

export const MYPAGE_ORDER_QNA_INFO_MODAL_TITLE_TEXT = "일반 상품 문의 안내";
export const MYPAGE_ORDER_DESTINATION_MODAL_TITLE_TEXT = "배송지 변경";
export const MYPAGE_ORDER_DESTINATION_MODAL_PREV_DESTINATION_TEXT = "기본 배송지 정보";
export const MYPAGE_ORDER_DESTINATION_MODAL_NEXT_DESTINATION_TEXT = "새 배송지 정보";
export const MYPAGE_ORDER_DESTINATION_MODAL_NEXT_DESTINATION_INFO_TEXT = [
  "*주문 건 전체 변경이 아닙니다. ",
  "배송지 변경은 해당 상품에만 적용됩니다.",
];
export const MYPAGE_ORDER_DESTINATION_MODAL_ONE_BTN_TEXT = "배송지가 변경되었습니다.";
export const MYPAGE_ORDER_DESTINATION_MODAL_TWO_BTN_MSG_TEXT = "해당상품의 배송지를\n변경한 이력이 있습니다.";
export const MYPAGE_ORDER_DESTINATION_MODAL_TWO_BTN_MSG_MOBILE_TEXT = "해당상품의 배송지를 변경한 이력이 있습니다.";
export const MYPAGE_ORDER_DESTINATION_MODAL_TWO_BTN_SUB_MSG_TEXT = "다시 변경하시겠습니까?";
export const MYPAGE_ORDER_DESTINATION_MODAL_TWO_BTN_RIGHT_TEXT = "변경하기";

export const MYPAGE_ORDER_DELIVERY_TRACKING_MODAL_TITLE_TEXT = "배송추적";
export const MYPAGE_ORDER_DELIVERY_TRACKING_MODAL_DELIVERY_STATUS_TEXT = "배송상태 : ";
export const MYPAGE_ORDER_DELIVERY_TRACKING_MODAL_POST_MAN_TEXT = "담당자:";
export const MYPAGE_ORDER_DELIVERY_TRACKING_MODAL_DELIVERY_NUMBER_TEXT = "운송장번호";
export const MYPAGE_ORDER_DELIVERY_TRACKING_MODAL_DELIVERY_ENTERPRISE_TEXT = "택배사";
export const MYPAGE_ORDER_DELIVERY_TRACKING_MODAL_DELIVERY_ADDRESS_TEXT = "수령주소";
export const MYPAGE_ORDER_DELIVERY_TRACKING_MODAL_WHERE_TEXT = "위치";
export const MYPAGE_ORDER_DELIVERY_TRACKING_MODAL_STATUS_TEXT = "상태";
export const MYPAGE_ORDER_DELIVERY_TRACKING_MODAL_TIME_TEXT = "처리시간";

export const MYPAGE_ORDER_ETC_TYPE_MODAL_TITLE_TEXT = (type: DELIVERY_TRACKING_TYPE) =>
  `${includes([DELIVERY_TRACKING.VISIT, DELIVERY_TRACKING.ETC], type) ? "으" : ""}로 배송된 주문 건입니다.`;
export const MYPAGE_ORDER_ETC_TYPE_MODAL_CONTENT_TEXT = (type: DELIVERY_TRACKING_TYPE) => [
  `${DELIVERY_TRACKING_TEXT[type]} 배송 건은 배송현황이\n확인되지 않습니다.`,
  "배송 관련 문의 사항은 판매자에게\n확인 부탁드립니다.",
];

// 주문 공통 - 판매자 정보
export const MYPAGE_ORDER_COMMON_STORE_INFO_TITLE_TEXT = "판매자 정보";
export const MYPAGE_ORDER_COMMON_STORE_NAME_TEXT = "판매자";
export const MYPAGE_ORDER_COMMON_STORE_PHONE_TEXT = "연락처";
export const MYPAGE_ORDER_COMMON_STORE_ADDRESS_TEXT = "반송지 정보";

export const MYPAGE_ORDER_SUCCESS_LEFT_BTN_TEXT = "주문내역 조회";
export const MYPAGE_ORDER_SUCCESS_RIGHT_BTN_TEXT = "쇼핑 계속하기";
export const MYPAGE_ORDER_TODAY_DELIVERY_SUCCESS_RIGHT_BTN_TEXT = "재주문하기";

/**
 *
 * 배송 지연 사유 모달
 *
 */
// 취소 거부
export const MYPAGE_ORDER_SHIPPING_DELAY_TITLE_TEXT = "배송이 지연되고있어요.";
export const MYPAGE_ORDER_SHIPPING_DELAY_CONFIRM_TEXT = "배송지연상품 확인";
export const MYPAGE_ORDER_SHIPPING_DELAY_TYPE_TEXT = "배송지연사유 : ";
export const MYPAGE_ORDER_SHIPPING_DELAY_DATE_TEXT = "발송기한";
export const MYPAGE_ORDER_SHIPPING_DELAY_NOTION_TEXT = "추가적인 문의 사항은 판매자에게 확인 부탁드립니다.";

/**
 *
 * 주문 취소
 *
 */
// 취소 요청
export const MYPAGE_ORDER_CANCEL_MOBILE_TITLE_TEXT = "취소상품 확인";
export const MYPAGE_ORDER_CANCEL_PRODUCT_INFO_TEXT = "상품정보";
export const MYPAGE_ORDER_CANCEL_PRODUCT_SPREAD_TEXT = ["총", "의 상품 펼쳐보기"];
export const MYPAGE_ORDER_CANCEL_CAUSE_TITLE_TEXT = "취소 사유";
export const MYPAGE_ORDER_CANCEL_REQ_CAUSE_TITLE_TEXT = "취소요청 사유";
export const MYPAGE_ORDER_CANCEL_CAUSE_DROP_DOWN_TITLE_TEXT = "취소유형선택";
export const MYPAGE_ORDER_CANCEL_CAUSE_INPUT_PLACEHOLDER_TEXT = [
  "최소 5자 이상 입력해 주세요.",
  "자세히 적어주시면 빠른 진행에 많은 도움이 됩니다.\n(최소 5자 / 최대 200자)",
];
export const MYPAGE_ORDER_CANCEL_INFO_CONFIRM_TITLE_TEXT = "취소정보 확인";
export const MYPAGE_ORDER_CANCEL_INFO_CONFIRM_DESCRIPTION_TEXT = [
  "(상품 결제수단으로 환불)",
  "(결제 시 출금된 계좌로 환불)",
  "(아래의 입력된 계좌로 환불)",
];
export const MYPAGE_ORDER_CANCEL_REFUND_AMOUNT_TITLE_TEXT = "최종 환불금액";
export const MYPAGE_ORDER_CANCEL_REFUND_AMOUNT_PRODUCT_PRICE_TEXT = "상품 결제금액";
export const MYPAGE_ORDER_CANCEL_REFUND_AMOUNT_EXPECT_PRICE_TEXT = "예상 환불금액";
export const MYPAGE_ORDER_CANCEL_INFO_LIST_TEXT = [
  "주문 취소 완료 시 사용한 적립금과 쿠폰이 자동 환급됩니다.",
  "부분 취소/환불 시 최종 주문금액이 할인쿠폰 적용 기준을 충족하지\n못 할 경우, 환불금액에서 쿠폰 할인액이 차감될 수 있습니다.",
  "주문취소 후 즉시 1~3일 이내에 카드사(결제대행사)를 통해\n취소내역을 확인하실 수 있습니다.",
  "가상계좌로 결제하신 경우 주문하신 분 계좌로 결제대행사를 통해\n1~3일 이내 환불처리됩니다.",
  "계좌환불시 예금주가 주문자 정보와 상이할 경우 환불지연될 수\n있습니다.",
];
export const MYPAGE_ORDER_CANCEL_REQ_INFO_LIST_TEXT = [
  "주문 취소 완료 시 사용한 적립금과 쿠폰이 자동 환급됩니다.",
  "부분 취소/환불 시 최종 주문금액이 할인쿠폰 적용 기준을 충족하지\n못 할 경우, 환불금액에서 쿠폰 할인액이 차감될 수 있습니다.",
  "계좌환불시 예금주가 주문자 정보와 상이할 경우 환불지연될 수\n있습니다.",
  "판매자의 확인이 필요합니다. 주문제작/배송 여부에 따라 취소가\n어려울 수 있습니다.",
];
export const MYPAGE_ORDER_CANCEL_INFO_PENDING_LIST_TEXT = [
  "주문 취소 완료 시 사용한 적립금과 쿠폰이 자동 환급됩니다.",
  "입금대기 상품의 주문 취소 시, 함께 주문한 상품이 일괄 취소됩니다.",
];
export const MYPAGE_ORDER_CANCEL_SUBMIT_BTN_TEXT = "주문취소 하기";
export const MYPAGE_ORDER_CANCEL_REQUEST_SUBMIT_BTN_TEXT = "주문취소 요청하기";
// 취소 완료
export const MYPAGE_ORDER_CANCEL_SUCCESS_TITLE_TEXT = "주문 취소가 완료되었어요.";
export const MYPAGE_ORDER_CANCEL_SUCCESS_DESCRIPTION_TEXT = [
  "PG사에 결제 취소 요청이 전달되었습니다.",
  "은행, 카드사에 따라 영업일 (주말 공휴일 제외)\n기준 1~3일 이내에 환불이 진행됩니다.",
];
export const MYPAGE_ORDER_CANCEL_SUCCESS_TOTAL_PRICE_TEXT = "최종 환불금액";
export const MYPAGE_ORDER_CANCEL_SUCCESS_CANCEL_TYPE_TEXT = "취소사유 : ";
export const MYPAGE_ORDER_CANCEL_SUCCESS_CANCEL_REQUEST_DATE_TEXT = "취소요청일시";
export const MYPAGE_ORDER_CANCEL_SUCCESS_CANCEL_COMPLETE_DATE_TEXT = "취소완료일시";
// 취소 요청 완료
export const MYPAGE_ORDER_CANCEL_REQUEST_TITLE_TEXT = "주문 취소가 요청되었어요.";
export const MYPAGE_ORDER_CANCEL_REQUEST_DESCRIPTION_TEXT =
  "판매자의 확인이 필요합니다.\n주문제작/배송 여부에 따라 취소가 어려울 수 있습니다.";
export const MYPAGE_ORDER_CANCEL_REQUEST_LIMIT_DATE_TEXT =
  "까지 판매자가 승인 혹은 거부하지 않으면 \n자동으로 취소 완료됩니다.";
export const MYPAGE_ORDER_CANCEL_REQUEST_PRODUCT_CONFIRM_TEXT = "취소요청상품 확인";
export const MYPAGE_ORDER_CANCEL_REQUEST_REFUND_CONFIRM_TEXT = "환불정보 확인";
export const MYPAGE_ORDER_CANCEL_REQUEST_EXPECT_AMOUNT_TEXT = ["예상 환불금액:", "원"];
export const MYPAGE_ORDER_CANCEL_REQUEST_WITHDRAW_BTN_TEXT = "취소요청 철회";
export const MYPAGE_ORDER_CANCEL_REQUEST_DATE_TEXT = "취소요청일시";
export const MYPAGE_ORDER_CANCEL_REQUEST_BTN_TEXT = "구매확정 하기";
// 취소 거부
export const MYPAGE_ORDER_CANCEL_REJECT_TITLE_TEXT = "취소가 거부되었어요.";
export const MYPAGE_ORDER_CANCEL_REJECT_DATE_TEXT = "취소거부일시";
export const MYPAGE_ORDER_CANCEL_REJECT_INFO_TEXT = "추가적인 문의 사항은 판매자에게 확인 부탁드립니다.";
export const MYPAGE_ORDER_CANCEL_REJECT_REASON_TEXT = "취소거부사유";
// 환불
export const MYPAGE_ORDER_REFUND_REQUEST_PRODUCT_CONFIRM_TEXT = "반품요청상품 확인";
// 판매자 취소
export const MYPAGE_ORDER_SELLER_CANCEL_TITLE_TEXT = "판매가 취소되었어요.";
export const MYPAGE_ORDER_SELLER_CANCEL_TYPE_TEXT = "판매취소사유 : ";
export const MYPAGE_ORDER_SELLER_CANCEL_DATE_TEXT = "취소일시";
export const MYPAGE_ORDER_SELLER_CANCEL_INFO_TEXT = "추가적인 문의 사항은 판매자에게 확인 부탁드립니다.";
export const MYPAGE_ORDER_SELLER_CANCEL_REFUND_CONFIRM_TEXT = "환불정보 확인";
export const MYPAGE_ORDER_SELLER_CANCEL_TOTAL_REFUND_PRICE_TEXT = "최종환불 금액";
export const MYPAGE_ORDER_SELLER_CANCEL_TOTAL_REFUND_PRICE_INFO_TEXT = "영업일 기준 1~3일 이내 환불처리됩니다.";

/**
 *
 * 주문 반품 & 교환 공통
 *
 */
export const MYPAGE_ORDER_RETURN_EXCHANGE_MODAL_TITLE_TEXT = "교환 및 반품을 진행하시겠습니까?";
export const MYPAGE_ORDER_RETURN_EXCHANGE_MODAL_CONTEXT_TEXT =
  "교환 및 반품요청을 선택하시고\n다음단계를 진행해주세요.";
export const MYPAGE_ORDER_RETURN_EXCHANGE_MODAL_EXCHANGE_BTN_TEXT = "교환요청";
export const MYPAGE_ORDER_RETURN_EXCHANGE_MODAL_TODAY_DELIVERY_EXCHANGE_BTN_TEXT = "동일한 옵션으로 교환 요청";
export const MYPAGE_ORDER_RETURN_EXCHANGE_MODAL_RETURN_BTN_TEXT = "반품요청";
export const MYPAGE_ORDER_RETURN_EXCHANGE_SUBMIT_MODAL_BTN_TEXT = "등록완료";

/**
 *
 * 주문 반품
 *
 */
// 반품 요청
export const MYPAGE_ORDER_RETURN_MOBILE_TITLE_TEXT = "반품상품 확인";
export const MYPAGE_ORDER_RETURN_CANCEL_CAUSE_TITLE_TEXT = "반품요청 사유";
export const MYPAGE_ORDER_RETURN_CANCEL_CAUSE_TYPE_LIST_TITLE_TEXT = "반품요청 유형 선택";
export const MYPAGE_ORDER_RETURN_REFUND_ACCOUNT_TITLE_TEXT = "환불받으실 계좌를 입력해 주세요";
export const MYPAGE_ORDER_RETURN_INFO_LIST_TEXT = [
  "아몬즈 정책에 따라 환불 완료 및 거부 시에 초기 배송비를 \n제외하고 환불처리 될 수 있습니다.",
  "현재 아몬즈에는 자동 수거 서비스가 없습니다. 직접 택배를 \n보내주시기 바랍니다.",
  "반품신청 등록 후 판매자를 통해 고객님께 반품 과정에 대한 친절한 \n상담이 가능합니다.",
  "제품 사용, 오염, 전용 박스 손상, 라벨 제거, 사은품 및 부속 \n사용/분실 시, 교환/환불이 불가능합니다.",
  "유선전화 접수 : 판매자 고객센터 번호로 영업시간내 연락\n주시면 접수 가능합니다.",
];
export const MYPAGE_ORDER_RETURN_INFO_TODAY_DELIVERY_LIST_TEXT = [
  "아몬즈 정책에 따라 환불 완료 및 거부 시에 초기 배송비를 \n제외하고 환불처리 될 수 있습니다.",
  "오늘출발 상품은 자동수거 서비스가 적용됩니다.\n텍배 기사님의 방문일에 맞춰 수거 장소에 상품을 준비해 주세요.",
  "반품신청 등록 후 판매자를 통해 고객님께 반품 과정에 대한 친절한 \n상담이 가능합니다.",
  "제품 사용, 오염, 전용 박스 손상, 라벨 제거, 사은품 및 부속 \n사용/분실 시, 교환/환불이 불가능합니다.",
  "유선전화 접수 : 판매자 고객센터 번호로 영업시간내 연락\n주시면 접수 가능합니다.",
];
// 반품 요청 완료
export const MYPAGE_ORDER_RETURN_SUCCESS_TITLE_TEXT = "반품요청이 등록되었어요.";
export const MYPAGE_ORDER_RETURN_SUCCESS_PRODUCT_CONFIRM_TEXT = "반품요청상품 확인";
export const MYPAGE_ORDER_RETURN_SUCCESS_TYPE_TEXT = "반품사유 : ";
export const MYPAGE_ORDER_RETURN_SUCCESS_REFUND_INFO_TEXT = "환불정보 확인";
export const MYPAGE_ORDER_RETURN_SUCCESS_DESCRIPTION_TEXT = [
  "현재 아몬즈에는 자동 수거 서비스가 없습니다.\n직접 택배를 보내주시기 바랍니다.",
  "4일(영업일 기준) 이내 발송하지 않으면,\n판매자에 의해 반품이 거부될 수 있습니다.",
];
export const MYPAGE_ORDER_RETURN_TODAY_DELIVERY_SUCCESS_DESCRIPTION_TEXT = [
  "오늘출발 상품은 자동 수거됩니다.",
  "택배 기사님의 방문 일자에 맞춰 수거 장소에 상품을",
  "준비해주세요. 4일(영업일 기준) 이내 발송하지 않으면,",
  "반품이 거부될 수 있습니다.",
];
export const MYPAGE_ORDER_RETURN_EXCHANGE_SUCCESS_REASON_NOTION_GENERAL_BUYER_TEXT = [
  "추가적인 문의 사항은 판매자에게 확인 부탁드립니다.",
  "받으신 택배 내에 반품 주소지 혹은 반품 방법 안내가 있다면, **해당 주소**로 안내에 따라 선불/착불 택배를 보내주세요.",
  "받으신 택배 내에 반품 주소지 혹은 반품 방법 안내가 없다면, **배송 받은 택배사**를 통해 **직접 반품 신청(선불)**을 해주세요.",
];
export const MYPAGE_ORDER_RETURN_EXCHANGE_SUCCESS_REASON_NOTION_GENERAL_SELLER_TEXT = [
  "추가적인 문의 사항은 판매자에게 확인 부탁드립니다.",
  "받으신 택배 내에 반품 주소지 혹은 반품 방법 안내가 있다면, **해당 주소**로 안내에 따라 선불/착불 택배를 보내주세요.",
  "받으신 택배 내에 반품 주소지 혹은 반품 방법 안내가 없다면, **배송 받은 택배사**를 통해 **직접 반품 신청(착불)**을 해주세요.",
];
export const MYPAGE_ORDER_RETURN_EXCHANGE_SUCCESS_REASON_NOTION_TODAY_DELIVERY_BUYER_TEXT = [
  "추가적인 문의 사항은 판매자에게 확인 부탁드립니다.",
  "오늘출발 상품은 **자동 수거됩니다.** 구매자 사유로 인한 반품의 경우, 수거 비용은 구매자가 부담합니다.",
];
export const MYPAGE_ORDER_RETURN_EXCHANGE_SUCCESS_REASON_NOTION_TODAY_DELIVERY_SELLER_TEXT = [
  "추가적인 문의 사항은 판매자에게 확인 부탁드립니다.",
  "오늘출발 상품은 **자동 수거됩니다.** 판매자 사유로 인한 반품의 경우, 수거 비용은 판매자가 부담합니다.",
];
export const MYPAGE_ORDER_EXCHANGE_CAUTION_NOTION_TODAY_DELIVERY_TEXT =
  "오늘출발 상품은 동일한 옵션으로만 교환이 가능합니다.\n다른 옵션으로 교환을 요청하실 경우, 반품 후 재주문해주세요.";
export const MYPAGE_ORDER_RETURN_SUCCESS_PAID_AMOUNT_TEXT = "고객 결제금액";
export const MYPAGE_ORDER_RETURN_SUCCESS_DELIVERY_FEE_TEXT = "최초 무료배송비";
export const MYPAGE_ORDER_RETURN_SUCCESS_CUSTOMER_LEVY_TEXT = "예상 고객부담금";
export const MYPAGE_ORDER_RETURN_SUCCESS_EXPECT_AMOUNT_TEXT = "예상 환불금액";
export const MYPAGE_ORDER_RETURN_SUCCESS_REFUND_NOTION_TEXT = [
  "아몬즈에서는 전 상품 무료 배송 서비스를 제공하고 있습니다.\n단, 구매자 사유로 반품해 주시는 경우 최초 무료배송비를 구매자가 부담하게 됩니다.",
  "최초 무료배송비와 추가청구비의 합이 결제금액을 초과할 경우, 별도의 고객부담금이 발생할 수 있습니다.",
  "구매자 사유로 인한 부분 반품의 경우, 브랜드별 반품 정책에 따라 최초 배송비 차감 여부가 변경될 수 있습니다.",
];
export const MYPAGE_ORDER_RETURN_SUCCESS_REFUND_NOTION_SELLER_TEXT = [
  "판매자 책임 사유이므로 최초 배송비는 판매자가 부담합니다.",
];
export const MYPAGE_ORDER_RETURN_SUCCESS_WITHDRAW_BTN_TEXT = "반품요청 철회";
// 반품 거부
export const MYPAGE_ORDER_RETURN_REJECT_TITLE_TEXT = "반품이 거부되었어요.";
export const MYPAGE_ORDER_RETURN_REJECT_REASON_TITLE_TEXT = "반품거부사유";
export const MYPAGE_ORDER_RETURN_REJECT_REASON_DATE_TEXT = "반품거부일시";
export const MYPAGE_ORDER_RETURN_REJECT_REASON_NOTION_TEXT = "추가적인 문의 사항은 판매자에게 확인 부탁드립니다.";
// 반품 승인
export const MYPAGE_ORDER_RETURN_CONFIRM_TITLE_TEXT = "반품요청이 승인되었어요.";
export const MYPAGE_ORDER_RETURN_CONFIRM_DESCRIPTION_TEXT = [
  "판매자가 책정한 금액이 맞는지 확인 중입니다.",
  "확인 후 반품 완료 처리됩니다.",
];
export const MYPAGE_ORDER_RETURN_CONFIRM_REASON_DATE_TEXT = "반품요청일시";
export const MYPAGE_ORDER_RETURN_CONFIRM_DATE_TEXT = "반품승인일시";
export const MYPAGE_ORDER_RETURN_CONFIRM_REASON_TITLE_TEXT = "반품승인사유";
export const MYPAGE_ORDER_RETURN_CONFIRM_CUSTOMER_CHARGE_TEXT = "고객부담금";
export const MYPAGE_ORDER_RETURN_CONFIRM_ADDITIONAL_CHARGE_TEXT = "추가청구비";
export const MYPAGE_ORDER_RETURN_CONFIRM_REASON_TEXT = (type: ORDER_RETURN_EXCHANGE_RESPONSIBILITY_TYPE) => {
  if (type === 0) {
    return ["판매자 책임 사유이므로 최초 배송비는 판매자가 부담합니다."];
  }

  return [
    "아몬즈에서는 전 상품 무료 배송 서비스를 제공하고 있습니다. 단, 구매 \n사유로 반품해 주시는 경우 최초 무료배송비를 구매자가 부담하게 됩니다.",
    "최초 무료배송비와 추가청구비의 합이 결제금액을 초과할 경우, 별도의 \n고객부담금이 발생할 수 있습니다.",
    "구매자 사유로 인한 부분 반품의 경우, 브랜드별 반품 정책에 따라 \n최초 배송비차감 여부가 변경될 수 있습니다.",
  ];
};
// 반품 완료
export const MYPAGE_ORDER_RETURN_DONE_TITLE_TEXT = "반품이 완료되었어요.";
export const MYPAGE_ORDER_RETURN_DONE_REFUND_INFO_TITLE_TEXT = "환불정보 확인";
export const MYPAGE_ORDER_RETURN_DONE_ACTUAL_AMOUNT_TEXT = "최종 환불금액";

/**
 *
 * 주문 교환
 *
 */
// 교환 요청
export const MYPAGE_ORDER_EXCHANGE_MOBILE_TITLE_TEXT = "교환상품 확인";
export const MYPAGE_ORDER_EXCHANGE_CANCEL_CAUSE_TITLE_TEXT = "교환요청 사유";
export const MYPAGE_ORDER_EXCHANGE_CANCEL_CAUSE_TITLE_INFO_TEXT = "*교환 받으실 상품의 옵션이 다르다면 꼭 적어주세요.";
export const MYPAGE_ORDER_EXCHANGE_TODAY_DELIVERY_CANCEL_CAUSE_TITLE_INFO_TEXT =
  "*오늘출발 상품은 동일한 옵션으로만 교환이 가능합니다.";
export const MYPAGE_ORDER_EXCHANGE_CANCEL_CAUSE_TYPE_LIST_TITLE_TEXT = "교환요청 유형 선택";
export const MYPAGE_ORDER_EXCHANGE_INFO_LIST_TEXT = [
  "교환을 원하시는 상품(사이즈)의 재고가 부족 시, 고객님께\n안내 후 환불처리 될 수 있습니다.",
  "현재 아몬즈에는 자동 수거 서비스가 없습니다. 직접 택배를 \n보내주시기 바랍니다.",
  "교환신청 등록 후 판매자를 통해 고객님께 교환 과정에 대한 친절한\n상담이 가능합니다.",
  "제품 사용, 오염, 전용 박스 손상, 라벨 제거, 사은품 및 부속\n사용/분실 시, 교환/환불이 불가능합니다.",
  "유선전화 접수 : 판매자 고객센터 번호로 영업시간 내 연락\n주시면 접수 가능합니다.",
];
export const MYPAGE_ORDER_EXCHANGE_INFO_DELIVERY_LIST_TEXT = [
  "교환을 원하시는 상품(사이즈)의 재고가 부족 시, 고객님께\n안내 후 환불처리 될 수 있습니다.",
  "오늘출발 상품은 자동수거 서비스가 적용됩니다.\n텍배 기사님의 방문일에 맞춰 수거 장소에 상품을 준비해 주세요.",
  "교환신청 등록 후 판매자를 통해 고객님께 교환 과정에 대한 친절한\n상담이 가능합니다.",
  "제품 사용, 오염, 전용 박스 손상, 라벨 제거, 사은품 및 부속\n사용/분실 시, 교환/환불이 불가능합니다.",
  "유선전화 접수 : 판매자 고객센터 번호로 영업시간 내 연락\n주시면 접수 가능합니다.",
];
// 교환 요청 완료
export const MYPAGE_ORDER_EXCHANGE_SUCCESS_TITLE_TEXT = "교환요청이 등록되었어요.";
export const MYPAGE_ORDER_EXCHANGE_SUCCESS_DESCRIPTION_TEXT = [
  "현재 아몬즈에는 자동 수거 서비스가 없습니다.\n직접 택배를 보내주시기 바랍니다.",
  "4일(영업일 기준) 이내 발송하지 않으면, 판매자에 의해\n교환이 거부될 수 있습니다.",
];
export const MYPAGE_ORDER_EXCHANGE_TODAY_DELIVERY_SUCCESS_DESCRIPTION_TEXT = [
  "오늘출발 상품은 자동 수거됩니다.",
  "택배 기사님의 방문 일자에 맞춰 수거 장소에 상품을",
  "준비해주세요. 4일(영업일 기준) 이내 발송하지 않으면,",
  "교환이 거부될 수 있습니다.",
];
export const MYPAGE_ORDER_EXCHANGE_SUCCESS_PRODUCT_CONFIRM_TEXT = "교환요청상품 확인";
export const MYPAGE_ORDER_EXCHANGE_SUCCESS_TYPE_TEXT = "교환사유 : ";
export const MYPAGE_ORDER_EXCHANGE_CONFIRM_REASON_DATE_TEXT = "교환요청일시";
export const MYPAGE_ORDER_EXCHANGE_SUCCESS_WITHDRAW_BTN_TEXT = "교환요청 철회";
// 교환 거부
export const MYPAGE_ORDER_EXCHANGE_REJECT_TITLE_TEXT = "교환이 거부되었어요.";
export const MYPAGE_ORDER_EXCHANGE_REJECT_REASON_TITLE_TEXT = "교환거부사유";
export const MYPAGE_ORDER_EXCHANGE_REJECT_REASON_DATE_TEXT = "교환거부일시";
// 교환 완료
export const MYPAGE_ORDER_EXCHANGE_DONE_TITLE_TEXT = "교환이 완료되었어요.";
export const MYPAGE_ORDER_EXCHANGE_DONE_NOTION_TEXT = "추가적인 문의 사항은 판매자에게 확인 부탁드립니다.";

// 마이페이지 상품 문의내역
export const MYPAGE_PRODUCT_QNA_HEAD_TEXT = "브랜드 문의내역";
export const MYPAGE_PRODUCT_QNA_STATUS_COMPLETE_TEXT = "답변완료";
export const MYPAGE_PRODUCT_QNA_STATUS_DELAY_TEXT = "답변대기";
export const MYPAGE_PRODUCT_QNA_EMPTY_TEXT = "등록된 상품 문의가 없습니다.";
export const MYPAGE_PRODUCT_QNA_DELETE_MSG_TEXT = "작성하신 문의를 삭제하시겠습니까?";
export const MYPAGE_PRODUCT_QNA_DELETE_SUB_MSG_TEXT = "삭제 후 복구가 불가능합니다.";
export const MYPAGE_PRODUCT_QNA_MODIFY_SUCCESS_TEXT = "수정되었습니다.";

// 마이페이지 회원정보 수정
export const MYPAGE_USER_MODIFY_HEAD_TEXT = "회원정보 수정";
export const MYPAGE_USER_MODIFY_USER_CHECK_CONTENT_TEXT = [
  "회원님의 소중한 정보 보호를 위해",
  "비밀번호를 재확인합니다.",
];
export const MYPAGE_USER_MODIFY_LOGIN_INFO_HEAD_TEXT = "로그인 정보";
export const MYPAGE_USER_MODIFY_LOGIN_INFO_SNS_MAIN_TEXT_V1 = "으로 로그인 하셨습니다.";
export const MYPAGE_USER_MODIFY_LOGIN_INFO_SNS_MAIN_TEXT_V2 = "로 로그인 하셨습니다.";
export const MYPAGE_USER_MODIFY_LOGIN_INFO_SNS_SUB_TEXT = "SNS로 가입하신 분들은 SNS계정에서 비밀번호를 바꿔주세요.";
export const MYPAGE_USER_MODIFY_LOGIN_INFO_SNS_SUB_MOBILE_TEXT =
  "SNS로 가입하신 분들은 SNS계정에서\n비밀번호를 바꿔주세요.";
export const MYPAGE_USER_MODIFY_PASSWORD_MODIFY_BTN_TEXT = "비밀번호 수정";
export const MYPAGE_USER_MODIFY_PASSWORD_MODIFY_TOGGLE_TEXT = "비밀번호가 수정되었습니다.";
export const MYPAGE_USER_MODIFY_USER_INFO_HEAD_TEXT = "회원 정보";
export const MYPAGE_USER_MODIFY_USER_INFO_NAME_PLACEHOLDER_TEXT = "이름을 입력해 주세요.";
export const MYPAGE_USER_MODIFY_USER_INFO_BIRTHDAY_PLACEHOLDER_TEXT = "날짜를 선택해주세요.";
export const MYPAGE_USER_MODIFY_USER_INFO_BIRTHDAY_INFO_TEXT =
  "아몬즈에서 생일 축하 쿠폰을 발급해드립니다. (계정당 연 1회)";
export const MYPAGE_USER_MODIFY_USER_INFO_SUBMIT_STATUS_TEXT = "회원정보가 수정되었습니다.";
export const MYPAGE_USER_MODIFY_ID_TEXT = "아이디";
export const MYPAGE_USER_MODIFY_NAME_TEXT = "이름";
export const MYPAGE_USER_MODIFY_CELL_PHONE_TEXT = "휴대폰 번호";
export const MYPAGE_USER_MODIFY_BIRTHDAY_TEXT = "생일";
export const MYPAGE_USER_MODIFY_GENDER_TEXT = "성별";

// 마이페이지 회원탈퇴
export const MYPAGE_USER_REMOVE_TEXT = "회원탈퇴";
export const MYPAGE_USER_REMOVE_MODAL_MAIN_TEXT = "정말 탈퇴 하시겠어요?";
export const MYPAGE_USER_REMOVE_MODAL_SUB_TEXT = [
  "탈퇴 시, 모든 정보가 삭제되며 향후 동일 ID로",
  "재가입하실 수 있습니다.",
  "탈퇴 이후에는 이전까지 적립했던 포인트 및 쿠폰은",
  "더 이상 사용하실 수 없습니다.",
];

// 마이페이지 메인 벳지
export const MYPAGE_MAIN_BADGE_TEXT = "받기";

// 마이페이지 더보기 버튼
export const MYPAGE_MORE_TEXT = "더보기";
