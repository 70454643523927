import React from "react";
import { ads_color } from "../../../tokens";
import { IconProps } from "../types";

const PlusLined = (props: IconProps) => {
  const { className, size = 17, stroke = ads_color.black } = props;
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M3.58337 8.5H12.9167" stroke="white" strokeWidth="0.8" strokeLinecap="square" />
      <path d="M8.25 13.1667V3.83333" stroke="white" strokeWidth="0.8" strokeLinecap="square" />
    </svg>
  );
};
export default PlusLined;
