import React from "react";
import { ads_color } from "../../../tokens";
import { IconProps } from "../types";

const BrandIcon = (props: IconProps) => {
  const { className, size = 24, stroke = ads_color.gray_02 } = props;
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M17.52 10.0003C17.52 14.1534 14.1532 17.5202 9.99998 17.5202C5.84681 17.5202 2.48 14.1534 2.48 10.0003C2.48 5.84709 5.84681 2.48027 9.99998 2.48027C14.1532 2.48027 17.52 5.84709 17.52 10.0003Z"
        stroke="black"
        strokeWidth="0.96"
      />
      <mask
        id="mask0_1619_5709"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="2"
        y="2"
        width="17"
        height="17"
      >
        <path
          d="M10.0005 18.0005C14.4187 18.0005 18.0005 14.4188 18.0005 10.0005C18.0005 5.58223 14.4187 2.00052 10.0005 2.00052C5.5822 2.00052 2.00049 5.58223 2.00049 10.0005C2.00049 14.4188 5.5822 18.0005 10.0005 18.0005Z"
          fill="black"
        />
      </mask>
      <g mask="url(#mask0_1619_5709)">
        <path d="M1.66016 10.171H18.2989" stroke="black" strokeWidth="0.96" />
        <path
          d="M11.6006 2.00122C12.9358 4.81788 13.6033 7.63455 13.6033 10.4512C13.6033 13.2679 12.9358 16.0801 11.6006 18.8878"
          stroke="black"
          strokeWidth="0.96"
        />
        <path
          d="M8.40088 2.00122C7.06571 4.81788 6.39813 7.63455 6.39813 10.4512C6.39813 13.2679 7.06571 16.0801 8.40088 18.8878"
          stroke="black"
          strokeWidth="0.96"
        />
      </g>
    </svg>
  );
};
export default BrandIcon;
