// 상품 식별 id
export const RV_PRODUCT_ID = "#productId";

// 상품명
export const RV_PRODUCT_NAME = "#productName";

// 상품 실판매가
export const RV_PRODUCT_SALES_PRICE = "#productSalesPrice";

// 기획전 상세 식별 id
export const RV_EVENT_ID = "#eventId";

// 위젯 / 기획전 타이틀
export const RV_HOME_DETAIL_TITLE = "#detailTitle";

// 로그인 타입 텍스트
export const RV_LOGIN_TYPE_TEXT = "#loginTypeText";

// IAMPORT 고유 거래 번호
export const RV_IAMPORT_UNIQUE_ID = "#impUid";

// 주무번호
export const RV_ORDER_ID = "#orderId";

// IAMPORT 결제처리 성공 여부
export const RV_IAMPORT_SUCCESS = "#impSuccess";
