import React from "react";

import { numberWithCommas } from "@amondz/utils";
import { HeartFilled } from "../../../icons/HeartFilled";
import { HeartLined } from "../../../icons/HeartLined";
import { BrandStyle, LikeButtonStyle, LikeCountStyle, PriceStyle, ProductStyle } from "./style";

export interface BrandProps {
  value: string;
}
export const BrandName = ({ value }: BrandProps): JSX.Element => <BrandStyle>{value}</BrandStyle>;

export interface ProductProps {
  value: string;
}
export const ProductName = ({ value }: ProductProps): JSX.Element => <ProductStyle>{value}</ProductStyle>;

export interface PriceProps {
  type?: "default" | "soldOut";
  size?: "regular" | "large";
  salesPrice: number;
  originPrice?: number;
  discountRate?: number;
  subText?: string;
}
export const PriceInfo = (props: PriceProps): JSX.Element => {
  const { type = "default", size = "regular", salesPrice, originPrice, discountRate, subText } = props;

  if (type === "soldOut") {
    return (
      <PriceStyle className="sold-out" size={size}>
        <span className="sales-price">{numberWithCommas(salesPrice)}원</span>
        {subText ? <span className="sub-text">({subText})</span> : null}
      </PriceStyle>
    );
  }
  return (
    <PriceStyle size={size}>
      {discountRate ? <span className="discount-rate">{discountRate}%</span> : null}
      <span className="sales-price">{numberWithCommas(salesPrice)}원</span>
      {originPrice ? <span className="origin-price">{numberWithCommas(originPrice)}원</span> : null}
      {subText ? <span className="sub-text">({subText})</span> : null}
    </PriceStyle>
  );
};

export interface LikeCountProps {
  count: number;
}

export const LikeCount = ({ count }: LikeCountProps): JSX.Element => (
  <LikeCountStyle>
    <span>좋아요</span>
    <span>{numberWithCommas(count)}</span>
  </LikeCountStyle>
);

export interface LikeButtonProps {
  isLike: boolean;
  onClick: (status: boolean) => void;
}
export const LikeButton = ({ isLike, onClick }: LikeButtonProps): JSX.Element => {
  const handleClick = (e: React.MouseEvent<HTMLDivElement>): void => {
    e.preventDefault();
    onClick(!isLike);
  };
  return <LikeButtonStyle onClick={handleClick}>{isLike ? <HeartFilled /> : <HeartLined />}</LikeButtonStyle>;
};
