import { all, fork } from "redux-saga/effects";
import { HYDRATE } from "next-redux-wrapper";
import { combineReducers } from "redux";

import { initialSearchState } from "@store/modules/search/reducer";
import cookies from "@lib/utility/cookies";
import { AMONDZ_LOGIN_TOKEN_KEY } from "@constants/service/auth/auth";
import base from "./base";
import auth, { authSaga } from "./auth";
import home, { homeSaga } from "./home";
import product, { productSaga } from "./product";
import mypage, { mypageSaga } from "./mypage";
import payment, { paymentSaga } from "./payment";
import search from "./search";
import { initialPaymentState } from "./payment/reducer";
import { initialMyPageState } from "./mypage/reducer";
import { initialBaseState } from "./base/reducer";
import { initialAuthState } from "./auth/reducer";
import { initialProductState } from "./product/reducer";

export const rootReducer = (state: any, action: any) => {
  switch (action.type) {
    case HYDRATE: {
      let nextState = {
        ...state, // 이전 상태
      };

      // 유저 인증을 서버에서 받아 올 경우
      nextState.auth = action.payload.auth;

      if (action.payload.product.productDetailState) {
        // 상품 상세 데이터를 서버에서 받아 올 경우
        nextState.product = {
          ...nextState.product,
          productDetailState: {
            ...action.payload.product.productDetailState,
          },
          productModalState: {
            ...action.payload.product.productModalState,
            orderReturnExchangeDeliveryInfoModal: {
              visible: false,
              data: null,
            },
            asInfoModal: {
              visible: false,
              data: null,
            },
          },
        };
      }

      if (action.payload.product.productReviewState) {
        // 상품 후기 상세 데이터를 서버에서 받아 올 경우
        nextState.product = {
          ...nextState.product,
          productReviewState: {
            ...action.payload.product.productReviewState,
          },
        };
      }

      if (action.payload.home.homeDetailState) {
        // 기회전 상세 데이터를 서버에서 받아 올 경우
        nextState.home = {
          ...nextState.home,
          homeDetailState: {
            ...action.payload.home.homeDetailState,
          },
        };
      }

      // 결제 결과를 서버에서 받아 올 경우
      if (action.payload.payment.paymentCheckoutState.orderPayResult) {
        nextState.payment = action.payload.payment;
      }

      // 회원 가입 중일 경우에는 브라우저의 회원 가입 정보를 유지 시킴
      if (state.auth.signUpState.data.id) {
        nextState.auth.signUpState = state.auth.signUpState;
      }

      // "SSR, CSR 에서 유저 정보 요청 시, 유저정보가 유효하지 않을 경우 강제 로그아웃 처리를 진행함"
      // CSR 에서 로그아웃을 할 경우, 상품 페이지를 reload 또는 상품 페이지로 이동을 하게 되는데,
      // 상품 페이지 SSR 에서 유저 정보를 확인 후 유효하지 않을 경우 isForceLogout 값을 true 로 넘겨주게 됨
      if (action.payload.auth.isForceLogout) {
        // 저장되어 있는 유저 인증 토큰 삭제
        // SSR 에서 api 요청 시 회원 정보가 유효하지 않을 경우도 있으므로 CLIENT 의 token 값을 삭제함
        cookies.remove(AMONDZ_LOGIN_TOKEN_KEY, { path: "/" });

        // 상태초기화
        nextState = {
          auth: initialAuthState,
          base: initialBaseState,
          product: initialProductState,
          payment: initialPaymentState,
          mypage: initialMyPageState,
          search: initialSearchState,
        };
      }
      return nextState;
    }
    default: {
      const combineReducer = combineReducers({
        base,
        auth,
        home,
        product,
        mypage,
        payment,
        search,
      });
      return combineReducer(state, action);
    }
  }
};

export type RootStateType = ReturnType<typeof rootReducer>;

export function* rootSaga() {
  yield all([fork(authSaga), fork(homeSaga), fork(productSaga), fork(mypageSaga), fork(paymentSaga)]);
}
