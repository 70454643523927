// 인트로 페이지 URL PATH
import { RV_IAMPORT_SUCCESS, RV_IAMPORT_UNIQUE_ID, RV_ORDER_ID } from "../common/replaceVariables";

// 메인 페이지 URL PATH
export const INTRO_PAGE_URL_PATH = "/";

// 홈 서브 페이지 URL PATH
export const HOME_SUB_PAGE_URL_PATH = "/home";

// 위젯 상세 페이지 URL PATH
export const WIDGET_PAGE_URL_PATH = "/widget";

// 기획전 상세 페이지 URL PATH
export const EVENT_PAGE_URL_PATH = "/event";

// [app] 기획전 상세 페이지 URL PATH
export const APP_EVENT_PAGE_URL_PATH = "/app/event";

// 로그인 페이지 URL PATH
export const SIGN_IN_PAGE_URL_PATH = "/auth/signin";

// 회원가입 페이지 URL PATH
export const SIGN_UP_PAGE_URL_PATH = "/auth/signup";

// 아디이 찾기 페이지 URL PATH
export const FIND_ID_PAGE_URL_PATH = "/auth/find/id";

// 비밀번호 찾기 페이지 URL PATH
export const FIND_PASSWORD_PAGE_URL_PATH = "/auth/find/password";

// 상품 리스트 페이지 URL PATH
export const PRODUCT_LIST_PAGE_URL_PATH = "/product/list";

// 상품 상세 페이지 URL PATH
export const PRODUCT_DETAIL_PAGE_URL_PATH = "/product";

// 후기 상세 페이지 URL PATH
export const REVIEW_DETAIL_PAGE_URL_PATH = "/product/review";

// 마이 페이지 URL PATH
export const MY_PAGE_URL_PATH = "/mypage";

// 마이 페이지 쿠폰 URL PATH
export const MY_PAGE_COUPON_URL_PATH = "/mypage/coupon";

// 쿠폰 적용상품 URL PATH
export const MY_PAGE_COUPON_PRODUCT_URL_PATH = "/mypage/coupon/[cid]/product";

// 쿠폰 적용브랜드 URL PATH
export const MY_PAGE_COUPON_BRAND_URL_PATH = "/mypage/coupon/[cid]/brand";

// 마이 페이지 포인트 URL PATH
export const MY_PAGE_POINT_URL_PATH = "/mypage/point";

// 마이 페이지 주문 정보 URL PATH
export const MY_PAGE_ORDER_INFO_URL_PATH = "/mypage/order/list";

// 마이 페이지 주문 상세 URL PATH
export const MY_PAGE_ORDER_DETAIL_URL_PATH = "/mypage/order";

// 마이 페이지 주문 취소 URL PATH
export const MY_PAGE_ORDER_CANCEL_URL_PATH = "/mypage/order/cancel";

// 마이 페이지 주문 취소 요청 URL PATH
export const MY_PAGE_ORDER_CANCEL_REQ_URL_PATH = "/mypage/order/cancel/req";

// 마이 페이지 주문 반품 URL PATH
export const MY_PAGE_ORDER_RETURN_URL_PATH = "/mypage/order/return";

// 마이 페이지 주문 교환 URL PATH
export const MY_PAGE_ORDER_EXCHANGE_URL_PATH = "/mypage/order/exchange";

// 마이 페이지 상품 좋아요 URL PATH
export const MY_PAGE_LIKE_PRODUCT_URL_PATH = "/mypage/like/product";

// 마이 페이지 브랜드 좋아요 URL PATH
export const MY_PAGE_LIKE_BRAND_URL_PATH = "/mypage/like/brand";

// 마이 페이지 후기 URL PATH
export const MY_PAGE_REVIEW_URL_PATH = "/mypage/review";

// 마이 페이지 후기 작성 URL PATH
export const MY_PAGE_REVIEW_WRITE_URL_PATH = "/mypage/review/write";

// 마이 페이지 상품 문의 URL PATH
export const MY_PAGE_QNA_PRODUCT_URL_PATH = "/mypage/qna/product";

// 마이 페이지 자주 묻는 질문 URL PATH(노션링크로 연결)
export const MY_PAGE_FAQ_URL_PATH = "https://amondz.notion.site/FAQ-6714164d224d4546ab145759d4f520e5";

// 마이 페이지 아몬즈에 문의하기 URL PATH
export const MY_PAGE_QNA_URL_PATH = "/mypage/qna/amondz";

// 마이 페이지 회원정보수정 URL PATH
export const MY_PAGE_MANAGE_URL_PATH = "/mypage/manage";

// 장바구니 페이지 URL PATH
export const PAYMENT_CART_URL_PATH = "/payment/cart";

// 결제하기 페이지 URL PATH
export const PAYMENT_CHECKOUT_URL_PATH = "/payment/checkout";

// 주문완료 페이지 URL PATH
export const PAYMENT_COMPLETION_URL_PATH = `/payment/complete?imp_uid=${RV_IAMPORT_UNIQUE_ID}&merchant_uid=${RV_ORDER_ID}&imp_success=${RV_IAMPORT_SUCCESS}`;

// 선물하기 배송지 입력 페이지 URL PATH(Token 제외)
export const GIFT_DELIVERY_URL_PATH = "/gift/delivery";

// 브랜드 페이지 URL PATH
export const BRAND_PAGE_URL_PATH = "/brand";

// 브랜드 리스트 페이지 URL PATH
export const BRAND_LIST_PAGE_URL_PATH = "/brand/list";

// 검색 결과 페이지 URL PATH
export const SEARCH_RESULT_PAGE_URL_PATH = "/search";
