import { superFetchV2 } from "./superFetchV2";
export { luckyDrawApiV2 } from "./lucky-draw";
export { couponApiV2 } from "./coupon";
export { productApiV2 } from "./product";
export { reviewApiV2 } from "./review";

export default superFetchV2;

export * from "./brand";
export * from "./product";
export * from "./types";
export * from "./event";
export * from "./home";
export * from "./mypage";
