import { startOfDay, differenceInDays } from "date-fns";

/**
 * D-day 계산하기
 *
 * @param {Date} startDate 특정 포맷으로 변경할 시작 날짜 데이터
 * @param {Date} endDate 특정 포맷으로 변경할 종료 날짜 데이터
 *
 * @returns {number} 포맷이 설정된 날짜 문자열
 */
export const getDifferenceInDays = (startDate: Date, endDate: Date):number => {
  const start = startOfDay(startDate);
  const end = startOfDay(endDate);

  return differenceInDays(start, end);
};
