import qs from "qs";
import { ProductQnaListResponseType } from "@amondz/types";
import {
  ProductDownloadableCouponsResponseDto,
  ProductPriceBenefitsResponseDto,
  LikeProductResponseType,
  ProductQnasRequestParamType,
  ProductDetailsResponseType,
  GetReviewListResponseType,
  ProductReviewListRequestType
} from "@amondz/apis-v2";
import { superFetchV2 } from "../superFetchV2";
import { ProductLikeListRequest, LikeProductListStateType, DeliveryInfoResponseType } from "./type";

export const productApiV2 = {
  likeProductList: ({ pageNumber, pageSize }: ProductLikeListRequest): Promise<LikeProductListStateType> =>
    superFetchV2.get(`/like-products?page=${pageNumber}&pageSize=${pageSize}`),
  // 상품 좋아요 요청 api
  likeProduct: (productId: number):  Promise<LikeProductResponseType> => superFetchV2.put(`/like-products/${productId}`),
  // 상품 좋아요 해제 요청 api
  unLikeProduct: (productId: number): Promise<LikeProductResponseType> => superFetchV2.delete(`/like-products/${productId}`),
  // 상품 연결 쿠폰 리스트 요청 api
  getCouponList: (productId: number): Promise<ProductDownloadableCouponsResponseDto> => superFetchV2.get(`/products/${productId}/downloadable-coupons`),
  // 상품 판매 가격 혜택 조회 api
  getPriceBenefits: (productId: number): Promise<ProductPriceBenefitsResponseDto> => superFetchV2.get(`/products/${productId}/price-benefits`),
  // 상품 상세 데이터 요청 API
  getProductDeliveryInfo: (productId: number): Promise<DeliveryInfoResponseType> => superFetchV2.get(`/products/${productId}/delivery-informations`),
  // 상품 문의 목록 조회 요청 api
  getProductQnaList: (payload: ProductQnasRequestParamType): Promise<ProductQnaListResponseType> => {
    const { productId, ...rest } = payload;
    const queryParams = qs.stringify({ ...rest }, { skipNulls: true });
    return superFetchV2.get(`/products/${productId}/qnas?${queryParams}`)
  },
  // 상품 기본 정보 조회 요청 api
  getProductDetail: (productId: number): Promise<ProductDetailsResponseType> => superFetchV2.get(`/products/${productId}/`),
  // 상품 후기 리스트 요청 API
  getProductReviewList: async (payload: ProductReviewListRequestType): Promise<GetReviewListResponseType> => {
    const{ productId, params} = payload;
    const queryParams = qs.stringify({...params}, { skipNulls: true });
    return superFetchV2.get(`/products/${productId}/reviews?${queryParams}`)
  },
};
