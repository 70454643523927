import { GetReviewListResponseType } from "@amondz/types";
import qs from "query-string";

import superFetch from "@amondz/apis-v1";
import superFetchV2 from "@amondz/apis-v2";
import {
  GetProductDetailRequestType,
  ProductDetailStateType,
  GetProductListRequestType,
  InsertProductQnaRequestType,
  GetProductDetailAdditionalSectionRequestType,
  ShareProductDetailLinkRequestType,
} from "@store/modules/product";
import { IGetCategoryTreeRequest, IGetCategoryTreeResponse } from "@swr/types/productTypes";
import { DeliveryInfoResponseType } from "@views/product/productDetail/common/DeliveryInfo/types/deliveryInfoType";
import { ReviewIdType } from "@store/modules/mypage";
import { ProductListKey } from "@swr/keys/productKeys";

const productAPI = {
  // 신규 상품 카테고리 트리 요청 API
  getCategoryTree: async (data?: IGetCategoryTreeRequest): Promise<IGetCategoryTreeResponse> => {
    return superFetchV2.get(
      qs.stringifyUrl({
        url: "/categories-tree",
        query: {
          ...data,
          isExhibit: data?.isExhibit || true,
          exposureType: data?.exposureType,
          sort: data?.sort || "type,no",
        },
      }),
    );
  },

  // 상품 카테고리 트리 요청 API
  getProductCategoryTree: async () => {
    return superFetch.post("/product/category/tree/web/v1");
  },

  // 상품 리스트 요청 API
  getProductList: async (data: GetProductListRequestType) => {
    return superFetch.post(ProductListKey, data);
  },

  // 브랜드 필터 트리 요청 API
  getBrandFilterTree: async (data: GetProductListRequestType) => {
    return superFetch.post("/product/filter/brand/tree/web/v1", data);
  },

  // 상품 상세 데이터 요청 API
  getProductDetail: async (data: GetProductDetailRequestType) => {
    return superFetch.post<ProductDetailStateType>("/product/detail/first/web/v3", data);
  },

  // 상품 상세 데이터 요청 API
  getProductDeliveryInfo: async (productId: number): Promise<DeliveryInfoResponseType> => {
    //@ts-ignore
    return superFetchV2.get<DeliveryInfoResponseType>(`/products/${productId}/delivery-informations`);
  },

  // 상품 장바구니 등록 요청 API
  insertProductCart: async (data: any) => {
    return superFetch.post<any>("/cart/insert/normal/web/v1", data);
  },

  // 상품 옵션 장바구니 등록 요청 API
  insertProductOptionCart: async (data: any) => {
    return superFetch.post<any>("/cart/insert/option/web/v1", data);
  },

  // 상품 후기 리스트 요청 API
  getProductReviewList: async (queryString: string) => {
    return superFetchV2.get<GetReviewListResponseType>(queryString);
  },

  // 상품 후기 상세 API
  getProductReviewDetail: async (data: ReviewIdType) => {
    return superFetch.post<ReviewIdType>("/review/detail/web/v1", data);
  },

  // 상품 후기 작성 API
  insertProductReview: async (data: FormData) => {
    return superFetch.post("/mypage/review/insert/web/v1", data);
  },

  // 상품 후기 작성 체크 API
  checkProductReview: async (data: { productId: number }) => {
    return superFetch.post("/product/review/check/web/v1", data);
  },

  // 상품 문의 리스트 요청 API
  getProductQnaList: async (data: GetProductDetailAdditionalSectionRequestType) => {
    return superFetch.post<GetProductDetailAdditionalSectionRequestType>("/product/qna/list/pagination/web/v1", data);
  },

  // 상품 문의 등록 요청 API
  insertProductQna: async (data: InsertProductQnaRequestType) => {
    return superFetch.post<InsertProductQnaRequestType>("/product/qna/insert/web/v2", data);
  },

  // 상품 상세 링크 공유 API
  shareProductDetailLink: async (data: ShareProductDetailLinkRequestType) => {
    return superFetch.post<ShareProductDetailLinkRequestType>("/share/product/web/v1", data);
  },

  // A/S 정보 요청 API
  getAsInfo: async (data: GetProductDetailRequestType) => {
    return superFetch.post<ProductDetailStateType>("/product/detail/inform/web/v1", data);
  },
};

export default productAPI;
