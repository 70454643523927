function addCustomAttribute(key: string, value: string) {
  if (window?.appboy) {
    window.appboy.getUser().addToCustomAttributeArray(key, value);
  }
}
function removeCustomAttribute(key: string, value: string) {
  if (window?.appboy) {
    window.appboy.getUser().removeFromCustomAttributeArray(key, value);
  }
}
function setCustomAttributeList(key: string, value: string[]) {
  if (window?.appboy) {
    window.appboy.getUser().setCustomUserAttribute(key, value);
  }
}

/**
 * 브레이즈 setEmailNotificationSubscriptionType 설정
 * - 회원가입 시 승인 유무 전달
 * @param state - 동의 여부
 */
export const setEmailNotificationSubscriptionType = (state: boolean) => {
  if (window?.appboy) {
    window.appboy.getUser().setEmailNotificationSubscriptionType(state ? "opted_in" : "unsubscribed");
  }
};

/**
 * 브레이즈 setFirstName 설정
 * - 로그인(SNS or Email) 시 이름 전달 *이름 없는 경우 빈값
 * - 회원가입 시 이름 전달
 * - 회원정보 수정 시 이름 전달
 * - 포인트 진입 시 이름 전달
 * - 장바구니 진입 시 이름 전달
 * @param name
 */
export const setFirstName = (name: string) => {
  if (window?.appboy) {
    window.appboy.getUser().setFirstName(name);
  }
};

/**
 * product_wishlist, product_wishlist_by_name - CA array 에 추가
 * - 기획전 내 상품 좋아요
 * - 브랜드 상세 내 상품 좋아요
 * - 쿠폰 적용 가능 상품 내 상품 좋아요
 * - 상품상세 내 상품 좋아요
 * - 상품상세>대표이미지 확대 좋아요
 * - 홈 위젯 내 상품 좋아요
 * - 위젯 상세 내 상품 좋아요
 * - 검색>상품 탭>상품리스트 좋아요
 * - 좋아요 리스트 내 상품 좋아요
 * @param id
 * @param name
 */
export function addProductWishlist(id: string, name: string) {
  addCustomAttribute("product_wishlist", id);
  addCustomAttribute("product_wishlist_by_name", name);
}
/**
 * product_wishlist, product_wishlist_by_name - CA array 에 제거
 * - 기획전 내 상품 좋아요 해제
 * - 브랜드 상세 내 상품 좋아요 해제
 * - 쿠폰 적용 가능 상품 내 상품 좋아요 해제
 * - 상품상세 내 상품 좋아요 해제
 * - 상품상세>대표이미지 확대 좋아요 해제
 * - 홈 위젯 내 상품 좋아요 해제
 * - 위젯 상세 내 상품 좋아요 해제
 * - 검색>상품 탭>상품리스트 좋아요 해제
 * - 좋아요 리스트 내 상품 좋아요 해제
 * @param id
 * @param name
 */
export function removeProductWishlist(id: string, name: string) {
  removeCustomAttribute("product_wishlist", id);
  removeCustomAttribute("product_wishlist_by_name", name);
}
/**
 * product_wishlist, product_wishlist_by_name - CA array 에 set
 * - 좋아요 리스트>상품 탭 활성 시 현재 좋아요 리스트 호출 (좋아요 리스트가 없을때는 unset)
 * @param id
 * @param name
 */
export function setProductWishlist(id: string[], name: string[]) {
  setCustomAttributeList("product_wishlist", id);
  setCustomAttributeList("product_wishlist_by_name", name);
}

/**
 * brand_wishlist, brand_wishlist_by_name - CA array 에 추가
 * - 브랜드 상세 좋아요
 * - 브랜드 리스트 좋아요
 * - 상품 상세 하단 좋아요
 * - 좋아요 리스트>브랜드 탭 좋아요
 * @param id
 * @param name
 */
export function addBrandWishlist(id: string, name: string) {
  addCustomAttribute("brand_wishlist", id);
  addCustomAttribute("brand_wishlist_by_name", name);
}
/**
 * brand_wishlist, brand_wishlist_by_name - CA array 에 제거
 * - 브랜드 상세 좋아요 해제
 * - 브랜드 리스트 좋아요 해제
 * - 상품 상세 하단 좋아요 해제
 * - 좋아요 리스트>브랜드 탭 좋아요 해제
 * @param id
 * @param name
 */
export function removeBrandWishlist(id: string, name: string) {
  removeCustomAttribute("brand_wishlist", id);
  removeCustomAttribute("brand_wishlist_by_name", name);
}
/**
 * brand_wishlist, brand_wishlist_by_name - CA array 에 set
 * - 좋아요 리스트>브랜드 탭 활성 시 현재 좋아요 리스트 호출 (좋아요 리스트가 없을때는 unset)
 * @param id
 * @param name
 */
export function setBrandWishlist(id: string[], name: string[]) {
  setCustomAttributeList("brand_wishlist", id);
  setCustomAttributeList("brand_wishlist_by_name", name);
}

/**
 * cart_list_by_name, cart_list_by_name_by_name - CA array 에 추가
 * - 장바구니>구매옵션>장바구니 담기 리스트에 추가
 * @param id
 * @param name
 */
export function addCartList(id: string, name: string) {
  addCustomAttribute("cart_list", id);
  addCustomAttribute("cart_list_by_name", name);
}
/**
 * cart_list_by_name, cart_list_by_name_by_name - CA array 에 제거
 * - 장바구니>결제하기(결제완료상태)시 장바구니 리스트에서 제거
 * @param id
 * @param name
 */
export function removeCartList(id: string, name: string) {
  removeCustomAttribute("cart_list", id);
  removeCustomAttribute("cart_list_by_name", name);
}

/**
 * coupon_list - CA array 에 제거
 * - 원링크/딥링크/쿠폰페이지 쿠폰발급 시 리스트 추가
 * @param id
 */
export function addCouponList(id: string) {
  addCustomAttribute("coupon_list", id);
}
/**
 * coupon_list - CA array 에 제거
 * - 결제하기>쿠폰 사용 결제완료 시 리스트 삭제
 * @param id
 */
export function removeCouponList(id: string) {
  removeCustomAttribute("coupon_list", id);
}
/**
 * coupon_list - CA array 에 set
 * - 마이>쿠폰 진입 시 쿠폰리스트 전달
 * @param id
 */
export function setCouponList(id: string[]) {
  setCustomAttributeList("coupon_list", id);
}

/**
 * 브레이즈 setCustomUserAttribute 설정 (key: search_word_list)
 * - 검색 결과 전달 시 리스트 추가
 * @param id
 */
export const addSearchWordList = (id: string) => {
  if (window?.appboy) {
    window.appboy.getUser().addToCustomAttributeArray("search_word_list", id);
  }
};

const brazeAttribute = {
  setEmailNotificationSubscriptionType,
  setFirstName,
  addProductWishlist,
  removeProductWishlist,
  setProductWishlist,
  addBrandWishlist,
  removeBrandWishlist,
  setBrandWishlist,
  addCartList,
  removeCartList,
  addCouponList,
  removeCouponList,
  setCouponList,
  addSearchWordList,
};
export default brazeAttribute;
