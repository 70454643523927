import { useRef, useEffect } from "react";
import { Fetcher, Key, SWRConfiguration, SWRHook } from "swr";

import superFetch from "@amondz/apis-v1";

// Fetcher 리턴 타입
type FetcherReturnType<T> = {
  status: number;
  data: T;
};

// 모든 SWR hook에 대한 전역 설정
export const SWRConfigOptions = {
  /**
   * SWR 공통 기본 Fetcher
   * @param args Request Key 배열(url, payload 객체 배열)
   */
  fetcher: async (...args: any): Promise<FetcherReturnType<any>> => {
    const url = args[0];
    const data = args[1];
    return superFetch.post(url, data).then((res) => res);
  },

  // SWR 공통 기본 Options
  // 창이 포커싱 되었을 때 자동 갱신 여부
  revalidateOnFocus: false,

  // SWR Middleware
  // 실행 순서 : swrApiHelper => laggy
  // 배열에 넣은 순서대로 동작하지 않는 이유 ?
  // laggy middleware에서 useSWRNext를 실행했을 때 다음 Middleware(swrApiHelper)로 넘어간 후 리턴값을 가져와 다시 동작하기 때문
  use: [laggy],
};

/**
 * 키가 변경되더라도 새로운 데이터를 불러오기 전까지 데이터를 캐싱하여 유지하기 위한 SWR 미들웨어
 * @param useSWRNext 다음 미들웨어를 처리하거나, 마지막인 경우 `useSWR` hook
 */
function laggy(useSWRNext: SWRHook) {
  return (key: Key, fetcher: Fetcher<any> | null, config: SWRConfiguration) => {
    // 이전에 반환된 데이터를 저장하기 위해 ref를 사용
    const laggyDataRef = useRef();

    // 실제 SWR hook.
    const swr = useSWRNext(key, fetcher, config);

    useEffect(() => {
      // 데이터가 undefined가 아니면 ref를 업데이트
      if (swr.data !== undefined) {
        laggyDataRef.current = swr.data;
      }
    }, [swr.data]);

    // 현재 데이터가 undefined인 경우에 이전 데이터로 폴백
    const dataOrLaggyData = swr.data === undefined ? laggyDataRef.current : swr.data;

    // `isLagging` 필드 또한 SWR에 추가
    return Object.assign({}, swr, {
      data: dataOrLaggyData,
    });
  };
}
