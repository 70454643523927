import { eventType, eventValue } from "./type";
import { zaiEventChannel } from "./zai";
import { gtmEventChannel } from "./gtm";
import { brazeCAEventChannel } from "./braze-ca";

/**
 * 마켓팅 툴 관련 이벤트가 발생했을 때, apps 에서 발생한 이벤트로부터 eventType 에 맞는 각 마켓팅 툴 이벤트로 매핑하여 호출시켜주는 중간 서비스 레이어
 * @param eventType - apps 에서 발생하는 이벤트의 타입
 * @param eventValue - 발생한 이벤트에 대한 정보
 */
function service<T extends eventType>(eventType: T, eventValue: eventValue[T]): void {
  zaiEventChannel(eventType, eventValue);
  gtmEventChannel(eventType, eventValue);
  brazeCAEventChannel(eventType, eventValue);
}

export default service;
