import { GetReviewListOptionsType } from "@amondz/types";

// 상품 상태 리스트
export const PRODUCT_STATUS_V1 = {
  // 승인대기
  CONFIRM_READ: 0,
  // 승인 거절
  CONFIRM_REJECT: 1,
  // 판매중
  NOW_ON_SALE: 2,
  // 품절
  SOLD_OUT: 3,
  // 판매종료
  SALES_CLOSE: 4,
  // 판매금지
  SALES_PROHIBIT: 5,
  // 판매대기
  SALES_READY: 6,
  // 판매중지
  SALES_STOP: 7,
  // 삭제
  DELETE: 8,
} as const;

export const PRODUCT_STATUS = {
  NOW_ON_SALE: "nowOnSale",
  SOLD_OUT: "soldOut",
} as const;
export type PRODUCT_STATUS_TYPE = (typeof PRODUCT_STATUS)[keyof typeof PRODUCT_STATUS];

export const TODAY_DELIVERY_FILTER = {
  // 전체
  ENTIRE: 0,
  // 오늘출발
  TODAY_DELIVERY: 1,
} as const;
export type TODAY_DELIVERY_FILTER_TYPE = (typeof TODAY_DELIVERY_FILTER)[keyof typeof TODAY_DELIVERY_FILTER];

export const GIFT_FILTER = {
  // 전체
  ENTIRE: 0,
  // 선물하기
  GIFT: 1,
} as const;
export type GIFT_FILTER_TYPE = (typeof GIFT_FILTER)[keyof typeof GIFT_FILTER];

export const INITIAL_GET_REVIEW_LIST_OPTIONS: GetReviewListOptionsType = {
  page: 1,
  pageSize: 5,
  sort: "-rate,-createDate",
};

export const REVIEW_PAGE_TYPE = {
  ALL: 0,
  ONLY_PHOTO: 1,
} as const;

export enum PRODUCT_DETAIL_DELIVERY_TYPE {
  NORMAL, // 일반 배송
  ORDER_MADE, // 주문 시 제작
  IMMEDIATELY, // 바로 배송
  TODAY_DELIVERY, // 오늘출발
}

export enum PRODUCT_SORT_TYPE {
  RECOMMENDATION = "recommendation",
  POPULAR = "popular",
  LATEST = "latest",
  LOW_PRICE = "lowPrice",
  HIGH_PRICE = "highPrice",
}

// 상품 정렬 타입 공통 텍스트
export const PRODUCT_SORT_TYPE_TEXT = {
  [PRODUCT_SORT_TYPE.RECOMMENDATION]: "추천순",
  [PRODUCT_SORT_TYPE.POPULAR]: "인기순",
  [PRODUCT_SORT_TYPE.LATEST]: "신상품순",
  [PRODUCT_SORT_TYPE.LOW_PRICE]: "낮은가격순",
  [PRODUCT_SORT_TYPE.HIGH_PRICE]: "높은가격순",
} as const;

export const PRODUCT_SORT_TYPE_LIST = [
  {
    id: PRODUCT_SORT_TYPE.RECOMMENDATION,
    name: PRODUCT_SORT_TYPE_TEXT[PRODUCT_SORT_TYPE.RECOMMENDATION],
  },
  {
    id: PRODUCT_SORT_TYPE.POPULAR,
    name: PRODUCT_SORT_TYPE_TEXT[PRODUCT_SORT_TYPE.POPULAR],
  },
  {
    id: PRODUCT_SORT_TYPE.LATEST,
    name: PRODUCT_SORT_TYPE_TEXT[PRODUCT_SORT_TYPE.LATEST],
  },
  {
    id: PRODUCT_SORT_TYPE.LOW_PRICE,
    name: PRODUCT_SORT_TYPE_TEXT[PRODUCT_SORT_TYPE.LOW_PRICE],
  },
  {
    id: PRODUCT_SORT_TYPE.HIGH_PRICE,
    name: PRODUCT_SORT_TYPE_TEXT[PRODUCT_SORT_TYPE.HIGH_PRICE],
  },
];

export const MINIMUM_VALUE_OF_PRICE_FILTER = 2000;
export const MAXIMUM_VALUE_OF_PRICE_FILTER = 9999999;

export const PRODUCT_LIST_PAGE_SIZE = 60;
export const PRODUCT_QNAS_PAGE_SIZE = 10;
export const PRODUCT_REVIEWS_PER_PAGE = 5;
export const PRODUCT_REVIEWS_PAGE_INIT = 1;
export const PRODUCT_REVIEWS_INFINITY_PAGE_SIZE = 20;

// 프로덕트 디테일 페이지에서 리뷰 탭의 인덱스
export const PRODUCT_DETAIL_TAB_REVIEW = "1";

// 사진 후기 모아보기 갤러리 페이지 초기 요청 파라미터
export const REVIEWS_GALLERY_PAGE_INIT_PARAMS = {
  isPhotoReview: true,
  pageSize: 30,
  page: 1,
  sort: "-rate,-createDate",
};

export const REVIEW_SORT_SELECTOR_TYPE = {
  HIGH_RATE: "highRate",
  LOW_RATE: "lowRate",
  LATEST: "latest",
} as const;
// 후기 정렬 리스트
export const REVIEW_SORT_SELECTOR_LIST = [
  { id: REVIEW_SORT_SELECTOR_TYPE.HIGH_RATE, name: "평점 높은 순" },
  { id: REVIEW_SORT_SELECTOR_TYPE.LOW_RATE, name: "평점 낮은 순" },
  { id: REVIEW_SORT_SELECTOR_TYPE.LATEST, name: "최신순" },
];
// 리뷰 페이지 인피니티 스크롤 교차관찰자 수치
export const INFINITY_SCROLL_THRESHOLD = 0.7;

// 상품 상세 페이지 스크롤 탭 높이
export const PRODUCT_DETAIL_SECTION_MARGIN = 73;
