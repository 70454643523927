import { useRouter } from "next/router";
import { ERROR_TYPE, ErrorHandlerType } from "@amondz/types";
import { useOverlay } from "@amondz/use-overlay";
import NotifyToast from "@components/base/toast/NotifyToastV2";
import { NOTIFY_TOAST_TYPE } from "@constants/enum/baseEnums";
import OneButtonModal from "@components/common/Modals/OneButtonModal";
import TwoButtonModal from "@components/common/Modals/TwoButtonModal";
import { ErrorType } from "@store/modules/common/types";
import { MY_PAGE_REVIEW_URL_PATH } from "@constants/url/internalUrlConstants";

//해당 에러 핸들러는 SWRConfig의 onError와 useMutation에서 실행 -> 이후 컴포넌트 에러 처리
export const useGlobalErrorHandler = (customErrorHandlers: { [errorCode: string]: ErrorHandlerType } = {}) => {
  const router = useRouter();
  const normalToastOverlay = useOverlay();
  const errorToastOverlay = useOverlay();
  const oneButtonModalOverlay = useOverlay();
  const twoButtonModalOverlay = useOverlay();

  const PRODUCT_ERROR: { [errorCode: string]: ErrorHandlerType } = {
    PRODUCT001: { type: ERROR_TYPE.ERROR_TOAST, msg: "유효하지 않은 상품 ID 입니다." },
    PRODUCT002: { type: ERROR_TYPE["404"] },
    PRODUCTREVIEW101: {
      type: ERROR_TYPE.ONE_BUTTON_MODAL,
      msg: "정보를 불러오던 중 오류가 발생했습니다.",
      btnText: "확인",
    },
    PRODUCTREVIEW102: {
      type: ERROR_TYPE.ONE_BUTTON_MODAL,
      msg: "정보를 불러오던 중 오류가 발생했습니다.",
      btnText: "확인",
    },
    LIKESTORE003: {
      type: ERROR_TYPE.ONE_BUTTON_MODAL,
      msg: "오류가 발생했습니다. 이전 페이지로 이동합니다.",
      btnText: "확인",
      onClickBtn: () => router.back(),
    },
    LIKESTORE004: {
      type: ERROR_TYPE.ONE_BUTTON_MODAL,
      msg: "오류가 발생했습니다. 이전 페이지로 이동합니다.",
      btnText: "확인",
      onClickBtn: () => router.back(),
    },
    LIKEPRODUCT003: {
      type: ERROR_TYPE.ONE_BUTTON_MODAL,
      msg: "오류가 발생했습니다. 이전 페이지로 이동합니다.",
      btnText: "확인",
      onClickBtn: () => router.back(),
    },
    LIKEPRODUCT004: {
      type: ERROR_TYPE.ONE_BUTTON_MODAL,
      msg: "오류가 발생했습니다. 이전 페이지로 이동합니다.",
      btnText: "확인",
      onClickBtn: () => router.back(),
    },
  };

  const REVIEW_ERROR: { [errorCode: string]: ErrorHandlerType } = {
    REVIEW001: { type: ERROR_TYPE.ONE_BUTTON_MODAL, msg: "일시적인 오류가 발생했습니다.", btnText: "확인" },
    REVIEW002: { type: ERROR_TYPE.ONE_BUTTON_MODAL, msg: "일시적인 오류가 발생했습니다.", btnText: "확인" },
    REVIEW011: {
      type: ERROR_TYPE.ONE_BUTTON_MODAL,
      msg: "일시적인 오류가 발생했습니다. 다시 시도해 주세요.",
      btnText: "확인",
      onClickBtn: () => router.push(MY_PAGE_REVIEW_URL_PATH),
    },
    REVIEW012: {
      type: ERROR_TYPE.ONE_BUTTON_MODAL,
      msg: "후기 작성이 불가능한 상품입니다. 마이페이지로 이동합니다.",
      btnText: "확인",
      onClickBtn: () => router.push(MY_PAGE_REVIEW_URL_PATH),
    },
  };

  const handleCommonEventError = () => {
    const { replace, pathname, query } = router;
    const { eid } = query;
    return replace(
      {
        pathname,
        query: { eid },
      },
      undefined,
      { shallow: true },
    );
  };
  const EVENT_ERROR: { [errorCode: string]: ErrorHandlerType } = {
    EVENTPRODUCT002: handleCommonEventError,
    EVENTPRODUCT003: handleCommonEventError,
    EVENTPRODUCT004: handleCommonEventError,
    EVENTPRODUCT005: handleCommonEventError,
    EVENTPRODUCT006: handleCommonEventError,
    EVENTPRODUCT007: handleCommonEventError,
    EVENTPRODUCT008: handleCommonEventError,
    EVENTPRODUCT009: handleCommonEventError,
    EVENTPRODUCT010: handleCommonEventError,
  };

  const ERROR_MAP = { ...PRODUCT_ERROR, ...REVIEW_ERROR, ...EVENT_ERROR, ...customErrorHandlers };

  return (error: unknown) => {
    //타입이 unknown 경우 response가 존재하지 않고 이 경우 타입스크립트에서 error.response로 접근조차 막기 때문에 강제로 ErrorType으로 선언
    // 서버의 에러코드에 모든 프로퍼티 값이 있다는 보장이 없기 때문에 각 프로퍼티 별로 옵셔널 처리
    const errorCode = (error as ErrorType)?.response?.data?.errors?.[0]?.code;

    const errorHandler = errorCode && ERROR_MAP[errorCode];
    if (!errorHandler) return;

    if (typeof errorHandler === "function") {
      errorHandler();
      return;
    }
    switch (errorHandler.type) {
      case ERROR_TYPE["404"]:
        router.push("/404");
        break;
      case ERROR_TYPE.ERROR_TOAST:
        errorToastOverlay.open(({ isOpen, close }) => {
          return <NotifyToast type={NOTIFY_TOAST_TYPE.ERROR} visible={isOpen} onClose={close} msg={errorHandler.msg} />;
        });
        break;
      case ERROR_TYPE.NORMAL_TOAST:
        normalToastOverlay.open(({ isOpen, close }) => {
          return <NotifyToast type={NOTIFY_TOAST_TYPE.INFO} visible={isOpen} onClose={close} msg={errorHandler.msg} />;
        });
        break;
      case ERROR_TYPE.ONE_BUTTON_MODAL:
        oneButtonModalOverlay.open(({ isOpen, close }) => {
          return (
            <OneButtonModal
              onCloseBtn={close}
              onClickBtn={errorHandler.onClickBtn || close}
              visible={isOpen}
              msg={errorHandler.msg}
              subMsg={errorHandler.subMsg}
              btnText={errorHandler.btnText}
            />
          );
        });
        break;
      case ERROR_TYPE.TWO_BUTTON_MODAL:
        twoButtonModalOverlay.open(({ isOpen, close }) => {
          return (
            <TwoButtonModal
              onCloseBtn={close}
              onClickLeftBtn={errorHandler.onClickLeftBtn}
              onClickRightBtn={errorHandler.onClickRightBtn}
              leftButtonText={errorHandler.leftButtonText}
              rightButtonText={errorHandler.rightButtonText}
              visible={isOpen}
              msg={errorHandler.msg}
              subMsg={errorHandler.subMsg}
            />
          );
        });
        break;
    }
  };
};
